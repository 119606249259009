import { useQuery } from "react-query";
import {
  GetProductsById,
  GetProductsClasfication,
  GetProducts,
  GetProductsStructure,
  GetMeasures,
  SaveProductsStructure,
  SaveNewProduct,
  GetStockProduct,
  GetListprovider,
  GetListUbicationProveider,
  GetListStockState,
  GetListMotive,
  GetListStockAudi,
  GetListDeposito,
  GetProcesos,
  GetProductsEntidadById,
  GetImgProducts,
  GetProcesosTipoId,
  GetTypeProduct,
  GetStockProductVerdadero,
} from "../services/productServices";
import { ordenarArrayConParametros, OrderGen } from "../utils/orderGen";

export const useProducts = (soloActivos = false, sku_tipo_id = null) => {
  const { data, isLoading, isError, isSuccess, isIdle, isFetching, refetch } =
    useQuery("products", async () => {
      const response = await GetProducts(sku_tipo_id);

      if (soloActivos) {
        return response.data.filter((item) => item.skuActivo === "1");
      }
      return response.data;
    });

  return {
    data: data,
    isLoading,
    isSuccess,
    isIdle,
    isFetching,
    refetch,
    isError,
  };
};
export const useTypeProduct = () => {
  const { data, isLoading, isError, isSuccess, isIdle, isFetching, refetch } =
    useQuery("useTypeProduct", async () => {
      const response = await GetTypeProduct();

      return response.data;
    });

  return {
    data: data,
    isLoading,
    isSuccess,
    isIdle,
    isFetching,
    refetch,
    isError,
  };
};
export const useImgProducts = (id) => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["imgProductId", id],
    () => GetImgProducts(id)
  );

  return {
    data: data?.data,
    isLoading,
    refetch,
    isError,
  };
};
export const useProductsById = (id) => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["productId", id],
    async () => {
      const response = await GetProductsById(id);
      const filtrarClasicacion = response.data.clasificacion.filter(
        (item) => item.categoriaSuperior !== ""
      );
      const data = {
        ...response.data,
        skuCostoUnitario:
          Number(response.data.skuCostoUnitario) > 0
            ? response.data.skuCostoUnitario
            : 0,
        skuLeadtimeProduccionHoras:
          Number(response.data.skuLeadtimeProduccionHoras) > 0
            ? response.data.skuLeadtimeProduccionHoras
            : 0,
        skuMbq: Number(response.data.skuMbq) > 0 ? response.data.skuMbq : 0,
        skuStockSeguridad:
          Number(response.data.skuStockSeguridad) > 0
            ? response.data.skuStockSeguridad
            : 0,
        skuLustre: filtrarClasicacion.find(
          (item) => item.categoriaSuperior === "lustre"
        )?.id,
        skuTipologia: filtrarClasicacion.find(
          (item) => item.categoriaSuperior === "tipologia"
        )?.id,
        skuColor: filtrarClasicacion.find(
          (item) => item.categoriaSuperior === "color"
        )?.id,
        skuTela: filtrarClasicacion.find(
          (item) => item.categoriaSuperior === "tela"
        )?.id,
      };

      return data;
    }
  );

  return {
    data: data,
    isLoading,
    refetch,
    isError,
  };
};
export const useProductsEntidadById = (id = "0") => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["productEntidadId", id],
    () => GetProductsEntidadById(id)
  );
  return {
    data: data?.data,
    isLoading,
    refetch,
    isError,
  };
};
export const useClasification = () => {
  const { data, isLoading, isError } = useQuery(
    "clasification",
    GetProductsClasfication
  );

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};
export const useStructure = (id) => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["skuFormulaId", id],
    () => GetProductsStructure(id)
  );

  return {
    data: data?.data,
    isLoading,
    refetch,
    isError,
  };
};
export const useMeasures = () => {
  const { data, isLoading, isError } = useQuery("measure", GetMeasures);

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};
export const useProcesos = (todo = false) => {
  const { data, isLoading, isError, refetch } = useQuery(
    "Procesos",
    async () => {
      const response = await GetProcesos();

      ordenarArrayConParametros(response.data, [
        "procesoProductivoTipoDescripcion",
        "procesoProductivoDescripcion",
      ]);

      let datos = [];
      for (let x = 0; x < response.data.length + 1; x++) {
        if (x === 0) {
          datos.push({
            procesoProductivoDescripcion: "Todos",
            procesoProductivoId: "0",
            procesoProductivoTipoDescripcion: "Todos",
            procesoProductivoActivo: "1",
          });
        } else {
          datos.push(response.data[x - 1]);
        }
      }
      if (todo) {
        return datos;
      }
      return response.data;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useProcesosTipoId = (todo = false) => {
  const { data, isLoading, isError, refetch } = useQuery(
    "ProcesosTipoId",
    async () => {
      const response = await GetProcesosTipoId();

      ordenarArrayConParametros(response.data, [
        "procesoProductivoTipoDescripcion",
        "procesoProductivoDescripcion",
      ]);

      let datos = [];
      for (let x = 0; x < response.data.length + 1; x++) {
        if (x === 0) {
          datos.push({
            procesoProductivoTipoDescripcion: "Todos",
            procesoProductivoTipoId: "0",
            procesoProductivoTipoActivo: "1",
          });
        } else {
          datos.push(response.data[x - 1]);
        }
      }
      if (todo) {
        return datos;
      }
      return response.data;
    }
  );

  return {
    data: data,
    isLoading,
    refetch,
    isError,
  };
};
export const PostProductsStructure = async ({ id, body }) => {
  return await SaveProductsStructure(id, body);
};
export const PostSaveNewProduct = async ({ body }) => {
  return await SaveNewProduct(body);
};
export const useStockProduct = () => {
  const { data, isLoading, isError, refetch } = useQuery("stock", async () => {
    const response = await GetStockProductVerdadero();

    let nameProductFilter = [];
    response.data.map((item) =>
      nameProductFilter.push({
        proveedorDescripcion: item.entidadDescripcion,
        skuCodigo: item.skuCodigo,
        skuDescripcion: item.skuDescripcion,
        unidadMedida: item.unidadMedida,
        skuId: item.skuId,
        unidadMedidaId: item.unidadMedidaId,
      })
    );

    var hash = {};
    nameProductFilter = nameProductFilter.filter(function (current) {
      var exists = !hash[current.skuId];
      hash[current.skuId] = true;
      return exists;
    });

    const stockProducts = nameProductFilter.map((item) => {
      let ubication = [];
      let nameUbication = [];
      let stockAprobadoTotal = 0;
      let stockRechazadoTotal = 0;
      let stockTerceroTotal = 0;
      let stockPendienteQaTotal = 0;

      response.data.map(
        (name) =>
          item.skuId === name.skuId &&
          nameUbication.push({
            stockUbicacionId: name.stockUbicacionId,
            stockUbicacionDescripcion: name.stockUbicacionDescripcion,
            proveider: name.entidadDescripcion,
            stockId: name.stockId,
            skuCodigo: name.skuCodigo,
            skuDescripcion: name.skuDescripcion,
            unidadMedida: name.unidadMedida,
          })
      );

      var hash = {};
      nameUbication = nameUbication.filter(function (current) {
        var exists = !hash[current.stockUbicacionId];
        hash[current.stockUbicacionId] = true;
        return exists;
      });
      nameUbication.map((ubiName) => {
        let stockAprobadoTotalUbication = 0;
        let stockRechazadoTotalUbication = 0;
        let stockTerceroTotalUbication = 0;
        let stockPendienteQaTotalUbication = 0;

        // eslint-disable-next-line array-callback-return
        response.data.map((ubi) => {
          if (
            ubiName.stockUbicacionId === ubi.stockUbicacionId &&
            ubi.skuId === item.skuId
          ) {
            if (ubi.stockEstadoId === "1") {
              stockAprobadoTotalUbication += Number(ubi.stockCantidad);
            }
            if (ubi.stockEstadoId === "2") {
              stockRechazadoTotalUbication += Number(ubi.stockCantidad);
            }
            if (ubi.stockEstadoId === "4") {
              stockTerceroTotalUbication += Number(ubi.stockCantidad);
            }
            if (ubi.stockEstadoId === "3") {
              stockPendienteQaTotalUbication += Number(ubi.stockCantidad);
            }
          }
        });

        ubication.push({
          proveider: ubiName.proveider,
          stockUbicacionId: ubiName.stockUbicacionId,
          stockUbicacionDescripcion: ubiName.stockUbicacionDescripcion,
          Aprobado: stockAprobadoTotalUbication,
          Rechazado: stockRechazadoTotalUbication,
          Tercero: stockTerceroTotalUbication,
          PendienteQa: stockPendienteQaTotalUbication,
          stockId: ubiName.stockId,
          skuCodigo: ubiName.skuCodigo,
          skuDescripcion: ubiName.skuDescripcion,
          unidadMedida: ubiName.unidadMedida,
        });
      });
      // eslint-disable-next-line array-callback-return
      response.data.map((ubi) => {
        if (item.skuId === ubi.skuId) {
          if (ubi.stockEstadoId === "1") {
            stockAprobadoTotal += Number(ubi.stockCantidad);
          }
          if (ubi.stockEstadoId === "2") {
            stockRechazadoTotal += Number(ubi.stockCantidad);
          }
          if (ubi.stockEstadoId === "3") {
            stockPendienteQaTotal += Number(ubi.stockCantidad);
          }
          if (ubi.stockEstadoId === "4") {
            stockTerceroTotal += Number(ubi.stockCantidad);
          }
        }
      });

      return {
        ...item,
        ubication,
        stockAprobadoTotal,
        stockRechazadoTotal,
        stockTerceroTotal,
        stockPendienteQaTotal,
        ubicacionTotal: "Todas",
      };
    });

    return stockProducts;
  });

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};

export const useStockProductOriginal = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    "stockPorProducto",
    async () => {
      const response = await GetStockProduct();

      return response;
    }
  );

  return {
    data: data?.data,
    isLoading,
    isError,
    refetch,
  };
};
export const useListprovider = (sinFiltro = false) => {
  const { data, isLoading, isError } = useQuery("provider", async () => {
    const response = await GetListprovider();

    const data = sinFiltro
      ? response.data.filter(
          (item) => item?.entidadTipoId === "1" || item?.entidadTipoId === "2"
        )
      : response.data.filter((item) => item.entidadTipoId === "2");
    return data;
  });

  return {
    data: data,
    isLoading,
    isError,
  };
};
export const useListClientes = (mostrarCamposTodos = false) => {
  const { data, isLoading, isError } = useQuery("provider", async () => {
    const response = await GetListprovider();

    const data = response.data.filter(
      (item) => item.entidadTipoId === "3" || item.entidadTipoId === "4"
    );
    if (mostrarCamposTodos) {
      data.unshift({ entidadId: 0, entidadDescripcion: "Todos" });
    }
    return data;
  });

  return {
    data: data,
    isLoading,
    isError,
  };
};
export const useListDeposito = () => {
  const { data, isLoading, isError } = useQuery("deposito", GetListDeposito);

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};
export const useListStockState = () => {
  const { data, isLoading, isError } = useQuery(
    "ListStockState ",
    GetListStockState
  );

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};
export const useListMotivoStock = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    "ListMotivoStock ",
    GetListMotive
  );

  return {
    data: data?.data,
    isLoading,
    isError,
    refetch,
  };
};
export const useListUbicationProvider = (id, soloActivo = true) => {
  const { data, isLoading, isError, refetch } = useQuery(
    "ubicationProvider",
    async () => {
      const response = await GetListUbicationProveider(id);
      // OrderGen(response.data, "stockUbicacionDescripcion");
      ordenarArrayConParametros(response.data, [
        "entidadDescripcion",
        "stockUbicacionDescripcion",
      ]);
      if (soloActivo) {
        return response?.data.filter(
          (item) => item?.stockUbicacionActivo === "1"
        );
      }
      return response.data;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useStockAudi = (id) => {
  const { data, isLoading, isError } = useQuery(["StockAudi", id], () =>
    GetListStockAudi(id)
  );

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};
