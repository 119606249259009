export const getColorChip = (id) => {
    
    if (id === "1") {
    return "default"
    }
     if (id === "2") {
    return "success"
    }
     if (id === "3") {
    return "error"
    }
     if (id === "4") {
    return "warning"
    }
     if (id === "5") {
    return "error"
    }
     if (id === "6") {
    return "success"
    }

}
export const getBackgroundColorChip = (id) => {
    
    if (id === "1") {
    return ""
    }
     if (id === "2") {
    return "#98E87C"
    }
     if (id === "3") {
    return "rgba(255, 98, 98, 0.29);"
    }
     if (id === "4") {
    return "#E8E13E"
    }
     if (id === "5") {
    return " rgba(255, 98, 98, 0.29);"
    }
     if (id === "6") {
    return "#98E87C"
    }

}