import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Container,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Box } from "@mui/system";
import { IsMobi } from "../../hooks/isMobi";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { InputNotForm } from "../Input";
import Button from "../Button";
import { verificarPermiso } from "../../utils/verificarPermiso";
import { PERMISOS } from "../../constants/MenuOptions";
import { getUser } from "../../hooks/auth";

const TableGen = ({
  datos,
  header,
  nameRow,
  nameRowUbi,
  onClick,
  permisos = [],
  listUsers = [],
}) => {
  const [pageSelected, setPageSelected] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [items, setItems] = useState(datos);
  const [itemOffset, setItemOffset] = useState(0);
  const changeNumPage = (evt) => {
    setItemOffset(0);
    setPageSelected(0);
    setItemsPerPage(evt.target.value);
  };

  let dataFilter;
  const changeSearch = (evt) => {
    setItemOffset(0);
    setPageSelected(0);
    if (evt.target.value !== "") {
      dataFilter = datos.filter((item) =>
        Object.values(item).find(
          (valor) =>
            !!valor &&
            valor
              .toString()
              .toUpperCase()
              .includes(evt.target.value.toUpperCase())
        )
      );
    } else {
      dataFilter = datos;
    }
    setItems(dataFilter);
  };
  useEffect(() => {
    setItems(datos);
  }, [datos]);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items, datos]);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setPageSelected(event.selected);
    setItemOffset(newOffset);
  };
  return (
    <>
      <div className="d-flex flex-column mx-2" style={{ maxWidth: "100%" }}>
        <div className="d-flex justify-content-between mb-2 mx-4">
          <FormControl
            sx={{ width: "160px", backgroundColor: "#fafafa", mx: 2 }}
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{ marginTop: "4px" }}
            >
              Item por pagina
            </InputLabel>

            <Select
              name="Number"
              onChange={(evt) => changeNumPage(evt)}
              disabled={
                !verificarPermiso(
                  permisos,
                  PERMISOS.SOLO_CONSULTA_ITEM_POR_PAGINA,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                )
              }
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fafafa",
            }}
          >
            <InputNotForm
              disabled={
                !verificarPermiso(
                  permisos,
                  PERMISOS.SOLO_CONSULTA_BUSCAR,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                )
              }
              onchange={(evt) => changeSearch(evt)}
              label="Buscar"
            />
          </Box>
        </div>
        {currentItems && (
          <TableMui
            rows={currentItems}
            header={header}
            nameRow={nameRow}
            nameRowUbi={nameRowUbi}
            onClick={onClick}
          />
        )}
        <div
          className={!IsMobi() && "d-flex justify-content-center "}
          style={{ display: "solid", overflowX: "auto", marginTop: "16px" }}
        >
          <ReactPaginate
            nextLabel="Siguiente >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={IsMobi() ? 2 : 5}
            pageCount={pageCount}
            forcePage={pageSelected}
            previousLabel="< Anterior"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );

  function TableMui({ rows, header, nameRow, nameRowUbi, onClick }) {
    const [itemIndex, setItemIndex] = useState();

    const ItemGridHeader = ({ valor }) => (
      <Typography
        variant="h3"
        align="center"
        sx={{
          margin: "8px",
          width: "100px",
          wordWrap: "break-word",
          textTransform: "capitalize",
        }}
      >
        {valor}
      </Typography>
    );

    const ItemGrid = ({ valor }) => (
      <Typography
        variant="body1"
        align="center"
        sx={{ width: "100px", margin: "8px", wordWrap: "break-word" }}
      >
        {valor}
      </Typography>
    );

    const ItemGridAccordion = ({ valor }) => (
      <Typography
        variant="body1"
        align="center"
        sx={{
          width: "100px",
          margin: "8px",
          wordWrap: "break-word",
          fontWeight: 600,
        }}
      >
        {valor}
      </Typography>
    );

    return (
      <>
        <Container sx={{ p: 1, minWidth: "1250px", width: "100%", m: 1 }}>
          <Container
            sx={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              alignItems: "center",
            }}
          >
            {header.map((item, index) => (
              <ItemGridHeader key={index} valor={item} />
            ))}
          </Container>
          {rows.map((item, index) => (
            <div className="d-flex flex-row">
              <Accordion
                expanded={index === itemIndex}
                onChange={(props) =>
                  setItemIndex(index === itemIndex ? -1 : index)
                }
              >
                <AccordionSummary
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <>
                    {nameRow.map((name, indexRow) => (
                      <ItemGrid valor={item[name]} />
                    ))}

                    <IconButton aria-label="expand row" size="small">
                      {index === itemIndex ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  </>
                </AccordionSummary>

                <Divider />

                {item.ubication.map((name, indexRow) => (
                  <AccordionDetails
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "rgba(0,0,0, 0.0)",
                    }}
                  >
                    {nameRowUbi.map((Ubi) => (
                      <ItemGridAccordion valor={name[Ubi]} />
                    ))}
                  </AccordionDetails>
                ))}
              </Accordion>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxHeight: 94,
                  backgroundColor: "rgba(0,0,0, 0.0)",
                }}
              >
                <Button
                  label="Ver Historial"
                  onClick={() => onClick([item])}
                  disabled={
                    !verificarPermiso(
                      permisos,
                      PERMISOS.SOLO_CONSULTA_VER_HISTORIAL,
                      listUsers?.find(
                        (item) => item?.radiusIdCodigo === getUser()?.uid
                      )?.idGrupo
                    )
                  }
                />
              </Box>
            </div>
          ))}
        </Container>
      </>
    );
  }
};

export default TableGen;
