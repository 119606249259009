import { Box } from "@mui/material";
import { useState } from "react";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";
import { useListGroup, useListUsers } from "../../hooks/useUsers";
import { Loading } from "../loading";
import FormUpdateUser from "./component/formUpdateUser";
import { Table } from "./component/tabla";

export const UpdateUsers = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
    refetch,
  } = useListUsers();
  const {
    data: listGroup,
    isError: isErrorListGroup,
    isLoading: loadingListGroup,
  } = useListGroup();
  if (loadingListGroup || loadingListUsers) {
    return <Loading />;
  }

  if (
    !Array.isArray(listGroup) ||
    !Array.isArray(listUsers) ||
    isErrorListGroup ||
    isErrorListUsers
  ) {
    return <ErrorMsg />;
  }
  const headerName = ["usuario", "Nombre", "Apellido", "Email", "Grupo"];
  const rowName = ["user", "nombre", "apellido", "email", "grupoNombre"];
  return (
    <>
      <Layout title={"Modificar usuarios"}>
        <Box sx={{ width: "100%", p: 3 }}>
          <Table
            rows={listUsers}
            headerName={headerName}
            rowName={rowName}
            onClick={(values) => {
              setUser(values);
              setOpen(true);
            }}
          />
        </Box>
        <DialogGen
          open={open}
          onClick={() => setOpen(false)}
          sxBody={{ maxHeight: "500px" }}
        >
          <FormUpdateUser
            user={user}
            listGroup={listGroup}
            setAlertCustom={setAlertCustom}
            closer={() => setOpen(false)}
            refetch={refetch}
          />
        </DialogGen>
      </Layout>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </>
  );
};
