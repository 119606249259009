import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { AlertCustom } from "../../components/AlertMui";
import { AutoComplete } from "../../components/AutoComplete";
import Button from "../../components/Button";
import arrayMutators from "final-form-arrays";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FieldArray } from "react-final-form-arrays";
import ErrorMsg from "../../components/ErrorMsg";
import { InputDate, InputNumber, InputText } from "../../components/Input";
import Layout from "../../components/Layout";
import {
  useListDeposito,
  useProcesos,
  useProducts,
} from "../../hooks/useProducts";
import { CrearOrdenTrabajo } from "../../services/ordenTrabajo";
import { fechaActual } from "../../utils/Fecha";
import { required } from "../../utils/validations";
import { Loading } from "../loading";
import { useOrdenTrabajoFormula } from "../../hooks/useOrdenTrabajo";
import { DialogGen } from "../../components/Dialog";
import { FormularioAgregarMaterial } from "../../components/FormularioAgregarMaterial";
import saveData, { crearNuevaOtg } from "./hook/SaveData";
import { numeroOtg } from "../../utils/numeroOtg";
import { usePermisos } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";
import { CardDetalleFormulaCrearOtg } from "../detailsOtg/component/cardDetalleFormulaCrearOtg";
import { useListPedidos } from "../../hooks/usePedidos";
import { CheckBoxMuiTrueOrFalse } from "../../components/CheckBoxMui";
import { SelectMui } from "../../components/SelectMui";

const CrearOtg = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [showAgregarMaterial, setShowAgregarMaterial] = useState(null);
  const [datosCompleto, setDatosCompleto] = useState(null);
  const [datosOtgApi, setDatosOtgApi] = useState([]);
  const [initialValues, setInitialValue] = useState({});
  const [expanded, setExpanded] = useState(null);
  const { data: products, isLoading, isError } = useProducts();
  const {
    data: procesos,
    isLoading: isLoadingProcesos,
    isError: isErrorProcesos,
  } = useProcesos();
  const {
    data: otgFormula,
    isLoading: isLoadingOtgFormula,
    isError: isErrorOtgFormula,
    refetch,
  } = useOrdenTrabajoFormula(0);
  const {
    data: listDeposito,
    isLoading: isLoadingDeposito,
    isError: isErrorDeposito,
  } = useListDeposito();
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  const {
    data: listPedidos,
    isLoading: isLoadingListPedidos,
    isError: isErrorListPedidos,
  } = useListPedidos(0);
  useEffect(() => {
    setDatosCompleto(
      datosOtgApi.map((item) => {
        let newDatos = [];
        otgFormula.map(
          (x) => item?.ordenTrabajoId === x?.ordenTrabajoId && newDatos.push(x)
        );
        return {
          ...item,
          formula: newDatos,
        };
      })
    );
  }, [otgFormula, datosOtgApi]);
  if (
    isLoading ||
    isLoadingOtgFormula ||
    isLoadingProcesos ||
    loadingListUsers ||
    isLoadingPermisos ||
    isLoadingDeposito ||
    isLoadingListPedidos
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(products) ||
    !Array.isArray(procesos) ||
    !Array.isArray(otgFormula) ||
    !Array.isArray(listDeposito) ||
    isError ||
    isErrorOtgFormula ||
    isErrorProcesos ||
    isErrorDeposito ||
    isErrorListUsers ||
    isErrorPermisos ||
    isErrorListPedidos
  ) {
    <ErrorMsg />;
  }
  if (loading) {
    return <Loading />;
  }

  const handleChange = (panel) => (_event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Layout title={"Agregar nueva OTG"} clase={true}>
      <Form
        onSubmit={(values) => {
          crearNuevaOtg(
            values,
            products,
            setLoading,
            setInitialValue,
            setDatosOtgApi,
            refetch,
            setAlertCustom
          );
        }}
        initialValues={{
          cantidad: 0,
          ...initialValues,
          datos: datosCompleto,
        }}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          values,
          form: {
            restart,
            mutators: { push },
          },
        }) => (
          <Box
            sx={{
              m: 1,
              p: 1,
              mx: 3,
              background: "#FFFFFF",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ width: "100%", maxWidth: "340px" }}>
                <Field
                  disabled={datosOtgApi.length > 0}
                  validate={required}
                  name="sku"
                  component={AutoComplete as any}
                  sx={{}}
                  options={products.map((item) => {
                    return {
                      id: item.skuId,
                      label: item.skuCodigo,
                    };
                  })}
                />
              </Box>
              <Box sx={{ pl: 1 }}>
                <Field
                  disabled={datosOtgApi.length > 0}
                  name="showSelectPedido"
                  component={CheckBoxMuiTrueOrFalse as any}
                  label="Asociar OTG a una nota pedido"
                />
              </Box>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Field
                disabled={datosOtgApi.length > 0}
                name="nombre"
                component={InputText as any}
                label={"Nombre"}
                sx={{ maxWidth: "340px" }}
                values={
                  values?.sku?.id
                    ? products.find((item) => values?.sku?.id === item.skuId)
                        ?.skuDescripcion
                    : ""
                }
              />
            </Box>
            {values?.showSelectPedido && (
              <Box sx={{ mt: 3 }}>
                <Field
                  validate={required}
                  disabled={datosOtgApi.length > 0}
                  name="comprobanteDetalleId"
                  component={SelectMui as any}
                  label={"Nota de Pedido"}
                  sx={{ width: "100%", maxWidth: "340px" }}
                  options={listPedidos
                    ?.filter(
                      (pedido) => pedido?.skuCodigo === values?.sku?.label
                    )
                    .map((item) => {
                      return {
                        value: item?.comprobanteDetalleId,
                        name: item?.nombreSelectCrearOtg,
                      };
                    })}
                  onchange={(pedidoId) =>
                    setInitialValue({
                      ...values,
                      ordenTrabajoCantidad: listPedidos?.find(
                        (pedido) => pedido?.comprobanteDetalleId === pedidoId
                      )?.comprobanteDetalleCantidad,
                      comprobanteDetalleId: pedidoId,
                    })
                  }
                />
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
              <Box sx={{ pr: 0.5 }}>
                <Field
                  disabled={datosOtgApi.length > 0}
                  validate={(valor) =>
                    Number(valor) > 0 ? undefined : "No puede ser 0"
                  }
                  name="ordenTrabajoCantidad"
                  label="Cantidad a fabricar"
                  component={InputNumber as any}
                  sx={{ width: "100%", maxWidth: "340px" }}
                />
              </Box>
              <Box sx={{ pl: 0.5 }}>
                <Field
                  focused
                  validate={required}
                  disabled={datosOtgApi.length > 0}
                  sx={{ width: "100%", maxWidth: "340px" }}
                  name="fecha"
                  label="fecha inicio de elaboración"
                  component={InputDate as any}
                  min={fechaActual()}
                  defaultValue={fechaActual()}
                />
              </Box>
            </Box>
            {datosOtgApi.length > 0 && (
              <>
                <Typography
                  sx={{ color: "#797979", mt: 2, ml: 1 }}
                  variant={"h1"}
                >
                  Ordenes de trabajo generadas
                </Typography>
                <FieldArray name="datos">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <Box sx={{ mt: 2 }}>
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                          onClick={() => {}}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h3">{`Codigo: ${
                              values.datos[index].skuCodigo
                            } || Otg n° : ${numeroOtg(
                              Number(values.datos[index].ordenTrabajoId)
                            )}`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              border: "1px solid #C1C1C1",
                              boxShadow: "1px 1px 1px 1px rgba(0,0,0, 0.05)",
                            }}
                          >
                            {datosOtgApi?.length !== 0 && (
                              <>
                                <FieldArray name={`${name}.formula`}>
                                  {({ fields }) =>
                                    fields.map((name1, index1) => (
                                      <>
                                        <CardDetalleFormulaCrearOtg
                                          name={
                                            values.datos[index].formula[index1]
                                              .skuDescripcion
                                          }
                                          nameField={name1}
                                          consumoActual={
                                            Number(
                                              values.datos[index]
                                                ?.ordenTrabajoCantidadProducida *
                                                values.datos[index].formula[
                                                  index1
                                                ]?.ordenTrabajoFormulaCantidad
                                            ) +
                                            (Number(
                                              values.datos[index].cantidad
                                            )
                                              ? Number(
                                                  values.datos[index].cantidad
                                                ) *
                                                values.datos[index].formula[
                                                  index1
                                                ]?.ordenTrabajoFormulaCantidad
                                              : 0)
                                          }
                                          consumoTotal={
                                            values.datos[index]
                                              ?.ordenTrabajoCantidad *
                                            values.datos[index].formula[index1]
                                              ?.ordenTrabajoFormulaCantidad
                                          }
                                          ordenTrabajoFormulaMermaPorcentaje={
                                            values.datos[index].formula[index1]
                                              .ordenTrabajoFormulaMermaPorcentaje
                                          }
                                          ordenTrabajoFormulaCantidad={
                                            values.datos[index].formula[index1]
                                              ?.ordenTrabajoFormulaCantidad
                                          }
                                          permisos={permisos}
                                          listUsers={listUsers}
                                        />
                                      </>
                                    ))
                                  }
                                </FieldArray>

                                <Box sx={{ mt: 1, mb: 2 }}>
                                  <Button
                                    label={"Agregar materiales"}
                                    onClick={() =>
                                      setShowAgregarMaterial(index)
                                    }
                                  />
                                </Box>

                                <DialogGen
                                  open={showAgregarMaterial === index}
                                  title={"Agregar producto a formula"}
                                  onClick={() => {
                                    setShowAgregarMaterial(null);
                                  }}
                                  sx={{
                                    width: "100%",
                                    maxWidth: "400px",
                                  }}
                                >
                                  <FormularioAgregarMaterial
                                    datosFormPrincipal={values}
                                    nameKeyArray={"datos"}
                                    push={(valor) => {
                                      push(`${name}.formula`, valor);
                                    }}
                                    setShowAgregarMaterial={(valor) =>
                                      setShowAgregarMaterial(valor)
                                    }
                                    products={products}
                                    otg={values.datos[index]}
                                  />
                                </DialogGen>
                              </>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ))
                  }
                </FieldArray>
              </>
            )}
            {datosOtgApi.length > 0 && (
              <Box sx={{ mt: 3 }}>
                <Button
                  label="Guarda Formula"
                  color="success"
                  sx={{ width: "100%", maxWidth: "300px" }}
                  onClick={() =>
                    saveData({
                      datos: values,
                      setAlertCustom: setAlertCustom,
                      restart: restart,
                      refetch: refetch,
                      setDatosOtgApi: setDatosOtgApi,
                      setInitialValue: setInitialValue,
                    })
                  }
                  disabled={submitting || invalid}
                />
              </Box>
            )}
            {datosOtgApi?.length === 0 && (
              <Box sx={{ mt: 3 }}>
                <Button
                  label="Crear OTG"
                  color="success"
                  sx={{ width: "100%", maxWidth: "300px" }}
                  onClick={handleSubmit}
                  disabled={submitting || invalid}
                />
              </Box>
            )}
            <AlertCustom
              open={alertCustom.open}
              onclick={() =>
                setAlertCustom({
                  open: false,
                  title: "",
                  severity: "",
                  message: "",
                })
              }
              severity={alertCustom.severity}
              title={alertCustom.title}
              message={alertCustom.message}
            />
          </Box>
        )}
      />
    </Layout>
  );
};
export default CrearOtg;
