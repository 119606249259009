import { Box } from "@mui/material";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputNumber } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { required } from "../../../utils/validations";
import { saveInfoConversionUnidadMedida } from "../hook";

const FormAgregarNuevaConversionUnidadMedida = ({
  setAlertCustom,
  refetch,
  measures,
}) => {
  const obj: any = {};
  return (
    <Form
      onSubmit={(values) => {
        saveInfoConversionUnidadMedida(
          values,
          setAlertCustom,
          refetch,
          obj.funcion
        );
      }}
      initialValues={{}}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        obj.funcion = restart;
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="col-12" sx={{ p: 1 }}>
              <Field
                validate={required}
                name={`unidad_medida_id_origen`}
                label="Unidad medida origen"
                component={SelectMui as any}
                options={measures.map((item) => {
                  return {
                    value: item.unidadMedidaId,
                    name: item.unidadMedida,
                  };
                })}
                sx={{ minWidth: 100, width: "100%" }}
              />
            </Box>
            <Box className="col-12" sx={{ p: 1 }}>
              <Field
                validate={required}
                name={`unidad_medida_id_destino`}
                label="Unidad medida destino"
                component={SelectMui as any}
                options={measures.map((item) => {
                  return {
                    value: item.unidadMedidaId,
                    name: item.unidadMedida,
                  };
                })}
                sx={{ minWidth: 100, width: "100%" }}
              />
            </Box>
            <Box className="col-12" sx={{ p: 1 }}>
              <Field
                validate={required}
                name={`coeficiente`}
                component={InputNumber as any}
                label="Coeficiente"
              />
            </Box>

            <Box
              className="col-12"
              sx={{
                display: "flex",
                justifyContent: "center",
                maxHeight: 40,
                my: 1,
              }}
            >
              <Button label="Agregar" onClick={handleSubmit} color="success" />
            </Box>
          </Box>
        );
      }}
    />
  );
};
export default FormAgregarNuevaConversionUnidadMedida;
