import { Card } from "@mui/material";
import TableMovement from "../../../components/StockPerProduct/TableMovement";

export const ArmarDatosMovimientos = ({ datosTable1, datosTable2 }) => {
  const AuditoriaFiltrada = datosTable2.filter(
    (item) => datosTable1[0].skuCodigo === item.skuCodigo
  );
  const AuditoriaNuevaFecha = AuditoriaFiltrada.map((item) => {
    return {
      ...item,
      stockAuditoriaFecha: `
      ${item?.stockAuditoriaFecha?.substr(
        8,
        2
      )}/${item?.stockAuditoriaFecha?.substr(
        5,
        2
      )}/${item?.stockAuditoriaFecha?.substr(0, 4)}`,
      usuario: `${item?.nombre} ${item?.apellido}`,
    };
  });

  return (
    <Card>
      <div style={{ marginBottom: 32 }}>
        <TableMovement
          paginate={false}
          datos={datosTable1}
          header={[
            "codigo",
            "nombre",
            "Stock aprobado",
            "Stock rechazado",
            "Stock Cuarentena",
            "Stock pendiente calidad",
          ]}
          nameRow={[
            "skuCodigo",
            "skuDescripcion",
            "stockAprobadoTotal",
            "stockRechazadoTotal",
            "stockTerceroTotal",
            "stockPendienteQaTotal",
          ]}
        />
      </div>
      <div>
        <TableMovement
          paginate={false}
          datos={AuditoriaNuevaFecha}
          header={[
            "Fecha",
            "Hora",
            "Accion tomada",
            "Proveedor",
            "Ubicacion",
            "Estado",
            "Cantidad previa al ajuste",
            "Cantidad ajustada",
            "Cantidad posterior al ajuste",
            "Unidad de medida",
            "Motivo de ajuste",
            "Usuario",
            "Observacion",
          ]}
          nameRow={[
            "stockAuditoriaFecha",
            "stockAuditoriaHora",
            "stockMotivoDescripcion",
            "entidadDescripcion",
            "stockUbicacionDescripcion",
            "stockEstadoDescripcion",
            "stockAuditoriaCantidadOriginal",
            "stockAuditoriaCantidad",
            "stockAuditoriaCantidadActual",
            "unidadMedida",
            "stockMotivoAjusteDescripcion",
            "usuario",
            "stockAuditoriaObservacion",
          ]}
        />
      </div>
    </Card>
  );
};
