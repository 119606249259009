import { fetchContent, Method } from "../utils/fetchContent";

export const GetProducts = async (sku_tipo_id) => {
  try {
    let condicion = "";
    if (sku_tipo_id) {
      condicion += `sku_tipo_id=${sku_tipo_id}`;
    }
    return await fetchContent(
      `/sku_listado/0${condicion ? "?" + condicion : ""}`
    );
  } catch (error) {
    throw error;
  }
};
export const GetTypeProduct = async () => {
  try {
    return await fetchContent(`/sku_tipo_listado`);
  } catch (error) {
    throw error;
  }
};

export const GetProductsById = async (id) => {
  try {
    return await fetchContent(`/sku/${id}`);
  } catch (error) {
    throw error;
  }
};
export const GetImgProducts = async (id) => {
  try {
    return await fetchContent(`sku/imagenes/${id}`);
  } catch (error) {
    throw error;
  }
};
export const DeleteImgProducts = async (id) => {
  try {
    return await fetchContent(`sku/imagen/${id}`, {
      method: Method.DELETE,
    });
  } catch (error) {
    throw error;
  }
};

export const GetProductsEntidadById = async (id) => {
  try {
    return await fetchContent(`/sku_listado/${id}`);
  } catch (error) {
    throw error;
  }
};
export const GetProductsClasfication = async () => {
  try {
    return await fetchContent("/clasificacion");
  } catch (error) {
    throw error;
  }
};
export const GetProductsStructure = async (id) => {
  try {
    return await fetchContent(`sku/formula/${id}`);
  } catch (error) {
    throw error;
  }
};
export const GetMeasures = async () => {
  try {
    return await fetchContent("/unidad-medida");
  } catch (error) {
    throw error;
  }
};
export const GetProcesos = async () => {
  try {
    return await fetchContent("/proceso_productivo");
  } catch (error) {
    throw error;
  }
};
export const GetProcesosTipoId = async () => {
  try {
    return await fetchContent(`/proceso_productivo_tipo`);
  } catch (error) {
    throw error;
  }
};
export const PostProcesosProductivo = async (body) => {
  try {
    return await fetchContent(`/proceso_productivo_update `, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const PostProcesosProductivoArea = async (body) => {
  try {
    return await fetchContent(`/proceso_productivo_tipo_update `, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};

export const SaveProductsDetails = async (id, body) => {
  try {
    return await fetchContent(`/sku/${id}`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const SaveProductsStructure = async (id, body) => {
  try {
    return await fetchContent(`sku/formula/${id}`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const SaveNewProduct = async (body) => {
  try {
    return await fetchContent("sku", {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const postDataIngreso = async (body) => {
  try {
    return await fetchContent("stock/orden_recepcion/ ", {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
// export const GetDataIngreso = async () => {

//   try {
//     return await fetchContent('http://localhost:3000/save-date-ingreso', {
//       method: Method.GET,
//     });
//   } catch (error) {
//     console.log('GetDataIngreso:', error);
//   }
// }
export const GetStockProductVerdadero = async () => {
  try {
    return await fetchContent("sku/stock/0", {
      method: Method.POST,
    });
  } catch (error) {
    console.log("GetStockProductVerdadero:", error);
  }
};
export const GetStockProduct = async () => {
  try {
    return await fetchContent("stock_disponible_egreso_listado/0", {});
  } catch (error) {
    console.log("GetStockProduct:", error);
  }
};
export const GetListprovider = async () => {
  try {
    return await fetchContent("/entidad_listado");
  } catch (error) {
    console.log("GetListprovider:", error);
  }
};
export const GetListDeposito = async () => {
  try {
    return await fetchContent("/entidad_deposito_listado");
  } catch (error) {
    console.log("GetListDeposito:", error);
  }
};

export const GetListStockState = async () => {
  try {
    return await fetchContent("/stock/estado");
  } catch (error) {
    console.log("GetListStockState:", error);
  }
};
export const GetListMotive = async () => {
  try {
    return await fetchContent("/stock/motivo");
  } catch (error) {
    console.log("GetListStockState:", error);
  }
};
export const SaveUbicationProvider = async ({ body }) => {
  console.log(body);
  try {
    return await fetchContent("/ubicacion/ubicacion_update", {
      method: Method.POST,
      body,
    });
  } catch (error) {
    throw error;
  }
};
export const GetListStockAudi = async (id) => {
  try {
    return await fetchContent(`/stock/stock_auditoria/${id}`);
  } catch (error) {
    console.log("GetListStockAudi:", error);
  }
};
export const GetListUbicationProveider = async (id) => {
  try {
    return await fetchContent(`/entidad/ubicacion/${id}`, {
      method: Method.POST,
    });
  } catch (error) {
    console.log("GetListUbicationProveider:", error);
  }
};
export const SaveAjusteStock = async (body) => {
  try {
    return await fetchContent("/sku/stock_update/", {
      method: Method.POST,
      body,
    });
  } catch (error) {
    throw error;
  }
};
export const SaveMovementStock = async (body) => {
  try {
    return await fetchContent("/stock/transferencia/", {
      method: Method.POST,
      body,
    });
  } catch (error) {
    throw error;
  }
};
