import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { Modal, Box, Typography, Button } from "@mui/material";
import { InProcess } from "../Animation";
import { resetPassword, CreateUser } from "../../hooks/auth";
import { titleDialog } from "../../constants/MenuOptions";
import { BsXLg as X } from "react-icons/bs";
import { FormCreateUser } from "../../pages/FormCreateUser/createUser";

interface DialogProps {
  open?: boolean;
  title?: string;
  body?: String;
  onClick?: () => void;
  refetch?: () => void;
  clickStructure?: (id) => void;
  setInitialValue?: (value: boolean) => void;
  refetchProducts?: () => void;
  datos?: any;
  measures?: any;
  procesos?: any;
  product?: string;
  skuCods?: any;
  sx?: any;
  sxBody?: any;
  disabled?: boolean;
  children?: React.ReactNode;
  idProduct?: number;
}
export const Dialog: React.FC<DialogProps> = ({
  open,
  title,
  body,
  onClick,
}) => {
  return (
    <Modal
      open={open as any}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          boxShadow: "5px 5px 10px 5px  ",
          borderRadius: "16px",
          paddingLeft: "32px",
          paddingRight: "32px",
          padding: "16px 32px 16px 32px",
          backgroundColor: "#fafafa",
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h5" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {body}
        </Typography>
        <Button onClick={onClick as any} href="">
          Continuar
        </Button>
      </Box>
    </Modal>
  );
};
export const DialogResetPass: React.FC<DialogProps> = ({ open, onClick }) => {
  const [response, setResponse] = useState(null);
  const onSubmit = async (email: any) => {
    setResponse(await resetPassword(email));
  };
  let formData = {};
  return (
    <>
      {response === 1 && (
        <Dialog
          open={true}
          title={titleDialog.informacion}
          body={"Se envio correctamente "}
          onClick={() => {
            setResponse(null);
            onClick();
          }}
        />
      )}

      {response === 2 && (
        <Dialog
          open={true}
          title={titleDialog.error}
          body={"El correo NO esta registrado"}
          onClick={() => setResponse(null)}
        />
      )}

      <Modal
        open={open as any}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className="col-11 col-sm-8 col-md-6 col-lg-5 col-xl-4 "
          sx={{
            boxShadow: "5px 5px 10px 5px  ",
            borderRadius: "16px",
            padding: "1% 2% 1% 2%",
            backgroundColor: "#fafafa",
            textAlign: "center",
          }}
        >
          <span className="d-flex justify-content-end mb-2">
            <X onClick={onClick} style={{ cursor: "pointer" }} />
          </span>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Reestablecer Contraseña
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Form
              onSubmit={onSubmit}
              initialValues={formData}
              validate={({ email }) => {
                const errors: any = {};
                if (!email) {
                  errors.email = "Requerido";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
                )
                  errors.email = "correo invalido";

                return errors;
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <div>
                  <form className="d-flex flex-column" onSubmit={handleSubmit}>
                    <Field name="email">
                      {({ input, meta }) => (
                        <div className="d-flex flex-column mt-3">
                          <input
                            {...input}
                            type="text"
                            placeholder="Correo"
                            className="inputResetPass px-4"
                          />
                          {meta.error && meta.touched && (
                            <span className="d-flex justify-content-start px-4 mt-2">
                              {meta.error}
                            </span>
                          )}
                        </div>
                      )}
                    </Field>
                    <div className="d-flex justify-content-center">
                      <button
                        className="button mt-4 mx-2"
                        type="submit"
                        disabled={submitting}
                      >
                        Enviar
                      </button>
                    </div>
                  </form>
                </div>
              )}
            />
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export const DialogCreateUser: React.FC<DialogProps> = ({ open, onClick }) => {
  const [response, setResponse] = useState(null);
  const onSubmit = async (values: any) => {
    setResponse(await CreateUser(values));
  };
  return (
    <>
      {response === 1 && (
        <Dialog
          open={true}
          title={titleDialog.informacion}
          body={"Se creo correctamente"}
          onClick={() => {
            setResponse(null);
            onClick();
          }}
        />
      )}

      {response === 2 && (
        <Dialog
          open={true}
          title={titleDialog.error}
          body={"Correo ya existente "}
          onClick={() => setResponse(null)}
        />
      )}

      <Modal
        open={open as any}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className="col-8 col-sm-7 col-md-6 col-lg-5 col-xl-4 "
          sx={{
            boxShadow: "5px 5px 10px 5px  ",
            borderRadius: "16px",
            padding: "1% 2% 1% 2%",
            backgroundColor: "#fafafa",
            textAlign: "center",
          }}
        >
          <span className="d-flex justify-content-end mb-2">
            <X onClick={onClick} style={{ cursor: "pointer" }} />
          </span>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Crear usuario
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <FormCreateUser CreateUser={CreateUser} closer={() => onClick()} />
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
export const DialogInProcess: React.FC<DialogProps> = ({ open, onClick }) => {
  return (
    <Modal
      open={open as any}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        className="col-8 col-sm-5 col-md-5 col-lg-4 col-xl-4 "
        sx={{
          boxShadow: "5px 5px 10px 5px  ",
          borderRadius: "16px",
          padding: "1% 2% 1% 2%",
          backgroundColor: "#fafafa",
          textAlign: "center",
        }}
      >
        <Typography id="modal-modal-title" variant="h5" component="h2">
          Estamos trabajando
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <InProcess width="80%" height="80%" />
        </Typography>
        <Button onClick={onClick as any} href="">
          Continuar
        </Button>
      </Box>
    </Modal>
  );
};
export const DialogGen: React.FC<DialogProps> = ({
  open,
  title,
  onClick,
  children,
  sx,
  sxBody = {},
  disabled = false,
}) => {
  return (
    <div>
      <Modal
        open={open as any}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            boxShadow: "5px 5px 10px 5px  ",
            borderRadius: "16px",
            padding: "24px 32px 24px 32px",
            backgroundColor: "#fafafa",
            textAlign: "center",
            ...sx,
          }}
        >
          <div className="d-flex justify-content-end ">
            <X
              className=" mb-2"
              onClick={disabled ? () => {} : () => onClick()}
              style={{ cursor: "pointer" }}
            />
          </div>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {title}
          </Typography>
          <Box
            sx={{
              display: "solid",
              maxHeight: "300px",
              overflowY: "auto",
              ...sxBody,
            }}
          >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {children}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
