import { Box, InputAdornment, Typography } from "@mui/material";
import { Field } from "react-final-form";
import { AutoComplete } from "../../../components/AutoComplete";
import { InputNumber } from "../../../components/Input";
import { required } from "../../../utils/validations";
import { BsFillTrashFill as Delete } from "react-icons/bs";
import { SelectMui } from "../../../components/SelectMui";
export const CardEgreso = ({
  name,
  products,
  nombreProducto,
  eliminar,
  stockCantidad,
  values,
  index,
}) => {
  const cantidadDisponible = stockCantidad.find(
    (item) =>
      item.stockLote ===
      (values.datos[index]?.lote === "Sin nombre"
        ? null
        : values.datos[index]?.lote)
  )?.stockCantidad;
  const lotes = stockCantidad.map((item) => {
    return {
      lote: item.stockLote ? item.stockLote : "Sin nombre",
    };
  });
  const unidadMedida = stockCantidad.find(
    (item) =>
      item.stockLote ===
      (values.datos[index]?.lote === "Sin nombre"
        ? null
        : values.datos[index]?.lote)
  )?.unidadMedida;

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 }}>
      <Box className="col-3" sx={{ p: 1 }}>
        <Field
          validate={required}
          name={`${name}.sku`}
          component={AutoComplete as any}
          placeholder="Componente"
          sx={{ maxWidth: "300px" }}
          options={products.map((item) => {
            return {
              id: item.skuId,
              label: item.skuCodigo,
              name: item.skuDescripcion,
              unidadMedida: item.unidadMedida,
              unidadMedidaId: item.unidadMedidaId,
            };
          })}
        />
      </Box>
      <Box
        className="col-2"
        sx={{
          p: 1,
        }}
      >
        <Typography
          variant="subtitle1"
          alignItems="center"
          sx={{
            maxWidth: "300px",
          }}
        >
          {nombreProducto}
        </Typography>
      </Box>
      <Box className="col-2" sx={{ p: 1 }}>
        <Field
          name={`${name}.lote`}
          label="Lote"
          validate={required as any}
          component={SelectMui as any}
          options={lotes.map((item) => ({
            value: item.lote,
            name: item.lote,
          }))}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box
        className="col-2"
        sx={{ display: "flex", justifyContent: "center", p: 1 }}
      >
        <Typography
          variant="subtitle1"
          alignItems="center"
          sx={{
            maxWidth: "300px",
          }}
        >
          {`${
            cantidadDisponible && unidadMedida
              ? ` ${cantidadDisponible} ${unidadMedida} `
              : ""
          }`}
        </Typography>
      </Box>

      <Box
        className="col-3"
        sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
      >
        <Field
          validate={(valor) =>
            !valor
              ? "requerido"
              : Number(valor) > Number(cantidadDisponible)
              ? "Maximo superado"
              : undefined
          }
          component={InputNumber as any}
          name={`${name}.Cantidad`}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{unidadMedida}</InputAdornment>
            ),
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          sx={{ maxWidth: 200 }}
        />
        <Box sx={{ px: 2 }}>
          <Delete
            onClick={() => eliminar()}
            style={{
              width: "20px",
              height: "20px",

              color: "red",
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
