import TreeView from "@mui/lab/TreeView";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import TreeItem from "@mui/lab/TreeItem";
import { Box } from "@mui/material";
import { posicionesTabla } from "../contants";
import { FuncionRecursiva } from "./funcionRecursiva";
import { Fila } from "./fila";
const IntentoArbolito = ({ datos }) => {
  const productosPrincipales = datos.filter((item) => item["4"].length === 6);
  return (
    <Box sx={{ width: "100%" }}>
      <Fila
        datos={posicionesTabla.header}
        sx={{
          background: "#0D6EFD",
          border: "1px solid #FFFFFF",
          color: "#FAFAFA",
        }}
      />
      {productosPrincipales.map((item) => (
        <>
          {datos.filter((x) => x["4"].startsWith(item["4"])).length > 1 ? (
            <TreeView
              aria-label="file system navigator"
              defaultCollapseIcon={<RemoveIcon />}
              defaultExpandIcon={<AddIcon />}
            >
              <TreeItem
                nodeId={item["4"]}
                label={
                  <Fila
                    datos={[item]}
                    nameObj={posicionesTabla.row}
                    mostrarPorcentaje={true}
                  />
                }
                sx={{
                  "& .MuiTreeItem-content": {
                    p: 0,
                  },
                }}
              >
                <FuncionRecursiva
                  array={datos.filter((x) => x["4"].startsWith(item["4"]))}
                  item={item["4"]}
                />
              </TreeItem>
            </TreeView>
          ) : (
            <Box sx={{ pl: 3 }}>
              <Fila
                datos={[item]}
                nameObj={posicionesTabla.row}
                mostrarPorcentaje={true}
              />
            </Box>
          )}
        </>
      ))}
    </Box>
  );
};

export default IntentoArbolito;
