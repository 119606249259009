import { fetchContent, Method } from "../utils/fetchContent";
export const GetMonedas = async () => {

  try {
    return await fetchContent(`/moneda_listado`);
  } catch (error) {
    throw (error)
  }
}
export const UpdateMoneda = async (body) => {

  try {
    return await fetchContent(`/moneda_update` , {
        body,
        method: Method.POST,
      });
  } catch (error) {
    throw (error)
  }
}
export const GetCotizaciones = async () => {

  try {
    return await fetchContent(`/cotizacion_listado`);
  } catch (error) {
    throw (error)
  }
}
export const UpdateCotizacion = async (body) => {

  try {
    return await fetchContent(`/cotizacion_update` , {
        body,
        method: Method.POST,
      });
  } catch (error) {
    throw (error)
  }
}
