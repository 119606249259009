import { fetchContent, Method } from "../utils/fetchContent";
export const GetPermisos = async (idRadius) => {
  try {
    return await fetchContent(`/radius/${idRadius}`);
  } catch (error) {
    throw (error)
  }
}
export const GetPermisosParaOtorgar = async () => {
  try {
    return await fetchContent(`/crm_modulos_listado`);
  } catch (error) {
    throw (error)
  }
}
export const GetPermisosGrupo = async (id) => {
  try {
    return await fetchContent(`/grupo_permisos/${id}`);
  } catch (error) {
    throw (error)
  }
}
export const PostPermisosGrupo = async (body) => {
  try {
      return await fetchContent(`/radius_permisos_update`, {
          body,
          method: Method.POST,
         
      }, false);
  } catch (error) {
    throw (error)
  }
}
export const PostGrupoDetalle = async (body) => {
  try {
      return await fetchContent(`/radius_grupos_update`, {
          body,
          method: Method.POST,
         
      }, false);
  } catch (error) {
    throw (error)
  }
}
