import { SaveFeriado } from "../../../services/ordenTrabajo"

export const guardarDatosFeriado = ({datos, setAlertCustom,refetch}) => {

     SaveFeriado({
          ...datos,
         
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
          
            refetch();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });

}