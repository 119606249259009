import { SaveDatailsOtg, SaveFormulaOtg} from "../../../services/ordenTrabajo";


export const saveDatailsOtgNew = ({ values,  measures, otg, setAlertCustom, restart, refetch, setShowDeclaraciones, refetchOtg, id ,refetchOrdenTrabajoUsuario, formula,products}) => {
console.log(formula)
    let datosDatailsOtg = null;
    if (values?.cantidad || values?.fecha || values.ordenTrabajoEstadoId !== otg[0].ordenTrabajoEstadoId || values.ordenTrabajoElaboracionFecha !== otg[0].ordenTrabajoElaboracionFecha || values.usersOtg) {
        datosDatailsOtg = {
            orden_trabajo_cantidad: Number(otg[0].ordenTrabajoCantidad),
            orden_trabajo_unidad_medida_id: Number(
                measures.find(
                    (m) => m.unidadMedida === otg[0].unidadMedida
                ).unidadMedidaId),
            orden_trabajo_estado_id: Number(values.ordenTrabajoEstadoId),
            orden_trabajo_cantidad_producida: Number(values.cantidad) ? Number(values.cantidad) : 0,
            proceso_productivo_id: Number(otg[0].procesoProductivoId),
            orden_trabajo_elaboracion_fecha: values.ordenTrabajoElaboracionFecha,
            array_orden_trabajo_usuario: values.usersOtg,
            orden_trabajo_generar_nuevo_lote:values?.orden_trabajo_generar_nuevo_lote ? Number(values?.orden_trabajo_generar_nuevo_lote) :"0"
        }
    }
    const formulaNueva = values.Formula.filter(
        (item) => Number(formula.find((formula) =>
            item.skuId === formula.skuId)?.ordenTrabajoFormulaMermaPorcentaje) !== Number(item.ordenTrabajoFormulaMermaPorcentaje) ||
            Number(formula.find((formula) => item.skuId === formula.skuId)?.ordenTrabajoFormulaCantidad) !== Number(item.ordenTrabajoFormulaCantidad)
    );
    console.log(formulaNueva)
    const formulaEliminar = values.Formula.filter(
        (item) => !!item.eliminar && item.eliminar === "s" && !item.esNuevo
    );
    const NuevoSku = formulaNueva.map((item) => {
        return {
            sku_id: Number(products.find((product) => product.skuCodigo === item.skuCodigo)?.skuId),
            unidad_medida_id : Number(item?.unidadMedidaId),
        formula_id:Number(item.ordenTrabajoFormulaId),
            cantidad: Number(item.ordenTrabajoFormulaCantidad),
            merma: Number(item.ordenTrabajoFormulaMermaPorcentaje),
        };
    });

    const SkuEliminar = formulaEliminar.map((item) => {
        return {
            formulaId: item.ordenTrabajoFormulaId,
            eliminar: item.eliminar,
        };
    });
    const body = {
        detalle_orden_trabajo_formula: [...NuevoSku, ...SkuEliminar],
    };

    if (datosDatailsOtg) {
        SaveDatailsOtg(id, datosDatailsOtg).then(() => {
            refetchOtg();
            refetchOrdenTrabajoUsuario();
            setShowDeclaraciones(false);
            if (body.detalle_orden_trabajo_formula.length === 0) {
                setAlertCustom({
                    open: true,
                    title: "Confirmacion",
                    severity: "success",
                    message: "Se guardo correctamente",
                });
            }
        }).catch((error) => {
            if (body.detalle_orden_trabajo_formula.length === 0) {
                setAlertCustom({
                    open: true,
                    title: "Error",
                    severity: "error",
                    message: error?.message,
                });
            }
        })
    }
    
    if (body.detalle_orden_trabajo_formula.length !== 0) {
        SaveFormulaOtg(id, body)
            .then(() => {
                setAlertCustom({
                    open: true,
                    title: "Confirmacion",
                    severity: "success",
                    message: "Se guardo correctamente",
                });
                refetch();
                restart();
            })
            .catch((error) => {
                setAlertCustom({
                    open: true,
                    title: "Error",
                    severity: "error",
                    message: error.measures,
                });
            });
    } else {
        
        restart();
    }
}
