import { Box, Typography } from "@mui/material";
import { Form, Field } from "react-final-form";
import { AutoComplete } from "../../../components/AutoComplete";
import Button from "../../../components/Button";
import { CheckBoxMuiOneOrZero } from "../../../components/CheckBoxMui";
import { InputText } from "../../../components/Input";
import { SaveMotivoDePausa } from "../../../services/ordenTrabajo";

const UpdateMotivoPausaOtg = ({
  ordentrabajoMotivoPausa,
  setAlertCustom,
  refetch,
}) => {
  return (
    <Form
      onSubmit={(values) => {
        SaveMotivoDePausa({
          ...values,
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
            refetch();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={{}}
      validate={(values) => {
        return {};
      }}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        return (
          <Box sx={{ width: "100%" }}>
            {console.log(values)}
            <Box>
              <Field
                name={`motivo`}
                component={AutoComplete as any}
                sx={{ width: "100%" }}
                label={"Motivo de pausa"}
                options={ordentrabajoMotivoPausa.map((item) => {
                  return {
                    id: item.ordenTrabajoMotivoPausaId,
                    label: item.ordenTrabajoMotivoPausaDescripcion,
                  };
                })}
                onchange={(evt) => {
                  if (evt) {
                    restart({
                      motivo: evt,
                      ...ordentrabajoMotivoPausa
                        .filter(
                          (item) => item.ordenTrabajoMotivoPausaId === evt.id
                        )
                        .map((x) => {
                          return {
                            ...x,
                          };
                        })[0],
                    });
                  } else {
                    restart({});
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                mt: 2,
              }}
            >
              <Box className="col-9" sx={{ pr: 1 }}>
                <Field
                  disabled={!values.motivo}
                  component={InputText as any}
                  name={"ordenTrabajoMotivoPausaDescripcion"}
                  label={"Descripcion"}
                />
              </Box>
              <Box
                className="col-3"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ pr: 1 }}>Activo</Typography>

                <Field
                  disabled={!values.motivo}
                  component={CheckBoxMuiOneOrZero as any}
                  name={"ordenTrabajoMotivoPausaActivo"}
                />
              </Box>
            </Box>
            {!!values.motivo &&
              (ordentrabajoMotivoPausa.filter(
                (item) => item.ordenTrabajoMotivoPausaId === values.motivo.id
              )[0]?.ordenTrabajoMotivoPausaDescripcion !==
                values.ordenTrabajoMotivoPausaDescripcion ||
                ordentrabajoMotivoPausa
                  .filter(
                    (item) =>
                      item.ordenTrabajoMotivoPausaId === values.motivo.id
                  )
                  .map((x) => {
                    return {
                      ...x,
                    };
                  })[0]?.ordenTrabajoMotivoPausaActivo !==
                  values.ordenTrabajoMotivoPausaActivo) && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                  <Button
                    label={"Cancelar"}
                    onClick={() =>
                      restart({
                        motivo: values.motivo,
                        ...ordentrabajoMotivoPausa
                          .filter(
                            (item) =>
                              item.ordenTrabajoMotivoPausaId ===
                              values.motivo.id
                          )
                          .map((x) => {
                            return {
                              ...x,
                            };
                          })[0],
                      })
                    }
                  />
                  <Button
                    label={"Guardar Cambios"}
                    onClick={handleSubmit}
                    color="success"
                  />
                </Box>
              )}
          </Box>
        );
      }}
    />
  );
};

export default UpdateMotivoPausaOtg;
