import { useState } from "react";
import { AlertCustom } from "../../components/AlertMui";
import { IsLoadingModalProcesos } from "../../components/Animation";
import ErrorMsg from "../../components/ErrorMsg";
import { useListUsers, useListGroup } from "../../hooks/useUsers";
import { Loading } from "../loading";
import FormUpdateUser from "../UpdateUsers/component/formUpdateUser";

export const FormCreateUser = ({ CreateUser, closer }) => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
    refetch,
  } = useListUsers();
  const {
    data: listGroup,
    isError: isErrorListGroup,
    isLoading: loadingListGroup,
  } = useListGroup();
  if (loadingListGroup || loadingListUsers) {
    return (
      <IsLoadingModalProcesos
        height={"250px"}
        width={"300px"}
        open={loadingListGroup || loadingListUsers}
      />
    );
  }

  if (
    !Array.isArray(listGroup) ||
    !Array.isArray(listUsers) ||
    isErrorListGroup ||
    isErrorListUsers
  ) {
    return <ErrorMsg />;
  }
  return (
    <>
      <FormUpdateUser
        user={[]}
        listGroup={listGroup}
        setAlertCustom={setAlertCustom}
        closer={closer}
        refetch={refetch}
        CreateUser={CreateUser}
      />
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </>
  );
};
