import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import {
  Typography,
  InputAdornment,
  Box,
  Breadcrumbs,
  Link,
} from "@mui/material";
import {
  useProductsById,
  useClasification,
  useStructure,
  useMeasures,
  useProducts,
  useProcesos,
  useListprovider,
  useTypeProduct,
} from "../../hooks/useProducts";
import { Dialog, DialogGen } from "../../components/Dialog";
import { Loading } from "../loading";
import { InputText, InputFile, InputNumber } from "../../components/Input";
import ButtonCustom from "../../components/Button";
import { SelectMui } from "../../components/SelectMui";
import Layout from "../../components/Layout";
import {
  DeleteImgProducts,
  SaveProductsDetails,
} from "../../services/productServices";
import ErrorMsg from "../../components/ErrorMsg";
import { isObjEmpty } from "../../utils/isObjectEmpty";
import { SwitchMui } from "../../components/SwichMui";
import { required } from "../../utils/validations";
import { getUser, usePermisos } from "../../hooks/auth";
import { verificarPermiso } from "../../utils/verificarPermiso";
import { PERMISOS } from "../../constants/MenuOptions";
import { FormStructure } from "./components/formStructura";
import { useListUsers } from "../../hooks/useUsers";
import Button from "../../components/Button";
const DetailsProducts = (params) => {
  let { id, array } = useParams();

  const idEstructura = id;
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
  } = useListUsers();
  const [datos, setDatos] = useState([]);

  const [inactivarFormulario, setInactivarFormulario] = useState(false);

  const [showModalConfir, setShowModalConfir] = useState({
    open: false,
    values: [],
  });
  const [valueInputImage, setValueInputImage] = useState(false);
  const [showModalConfirDeleteImage, setShowModalConfirDeleteImage] = useState({
    open: false,
    onClick: () => {},
  });
  const {
    data: productDetails1,
    isLoading: isLoadingProducts,
    isError: isErrorProductDetails1,
    refetch: refetchProductDetails1,
  } = useProductsById(id);

  const {
    data: clasifications,
    isLoading: isLoadingClasification,
    isError: isErrorClasifications,
  } = useClasification();
  const {
    data: structure,
    isLoading: isLoadingStructure,
    isError: isErrorStructure,
    refetch,
  } = useStructure(idEstructura);

  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();
  const {
    data: procesos,
    isLoading: isLoadingProcesos,
    isError: isErrorProcesos,
  } = useProcesos();
  const {
    data: products,
    isLoading,
    isError: isErrorProducts,
    refetch: refetchProducts,
  } = useProducts(true);
  const {
    data: listProvider,
    isLoading: isLoadingListProvider,
    isError: isErrorListProvider,
  } = useListprovider(true);
  const {
    data: listTypeProduct,
    isLoading: isLoadingListTypeProduct,
    isError: isErrorListTypeProduct,
  } = useTypeProduct();

  const [mensaje, setMensaje] = useState({ open: false, title: "", body: "" });
  const [initialValue, setInitialValue] = useState(null);
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  useEffect(() => {
    if (!isLoadingStructure || structure !== undefined) {
      setDatos(structure);
    }
  }, [isLoadingStructure, structure]);
  useEffect(() => {
    setInitialValue({
      ...productDetails1,
    });
  }, [id, productDetails1]);

  if (
    isLoading ||
    !initialValue ||
    !permisos ||
    isLoadingProducts ||
    isLoadingClasification ||
    isLoadingMeasures ||
    isLoadingProcesos ||
    isLoadingListProvider ||
    isLoadingPermisos ||
    loadingListUsers ||
    isLoadingStructure ||
    isLoadingListTypeProduct
  ) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  if (
    isObjEmpty(productDetails1) ||
    !Array.isArray(clasifications) ||
    !Array.isArray(structure) ||
    !Array.isArray(measures) ||
    !Array.isArray(procesos) ||
    !Array.isArray(products) ||
    !Array.isArray(listProvider) ||
    !Array.isArray(listTypeProduct) ||
    isErrorProductDetails1 ||
    isErrorMeasures ||
    isErrorClasifications ||
    isErrorStructure ||
    isErrorProducts ||
    isErrorListProvider ||
    isErrorPermisos ||
    isErrorListUsers ||
    isErrorProcesos ||
    isErrorListTypeProduct
  ) {
    return <ErrorMsg />;
  }
  const saveInfoDetails = async (
    values,
    showModal = true,
    tieneEstructura = false
  ) => {
    const { skuStockSeguridad, skuUnidadMedidaId } = values;

    const clasificacion = [
      {
        categoriaInferior: "lustre",
        categoriaSuperior: "",
        valor: "lustre",
        id: 220,
      },
      {
        categoriaInferior: "tela",
        categoriaSuperior: "",
        valor: "tela",
        id: 219,
      },
      {
        categoriaInferior: "tipologia",
        categoriaSuperior: "",
        valor: "tipologia",
        id: 223,
      },
      {
        categoriaInferior: "color",
        categoriaSuperior: "",
        valor: "color",
        id: 224,
      },
      ...clasifications
        .filter(
          ({ ingresaDato, ...item }) =>
            item.id === values?.skuLustre ||
            item.id === values?.skuTipologia ||
            item.id === values?.skuColor ||
            item.id === values?.skuTela
        )
        .map(({ ingresaDato, ...item }) => {
          return {
            ...item,
            valor: item?.categoriaInferior,
          };
        }),
    ];
    const skuActivo =
      values.skuActivo === "1"
        ? "1"
        : values.skuActivo === "0"
        ? "0"
        : values.skuActivo === true
        ? "1"
        : "0";

    let imagenes = [];
    if (
      values?.imagen?.length !== 0 &&
      values?.imagen[0]["skuImagenDescripcion"] !== null &&
      !values?.imagen[0]["skuImagenId"]
    ) {
      if (
        productDetails1?.imagen[0]?.skuImagenUrl &&
        productDetails1?.imagen[0]?.skuImagenUrl !==
          values.imagen[0].skuImagenUrl
      ) {
        await DeleteImgProducts(productDetails1?.imagen[0]?.skuImagenId);
      }
      imagenes.push({
        sku_id: values.skuId,
        sku_imagen_descripcion: values.imagen[0].skuImagenDescripcion,
        sku_imagen_url: values.imagen[0].skuImagenUrl,
      });
    }
    delete values.skuProcesoProductivoId;
    SaveProductsDetails(id, {
      ...values,
      skuStockSeguridad: Number(skuStockSeguridad),
      skuUnidadMedidaId: Number(skuUnidadMedidaId),
      skuEntidadId: Number(values?.entidadId),

      skuLeadtimeProduccionHoras:
        structure?.length > 0 ? Number(values?.skuLeadtimeProduccionHoras) : 0,
      skuLeadtime:
        structure?.length === 0 && !tieneEstructura
          ? Number(values?.skuLeadtime)
          : 0,
      skuCostoUnitario:
        structure?.length === 0 && !tieneEstructura
          ? Number(values?.skuCostoUnitario)
          : 0,

      skuActivo: skuActivo,
      clasificacion,
      imagenes,
    })
      .then(() => {
        if (showModal) {
          setMensaje({
            open: true,
            title: "Informacion",
            body: "Se guardo correctamente",
          });
        }
        refetchProductDetails1();
      })
      .catch((error) => {
        if (showModal) {
          setMensaje({
            open: true,
            title: "Error",
            body: error?.message,
          });
        }
      });
  };

  let arr = !!array ? JSON.parse(array) : [];

  return (
    <Layout title="Módulo de productos , insumos y tercerizados">
      <Box
        className="d-flex justify-content-center col-12 "
        sx={{ width: "100%" }}
      >
        <Form
          onSubmit={(values) => {
            setShowModalConfir({ open: true, values });
          }}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={initialValue ? initialValue : {}}
          render={({
            handleSubmit,
            form: {
              mutators: { push },
              restart,
            },

            submitting,
            pristine,
            values,
          }) => {
            return (
              <Box
                className="	col-12  col-md-11	"
                sx={{
                  background: "#FAFAFA",
                  p: 2,
                  borderRadius: "16px",
                }}
              >
                <Box
                  role="presentation"
                  sx={{ display: "flex", flexWrap: "wrap", px: 2 }}
                >
                  <Breadcrumbs aria-label="breadcrumb">
                    {arr.map((item) => {
                      let hrefMigaDePan = `/DetalleProductos/${item}/[${arr
                        .slice(0, arr.indexOf(item) + 1)
                        .toString()}]`;
                      return (
                        <Link
                          underline="hover"
                          color="text.primary"
                          href={hrefMigaDePan}
                        >
                          <Typography>
                            {
                              products.find(
                                (product) => item === Number(product?.skuId)
                              )?.skuDescripcion
                            }
                          </Typography>
                        </Link>
                      );
                    })}
                  </Breadcrumbs>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end", p: 1 }}>
                  <Field
                    disabled={
                      !verificarPermiso(
                        permisos,
                        PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                        listUsers?.find(
                          (item) => item?.radiusIdCodigo === getUser()?.uid
                        )?.idGrupo
                      )
                    }
                    name="skuActivo"
                    color={values.skuActivo === "0" ? "error" : "success"}
                    component={SwitchMui as any}
                    check={values.skuActivo === "0" ? false : true}
                    setInactivarFormulario={setInactivarFormulario}
                  />
                </Box>

                <Box className="col-9 col-lg-10 d-flex flex-wrap">
                  <Box className="col-12 col-sm-4 col-md-6 col-lg-4 col-xl-3 p-2">
                    <Field
                      disabled={
                        inactivarFormulario ||
                        !verificarPermiso(
                          permisos,
                          PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                          listUsers?.find(
                            (item) => item?.radiusIdCodigo === getUser()?.uid
                          )?.idGrupo
                        )
                      }
                      validate={required}
                      name="skuDescripcion"
                      component={InputText as any}
                      label="Nombre"
                      sx={{ maxWidth: "300px", width: "100%" }}
                    />
                    <Field
                      disabled={
                        inactivarFormulario ||
                        !verificarPermiso(
                          permisos,
                          PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                          listUsers?.find(
                            (item) => item?.radiusIdCodigo === getUser()?.uid
                          )?.idGrupo
                        )
                      }
                      name="descripcion"
                      component={InputText as any}
                      label="Descripción"
                      values={`${values?.skuDescripcion} ${
                        values?.skuTipologia
                          ? clasifications.find(
                              (item) => item.id === values?.skuTipologia
                            )?.categoriaInferior
                          : ""
                      } ${
                        values?.skuTela
                          ? clasifications.find(
                              (item) => item.id === values?.skuTela
                            )?.categoriaInferior
                          : ""
                      } ${
                        values?.skuLustre
                          ? clasifications.find(
                              (item) => item.id === values?.skuLustre
                            )?.categoriaInferior
                          : ""
                      }  ${
                        values?.skuColor
                          ? clasifications.find(
                              (item) => item.id === values?.skuColor
                            )?.categoriaInferior
                          : ""
                      } `}
                      sx={{ maxWidth: "300px", width: "100%", mt: 1.5 }}
                    />
                    <Box sx={{ mt: 2 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name={`imagen[0]`}
                        component={InputFile as any}
                        nameUpdateFile={`${values.skuCodigo}`}
                        borrar={valueInputImage}
                        setBorrar={() => setValueInputImage(false)}
                        mostrarEliminar={
                          values?.imagen?.length > 0 &&
                          !!values?.imagen[0].skuImagenUrl
                            ? true
                            : false
                        }
                        dashabilitarEliminar={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        funcionEliminar={() => {
                          setShowModalConfirDeleteImage({
                            open: true,
                            onClick: () => {
                              setValueInputImage(true);
                            },
                          });
                        }}
                      />
                    </Box>
                  </Box>
                  <Box className="col-12 col-md-3 p-2">
                    <Box sx={{ pb: 1 }}>
                      <Field
                        component={SelectMui as any}
                        name="entidadId"
                        label="Proveedor preferencial"
                        disabled={
                          structure?.length !== 0 ||
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        options={listProvider.map((item) => {
                          return {
                            name: item.entidadDescripcion,
                            value: item.entidadId,
                          };
                        })}
                        optionSelected={structure?.length !== 0 ? "1" : null}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          structure?.length !== 0 ||
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuLeadtime"
                        component={InputNumber as any}
                        label="Tiempo de Compra"
                        sx={{ width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Dias</InputAdornment>
                          ),
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          structure?.length === 0 ||
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuLeadtimeProduccionHoras"
                        component={InputNumber as any}
                        label="Tiempo de produccion"
                        sx={{ width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              Horas
                            </InputAdornment>
                          ),
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuStockSeguridad"
                        component={InputNumber as any}
                        label="Stock de seguridad"
                        sx={{ width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {
                                measures?.find(
                                  (item) =>
                                    item?.unidadMedidaId ===
                                    values?.skuUnidadMedidaId
                                )?.unidadMedida
                              }
                            </InputAdornment>
                          ),
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuTela"
                        component={SelectMui as any}
                        label="Tela"
                        sx={{ width: "100%" }}
                        options={clasifications
                          .filter((item) => item.categoriaSuperior === "tela")
                          .map((item) => {
                            return {
                              value: item.id,
                              name: item.categoriaInferior,
                            };
                          })}
                      />
                    </Box>

                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuColor"
                        component={SelectMui as any}
                        label="Color"
                        sx={{ width: "100%" }}
                        options={clasifications
                          .filter((item) => item.categoriaSuperior === "color")
                          .map((item) => {
                            return {
                              value: item.id,
                              name: item.categoriaInferior,
                            };
                          })}
                      />
                    </Box>
                  </Box>

                  <Box className="col-12 col-md-3 p-2">
                    {/* <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        validate={required}
                        name="skuTipoId"
                        component={SelectMui as any}
                        label="Tipo de producto"
                        sx={{ minWidth: 150, width: "100%" }}
                        options={listTypeProduct.map((item) => {
                          return {
                            value: item.skuTipoId,
                            name: item.skuTipoDescripcion,
                          };
                        })}
                      />
                    </Box> */}
                    <Box sx={{ pb: 1 }}>
                      <Field
                        component={InputNumber as any}
                        name="sku_precio_venta_unitario"
                        label="Precio unitario de venta"
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        sx={{ width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">$</InputAdornment>
                          ),
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ pb: 1 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        validate={required}
                        name="skuUnidadMedidaId"
                        component={SelectMui as any}
                        label="unidad de medida"
                        sx={{ minWidth: 150, width: "100%" }}
                        options={measures.map((item) => {
                          return {
                            value: item.unidadMedidaId,
                            name: item.unidadMedida,
                          };
                        })}
                      />
                    </Box>
                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          structure?.length !== 0 ||
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuCostoUnitario"
                        component={InputNumber as any}
                        label="Costo"
                        sx={{ width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">$</InputAdornment>
                          ),
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                      />
                    </Box>
                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuMbq"
                        component={InputNumber as any}
                        label="Mbq"
                        sx={{ width: "100%" }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {
                                measures?.find(
                                  (item) =>
                                    item?.unidadMedidaId ===
                                    values?.skuUnidadMedidaId
                                )?.unidadMedida
                              }
                            </InputAdornment>
                          ),
                          inputProps: {
                            style: { textAlign: "right" },
                          },
                        }}
                      />
                    </Box>

                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuTipologia"
                        component={SelectMui as any}
                        label="Tipólogia"
                        sx={{ width: "100%" }}
                        options={clasifications
                          .filter(
                            (item) => item.categoriaSuperior === "tipologia"
                          )
                          .map((item) => {
                            return {
                              value: item.id,
                              name: item.categoriaInferior,
                            };
                          })}
                      />
                    </Box>
                    <Box sx={{ pb: 1.5 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        name="skuLustre"
                        component={SelectMui as any}
                        label="Lustre"
                        sx={{ width: "100%" }}
                        options={clasifications
                          .filter((item) => item.categoriaSuperior === "lustre")
                          .map((item) => {
                            return {
                              value: item.id,
                              name: item.categoriaInferior,
                            };
                          })}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="d-flex flex-row" style={{ marginTop: "16px" }}>
                  {(pristine &&
                    productDetails1?.imagen &&
                    Array.isArray(productDetails1?.imagen) &&
                    productDetails1?.imagen[0] &&
                    productDetails1?.imagen[0]?.skuImagenUrl &&
                    productDetails1?.imagen[0]?.skuImagenUrl ===
                      values.imagen[0]["skuImagenUrl"]) ||
                  !verificarPermiso(
                    permisos,
                    PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                    listUsers?.find(
                      (item) => item?.radiusIdCodigo === getUser()?.uid
                    )?.idGrupo
                  ) ? (
                    <Box className="d-flex justify-content-center col-9">
                      <ButtonCustom
                        label={"volver al listado de producto"}
                        href={"/Productos"}
                        color="success"
                        sx={{ width: "100%", maxWidth: 300 }}
                      />
                    </Box>
                  ) : (
                    <Box className="d-flex justify-content-center col-12">
                      <ButtonCustom
                        label={"Guardar"}
                        onClick={handleSubmit}
                        disabled={
                          submitting ||
                          (pristine &&
                            productDetails1?.imagen &&
                            productDetails1?.imagen[0]?.skuImagenUrl &&
                            productDetails1?.imagen[0]?.skuImagenUrl ===
                              values.imagen &&
                            values.imagen[0]["skuImagenUrl"] &&
                            verificarPermiso(
                              permisos,
                              PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                              listUsers?.find(
                                (item) =>
                                  item?.radiusIdCodigo === getUser()?.uid
                              )?.idGrupo
                            ))
                        }
                        color="success"
                        sx={{ width: "100%", maxWidth: 300 }}
                      />
                    </Box>
                  )}
                </Box>
                <Box sx={{ mt: 4 }}>
                  <FormStructure
                    permisos={permisos}
                    inactivarFormulario={inactivarFormulario}
                    datos={datos}
                    refetch={refetch}
                    refetchProducts={refetchProducts}
                    measures={measures}
                    skuCods={products}
                    procesos={procesos}
                    idProduct={idEstructura}
                    listUsers={listUsers}
                    arr={arr}
                    saveInfoDetails={() => saveInfoDetails(values, null, true)}
                  />
                </Box>
              </Box>
            );
          }}
        />
      </Box>
      <Dialog
        open={mensaje.open}
        body={mensaje.body}
        title={mensaje.title}
        onClick={() => setMensaje({ open: false, title: "", body: "" })}
      />
      <DialogGen
        open={showModalConfir.open}
        title={"Guardar cambios?"}
        onClick={() => setShowModalConfir({ open: false, values: [] })}
      >
        <div style={{ maxWidth: 500, marginBottom: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              label="Cancelar"
              color="error"
              onClick={() => setShowModalConfir({ open: false, values: [] })}
            />
            <Button
              label="Confirmar"
              onClick={() => {
                saveInfoDetails(showModalConfir.values);
                setShowModalConfir({ open: false, values: [] });
              }}
              color="success"
            />
          </Box>
        </div>
      </DialogGen>
      <DialogGen
        open={showModalConfirDeleteImage.open}
        title={"Confirmar"}
        onClick={() =>
          setShowModalConfirDeleteImage({ open: false, onClick: () => {} })
        }
      >
        <Box style={{ maxWidth: 500 }}>
          <Box className="d-flex flex-row">
            <Typography>
              Estas Seguro que quiere Eliminar Esta imagen ?
            </Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              onClick={() => {
                setShowModalConfirDeleteImage({
                  open: false,
                  onClick: () => {},
                });
              }}
              label={"Cancelar"}
            />
            <Button
              label={"Eliminar"}
              onClick={() => {
                showModalConfirDeleteImage.onClick();
                setShowModalConfirDeleteImage({
                  open: false,
                  onClick: () => {},
                });
              }}
              color={"error"}
            />
          </Box>
        </Box>
      </DialogGen>
    </Layout>
  );
};

export default DetailsProducts;
