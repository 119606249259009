import { useState } from "react";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Box, Card, InputAdornment, Typography } from "@mui/material";
import Layout from "../../components/Layout";
import { AlertCustom } from "../../components/AlertMui";
import Button from "../../components/Button";
import { FieldArray } from "react-final-form-arrays";
import { InputDate, InputNumber, InputText } from "../../components/Input";
import { SelectMui } from "../../components/SelectMui";
import { AutoComplete } from "../../components/AutoComplete";
import { required, requiredArray } from "../../utils/validations";
import { fechaActual } from "../../utils/Fecha";
import { BsFillTrashFill as Delete } from "react-icons/bs";
import {
  useListClientes,
  useMeasures,
  useProducts,
} from "../../hooks/useProducts";
import { Loading } from "../loading";
import ErrorMsg from "../../components/ErrorMsg";
import { CheckBoxMuiOneOrZero } from "../../components/CheckBoxMui";
import { getUser } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";
import { CreateNewNotaPedido } from "./hook";
import { useEmbalaje } from "../../hooks/embalaje";
const CrearNotasPedidos = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data: listClientes,
    isLoading: isLoadingListClientes,
    isError: isErrorListCliente,
  } = useListClientes();
  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();
  const {
    data: products,
    isLoading,
    isError: isErrorProducts,
  } = useProducts(true, 1);
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
  } = useListUsers();
  const {
    data: listEmbalaje,
    isLoading: isLoadingListEmbalaje,
    isError: isErrorListEmbalaje,
  } = useEmbalaje();
  if (
    isLoadingListClientes ||
    isLoadingMeasures ||
    isLoading ||
    loadingListUsers ||
    isLoadingListEmbalaje
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(measures) ||
    !Array.isArray(listClientes) ||
    !Array.isArray(products) ||
    !Array.isArray(listUsers) ||
    isErrorListCliente ||
    isErrorMeasures ||
    isErrorProducts ||
    isErrorListUsers ||
    isErrorListEmbalaje
  ) {
    return <ErrorMsg />;
  }
  const formulario: any = {};
  const user = listUsers.find((item) => item.radiusIdCodigo === getUser().uid);
  return (
    <Layout title={"Crear nota de pedido"}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          borderRadius: 5,
          width: "100%",
        }}
      >
        <Form
          onSubmit={(values) => {
            const detalleSku = values?.detalleSku?.map((item) => {
              return {
                skuId: item?.idProducto?.id,
                cantidad: item?.cantidad,
                precioUnitario: item?.precioUnitario,
                plano_aprobado: item?.plano_aprobado,
                tela_provista_por_cliente: item?.tela_provista_por_cliente,
                embalaje_id: Number(item?.embalaje_id),
              };
            });
            const body = {
              ...values,
              detalleSku: detalleSku,
              idRadius: user?.idRadius,
            };
            CreateNewNotaPedido(body, setAlertCustom, formulario.restart);
          }}
          initialValues={{ ordenTrabajoGenerar: "1" }}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push },
              restart,
            },
            submitting,
            pristine,
            values,
          }) => {
            formulario.restart = restart;
            return (
              <Box sx={{ width: "100%" }}>
                <Box className="col-12 m-2 p-3">
                  <Box className="d-flex flex-wrap">
                    <Box className="col-12 col-md-3 col-lg-3 col-xl-3 p-1 ">
                      <Field
                        validate={required}
                        name="entidadId"
                        component={SelectMui as any}
                        label="Cliente"
                        options={listClientes?.map(
                          ({ entidadDescripcion, entidadId }) => ({
                            value: entidadId,
                            name: entidadDescripcion,
                          })
                        )}
                        sx={{ width: "100%" }}
                      />
                    </Box>

                    <Box className="col-12 col-md-3 col-lg-3 col-xl-3 p-1 ">
                      <Field
                        validate={required}
                        focused
                        component={InputDate as any}
                        min={fechaActual()}
                        sx={{ width: "100%" }}
                        name={`fechaEntrega`}
                        label={"Fecha de entrega"}
                      />
                    </Box>
                    <Box className="col-12 col-md-3 col-lg-3 col-xl-3 p-1 ">
                      <Field
                        name="comprobanteObservacion"
                        component={InputText as any}
                        label="Observacion"
                        sx={{ width: "100%" }}
                      />
                    </Box>
                    <Box
                      className="col-12 col-md-3 col-lg-3 col-xl-3"
                      sx={{ px: 1 }}
                    >
                      <Field
                        validate={required}
                        component={CheckBoxMuiOneOrZero as any}
                        sx={{ width: "100%" }}
                        name={`ordenTrabajoGenerar`}
                        label={"Generar OTGs"}
                      />
                    </Box>
                  </Box>

                  <>
                    <FieldArray name="detalleSku" validate={requiredArray}>
                      {({ fields }) => (
                        <Box>
                          {fields.map((name, index) => {
                            return (
                              <Card
                                sx={{
                                  boxShadow: "1px 1px 5px 1px grey",
                                  m: 1,
                                }}
                              >
                                <Box
                                  className="d-flex justify-content-end align-items-center col-12 px-1 pt-1"
                                  style={{ maxHeight: "40px" }}
                                >
                                  <Button
                                    label={"Eliminar"}
                                    onClick={() => fields.remove(index)}
                                    color="error"
                                  />
                                </Box>
                                <Box
                                  key={name}
                                  className="d-flex flex-wrap p-2"
                                >
                                  <Box className="col-12 col-md-3 col-lg-3 col-xl-2 p-1 ">
                                    <Field
                                      validate={required}
                                      name={`${name}.idProducto`}
                                      component={AutoComplete as any}
                                      sx={{ width: "100%" }}
                                      options={products
                                        ?.filter(
                                          (product) =>
                                            !values?.detalleSku?.find(
                                              (productFormulario) =>
                                                productFormulario?.idProducto
                                                  ?.id === product?.skuId
                                            )
                                        )
                                        .map((item) => {
                                          return {
                                            id: item.skuId,
                                            label: item.skuCodigo,
                                            name: item.skuDescripcion,
                                            unidadMedidaId: item.unidadMedidaId,
                                            unidadMedida: item.unidadMedida,
                                          };
                                        })}
                                    />
                                  </Box>
                                  <Box
                                    className="d-flex align-items-center col-12 col-md-3 col-lg-3 col-xl-2 "
                                    sx={{ px: 1 }}
                                  >
                                    <Typography
                                      variant="subtitle1"
                                      alignItems="center"
                                    >
                                      {
                                        values?.detalleSku[index]?.idProducto
                                          ?.name
                                      }
                                    </Typography>
                                  </Box>
                                  <Box className="col-12 col-md-3 col-lg-3 col-xl-2 p-1 ">
                                    <Field
                                      name={`${name}.tela_provista_por_cliente`}
                                      label="Provee tela"
                                      component={SelectMui as any}
                                      options={[
                                        { value: "0", name: "Poltron" },
                                        { value: "1", name: "Cliente" },
                                      ].map((item) => ({
                                        value: item.value,
                                        name: item.name,
                                      }))}
                                      sx={{ width: "100%" }}
                                    />
                                  </Box>
                                  <Box className="col-12 col-md-3 col-lg-3 col-xl-2 p-1 ">
                                    <Field
                                      name={`${name}.embalaje_id`}
                                      label="Embalaje"
                                      component={SelectMui as any}
                                      options={listEmbalaje?.map((item) => ({
                                        value: item?.embalajeId,
                                        name: item?.embalajeDescripcion,
                                      }))}
                                      sx={{ width: "100%" }}
                                    />
                                  </Box>
                                  <Box
                                    className="col-12 col-md-3 col-lg-3 col-xl-1 p-1 "
                                    style={{ textAlign: "start" }}
                                  >
                                    <Field
                                      validate={(value) =>
                                        !value
                                          ? "Requerido"
                                          : value <= 0
                                          ? "Debe ser mayor a 0"
                                          : undefined
                                      }
                                      name={`${name}.cantidad`}
                                      label="Cantidad"
                                      component={InputNumber as any}
                                      sx={{ width: "100%" }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {
                                              values?.detalleSku[index]
                                                ?.idProducto?.unidadMedida
                                            }
                                          </InputAdornment>
                                        ),
                                        inputProps: {
                                          style: { textAlign: "right" },
                                        },
                                      }}
                                    />
                                  </Box>

                                  <Box
                                    className="col-12 col-md-3 col-lg-3 col-xl-1 p-1 "
                                    style={{ textAlign: "start" }}
                                  >
                                    <Field
                                      validate={(value) =>
                                        !value
                                          ? "Requerido"
                                          : value <= 0
                                          ? "Debe ser mayor a 0"
                                          : undefined
                                      }
                                      name={`${name}.precioUnitario`}
                                      label="Precio U."
                                      component={InputNumber as any}
                                      sx={{ width: "100%" }}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            $
                                          </InputAdornment>
                                        ),
                                        inputProps: {
                                          style: { textAlign: "right" },
                                        },
                                      }}
                                    />
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",

                                      "@media (max-width:767px)": {
                                        justifyContent: "center",
                                      },
                                    }}
                                    className="col-12 col-sm-12 col-md-2 xl-1 px-1 "
                                    style={{ maxHeight: "40px" }}
                                  >
                                    <Field
                                      component={CheckBoxMuiOneOrZero as any}
                                      name={`${name}.plano_aprobado`}
                                      label="Plano aprobado"
                                    />
                                  </Box>
                                </Box>
                              </Card>
                            );
                          })}
                        </Box>
                      )}
                    </FieldArray>
                    <Box
                      className="d-flex align-items-center"
                      style={{ marginTop: "16px" }}
                    >
                      <Box sx={{ ml: 3 }} className="col-4 ">
                        <Button
                          label={"Agregar producto"}
                          onClick={() => push("detalleSku", {})}
                        />
                      </Box>
                    </Box>
                  </>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "end", mr: 5 }}>
                  <Typography variant="h3">
                    {`Total  $${
                      values?.detalleSku
                        ? values?.detalleSku?.reduce(
                            (acumulador, actual) =>
                              acumulador +
                              (actual?.cantidad * actual?.precioUnitario
                                ? actual?.cantidad * actual?.precioUnitario
                                : 0),
                            0
                          )
                        : 0
                    }`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  <Button
                    label={"generar nota de pedido"}
                    onClick={handleSubmit}
                    sx={{ width: "100%", maxWidth: 300 }}
                    color="success"
                    disabled={submitting || pristine}
                  />
                </Box>
              </Box>
            );
          }}
        />
      </Box>

      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({ open: false, title: "", severity: "", message: "" })
        }
      />
    </Layout>
  );
};
export default CrearNotasPedidos;
