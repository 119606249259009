import { Button } from "@mui/material";
import { Form } from "react-final-form";
import {
  useListDeposito,
  useListprovider,
  useListStockState,
  useListUbicationProvider,
  useMeasures,
  useProducts,
} from "../../hooks/useProducts";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box } from "@mui/system";
import { DialogGen } from "../../components/Dialog";
import { useState } from "react";
import { useListDetailsOC } from "../../hooks/useStock";

import { postDataIngreso } from "../../services/productServices";
import { AlertCustom } from "../../components/AlertMui";
import { fechaActual } from "../../utils/Fecha";
import ButtonCustom from "../../components/Button";
import Layout from "../../components/Layout";
import { requiredArray } from "../../utils/validations";

import { EncabezadoTabla } from "./components/encabezadoTabla";
import { ItemCard } from "../income/components/itemCard";
import { VistPreviaDatos } from "./components/vistaPreviaDatos";
import { armarObjeto } from "./hook";
import { FieldForm } from "./components/fieldForm";
import { Loading } from "../loading";

const Income = () => {
  const {
    data: listUbicationProvider,
    isLoading: isLoadingListUbicationProvider,
  } = useListUbicationProvider(0);
  const { data: listProvider, isLoading: isLoadingListProvider } =
    useListprovider();
  const {
    data: listDetailsOC,
    isLoading: isLoadingListDetailsOC,
    refetch,
  } = useListDetailsOC();
  const { data: listDeposito, isLoading: isLoadingDeposito } =
    useListDeposito();
  const { data: listStockState, isLoading: isLoadingListStockState } =
    useListStockState();
  const [showModalConfir, setShowModalConfir] = useState({
    open: false,
    values: null,
  });
  const [initialValues, setInitialValues] = useState({
    estado: "3",
    tipo: "1",
    datos: [],
  });
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });

  const { data: measures, isLoading: isLoadingMeasures } = useMeasures();
  const { data: products, isLoading: isLoadingProducts } = useProducts();

  if (
    isLoadingMeasures ||
    isLoadingProducts ||
    isLoadingListProvider ||
    isLoadingListUbicationProvider ||
    isLoadingListDetailsOC ||
    isLoadingDeposito ||
    isLoadingListStockState
  ) {
    return <Loading />;
  }
  return (
    <Layout title="Ingresos">
      <Form
        onSubmit={(values) => {
          const datosAenviar = armarObjeto(values, products, measures);

          setShowModalConfir({ open: true, values: datosAenviar });
        }}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          values,
          form: {
            mutators: { push },
            restart,
          },
          invalid,
          submitting,
          pristine,
        }) => {
          return (
            <>
              <Box
                className="d-flex flex-wrap "
                style={{ padding: "8px 24px 8px 24px" }}
              >
                <DialogGen
                  open={showModalConfir.open}
                  title={"Confirmar"}
                  onClick={() =>
                    setShowModalConfir({ open: false, values: [] })
                  }
                  sxBody={{ maxHeight: "500px" }}
                  sx={{ width: "90%", maxHeight: "90%" }}
                >
                  <VistPreviaDatos
                    values={showModalConfir.values}
                    listDetailsOC={listDetailsOC}
                    listUbicationProvider={listUbicationProvider}
                    listStockState={listStockState}
                    measures={measures}
                  />
                  <Box className="d-flex flex-row mt-4">
                    <Button
                      className="button"
                      onClick={() =>
                        setShowModalConfir({ open: false, values: null })
                      }
                    >
                      editar
                    </Button>
                    <Button
                      className="button mx-4"
                      onClick={() => {
                        postDataIngreso(showModalConfir.values.body)
                          .then(() => {
                            setShowModalConfir({ open: false, values: null });
                            setAlertCustom({
                              open: true,
                              title: "Confirmacion",
                              severity: "success",
                              message: "transacción exitosa",
                            });
                            restart({
                              estado: "",
                              tipo: "",
                              fecha: fechaActual(),
                              datos: [],
                            });
                            refetch();
                          })
                          .catch((response) => {
                            setShowModalConfir({ open: false, values: null });
                            setAlertCustom({
                              open: true,
                              title: "Error",
                              severity: "error",
                              message: `${response.message}`,
                            });
                          });
                      }}
                    >
                      Confirmar ingreso
                    </Button>
                  </Box>
                </DialogGen>

                <FieldForm
                  values={values}
                  listProvider={listProvider}
                  listDetailsOC={listDetailsOC}
                  listDeposito={listDeposito}
                  listUbicationProvider={listUbicationProvider}
                  setInitialValues={(valor) => setInitialValues(valor)}
                />

                <Box
                  className="col-12 mt-5"
                  sx={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}
                >
                  <EncabezadoTabla />
                </Box>

                <FieldArray name="datos" validate={requiredArray}>
                  {({ fields }) => (
                    <ItemCard
                      fields={fields}
                      push={(valor) => push(valor)}
                      values={values}
                      invalid={invalid}
                    />
                  )}
                </FieldArray>
                <Box className="d-flex justify-content-between col-8 mt-5">
                  <Box className="col-2">
                    <ButtonCustom
                      label="Reset"
                      sx={{ width: "100%" }}
                      onClick={() =>
                        setInitialValues({
                          ...values,
                          datos: listDetailsOC.filter(
                            (item) => item.comprobanteId === values.OC
                          ),
                        })
                      }
                    />
                  </Box>
                  <Box className="col-6">
                    <ButtonCustom
                      label="Procesar"
                      color="success"
                      sx={{ width: "100%" }}
                      onClick={handleSubmit}
                      disabled={submitting || pristine}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          );
        }}
      />
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({ open: false, title: "", severity: "", message: "" })
        }
      />
    </Layout>
  );
};

export default Income;
