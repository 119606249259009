export const convertDateDDMMAAAA = (date) => {
  if (!date) {
    return "";
  }
  return `${date.substr(8, 2)}-${date.substr(5, 2)}-${date.substr(0, 4)}`;
};
export const fechaActual = (dias = 0) => {
  var today = new Date(),
    date =
      today.getFullYear().toString() +
      "-" +
      `${
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1
      }` +
      "-" +
      `${
        today.getDate() + dias < 10
          ? `0${today.getDate() + dias}`
          : today.getDate() + dias
      }`;

  return date;
};
