import { fetchContent, Method } from "../utils/fetchContent";
export const GetConversionUnidadMedida = async () => {

  try {
    return await fetchContent(`/unidad_medida_conversion_listado`);
  } catch (error) {
    throw (error)
  }
}
export const UpdateConversionUnidadMedida = async (body) => {

  try {
    return await fetchContent(`/unidad_medida_conversion_update` , {
        body,
        method: Method.POST,
      });
  } catch (error) {
    throw (error)
  }
}