import { Button as ButtonMui, Typography } from "@mui/material";

interface ButtonProps {
  label: string;
  onClick?: () => void;
  href?: string;
  disabled?: boolean;
  color?:
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  sx?: any;
  hidden?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick = () => {},
  href = null,
  color = "primary",
  disabled,
  sx = {},
  hidden = false,
  children,
}) => {
  return (
    <ButtonMui
      onClick={onClick}
      variant="contained"
      color={color}
      hidden={hidden}
      disabled={disabled}
      href={href ? href : undefined}
      sx={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: 8,
        display: "flex",
        justifyContent: children ? "space-between" : "center",
        ...sx,
      }}
    >
      {children}
      <Typography
        variant="caption"
        sx={href && color !== "primary" && { color: "#FAFAFA" }}
      >
        {label}
      </Typography>
    </ButtonMui>
  );
};
export default Button;
