import { TableRow, TableCell, IconButton, Collapse, Box } from "@mui/material";
import { useState, Fragment } from "react";
import Button from "../../../components/Button";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from "@mui/icons-material/Edit";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";
export const AddDataTable = (props) => {
  const { row, ImgProducts, permisos, listUsers } = props;

  const [open, setOpen] = useState(false);

  const img = ImgProducts.find(
    (item) => item.skuId === row.skuId
  )?.skuImagenUrl;

  return (
    <Fragment>
      <TableRow>
        <TableCell style={{ paddingTop: 5, paddingBottom: 5 }}>
          {img && <img src={img} alt="" className="imgProduct" />}
        </TableCell>
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
          {row.skuCodigo}
        </TableCell>
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
          {row.skuDescripcion}
        </TableCell>
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} align="right">
          {row.skuLeadtime}
        </TableCell>
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} align="right">
          {row.skuMbq}
        </TableCell>
        <TableCell
          style={{
            display: "flex",
            paddingTop: 10,
            paddingBottom: 0,
            justifyContent: "center",
          }}
        >
          <Button
            disabled={
              !verificarPermiso(
                permisos,
                PERMISOS.NAVEGACION_GENERAL_SIN_BOTON_CREAR_NI_EDITAR,
                listUsers?.find(
                  (item) => item?.radiusIdCodigo === getUser()?.uid
                )?.idGrupo
              )
            }
            label="editar"
            color={row.skuActivo === "1" ? "primary" : "error"}
            href={`/DetalleProductos/${row.skuId}/[${row.skuId}]`}
            sx={{ maxWidth: 100 }}
          >
            <EditIcon sx={{ pr: 1 }} />
          </Button>
        </TableCell>
        <TableCell style={{ paddingTop: 0, paddingBottom: 0 }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}></Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
