export function imprimirElemento(elemento) {
  var ventana = window.open("", "PRINT", "height=400,width=600");
  ventana.document.write(elemento.innerHTML);
  ventana.document.close();
  ventana.focus();
  ventana.onload = function () {
    ventana.print();
    ventana.close();
  };
  return true;
}
