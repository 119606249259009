import { useState, useEffect } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ReactPaginate from "react-paginate";
import { IsMobi } from "../../../hooks/isMobi";
import "../../../App.css";
const TableMovement = ({ datos, header, nameRow, paginate, sx = {} }) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const items = datos;
  const itemsPerPage = 5;

  const [itemOffset, setItemOffset] = useState(0);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="d-flex flex-column mx-3" style={{ maxWidth: "100%" }}>
        {currentItems && (
          <TableMui datos={datos} header={header} nameRow={nameRow} sx={sx} />
        )}
      </div>
      {paginate && (
        <div
          className={!IsMobi() && "d-flex justify-content-center "}
          style={{ display: "solid", overflowX: "auto", marginTop: "16px" }}
        >
          <ReactPaginate
            nextLabel="Siguiente >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={IsMobi() ? 2 : 5}
            pageCount={pageCount}
            previousLabel="< Anterior"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
};
function TableMui({ datos, header, nameRow, sx = {} }) {
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table>
            <TableHead
              className="headTable"
              style={{
                position: "sticky",
                top: "-10px",
                backgroundColor: "rgb(184, 184, 184)",
              }}
              sx={sx}
            >
              <TableRow>
                {header.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {datos.map((item) => (
                <TableRow>
                  {nameRow.map((row) => (
                    <TableCell>{item[row]}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default TableMovement;
