import { Field } from "react-final-form";
import { Box } from "@mui/material";
import { InputNumber } from "../../../components/Input";
import { required } from "../../../utils/validations";
import Button from "../../../components/Button";
import { SelectMui } from "../../../components/SelectMui";

const CardConversionUnidadMedida = ({ name, measures, onclick, disabled }) => {
  return (
    <Box
      className="col-12 col-lg-10 col-xl-8"
      sx={{ display: "flex", flexWrap: "wrap" }}
    >
      <Box className="col-3" sx={{ p: 1 }}>
        <Field
          validate={required}
          name={`${name}.unidadMedidaConversionUnidadMedidaIdOrigen`}
          label="Unidad medida origen"
          component={SelectMui as any}
          options={measures.map((item) => {
            return {
              value: item.unidadMedidaId,
              name: item.unidadMedida,
            };
          })}
          sx={{ minWidth: 150, width: "100%" }}
        />
      </Box>
      <Box className="col-3" sx={{ p: 1 }}>
        <Field
          validate={required}
          name={`${name}.unidadMedidaConversionUnidadMedidaIdDestino`}
          label="Unidad medida destino"
          component={SelectMui as any}
          options={measures.map((item) => {
            return {
              value: item.unidadMedidaId,
              name: item.unidadMedida,
            };
          })}
          sx={{ minWidth: 150, width: "100%" }}
        />
      </Box>
      <Box className="col-3" sx={{ p: 1 }}>
        <Field
          validate={required}
          name={`${name}.unidadMedidaConversionCoeficiente`}
          component={InputNumber as any}
          label="Coeficiente"
        />
      </Box>
      <Box
        className="col-2"
        sx={{ display: "flex", alignItems: "center", maxHeight: 50 }}
      >
        <Button label={"Aplicar"} onClick={onclick} disabled={disabled} />
      </Box>
    </Box>
  );
};
export default CardConversionUnidadMedida;
