import { Box, Typography, Chip, TextField, Button } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Field } from "react-final-form";
import { DialogSelect } from "../../../components/SelectMui";
import { SaveStorage } from "../../../utils/localStorage";
import { getColorMaterial } from "../../../utils/getColorMaterial";
import { numeroOtg } from "../../../utils/numeroOtg";
import {
  getBackgroundColorChip,
  getColorChip,
} from "../../../utils/getColorChip";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";

export const CardOTG = ({
  datos: {
    ordenTrabajoId,
    ordenTrabajoEstadoDescripcion,
    ordenTrabajoCantidad,
    ordenTrabajoCantidadProducida,
    skuDescripcion,
    index,
    ordenTrabajoEstadoId,
    estados,
    ordenTrabajoPrioridad,
    ordenTrabajoStatusMateriales,
    datosGuardarStorage,
    permisos,
    boton = false,
    listUsers,
    sx = {},
  },
}) => {
  console.log(listUsers);
  const href = `/detailsOrdenTrabajo/${ordenTrabajoId}`;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        border: "0.5px solid #C1C1C1",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        p: 1,
        mt: 1,
        ...sx,
      }}
    >
      {boton ? (
        <BodyFuncion
          ordenTrabajoStatusMateriales={ordenTrabajoStatusMateriales}
          ordenTrabajoId={ordenTrabajoId}
          ordenTrabajoEstadoDescripcion={ordenTrabajoEstadoDescripcion}
          ordenTrabajoCantidad={ordenTrabajoCantidad}
          ordenTrabajoCantidadProducida={ordenTrabajoCantidadProducida}
          skuDescripcion={skuDescripcion}
          index={index}
          permisos={permisos}
          ordenTrabajoEstadoId={ordenTrabajoEstadoId}
          boton={boton}
          estados={estados}
          ordenTrabajoPrioridad={ordenTrabajoPrioridad}
          listUsers={listUsers}
        />
      ) : (
        <Button
          href={href}
          style={{ color: "black" }}
          onClick={() =>
            SaveStorage("datos", JSON.stringify(datosGuardarStorage))
          }
          disabled={
            boton ||
            !verificarPermiso(
              permisos,
              PERMISOS.FLECHA_PARA_DETALLE_DE_LAS_OTG,
              listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
                ?.idGrupo
            )
          }
        >
          <BodyFuncion
            ordenTrabajoStatusMateriales={ordenTrabajoStatusMateriales}
            ordenTrabajoId={ordenTrabajoId}
            ordenTrabajoEstadoDescripcion={ordenTrabajoEstadoDescripcion}
            ordenTrabajoCantidad={ordenTrabajoCantidad}
            ordenTrabajoCantidadProducida={ordenTrabajoCantidadProducida}
            skuDescripcion={skuDescripcion}
            index={index}
            ordenTrabajoEstadoId={ordenTrabajoEstadoId}
            boton={boton}
            estados={estados}
            ordenTrabajoPrioridad={ordenTrabajoPrioridad}
            permisos={permisos}
            listUsers={listUsers}
          />
        </Button>
      )}
    </Box>
  );
};
const BodyFuncion = ({
  ordenTrabajoId,
  ordenTrabajoEstadoDescripcion,
  ordenTrabajoCantidad,
  ordenTrabajoCantidadProducida,
  skuDescripcion,
  index,
  listUsers,
  ordenTrabajoPrioridad,
  ordenTrabajoEstadoId,
  boton,
  permisos,
  ordenTrabajoStatusMateriales,
  estados,
}) => (
  <>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Prioridad
      </Typography>

      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {ordenTrabajoPrioridad}
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className="col-5"
    >
      <Typography sx={{ fontSize: "10px" }}>{`OTG - ${numeroOtg(
        Number(ordenTrabajoId)
      )}`}</Typography>
      <Typography
        sx={{
          mt: 0.1,
          fontSize: "10px",
          px: 1,
        }}
      >
        {skuDescripcion}
      </Typography>
      {boton ? (
        <Field
          disabled={
            !verificarPermiso(
              permisos,
              PERMISOS.CAMBIAR_ESTADO_DE_LA_OTG_PLAN,
              listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
                ?.idGrupo
            )
          }
          component={DialogSelect as any}
          name={`ordenTrabajoEstadoId`}
          options={estados.map((item) => {
            return {
              name: item.ordenTrabajoEstadoDescripcion,
              value: item.ordenTrabajoEstadoId,
            };
          })}
          sxChip={{ mt: 1, fontSize: "10px" }}
          color={getColorChip(ordenTrabajoEstadoId)}
          backgroundColor={getBackgroundColorChip(ordenTrabajoEstadoId)}
          estados={estados}
        />
      ) : (
        <Chip
          size="small"
          label={ordenTrabajoEstadoDescripcion}
          variant="outlined"
          color={getColorChip(ordenTrabajoEstadoId)}
          sx={{
            backgroundColor: getBackgroundColorChip(ordenTrabajoEstadoId),
            mt: 1,
            color: "black",
            fontSize: "10px",
          }}
        />
      )}
    </Box>
    <Box className="col-4">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography variant="body1" justifyContent={"center"}>
          Materiales
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircleIcon
            color={getColorMaterial(Number(ordenTrabajoStatusMateriales))}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 0.2 }}>
        <Typography>{`${ordenTrabajoCantidadProducida} / ${ordenTrabajoCantidad}`}</Typography>
      </Box>
    </Box>
  </>
);
