import { useState } from "react";
import { useParams } from "react-router";
import { Box } from "@mui/material";
import Layout from "../../components/Layout";
import { Loading } from "../loading";
import {
  useAllOrdenTrabajo,
  useListadoHorasPorUsuario,
  useOrdenTrabajoMotivoPausa,
  useOrdenTrabajoUsuario,
} from "../../hooks/useOrdenTrabajo";
import { AlertCustom } from "../../components/AlertMui";
import { Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { CardOtgHorasTrabajo } from "./component/cardOtgHorasTrabajo";
import { DialogGen } from "../../components/Dialog";
import { ModalMenuStop } from "../horasDeTrabajoMobi/component/modalMenuStop";
import { SaveActividadUsuario } from "../../services/ordenTrabajo";
import { getUser } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";
import ErrorMsg from "../../components/ErrorMsg";

const HorasDeTrabajo = () => {
  let { id } = useParams();
  const [showModalStop, setShowModalStop] = useState({
    open: false,
    userId: "",
  });
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const { data, isLoading, isError } = useAllOrdenTrabajo();
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
  } = useListUsers();
  const {
    data: ordentrabajoUsuario,
    isLoading: isLoadingOrdentrabajoUsuario,
    isError: isErrorOrdentrabajoUsuario,
  } = useOrdenTrabajoUsuario(id);
  const {
    data: listadoHorasPorUsuario,
    isLoading: isLoadingListadoHorasPorUsuario,
    isError: isErrorListadoHorasPorUsuario,
    refetch: refetchListadoHorasPorUsuario,
  } = useListadoHorasPorUsuario(id);
  const {
    data: ordentrabajoMotivoPausa,
    isLoading: isLoadingOrdentrabajoMotivoPausa,
    isError: isErrorOrdentrabajoMotivoPausa,
  } = useOrdenTrabajoMotivoPausa();
  if (
    isLoading ||
    loadingListUsers ||
    isLoadingOrdentrabajoUsuario ||
    isLoadingListadoHorasPorUsuario ||
    isLoadingOrdentrabajoMotivoPausa
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(data) ||
    !Array.isArray(ordentrabajoUsuario) ||
    !Array.isArray(listadoHorasPorUsuario) ||
    !Array.isArray(listUsers) ||
    !Array.isArray(ordentrabajoMotivoPausa) ||
    isError ||
    isErrorOrdentrabajoUsuario ||
    isErrorListadoHorasPorUsuario ||
    isErrorListUsers ||
    isErrorOrdentrabajoMotivoPausa
  ) {
    return <ErrorMsg />;
  }

  const otgFiltrada = data.filter((item) => item.ordenTrabajoId === id);
  const otg = ordentrabajoUsuario.map((item) => {
    return {
      ...otgFiltrada[0],
      estadoOtgId: listadoHorasPorUsuario.filter(
        (item2) => item2.idRadius === item.idRadius
      )[0]?.ordenTrabajoEstadoId
        ? listadoHorasPorUsuario.filter(
            (item2) => item2.idRadius === item.idRadius
          )[0]?.ordenTrabajoEstadoId
        : "3",
      user: item?.nombre,
      userId: item.idRadius,
    };
  });

  // const estadoOtgId = listadoHorasPorUsuario.filter(
  //   (item) => item.idRadius === user.idRadius
  // )[0]?.ordenTrabajoEstadoId;
  // const playUserOtg = !!ordentrabajoUsuario.find(
  //   (item) => item.radiusId === user.radiusId
  // );

  return (
    <>
      <Layout title={"Horas de trabajo"} clase={false}>
        <Box sx={{ width: "100%" }}>
          <Form
            onSubmit={(values) => {}}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={{ datos: otg }}
            render={({
              handleSubmit,
              submitting,
              pristine,
              values,
              invalid,
              form: {
                restart,
                mutators: { push },
              },
            }) => (
              <Box
                sx={{
                  m: 1,
                  p: 1,
                  background: "#FFFFFF",
                }}
              >
                <FieldArray name="datos">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <>
                          <CardOtgHorasTrabajo
                            datos={{
                              ...values.datos[index],
                              setShowModalStop,
                              id,
                              user: values.datos[index].user,
                              userId: values.datos[index].userId,
                              setAlertCustom,
                              estadoOtgId: values.datos[index].estadoOtgId,
                              refetchListadoHorasPorUsuario,
                            }}
                          />
                        </>
                      ))}
                    </>
                  )}
                </FieldArray>
              </Box>
            )}
          />
        </Box>
      </Layout>
      <AlertCustom
        open={alertCustom.open}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
        severity={alertCustom.severity}
        title={alertCustom.title}
        message={alertCustom.message}
      />
      <DialogGen
        open={showModalStop.open}
        onClick={() => setShowModalStop({ open: false, userId: "" })}
        title={"Debe Seleccionar un motivo de parada"}
        sx={{ maxWidth: "300px" }}
      >
        <ModalMenuStop
          ordentrabajoMotivoPausa={ordentrabajoMotivoPausa}
          SaveActividadUsuario={SaveActividadUsuario}
          user={showModalStop.userId}
          id={id}
          closer={() => setShowModalStop({ open: false, userId: "" })}
          setAlertCustom={setAlertCustom}
          refetchListadoHorasPorUsuario={refetchListadoHorasPorUsuario}
        />
      </DialogGen>
    </>
  );
};
export default HorasDeTrabajo;
