import { useQuery } from 'react-query';
import { GetCotizaciones, GetMonedas } from '../services/cotizacion';
import { OrderGen } from '../utils/orderGen';
 export const useMonedas = () => {

     const { data, isLoading, isError, refetch } = useQuery('moneda', async() => {
         const response = await GetMonedas();
         OrderGen(response?.data, "monedaOrden");
         return response;
     });
  return {
    data: data?.data,
    isLoading,
    refetch,
    isError
   }
}
 export const useCotizacion = () => {

     const { data, isLoading, isError, refetch } = useQuery('cotizacion', async() => {
         const response = await GetCotizaciones();
        //  OrderGen(response?.data, "monedaOrden");
         return response;
     });
  return {
    data: data?.data,
    isLoading,
    refetch,
    isError
   }
}