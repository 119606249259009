import { FieldArray } from "react-final-form-arrays";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Box } from "@mui/material";
import Button from "../../../components/Button";
import { ChipMui } from "../../../components/ChipMui";
import { AutoComplete } from "../../../components/AutoComplete";
import { useEffect, useState } from "react";
import { saveProductsSupplier } from "../hook/saveProductsSupplier";
import { IsLoadingModalProcesos } from "../../../components/Animation";
export const FormProductsSupplier = ({
  supplier,
  products,
  productsSuppliers,
  setOpenAlertAnimation,
  refetchProductsSuppliers,
}) => {
  const [initialValues, setInitialValue] = useState([]);
  const [borrarTodo, setBorrarTodo] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setInitialValue(productsSuppliers);
  }, [productsSuppliers]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          p: 1,
        }}
      >
        <Form
          onSubmit={(values) => {}}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{ datos: initialValues }}
          render={({
            form: {
              reset,
              mutators: { push },
            },
            pristine,
            submitting,
            values,
          }) => (
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Field
                  name={`formulaComponenteSku`}
                  component={AutoComplete as any}
                  placeholder="Componente"
                  sx={{ maxWidth: "300px", width: "100%" }}
                  options={products
                    ?.filter(
                      (sku) =>
                        !values?.datos?.find(
                          (id) =>
                            sku?.skuId === id?.formulaComponenteSku?.id ||
                            sku?.skuId === id.skuId
                        )
                    )
                    ?.map((item) => {
                      return {
                        skuId: item.skuId,
                        id: item.skuId,
                        label: item.skuCodigo,
                        skuCodigo: item.skuCodigo,
                      };
                    })}
                />
                <Button
                  disabled={!values?.formulaComponenteSku?.id}
                  label={"Agregar Producto"}
                  onClick={() => {
                    let datos = values.datos;
                    datos.push(values?.formulaComponenteSku);
                    push("datos", values?.formulaComponenteSku);

                    reset({
                      datos: datos,
                      formulaComponenteSku: null,
                    });
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  maxHeight: 300,
                  overflowY: "auto",
                  mt: 3,
                }}
              >
                <FieldArray name="datos">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <Field
                          component={ChipMui as any}
                          name={`${name}.skuCodigo`}
                          handleDelete={() => {
                            fields.remove(index);
                            if (index === 0) {
                              setBorrarTodo(true);
                            }
                          }}
                          sx={{ p: 1 }}
                        />
                      ))}
                    </>
                  )}
                </FieldArray>
              </Box>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button
                  sx={{ maxWidth: "300px" }}
                  label="Borrar todo"
                  onClick={() => {
                    reset({
                      datos: [],
                      formulaComponenteSku: null,
                    });
                    setBorrarTodo(true);
                  }}
                  disabled={values.datos.length === 0}
                />
                <Button
                  sx={{ width: "100%", maxWidth: "300px" }}
                  label="Guardar"
                  color="success"
                  onClick={() => {
                    saveProductsSupplier({
                      values,
                      supplier,
                      setLoading,
                      setOpenAlertAnimation,
                      refetchProductsSuppliers,
                    });
                    setLoading(true);
                  }}
                  disabled={
                    !borrarTodo &&
                    (values.datos.length === 0 ||
                      submitting ||
                      (values.formulaComponenteSku !== null && pristine))
                  }
                />
              </Box>
            </Box>
          )}
        />
      </Box>
      <IsLoadingModalProcesos height={200} width={200} open={loading} />;
    </>
  );
};
