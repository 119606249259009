import React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Typography,
  TextField,
  Stack,
  Button,
  Box,
  InputLabel,
  Chip,
} from "@mui/material";
import NumberFormat, { InputAttributes } from "react-number-format";
import { DialogGen } from "../Dialog";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import img from "../../images/subir-imagen.png";
import MenuItem from "@mui/material/MenuItem";
import { IconoSubirImagen } from "../IconoSubirImagen";

const useStyles = makeStyles({
  cssLabel: {
    marginTop: "5px",
    borderWidth: "0.5px",
  },
  cssDisabled: {
    fontSize: "16px",
    marginTop: "0px",
  },
  cssFocused: {
    "&$cssFocused": {
      borderWidth: "0.5px",
    },
  },
});

export const InputText = ({
  meta: { touched, error, ...rest },
  input: { onChange, ...inputProps },
  isNumber,
  propsInput,
  inputLabel,
  sx = {},
  onchange = null,
  ...props
}: any) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      size="small"
      inputLabelProps={<InputLabel />}
      onChange={({ target: { value } }) => {
        onChange(value);
        if (onchange) {
          onchange(value);
        }
      }}
      {...inputProps}
      {...props}
      value={props?.values ? props.values : inputProps.value}
      multiline
      error={!!(touched && error)}
      helperText={touched && error}
      fullWidth
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "textTertiary.main",
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "textTertiary.main",
          },
        },
        "& .MuiFormHelperText-root": {
          fontSize: "12px",
          top: "100%",
        },
        ...sx,
      }}
      InputProps={{ ...inputLabel, ...propsInput }}
      InputLabelProps={{
        classes: {
          focused: classes.cssFocused,
          disabled: classes.cssDisabled,
        },
      }}
    />
  );
};

InputText.displayName = "InputText";

export function InputFile({
  input: { value, onChange, label },
  meta,
  sx = {},
  nameUpdateFile,
  onchange,
  borrar,
  setBorrar,
  funcionEliminar,
  dashabilitarEliminar,
  mostrarEliminar,
  ...props
}) {
  const [ampliarImagen, setAmpliarImagen] = useState(false);
  if (borrar) {
    onChange({
      skuImagenUrl: null,
      skuImagenDescripcion: null,
    });
    setBorrar();
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          maxWidth: "100%",
        }}
      >
        {(!value || !value?.skuImagenUrl) && (
          <>
            <label htmlFor="imageUpload">
              <IconoSubirImagen
                img={img}
                funcionEliminar={funcionEliminar}
                dashabilitarEliminar={dashabilitarEliminar}
                mostrarEliminar={mostrarEliminar}
                setAmpliarImagen={() => {
                  setAmpliarImagen(!ampliarImagen);
                }}
              />
            </label>
            <input
              id="imageUpload"
              type="file"
              accept="image/*"
              onChange={(evt) => {
                const storage = getStorage();
                try {
                  const mountainImagesRef = ref(
                    storage,
                    `images/${nameUpdateFile}`
                  );
                  uploadBytes(mountainImagesRef, evt.target["files"][0]).then(
                    () => {
                      getDownloadURL(mountainImagesRef).then((url) => {
                        onChange({
                          skuImagenUrl: url,
                          skuImagenDescripcion: nameUpdateFile,
                        });
                      });
                    }
                  );
                } catch (error) {
                  console.warn(error);
                }
              }}
              style={{ display: "none", ...sx }}
              {...props}
            />
          </>
        )}

        {value && value?.skuImagenUrl !== null && (
          <IconoSubirImagen
            img={value?.skuImagenUrl}
            funcionEliminar={funcionEliminar}
            dashabilitarEliminar={dashabilitarEliminar}
            mostrarEliminar={mostrarEliminar}
            setAmpliarImagen={() => {
              setAmpliarImagen(!ampliarImagen);
            }}
          />
        )}
      </Box>
      <DialogGen
        open={ampliarImagen}
        onClick={() => setAmpliarImagen(!ampliarImagen)}
      >
        <img
          src={value?.skuImagenUrl}
          alt=""
          style={{
            maxWidth: `${window.screen.availWidth / 2}px`,
            width: "auto",
            height: "auto",
            maxHeight: window.screen.availHeight / 2,
          }}
        />
      </DialogGen>
    </>
  );
}
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
}
const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
    />
  );
});

export const InputNumber = ({
  meta: { touched, error },
  input: { value, onChange, ...inputProps },
  min = { min: 0 },
  isNumber,
  sx = {},
  propsInput,
  helperText = null,
  valueFijo = null,
  ...props
}: any) => {
  return (
    <>
      <TextField
        type="number"
        variant="outlined"
        size="small"
        InputProps={{
          ...min,
          ...propsInput,
          inputProps: {
            style: { textAlign: "right" },
          },
        }}
        onChange={(evt) => onChange(Number(evt?.target?.value))}
        value={valueFijo ? valueFijo : value}
        id="formatted-numberformat-input"
        inputLabelProps={<InputLabel />}
        {...inputProps}
        {...props}
        error={!!(touched && error)}
        helperText={
          touched && error ? touched && error : helperText ? helperText : ""
        }
        fullWidth
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "textTertiary.main",
          },
          "& .MuiOutlinedInput-root:hover": {
            "& > fieldset": {
              borderColor: "textTertiary.main",
            },
          },
          "& .MuiFormHelperText-root": {
            fontSize: "12px",
            top: "100%",
          },

          ...sx,
        }}
      />
    </>
  );
};
export const InputDate = ({
  meta: { touched, error, modifiedSinceLastSubmit, ...rest },
  input: { onChange, ...inputProps },
  sx = {},
  propsInput,
  inputLabel,
  onchange,

  ...props
}: any) => {
  const classes = useStyles();

  return (
    <TextField
      type="date"
      variant="outlined"
      size="small"
      onChange={(evt) => {
        onChange(evt.target.value);
        if (onchange) {
          onchange(evt.target.value);
        }
      }}
      InputProps={{ inputProps: { min: props.min, max: props.max } }}
      {...inputProps}
      {...props}
      error={!!(touched && error)}
      helperText={touched && error}
      fullWidth
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "textTertiary.main",
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "textTertiary.main",
          },
        },
        "& .MuiFormHelperText-root": {
          fontSize: "12px",

          top: "100%",
        },
        ...sx,
      }}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
        },
      }}
    />
  );
};
interface ButtonProps {
  label: string;
  onClick?: () => void;
  color?:
    | "primary"
    | "inherit"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  sx?: any;
  input?: any;
}
export const ButtonMui: React.FC<ButtonProps> = ({
  input: { value, onChange },
  label,
  sx = {},
  color = "primary",
}) => {
  const [showModalConfir, setShowModalConfir] = useState(false);
  return (
    <>
      <Stack spacing={2} direction="row">
        <Button
          onClick={() => setShowModalConfir(true)}
          variant="contained"
          color={color}
          sx={{
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 8,
            display: "flex",
            justifyContent: "center",
            ...sx,
          }}
        >
          {label}
        </Button>
      </Stack>
      <DialogGen
        open={showModalConfir}
        title={"Confirmar"}
        onClick={() => setShowModalConfir(false)}
      >
        <div style={{ maxWidth: 300 }}>
          <Typography>
            Esta seguro que quiere
            <b>{value === "0" ? " desarchivar " : " archivar "}</b> este
            producto
          </Typography>

          <div className="d-flex flex-row mt-4">
            <Button
              className="button"
              onClick={() => setShowModalConfir(false)}
            >
              Cancelar
            </Button>
            <Button
              className="button mx-4"
              onClick={() => {
                onChange(value === "0" ? "1" : "0");
                setShowModalConfir(false);
              }}
            >
              Confirmar
            </Button>
          </div>
        </div>
      </DialogGen>
    </>
  );
};
export function InputMenu({
  input: { value, onChange },
  meta: { touched, error },
  options,
  optionSelected = null,
  label,
  sx = {},
  onchange,
  meta,
  empty,
  disabled = false,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Chip
          size="small"
          label={
            <>
              {options.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </>
          }
          variant="outlined"
          color="error"
          sx={{ mt: 1, width: "100%" }}
        />
      </Button>
    </div>
  );
}
export const InputNotForm = ({
  onchange,
  label,
  sx = {},
  disabled = false,
}) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      size="small"
      label={label}
      multiline
      disabled={disabled}
      fullWidth
      onChange={(evt) => onchange(evt)}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "textTertiary.main",
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "textTertiary.main",
          },
        },
        "& .MuiFormHelperText-root": {
          fontSize: "12px",
          top: "100%",
        },
        ...sx,
      }}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
          disabled: classes.cssDisabled,
        },
      }}
    />
  );
};
export const InpuNumberNotForm = ({
  onchange,
  label,
  value,
  disabled,
  sx = {},
  ...props
}) => {
  const classes = useStyles();
  return (
    <TextField
      type="number"
      size="small"
      value={value}
      label={label}
      disabled={disabled}
      onChange={(evt) => onchange(evt)}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "textTertiary.main",
        },
        "& .MuiOutlinedInput-root:hover": {
          "& > fieldset": {
            borderColor: "textTertiary.main",
          },
        },
        "& .MuiFormHelperText-root": {
          fontSize: "12px",
        },
      }}
      InputProps={{
        inputProps: {
          style: { textAlign: "right" },
        },
        ...sx,
      }}
      {...props}
      InputLabelProps={{
        classes: {
          root: classes.cssLabel,
          focused: classes.cssFocused,
          disabled: classes.cssDisabled,
        },
      }}
    />
  );
};
