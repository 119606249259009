import {
  Autocomplete as AutoCompleteMUI,
  Box,
  FormHelperText,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  cssLabel: {
    marginTop: "5px",
    borderWidth: "0.5px",
  },
  cssFocused: {
    "&$cssFocused": {
      borderWidth: "0.5px",
    },
  },
});

export const AutoComplete = ({
  input: { value, onChange },
  options,
  sx = {},
  meta,
  label = "Codigo",
  disabled = false,
  onchange,
}) => {
  const TextInput = ({ ...props }: any) => {
    const classes = useStyles();
    return (
      <Box>
        <TextField
          {...props}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "textTertiary.main",
            },
            "& .MuiOutlinedInput-root:hover": {
              "& > fieldset": {
                borderColor: "textTertiary.main",
              },
            },
            "& .MuiFormHelperText-root": {
              fontSize: "12px",
              position: "absolute",
              top: "100%",
            },
          }}
          InputLabelProps={{
            classes: {
              focused: classes.cssFocused,
            },
          }}
        />
      </Box>
    );
  };

  useEffect(() => {}, [value]);
  return (
    <>
      <AutoCompleteMUI
        disabled={disabled}
        disablePortal
        size="small"
        options={options}
        value={value}
        isOptionEqualToValue={(option, value) =>
          option.id === value.id || option.id === value.id
        }
        onChange={(target, newValue: string | null) => {
          onChange(newValue);
          if (onchange) {
            onchange(newValue);
          }
        }}
        getOptionLabel={(option) =>
          option && option?.label ? option.label : ""
        }
        sx={sx}
        renderInput={(params) => {
          return <TextInput {...params} label={label} />;
        }}
      />

      <FormHelperText
        style={{ color: "red", fontSize: "12px", marginLeft: "16px" }}
      >
        {meta.touched && meta.error}
      </FormHelperText>
    </>
  );
};
