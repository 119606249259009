import { Box } from "@mui/material";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { AutoComplete } from "../../../components/AutoComplete";
import Button from "../../../components/Button";

import { InputText } from "../../../components/Input";
import { PostGrupoDetalle } from "../../../services/auth";
import { required } from "../../../utils/validations";

export const EditarGrupo = ({
  listGroup,
  setAlertCustom,
  refetchListGroup,
}) => {
  const obj: any = {};
  const [initialValue, setInitialValue] = useState({});

  return (
    <Form
      onSubmit={(values) => {
        PostGrupoDetalle({
          ...values,
          id_grupo: values?.grupo?.id,
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
            refetchListGroup();
            obj.funcion(values);
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={initialValue}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        obj.funcion = restart;
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Box className="col-4" sx={{ p: 1 }}>
              <Field
                name={`grupo`}
                component={AutoComplete as any}
                label={"Grupo"}
                sx={{ maxWidth: "300px" }}
                options={listGroup.map((item) => {
                  return {
                    id: item.idGrupo,
                    label: item.nombre,
                    descripcion: item.descripcion,
                    nombre: item.nombre,
                  };
                })}
                onchange={(dato) => setInitialValue({ ...dato, grupo: dato })}
              />
            </Box>
            <Box
              className="col-4"
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Field
                validate={required}
                name={`nombre`}
                component={InputText as any}
                label="Nombre"
                sx={{ maxWidth: "300px" }}
              />
            </Box>
            <Box
              className="col-4"
              sx={{
                display: "flex",
                alignItems: "center",
                p: 1,
              }}
            >
              <Field
                validate={required}
                name={`descripcion`}
                component={InputText as any}
                label="Descripcion"
                sx={{ maxWidth: "300px" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
              className="col-12"
            >
              <Button
                label={"Guardar cambios"}
                disabled={pristine}
                onClick={handleSubmit}
                sx={{ minWidth: "150px" }}
                color="success"
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};
