import { Box } from "@mui/material";

const CardBoxCalidad = ({ size, color, title, info, funcion }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        p: 1,
        ...color,
        ...size,
      }}
      onClick={funcion}
    >
      <Box
        sx={{
          fontFamily: "Raleway",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "28px",
          textAlign: "center",
          color: "#FFFFFF",
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          fontFamily: "Raleway",
          fontStyle: "normal",
          fontWeight: "700",
          fontSize: "24px",
          lineHeight: "28px",
          textAlign: "center",
          color: "#FFFFFF",
        }}
      >
        {info}
      </Box>
    </Box>
  );
};
export default CardBoxCalidad;
