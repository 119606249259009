import Layout from "../components/Layout";
import NotFound from "../images/pageNotFound/error-500.png";
export const PageNotFound = () => {
  return (
    <Layout title="404 - Pagina no encontrada">
      <img
        src={NotFound}
        alt=""
      />
    </Layout>
  );
};
