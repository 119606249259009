import TableGen from "../../../components/TableMui";

export const TablaGeneral = ({
  stockProducts,
  setShowModalMovementsProduct,
  permisos,
  listUsers,
}) => {
  const header = [
    "codigo",
    "nombre",
    "Deposito",
    "ubicacion",
    "Stock aprobado",
    "Stock rechazado",
    "Stock Cuarentena",
    "Stock pendiente calidad",
    "Unidad medida",
    "",
  ];
  const nameRow = [
    "skuCodigo",
    "skuDescripcion",
    "",
    "ubicacionTotal",
    "stockAprobadoTotal",
    "stockRechazadoTotal",
    "stockTerceroTotal",
    "stockPendienteQaTotal",
    "unidadMedida",
  ];
  const nameRowUbi = [
    "",
    "",
    "proveider",
    "stockUbicacionDescripcion",
    "Aprobado",
    "Rechazado",
    "Tercero",
    "PendienteQa",
    "",
  ];
  return (
    <TableGen
      permisos={permisos}
      datos={stockProducts}
      onClick={(SkuCodigo) =>
        setShowModalMovementsProduct({ open: true, datos: SkuCodigo })
      }
      header={header}
      nameRow={nameRow}
      nameRowUbi={nameRowUbi}
      listUsers={listUsers}
    />
  );
};
