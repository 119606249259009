import { Card } from "@mui/material";

import { convertDateDDMMAAAA } from "../../../utils/Fecha";
import { StyleBoxModal } from "./styleBoxModal";

export const VistPreviaDatos = ({ listProvider, values, measures }) => {
  return (
    <>
      <Card
        sx={{
          mb: 1,
          p: 1,
          backgroundColor: "rgba(0,0,0,0.10)",
        }}
      >
        <StyleBoxModal
          label={"Proveedor:"}
          dato={
            listProvider.find((item) => item.entidadId === values?.proveedorId)
              ?.entidadDescripcion
          }
        />
        <StyleBoxModal
          label={"Observacion"}
          dato={values?.comprobanteObservacion}
        />
        <StyleBoxModal label={"Status orden de compra"} dato={"Pendiente"} />
      </Card>
      <div style={{ maxHeight: 450, overflowY: "auto" }}>
        {values?.detalleSku?.map(({ amount, precio_unitario, ...item }) => (
          <Card
            sx={{
              boxShadow: "1px 1px 5px 1px grey",
              m: 1,
              p: 1,
            }}
          >
            <StyleBoxModal label={"Codigo:"} dato={item.idProducto.label} />
            <StyleBoxModal label={"Nombre:"} dato={item.idProducto.name} />

            <StyleBoxModal
              label={"Unidad medida:"}
              dato={item?.idProducto?.unidadMedida}
            />
            <StyleBoxModal
              label={"Precio unitario:"}
              dato={`$${Number(precio_unitario).toFixed(2)}`}
            />
            <StyleBoxModal
              label={"Cantidad:"}
              dato={Number.parseFloat(amount).toFixed(
                measures.find((m) => m.unidadMedidaId === item.unidadMedidaId)
                  ?.unidadMedidaDecimales
              )}
            />
            <StyleBoxModal
              label={"Fecha:"}
              dato={convertDateDDMMAAAA(item.dateEntrega)}
            />
          </Card>
        ))}
      </div>
    </>
  );
};
