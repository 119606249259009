
import { PostProductsSupplier } from "../../../services/supplierServices";
import { SaveStorage } from "../../../utils/localStorage";

export const saveProductsSupplier = async ({ values, supplier, setLoading, setOpenAlertAnimation, refetchProductsSuppliers }) => {

  await SaveStorage(
    `${supplier.entidadId}`,
    JSON.stringify(values.datos)
  );
  const body = {
    detalle_sku: values.datos.map((item) => {
      return {
        skuId: item.skuId,
      };
    }),
  };
  
  PostProductsSupplier(supplier.entidadId, body).then(() => {
      
    setOpenAlertAnimation({
      open: true,
      message: "Se guardó correctamente",
      severity: "success"
    });
    refetchProductsSuppliers();
    setLoading(false);
  }).catch((error) => {
    setOpenAlertAnimation({
      open: true,
      message: error.message,
      severity: "error"
    });
    setLoading(false);
  })
  
}