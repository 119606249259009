 import { useQuery } from 'react-query';
import { GetCostoTest } from '../services/costoServices';
interface itemType {
    1: string;
    2: string;
    3: string;
    4: string;
    5: number;
    6: number;
    7: number;
    8: number;
    9: number;
    10: number;
}
export const useCosto = (datos) => {
    const { sku_descripcion, costo_sku_fecha_desde, costo_sku_fecha_hasta, sku_tipo_id } = datos;

    const body = sku_tipo_id === "todos" ? {
            sku_descripcion,
            costo_sku_fecha_desde,
            costo_sku_fecha_hasta,
        } : {
            sku_descripcion,
            costo_sku_fecha_desde,
            costo_sku_fecha_hasta,
            sku_tipo: [{ sku_tipo_id: sku_tipo_id  }]
        };

    const { data, isLoading, isError, refetch } = useQuery([`GetCosto${sku_descripcion}${costo_sku_fecha_desde}${costo_sku_fecha_hasta}${sku_tipo_id}`], async () => {
        if (costo_sku_fecha_desde !== "" && costo_sku_fecha_hasta !== "") {
            
            const response = await GetCostoTest(body );
            
        const dataFiltrada = Object.values(response.data);
        const dataConDatosFalsos = dataFiltrada.map((item :itemType ) => {  
            return {
                ...item,
                5: item["5"] ? Number(item["5"]).toFixed(2) : 0,
                6: item["6"] ? Number(item["6"]).toFixed(2) : 0,
                7: item["7"] ? Number(item["7"]).toFixed(2) : 0,
                8: item["8"] ? Number(item["8"]).toFixed(2) : 0,
                9: item["9"] ? Number(item["9"]).toFixed(2) : 0,
                10: item["10"] ? Number(item["10"]).toFixed(2) : 0,
            }
        })
            const dataConVariaciones = dataConDatosFalsos.map((item: itemType) => {
                return {
                    ...item,
                    variacionMerma: isNaN(Number((((item[5] - item[8]) / item[5]) * 100).toFixed(2))) ? 0.00 : (((item[5] - item[8]) / item[5]) * 100).toFixed(2),
                    variacionManoObra: isNaN(Number((((item[6] - item[9]) / item[6]) * 100).toFixed(2))) ? 0.00 : (((item[6] - item[9]) / item[6]) * 100).toFixed(2),
                    variacionTiempo: isNaN(Number((((item[7] - item[10]) / item[7]) * 100).toFixed(2))) ? 0.00 : (((item[7] - item[10]) / item[7]) * 100).toFixed(2),
                    
               
                }
            })
            return dataConVariaciones;
           
            // return dataConVariaciones.filter((item) => item["4"].length === 6);
        } else {
            return []
        }

  });   

  return { 
    data: data,
    isLoading,
    isError,
    refetch
    
   }
}