import { fetchContent, Method } from "../utils/fetchContent";

export const GetOrdenTrabajo = async (datos) => {
  const {
    proceso_productivo_id,
    elaboracion_fecha_desde,
    elaboracion_fecha_hasta,
    proceso_productivo_tipo_id,
  } = datos;
  try {
    let condicion = "";
    if (!!proceso_productivo_tipo_id && proceso_productivo_tipo_id !== "0") {
      condicion += `proceso_productivo_tipo_id=${proceso_productivo_tipo_id}`;
    }
    if (!!proceso_productivo_id && proceso_productivo_id !== "0") {
      if (condicion !== "") {
        condicion += `&`;
      }
      condicion += `proceso_productivo_id=${proceso_productivo_id}`;
    }
    if (!!elaboracion_fecha_desde) {
      if (condicion !== "") {
        condicion += `&`;
      }
      condicion += `elaboracion_fecha_desde=${elaboracion_fecha_desde}`;
    }
    if (!!elaboracion_fecha_hasta) {
      if (condicion !== "") {
        condicion += `&`;
      }
      condicion += `elaboracion_fecha_hasta=${elaboracion_fecha_hasta}`;
    }

    return await fetchContent(
      `/orden_trabajo_listado/0${condicion ? "?" + condicion : ""}`
    );
  } catch (error) {
    throw error;
  }
};
export const GetAllOrdenTrabajo = async () => {
  try {
    return await fetchContent(`/orden_trabajo_listado/0`);
  } catch (error) {
    throw error;
  }
};

export const GetOrdenTrabajoFormula = async (id) => {
  try {
    return await fetchContent(`orden_trabajo_formula/${id}`);
  } catch (error) {
    throw error;
  }
};
export const GetOrdenTrabajoEstado = async () => {
  try {
    return await fetchContent(`/orden_trabajo_estado`);
  } catch (error) {
    throw error;
  }
};
export const GetOrdenTrabajoMotivoPausa = async () => {
  try {
    return await fetchContent(`/orden_trabajo_motivo_pausa`);
  } catch (error) {
    throw error;
  }
};
export const GetOrdenTrabajoUsuario = async (id) => {
  try {
    return await fetchContent(`/orden_trabajo_usuario/${id}`);
  } catch (error) {
    throw error;
  }
};
export const GetListadoHorasPorUsuario = async (id) => {
  try {
    return await fetchContent(`orden_trabajo_usuario_auditoria/${id}`);
  } catch (error) {
    throw error;
  }
};
export const GetDiasNoLaborables = async () => {
  try {
    return await fetchContent(`/feriado_listado`);
  } catch (error) {
    throw error;
  }
};

export const GetDiasLaborablesExcepcion = async () => {
  try {
    return await fetchContent(`/dia_laborable_excepcion_listado`);
  } catch (error) {
    throw error;
  }
};

export const CrearOrdenTrabajo = async (body) => {
  try {
    return await fetchContent(`orden_trabajo`, { body, method: Method.POST });
  } catch (error) {
    throw error;
  }
};

export const SaveFormulaOtg = async (id, body) => {
  try {
    return await fetchContent(`/orden_trabajo_formula_update/${id}`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const SaveDatailsOtg = async (id, body) => {
  try {
    return await fetchContent(`orden_trabajo_update/${id}`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const SaveUsersOtg = async (body) => {
  try {
    return await fetchContent(`orden_trabajo_update`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const SaveActividadUsuario = async (orden_trabajo_id, body) => {
  try {
    return await fetchContent(
      `orden_trabajo_usuario_auditoria_update/${orden_trabajo_id}`,
      {
        body,
        method: Method.POST,
      }
    );
  } catch (error) {
    throw error;
  }
};
export const SaveMotivoDePausa = async (body) => {
  try {
    return await fetchContent(`/orden_trabajo_motivo_pausa_update`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const SaveFeriado = async (body) => {
  try {
    return await fetchContent(`/feriado_update`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
export const SaveDiaExeptuado = async (body) => {
  try {
    return await fetchContent(`/dia_laborable_excepcion_update`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
