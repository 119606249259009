import { TableRow, TableCell } from "@mui/material";
import { Fragment } from "react";
import Button from "../../../components/Button";
import EditIcon from "@mui/icons-material/Edit";
export const AddDataTable = (props) => {
  const { row, rowName, onClick } = props;

  return (
    <Fragment>
      <TableRow>
        {rowName.map((item) => (
          <TableCell>{row[item]}</TableCell>
        ))}
        <TableCell>
          <Button
            label="editar"
            onClick={() => onClick(row)}
            sx={{ maxWidth: 100 }}
          >
            <EditIcon sx={{ pr: 1 }} />
          </Button>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
