export const posicionesTabla ={
header : [
  "Codigo",
  "Descripcion",
  "Costo Materiales std",
  "Costo Mano Obra std",
  "Tiempo Produccion std",
  "Costo Real Materiales",
  "Costo Real Mano Obra por unidad",
  "Tiempo Real Produccion",
  "Variacion Materiales",
  "Variacion Mano Obra",
  "Var. tiempo (horas)",
],
 row : [
  "2",
  "3",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "variacionMerma",
  "variacionManoObra",
  "variacionTiempo",
]}