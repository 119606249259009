import { fetchContent, Method } from "../utils/fetchContent";
export const GetCostoTest = async (body) => {
    try {
      
      return await fetchContent(`/costo_sku_listado?sku_tipo=2`,
      {
        body,
        method: Method.POST,
      });
    } catch (error) {
        throw (error)
    }
}
