import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { Box, Button, Collapse, Typography } from "@mui/material";
import * as React from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { typography } from "@mui/system";

const Alerta = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function AlertCustom({
  open,
  onclick,
  severity,
  title,
  message,
  children = null,
}) {
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={onclick}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alerta
          onClose={onclick}
          severity={severity}
          sx={{ width: "100%", zIndex: 999 }}
        >
          <AlertTitle>
            <Typography variant="subtitle1">{title}</Typography>
          </AlertTitle>
          <Box> {message}</Box>
          {children && <Box> {children}</Box>}
        </Alerta>
      </Snackbar>
    </Stack>
  );
}
