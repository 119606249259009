import { useState } from "react";
import arrayMutators from "final-form-arrays";
import { Box, Typography } from "@mui/material";
import { Loading } from "../loading";

import Button from "../../components/Button";
import { Field, Form } from "react-final-form";
import Layout from "../../components/Layout";
import ErrorMsg from "../../components/ErrorMsg";
// import { useListUsers } from "../../hooks/useUsers";
// import { getUser, usePermisos } from "../../hooks/auth";
// import { PERMISOS } from "../../constants/MenuOptions";
// import { verificarPermiso } from "../../utils/verificarPermiso";
import { InputDate, InputText } from "../../components/Input";
import { fechaActual } from "../../utils/Fecha";
import { useCosto } from "../../hooks/useCosto";
import { NoResults } from "../../components/Animation";
import IntentoArbolito from "./components/intentoArbolito";
import { required } from "../../utils/validations";
import { useTypeProduct } from "../../hooks/useProducts";
import { SelectMui } from "../../components/SelectMui";

const ReporteCosto = () => {
  const [paramsOtgApi, setParamsOtgApi] = useState({
    sku_descripcion: "",
    costo_sku_fecha_desde: "",
    costo_sku_fecha_hasta: "",
    sku_tipo_id: "",
  });
  const [initialValue, setInitialValue] = useState({});
  const { data, isLoading, isError } = useCosto(paramsOtgApi);
  const {
    data: listTypeProduct,
    isLoading: isLoadingListTypeProduct,
    isError: isErrorListTypeProduct,
  } = useTypeProduct();

  if (isLoading || isLoadingListTypeProduct) {
    return <Loading />;
  }
  if (isError || isErrorListTypeProduct) {
    return <ErrorMsg />;
  }
  const error =
    data.length === 0 && paramsOtgApi?.sku_descripcion?.length > 0
      ? true
      : false;

  return (
    <Layout title={`Reporte de Costo Por Producto`} clase={false} sx={{ p: 2 }}>
      <Form
        onSubmit={(values) => {
          setInitialValue({
            productoPadre: values?.productoPadre ? values?.productoPadre : "",
            fechaDesde: values?.fechaDesde,
            fechaHasta: values?.fechaHasta,
            skuTipo: values?.skuTipo,
          });
          setParamsOtgApi({
            sku_descripcion: values?.productoPadre ? values?.productoPadre : "",
            costo_sku_fecha_desde: values?.fechaDesde,
            costo_sku_fecha_hasta: values?.fechaHasta,
            sku_tipo_id: values?.skuTipo,
          });
        }}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          ...initialValue,
          fechaHasta: fechaActual(),
        }}
        render={({
          handleSubmit,
          values,
          form: {
            restart,
            mutators: { push },
          },
        }) => (
          <Box sx={{ width: "100%", py: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mx: 1,
                px: 1,
                p: 2,
                background: "#DFE0FF",
                border: "0.5px solid #797979",
                borderRadius: "8px",
              }}
            >
              <Box sx={{ px: 1 }}>
                <Field
                  component={InputText as any}
                  label="Producto padre"
                  name="productoPadre"
                  sx={{ background: "#FAFAFA" }}
                />
              </Box>
              <Box sx={{ px: 1 }}>
                <Field
                  validate={required}
                  component={InputDate as any}
                  defaultValue={fechaActual()}
                  label={values?.fechaDesde ? "Fecha desde (elaboracion)" : ""}
                  name="fechaDesde"
                  max={values?.fechaHasta ? values.fechaHasta : fechaActual()}
                  sx={{ background: "#FAFAFA" }}
                  onchange={(valor) => {
                    setInitialValue({
                      ...values,
                      fechaDesde: valor,
                    });
                  }}
                />
              </Box>

              <Box sx={{ px: 1 }}>
                <Field
                  validate={
                    required
                    // (fecha) =>
                    //   console.log(
                    //     Number(fecha?.replaceAll("-", "")),
                    //     Number(values?.fechaDesde?.replaceAll("-", "")),
                    //     "hasta"
                    //   )
                  }
                  label={values?.fechaHasta ? "Fecha hasta (elaboracion)" : ""}
                  component={InputDate as any}
                  min={values?.fechaDesde ? values.fechaDesde : fechaActual()}
                  max={fechaActual()}
                  name={"fechaHasta"}
                  sx={{ background: "#FAFAFA" }}
                  onchange={(valor) => {
                    setInitialValue({
                      ...values,
                      fechaHasta: valor,
                    });
                  }}
                />
              </Box>
              {/* {console.log(values)} */}
              <Box sx={{ px: 1 }}>
                <Field
                  validate={required}
                  label={"Tipo de producto"}
                  component={SelectMui as any}
                  name={"skuTipo"}
                  empty={true}
                  defaultValue={"todos"}
                  sx={{ background: "#FAFAFA", minWidth: 150 }}
                  options={listTypeProduct.map((item) => {
                    return {
                      value: item.skuTipoId,
                      name: item.skuTipoDescripcion,
                    };
                  })}
                  onchange={(valor) => {
                    setInitialValue({
                      ...values,
                      skuTipo: valor,
                    });
                  }}
                />
              </Box>
              <Box sx={{ px: 1 }}>
                <Button label="Buscar" onClick={handleSubmit} />
              </Box>
            </Box>
          </Box>
        )}
      />
      {data?.length > 0 && <IntentoArbolito datos={data} />}
      {error && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h3">
            No se encontraron datos con esos filtros
          </Typography>
          <NoResults height={300} width={300} />
        </Box>
      )}
    </Layout>
  );
};
export default ReporteCosto;
