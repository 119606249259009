import { Box, InputAdornment } from "@mui/material";
import { Field, Form } from "react-final-form";
import Button from "../../../components/Button";
import { InputNumber, InputText } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { required } from "../../../utils/validations";
import { newProcesoProductivo } from "../hook/newProcesosProductivo";

const CardNewProcesos = ({
  procesosTipoId,
  listUbicationProvider,
  refetch,
  setAlertCustom,
  setModalNewProceso,
}) => {
  return (
    <Form
      onSubmit={(values) => {
        newProcesoProductivo({
          values,
          refetch,
          setAlertCustom,
          setModalNewProceso,
        });
      }}
      initialValues={{}}
      render={({
        handleSubmit,
        form: { restart },

        submitting,
        pristine,
        values,
      }) => {
        return (
          <>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Box className="col-6" sx={{ p: 1 }}>
                <Field
                  name={`procesoProductivoTipoId`}
                  component={SelectMui as any}
                  label={"Area"}
                  options={procesosTipoId.map((item) => {
                    return {
                      value: item.procesoProductivoTipoId,
                      name: item.procesoProductivoTipoDescripcion,
                    };
                  })}
                  sx={{ width: "100%", maxWidth: "200px" }}
                />
              </Box>
              <Box className="col-6" sx={{ p: 1 }}>
                <Field
                  validate={required}
                  name={`procesoProductivoDescripcion`}
                  label={"Procesos productivo"}
                  component={InputText as any}
                  sx={{ width: "100%", maxWidth: "200px" }}
                />
              </Box>
              <Box className="col-6" sx={{ p: 1 }}>
                <Field
                  name={`stockUbicacionId`}
                  component={SelectMui as any}
                  label={"Deposito"}
                  options={listUbicationProvider.map((item) => {
                    return {
                      value: item.stockUbicacionId,
                      name: item.stockUbicacionDescripcion,
                    };
                  })}
                  sx={{ width: "100%", maxWidth: "200px" }}
                />
              </Box>
              <Box
                className="col-6"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                }}
              >
                <Field
                  name={`proceso_productivo_costo_por_hora_por_persona`}
                  component={InputNumber as any}
                  label="Costo mano de obra por hora por persona"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                />
              </Box>
              <Box
                className="col-6"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  p: 1,
                }}
              >
                <Field
                  name={`proceso_productivo_horas_disponibles_por_dia`}
                  component={InputNumber as any}
                  label="Horas disponible por dia"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Horas</InputAdornment>
                    ),
                    inputProps: {
                      style: { textAlign: "right" },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 1,
                p: 1,
              }}
            >
              <Button
                label="Cancelar"
                onClick={() => setModalNewProceso(false)}
              />
              <Button
                label="Confirmar"
                onClick={handleSubmit}
                color="success"
              />
            </Box>
          </>
        );
      }}
    />
  );
};
export default CardNewProcesos;
