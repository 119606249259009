import { Box, Typography } from "@mui/material";

export const StyleBoxModal = ({ label, dato }) => (
  <Box className="d-flex align-items-center flex-row ">
    <Typography variant="subtitle1" align="center">
      {label}
    </Typography>
    <Typography variant="body2" align="center" sx={{ marginLeft: 1 }}>
      {dato}
    </Typography>
  </Box>
);
