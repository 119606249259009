export const datosExcel = [
  {
    nombre: "Proveedor",
    key: "entidadDescripcion",
  },
  {
    nombre: "OC Número",
    key: "comprobanteNumero",
  },
  {
    nombre: "Fecha OC",
    key: "fechaCreacion",
  },
  {
    nombre: "Status OC",
    key: "status",
  },
  {
    nombre: "Codigo",
    key: "skuCodigo",
  },
  {
    nombre: "Nombre",
    key: "skuDescripcion",
  },
  {
    nombre: "Unidad Pedidas",
    key: "comprobanteDetalleCantidad",
  },
  {
    nombre: "Unidad Entregadas",
    key: "comprobanteDetalleCantidadRecibida",
  },
  {
    nombre: "Uniadad Restantes",
    key: "comprobanteDetalleCantidadSaldo",
  },
  {
    nombre: "Fecha de entrega final pactada",
    key: "fechaEntrega",
  },
  {
    nombre: "Unidad medida",
    key: "unidadMedida",
  },
  {
    nombre: "Observación",
    key: "comprobanteObservacion",
  },
  {
    nombre: "Usuario",
    key: "usuario",
  },
];
