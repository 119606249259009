import { useState, SyntheticEvent } from "react";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const FuncionRecursiva = ({ array, item }) => {
  const resultado = array.filter(
    (data) =>
      data.ordenTrabajoEstructura.startsWith(`${item}.`) &&
      data.ordenTrabajoEstructura.length === item.length + 4
  );
  return (
    <>
      {resultado.map((ramita) => (
        <TreeItem
          nodeId={ramita.ordenTrabajoEstructura}
          label={`${ramita.skuDescripcion} - ${ramita.ordenTrabajoEstadoDescripcion} - ${ramita.procesoProductivoTipoDescripcion}`}
        >
          {!!array.find(
            (data) =>
              data.ordenTrabajoEstructura.length >
              ramita.ordenTrabajoEstructura.length
          ) && (
            <FuncionRecursiva
              array={array}
              item={ramita.ordenTrabajoEstructura}
            />
          )}
        </TreeItem>
      ))}
    </>
  );
};
export default function Arbolito({ otgFamilia }) {
  const [selected, setSelected] = useState(
    otgFamilia.map((item) => item.ordenTrabajoEstructura)
  );

  const handleToggle = (event: SyntheticEvent, nodeIds: string[]) => {
    setSelected(nodeIds);
  };

  return (
    <TreeView
      sx={{
        p: 2,
        height: 240,
        flexGrow: 1,
        maxWidth: "80%",
        overflowY: "auto",
      }}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeToggle={handleToggle}
      expanded={selected}
    >
      <TreeItem
        nodeId="001"
        label={
          otgFamilia.find((item) => item.ordenTrabajoEstructura === "001")
            .skuDescripcion
        }
      >
        <FuncionRecursiva array={otgFamilia} item="001" />
      </TreeItem>
    </TreeView>
  );
}
