import {
  TableContainer as TableMui,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
} from "@mui/material";
import Button from "../Button";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { maxHeight } from "@mui/system";
interface editOptionsProps {
  onClick: (value: any) => void;
  key: string;
  disabled: boolean;
}
interface TableContainerProps {
  editOptions?: editOptionsProps;
  imprimirOption?: editOptionsProps;
  itemRight?: Array<any>;
  objeto: Array<any>;
  header;
  row;
  sx?: any;
  maxHeightBody?: any;
}

const TableContainer: React.FC<TableContainerProps> = ({
  objeto,
  editOptions = null,
  imprimirOption = null,
  header,
  row,
  itemRight = [],
  sx = {},
  maxHeightBody = null,
}) => {
  const maxHeight = maxHeightBody
    ? { maxHeight: maxHeightBody }
    : { maxHeight: "100%" };
  return (
    <TableMui sx={{ ...sx }}>
      <Box sx={{ overflowY: "auto", ...maxHeight }}>
        <TableHead
          sx={{
            position: "sticky",
            top: "-10px",
            backgroundColor: "#777",
            borderRadius: 24,
          }}
        >
          <TableRow sx={{ backgroundColor: "#777", borderRadius: 24 }}>
            {header.map((name) => (
              <TableCell sx={{ color: "#FAFAFA" }}>
                <Typography variant="body2">{name}</Typography>
              </TableCell>
            ))}
            {!!editOptions && <TableCell />}
          </TableRow>
        </TableHead>

        <TableBody sx={{ mt: 1 }}>
          {objeto.map((item) => (
            <TableRow>
              {row.map((name) => (
                <TableCell>
                  <Typography
                    sx={
                      Number(item[name]) ||
                      Number(item[name]) === 0 ||
                      itemRight.find((x) => x === name)
                        ? { display: "flex", justifyContent: "end" }
                        : {}
                    }
                  >
                    {item[name]}
                  </Typography>
                </TableCell>
              ))}
              {!!editOptions && (
                <TableCell>
                  <Button
                    label="editar"
                    disabled={editOptions?.disabled}
                    onClick={() => editOptions?.onClick(item[editOptions?.key])}
                  >
                    <EditIcon sx={{ pr: 1 }} />
                  </Button>
                </TableCell>
              )}
              {!!imprimirOption && (
                <TableCell>
                  <PrintIcon
                    color="error"
                    onClick={() =>
                      imprimirOption?.onClick(item[imprimirOption?.key])
                    }
                    sx={{ cursor: "pointer" }}
                  >
                    Imprimir
                  </PrintIcon>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Box>
    </TableMui>
  );
};

export default TableContainer;
