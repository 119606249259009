import { Box, Typography } from "@mui/material";
import { Field } from "react-final-form";
import { CheckBoxMuiOneOrZero } from "../../../components/CheckBoxMui";
import { InputDate, InputNumber } from "../../../components/Input";
import { fechaActual } from "../../../utils/Fecha";
import { required } from "../../../utils/validations";

export const FormDeclaraciones = ({
  sx = { sxBackColor: null, body: null },
  nuevoLote,
  LoteAnterior,
  orden_trabajo_generar_nuevo_lote,
  cantidadRestante,
}) => {
  return (
    <>
      <Typography sx={{ color: "#797979" }} variant={"h1"}>
        Declaraciones
      </Typography>
      <Box sx={sx.sxBackColor && sx.sxBackColor}>
        <Box sx={{ display: "flex", flexDirection: "row", my: 1, ...sx.body }}>
          <Box
            className="col-4"
            sx={{
              p: 1,
            }}
          >
            <Field
              validate={(valor) =>
                Number(valor) !== 0 ? undefined : "Debe ser diferente de 0"
              }
              name="cantidad"
              defaultValue={0}
              component={InputNumber as any}
              label="Cantidad"
              sx={{ maxWidth: "300px" }}
              helperText={cantidadRestante}
            />
          </Box>
          <Box className="col-4" sx={{ p: 1 }}>
            <Field
              focused
              validate={required}
              name="fecha"
              component={InputDate as any}
              label="Fecha"
              defaultValue={fechaActual()}
              sx={{ maxWidth: "300px" }}
            />
          </Box>
          <Box className="col-4" sx={{ p: 1 }}>
            <Field
              focused
              disabled={!LoteAnterior}
              validate={required}
              name="orden_trabajo_generar_nuevo_lote"
              component={CheckBoxMuiOneOrZero as any}
              label={
                orden_trabajo_generar_nuevo_lote === "0"
                  ? `usar lote anterior ${LoteAnterior}`
                  : nuevoLote
                  ? `crear nuevo lote: ${nuevoLote}`
                  : "crear nuevo lote"
              }
              sx={{ maxWidth: "300px" }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
