import { Field, Form } from "react-final-form";
import { InputText } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { SaveUbicationProvider } from "../../../services/productServices";

export const FormCrearUbicacion = ({
  listDeposito,
  setShowModalAddUbi,
  setAlertCustom,
  user,
}) => (
  <Form
    onSubmit={(values) => {
      SaveUbicationProvider({ body: { ...values, id_radius: user } })
        .then(() => {
          setShowModalAddUbi(false);
          setAlertCustom({
            open: true,
            title: "Confirmacion",
            severity: "success",
            message: "Se agrego correctamente la ubicacíon",
          });
        })
        .catch((error) => {
          setAlertCustom({
            open: true,
            title: "Error",
            severity: "error",
            message: error.message,
          });
        });
    }}
    initialValues={{
      stock_ubicacion_entidad_id: "",
      stock_ubicacion_descripcion: "",
      stock_ubicacion_activo: "1",
    }}
    validate={(values) => {
      const error: any = {};
      if (!values.stock_ubicacion_entidad_id) {
        error.stock_ubicacion_entidad_id = "Requerido";
      }
      if (!values.stock_ubicacion_descripcion) {
        error.stock_ubicacion_descripcion = "Requerido";
      }

      return error;
    }}
    render={({
      handleSubmit,

      submitting,
      pristine,
      values,
    }) => {
      return (
        <div className="m-2">
          <div>
            <Field
              name="stock_ubicacion_entidad_id"
              component={SelectMui as any}
              label="Deposito"
              options={listDeposito.map(
                ({ entidadDescripcion, entidadId }) => ({
                  value: entidadId,
                  name: entidadDescripcion,
                })
              )}
              sx={{ width: "100%" }}
            />
          </div>
          <div className="mt-3">
            <Field
              name="stock_ubicacion_descripcion"
              component={InputText as any}
              label="Ubicacion"
              sx={{ width: "100%" }}
            />
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "16px" }}
          >
            <button
              className="button"
              onClick={handleSubmit}
              disabled={submitting || pristine}
            >
              Guardar
            </button>
          </div>
        </div>
      );
    }}
  />
);
