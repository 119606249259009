import { Box } from "@mui/material";
import { useState } from "react";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputDate, InputNumber, InputText } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { fechaActual } from "../../../utils/Fecha";
import { required } from "../../../utils/validations";
import { saveInfoCotizacion } from "../hook";

const FormAgregarNuevaCotizacion = ({
  setAlertCustom,
  refetch,
  listMonedas,
  listCotizacion,
}) => {
  const [datosMonedaSeleccionada, setDatosMonedaSeleccionada] = useState(null);
  const obj: any = {};
  return (
    <Form
      onSubmit={(values) => {
        const body = {
          cotizacion_moneda_id: Number(
            values?.datosMonedaSeleccionada?.monedaId
          ),
          cotizacion_fecha: values?.cotizacionFecha,
          cotizacion_valor: values?.cotizacionValor,
        };
        saveInfoCotizacion(body, setAlertCustom, refetch, obj.funcion);
      }}
      initialValues={datosMonedaSeleccionada}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        obj.funcion = restart;
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="col-6" sx={{ p: 1 }}>
              <Field
                validate={required}
                name={`monedaDescripcion`}
                component={SelectMui as any}
                label="Moneda"
                options={listMonedas.map((moneda) => {
                  return {
                    value: moneda.monedaId,
                    name: moneda.monedaDescripcion,
                  };
                })}
                onchange={(value) =>
                  setDatosMonedaSeleccionada({
                    ...values,
                    datosMonedaSeleccionada: listMonedas?.find(
                      (moneda) => moneda?.monedaId === value
                    ),
                    monedaDescripcion: value,
                  })
                }
                sx={{ width: "100%" }}
              />
            </Box>

            <Box className="col-6" sx={{ p: 1 }}>
              <Field
                disabled
                name={`datosMonedaSeleccionada.monedaSigno`}
                component={InputText as any}
                label="Moneda signo"
                value={values?.monedaDescripcion}
              />
            </Box>
            <Box className="col-6" sx={{ p: 1 }}>
              <Field
                validate={required}
                name={"cotizacionFecha"}
                component={InputDate as any}
                defaultValue={fechaActual()}
                label="Fecha cotización"
              />
            </Box>
            <Box className="col-6" sx={{ p: 1 }}>
              <Field
                validate={required}
                name={"cotizacionValor"}
                component={InputNumber as any}
                label="Cotizacion"
              />
            </Box>

            <Box
              className="col-12"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxHeight: 40,
              }}
            >
              <Button label="Agregar" onClick={handleSubmit} color="success" />
            </Box>
          </Box>
        );
      }}
    />
  );
};
export default FormAgregarNuevaCotizacion;
