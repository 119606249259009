import { Box, Card, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { InpuNumberNotForm } from "../../../components/Input";
import { Field } from "react-final-form";
import { CheckBoxMui } from "../../../components/CheckBoxMui";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";
export const CardDetailsOtg = ({
  name,
  consumoActual,
  consumoTotal,
  nameField,
  permisos,
  listUsers,
  eliminar = "n",
}) => {
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        background: eliminar !== "s" ? "#FFFFFF" : "rgba(0, 0, 0, 0.15)",
        border: "1px solid #C1C1C1",
        boxSizing: "border-box",
        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
        p: 1,
        my: 2,
      }}
    >
      <Box className="col-4" sx={{ px: 0.2 }}>
        <Typography>{name}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ color: "#C1C1C1 " }}>Status</Typography>
          <CircleIcon
            color="warning"
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 1,
              height: "16px",
              width: "16px",
            }}
          />
        </Box>
      </Box>
      <Box className="col-3" sx={{ px: 0.5, py: 1 }}>
        <InpuNumberNotForm
          onchange={() => {}}
          label={"C. Actual"}
          value={consumoActual}
          disabled
        />
      </Box>
      <Box className="col-4" sx={{ px: 0.5, py: 1 }}>
        <InpuNumberNotForm
          onchange={() => {}}
          label={"C. Total"}
          value={consumoTotal}
          disabled
        />
      </Box>
      <Box className="col-1" sx={{ px: 0.5 }}>
        <Field
          disabled={
            !verificarPermiso(
              permisos,
              PERMISOS.DES_SELECCIONAR_FORMULA_ORIGINAL,
              listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
                ?.idGrupo
            )
          }
          name={`${nameField}.eliminar`}
          component={CheckBoxMui as any}
        />
      </Box>
    </Card>
  );
};
