import { useQuery } from "react-query";
import {
  GetAllOrdenTrabajo,
  GetDiasLaborablesExcepcion,
  GetDiasNoLaborables,
  GetListadoHorasPorUsuario,
  GetOrdenTrabajo,
  GetOrdenTrabajoEstado,
  GetOrdenTrabajoFormula,
  GetOrdenTrabajoMotivoPausa,
  GetOrdenTrabajoUsuario,
} from "../services/ordenTrabajo";
import { fromSecondsToHours } from "../utils/fromSecondsToHours";
import { OrderGen } from "../utils/orderGen";

export const useOrdenTrabajo = (datos) => {
  const {
    proceso_productivo_id,
    elaboracion_fecha_desde,
    elaboracion_fecha_hasta,
    proceso_productivo_tipo_id,
  } = datos;
  const { data, isLoading, isError, refetch } = useQuery(
    [
      `GetOrdenTrabajo${proceso_productivo_id}${elaboracion_fecha_desde}${elaboracion_fecha_hasta}${proceso_productivo_tipo_id}`,
    ],
    async () => {
      const response = await GetOrdenTrabajo(datos);

      OrderGen(response.data, "ordenTrabajoPrioridad", "asc", true);
      const datosConFechaCorta = response?.data?.map((item) => {
        return {
          ...item,
          ordenTrabajoElaboracionFecha:
            item.ordenTrabajoElaboracionFecha?.substr(0, 10),
          ordenTrabajoFinalizacionFechaEstimada:
            item.ordenTrabajoFinalizacionFechaEstimada?.substr(0, 10),
        };
      });
      return datosConFechaCorta;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useAllOrdenTrabajo = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`GetAllOrdenTrabajo`],
    async () => {
      const response = await GetAllOrdenTrabajo();

      const datosConFechaCorta = response?.data?.map((item) => {
        return {
          ...item,
          ordenTrabajoElaboracionFecha:
            item.ordenTrabajoElaboracionFecha?.substr(0, 10),
          ordenTrabajoFinalizacionFechaEstimada:
            item.ordenTrabajoFinalizacionFechaEstimada?.substr(0, 10),
        };
      });
      return datosConFechaCorta;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};

export const useOrdenTrabajoFormula = (id) => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`GetOrdenTrabajoFormula${id}`],
    async () => {
      const response = await GetOrdenTrabajoFormula(id);
      const datos = response.data.map((item) => {
        return {
          ...item,
        };
      });

      return datos;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useOrdenTrabajoEstado = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`GetOrdenTrabajoEstado`],
    async () => {
      const response = await GetOrdenTrabajoEstado();
      const datos = response.data.map((item) => {
        return {
          ...item,
        };
      });
      return datos;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useOrdenTrabajoMotivoPausa = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`useOrdenTrabajoMotivoPausa`],
    async () => {
      const response = await GetOrdenTrabajoMotivoPausa();
      const datos = response.data.map((item) => {
        return {
          ...item,
        };
      });
      return datos;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useDiasNoLaborables = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`useDiasNoLaborables`],
    async () => {
      const response = await GetDiasNoLaborables();
      const datos = response.data.map((item) => {
        return {
          ...item,
        };
      });
      return datos;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useDiasLaborablesExcepcion = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`useDiasLaborablesExcepcion`],
    async () => {
      const response = await GetDiasLaborablesExcepcion();
      const datos = response.data.map((item) => {
        return {
          ...item,
        };
      });
      return datos;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useOrdenTrabajoUsuario = (id) => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`GetOrdenTrabajoUsuario`],
    async () => {
      const response = await GetOrdenTrabajoUsuario(id);
      const datos = response.data.map((item) => {
        return {
          ...item,
        };
      });
      return datos;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useListadoHorasPorUsuario = (id) => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`useListadoHorasPorUsuario`],
    async () => {
      const response = await GetListadoHorasPorUsuario(id);

      const datos = response.data.map((item) => {
        return {
          ...item,
          ordenTrabajoUsuarioAuditoriaDuracionSegundos: fromSecondsToHours(
            Number(item.ordenTrabajoUsuarioAuditoriaDuracionSegundos)
          ),
          duracion: item.ordenTrabajoUsuarioAuditoriaDuracionSegundos,
          ordentrabajoFechaInicio: `${
            item.ordenTrabajoUsuarioAuditoriaFechaInicio
              ? item.ordenTrabajoUsuarioAuditoriaFechaInicio
              : ""
          } ${
            item.ordenTrabajoUsuarioAuditoriaHoraInicio
              ? item.ordenTrabajoUsuarioAuditoriaHoraInicio
              : ""
          }`,
          ordentrabajoFechaFin: `${
            item.ordenTrabajoUsuarioAuditoriaFechaFinal
              ? item.ordenTrabajoUsuarioAuditoriaFechaFinal
              : ""
          } ${
            item.ordenTrabajoUsuarioAuditoriaHoraFinal
              ? item.ordenTrabajoUsuarioAuditoriaHoraFinal
              : ""
          }`,
        };
      });
      OrderGen(datos, "ordenTrabajoUsuarioAuditoriaId", "desc", true);

      return datos;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
