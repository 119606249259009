import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { InputNotForm } from "../../../components/Input";
import { Table as TableMuiOriginal } from "@mui/material";
import { nameDataTabla } from "../constant";
import { IsMobi } from "../../../hooks/isMobi";
export const AddDataTabla = ({ rows, nameHeader, nameRow }) => {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 1,
          boxShadow: "5px 1px 10px 1px rgba(122,122,122,0.49)",
        }}
      >
        <TableMuiOriginal
          sx={{
            background: "rgba(250, 250, 250, 0.5)",
          }}
        >
          <TableHead style={{ backgroundColor: "rgba(0, 0, 0, 0.49)" }}>
            <TableRow>
              {nameHeader.map((name) => (
                <TableCell>
                  <Typography
                    sx={{ color: "#FAFAFA", minWidth: "100px" }}
                    variant="h4"
                  >
                    {name}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}>
            {rows.map((row) => (
              <TableRow key={row?.name}>
                {nameRow.map((name, index) => {
                  const sx =
                    index === 0 || index === 4 || index === 5
                      ? {
                          display: "flex",
                          justifyContent: "center",
                        }
                      : {};
                  return (
                    <TableCell>
                      <Typography variant="body2" sx={{ ...sx }}>
                        {row[name]}
                      </Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </TableMuiOriginal>
      </TableContainer>
    </>
  );
};

const Tabla = ({ datos }) => {
  const [pageSelected, setPageSelected] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [items, setItems] = useState(datos);
  const [itemOffset, setItemOffset] = useState(0);
  const changeNumPage = (evt) => {
    setItemOffset(0);
    setPageSelected(0);
    setItemsPerPage(evt.target.value);
  };

  let dataFilter;

  const changeSearch = (evt) => {
    setItemOffset(0);
    setPageSelected(0);
    if (evt.target.value !== "") {
      dataFilter = datos.filter((item) =>
        Object.values(item).find(
          (valor) =>
            !!valor &&
            valor
              .toString()
              .toUpperCase()
              .includes(evt.target.value.toUpperCase())
        )
      );
    } else {
      dataFilter = datos;
    }
    setItems(dataFilter);
  };
  useEffect(() => {
    setItems(datos);
  }, [datos]);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items, datos]);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setPageSelected(event.selected);
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="d-flex flex-column">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FormControl
            sx={{ width: "160px", backgroundColor: "#fafafa" }}
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{ marginTop: "4px" }}
            >
              Item por pagina
            </InputLabel>

            <Select name="Number" onChange={(evt) => changeNumPage(evt)}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              backgroundColor: "#fafafa",
            }}
          >
            <InputNotForm
              onchange={(evt) => changeSearch(evt)}
              label="Buscar"
            />
          </Box>
        </Box>
        {currentItems && (
          <Box
            sx={{
              overflowX: "auto",
              mt: 1,
            }}
          >
            <AddDataTabla
              rows={currentItems}
              nameHeader={nameDataTabla.nameHeader}
              nameRow={nameDataTabla.nameRow}
            />
          </Box>
        )}

        <div
          className={"d-flex justify-content-center "}
          style={{ display: "solid", marginTop: "16px" }}
        >
          <ReactPaginate
            pageRangeDisplayed={IsMobi() ? 1 : 5}
            nextLabel={IsMobi() ? ">>" : "Siguiente >"}
            onPageChange={handlePageClick}
            pageCount={pageCount}
            forcePage={pageSelected}
            previousLabel={IsMobi() ? "<<" : "< Anterior"}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            marginPagesDisplayed={IsMobi() ? 1 : 5}
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
};

export default Tabla;
