import { Box, Typography } from "@mui/material";
import { Form, Field } from "react-final-form";
import { AutoComplete } from "../../../components/AutoComplete";
import Button from "../../../components/Button";
import { CheckBoxMuiTrueOrFalse } from "../../../components/CheckBoxMui";
import { InputText } from "../../../components/Input";
import { PostProcesosProductivoArea } from "../../../services/productServices";

const FormUpdateArea = ({
  procesosTipoId,
  setAlertCustom,
  RefetchProcesosTipoId,
}) => {
  return (
    <Form
      onSubmit={(values) => {
        PostProcesosProductivoArea({
          ...values,

          procesoProductivoTipoActivo: values?.procesoProductivoTipoActivo

            ? 1
            : 0,
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
            RefetchProcesosTipoId();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={{}}
      validate={(values) => {
        return {};
      }}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        return (
          <Box sx={{ width: "100%" }}>
            <Box>
              <Field
                name={`area`}
                component={AutoComplete as any}
                sx={{ width: "100%" }}
                label={"Area"}
                options={procesosTipoId.map((item) => {
                  return {
                    id: item.procesoProductivoTipoId,
                    label: item.procesoProductivoTipoDescripcion,
                  };
                })}
                onchange={(evt) => {
                  if (evt) {
                    restart({
                      area: evt,
                      ...procesosTipoId
                        .filter(
                          (item) => item.procesoProductivoTipoId === evt.id
                        )
                        .map((x) => {
                          return {
                            ...x,
                            procesoProductivoTipoActivo:
                              x.procesoProductivoTipoActivo === "1"
                                ? true
                                : false,
                          };
                        })[0],
                    });
                  } else {
                    restart({});
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                mt: 2,
              }}
            >
              <Box className="col-9" sx={{ pr: 1 }}>
                <Field
                  disabled={!values.area}
                  component={InputText as any}
                  name={"procesoProductivoTipoDescripcion"}
                  label={"Descripcion"}
                />
              </Box>
              <Box
                className="col-3"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ pr: 1 }}>Activo</Typography>

                <Field
                  disabled={!values.area}
                  component={CheckBoxMuiTrueOrFalse as any}
                  name={"procesoProductivoTipoActivo"}
                />
              </Box>
            </Box>
            {!!values.area &&
              (procesosTipoId.filter(
                (item) => item.procesoProductivoTipoId === values.area.id
              )[0]?.procesoProductivoTipoDescripcion !==
                values.procesoProductivoTipoDescripcion ||
                procesosTipoId
                  .filter(
                    (item) => item.procesoProductivoTipoId === values.area.id
                  )
                  .map((x) => {
                    return {
                      ...x,
                      procesoProductivoTipoActivo:
                        x.procesoProductivoTipoActivo === "1" ? true : false,
                    };
                  })[0]?.procesoProductivoTipoActivo !==
                  values.procesoProductivoTipoActivo) && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 3,
                  }}
                >
                  <Button
                    label={"Cancelar"}
                    onClick={() =>
                      restart({
                        area: values.area,
                        ...procesosTipoId
                          .filter(
                            (item) =>
                              item.procesoProductivoTipoId === values.area.id
                          )
                          .map((x) => {
                            return {
                              ...x,
                              procesoProductivoTipoActivo:
                                x.procesoProductivoTipoActivo === "1"
                                  ? true
                                  : false,
                            };
                          })[0],
                      })
                    }
                  />
                  <Button
                    label={"Guardar Cambios"}
                    onClick={handleSubmit}
                    color="success"
                  />
                </Box>
              )}
          </Box>
        );
      }}
    />
  );
};
export default FormUpdateArea;
