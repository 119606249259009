import Lottie from "react-lottie";
import loading from "../../images/loading.json";
import inProcess from "../../images/inProcess.json";
import noResults from "../../images/no-search-result.json";
import success from "../../images/success.json";
import error from "../../images/error.json";
import isLoadingModalProceso from "../../images/loadingModal.json";
import { DialogGen } from "../Dialog";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
export const InLoading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={"80%"} width={"80%"} />
    </div>
  );
};
export const InProcess = ({ height, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: inProcess,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  );
};
export const NoResults = ({ height, width }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noResults,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} width={width} />
    </div>
  );
};

export const AlertAnimation = ({
  height,
  width,
  message,
  open,
  closer,
  severity,
}) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: severity === "success" ? success : error,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  if (open) {
    setTimeout(() => {
      closer();
    }, 3000);
  }
  return (
    <DialogGen open={open} onClick={() => closer()}>
      <Box>
        <Typography variant="h3">{message}</Typography>
        <Lottie options={defaultOptions} height={height} width={width} />
      </Box>
    </DialogGen>
  );
};
export const IsLoadingModalProcesos = ({ height, width, open }) => {
  const defaultOptions = {
    loop: false,
    autoplay: true,

    animationData: isLoadingModalProceso,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <DialogGen open={open} onClick={() => {}}>
      <Box>
        <Lottie options={defaultOptions} height={height} width={width} />
      </Box>
    </DialogGen>
  );
};
