import { Typography, Box, Avatar, Stack } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import PositionedMenu from "../MenuNavegacion";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useUser } from "../../hooks/auth";
import { IsMobi } from "../../hooks/isMobi";
const Layout = ({ title = null, children, sx = {}, clase = true }) => {
  const auth = getAuth();
  const {
    data: user,
    isLoading: isLoadingUser,
    isError: isErrorUser,
  } = useUser();

  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          p: 1,
          pt: 2,
          m: 0,
          mt: 0,
          width: "100%",
          zIndex: 999,
          boxShadow: "4px 0px 8px 0px #00003a",
          background:
            "linear-gradient(180deg, #0101DF -20%, #2E64FE 60%, #fff 200%)",
        }}
      >
        <PositionedMenu
          signOut={signOut}
          auth={auth}
          sx={{ fontSize: "40px" }}
        />
        <Typography
          variant="h1"
          color="textSecondary.main"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            paddingLeft: "16px",
          }}
        >
          {title}
        </Typography>
        {IsMobi() ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",

              pl: 2,
            }}
          >
            {!isLoadingUser && !isErrorUser && !!user && (
              <Typography sx={{ color: "white" }}>
                {`Usuario: ${user?.nombre} ${user?.apellido}`}
              </Typography>
            )}
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center", pr: 2 }}>
            {!isLoadingUser && !isErrorUser && !!user && (
              <Typography variant="h3" sx={{ color: "white" }}>
                {`Usuario: ${user?.nombre} ${user?.apellido}`}
              </Typography>
            )}
          </Box>
        )}
      </Stack>
      <Box
        sx={{
          m: 0,
          mt: 6,
          p: 4,
          pt: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Box
          className={clase && "cardPrincipal"}
          sx={{
            p: 0,
            m: 0,
            mt: 4,
            pt: 6,
            pb: 6,
            backgroundColor: "#fff",
            borderRadius: 4,
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            ...sx,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
export const LayoutMobi = ({
  title = null,
  children,
  href = null,
  sx = {},
  clase = true,
}) => {
  const auth = getAuth();
  return (
    <>
      <Stack
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",

          p: 1,
          m: 0,
          mt: 0,
          width: "100%",
          zIndex: 999,
          boxShadow: "4px 0px 8px 0px #00003a",
          background:
            "linear-gradient(180deg, #0101DF -20%, #2E64FE 60%, #fff 200%)",
        }}
      >
        <PositionedMenu signOut={signOut} auth={auth} />
        <Typography
          variant="h1"
          color="textSecondary.main"
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            paddingLeft: "16px",
          }}
        >
          {title}
        </Typography>
        <Box>
          <Avatar />
        </Box>
      </Stack>
      <Box
        sx={{
          m: 0,

          p: 1,
          pt: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        {href && (
          <Box sx={{ mt: 1, ml: 2 }}>
            <a href={href}>
              <ArrowBackIcon fontSize="large" />
            </a>
          </Box>
        )}

        <Box
          className={clase ? "cardPrincipal" : ""}
          sx={{
            p: 1,
            m: 0,
            mt: 1,
            backgroundColor: "#fff",
            borderRadius: 4,
            ...sx,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
export default Layout;
