import { Box } from "@mui/material";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputText } from "../../../components/Input";
import { required } from "../../../utils/validations";


const FormAgregarNuevaUbicacion = ({
  setAlertCustom,
  refetch,
  listUbicationProvider,
}) => {
  const obj: any = {};
  return (
    <Form
      onSubmit={(values) => {
        // saveInfoMoneda(values, setAlertCustom, refetch, obj.funcion);
      }}
      initialValues={{}}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        obj.funcion = restart;
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="col-4" sx={{ p: 1 }}>
              <Field
                validate={required}
                name={"monedaDescripcion"}
                component={InputText as any}
                label="Descripcion"
              />
            </Box>
            <Box className="col-3" sx={{ p: 1 }}>
              <Field
                validate={required}
                name={"monedaSigno"}
                component={InputText as any}
                label="Signo"
              />
            </Box>
            <Box className="col-3" sx={{ p: 1 }}></Box>

            <Box
              className="col-2"
              sx={{ display: "flex", alignItems: "center", maxHeight: 40 }}
            >
              <Button label="Agregar" onClick={handleSubmit} color="success" />
            </Box>
          </Box>
        );
      }}
    />
  );
};
export default FormAgregarNuevaUbicacion;
