import { Box, Divider, Typography } from "@mui/material";

export const DataToPrint = ({ datos }) => {
  return (
    <Box>
      {datos?.map((item) => {
        return (
          <html hidden id={`imprimir${item?.comprobanteId}`}>
            <title>{`OC: ${item?.comprobanteNumero}`}</title>
            <body>
              <div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      marginTop: "1.5px",
                    }}
                  >
                    <Typography variant="h3">
                      {`Proveedor: ${item?.entidadDescripcion}`}
                    </Typography>
                  </div>
                  <div style={{ paddingLeft: "8px", paddingRight: "8px" }}>
                    <Typography variant="h3">
                      {`Usuario: ${item?.usuario}`}
                    </Typography>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      width: "250px",
                    }}
                  >
                    <Typography variant="h3">OC Número:</Typography>
                    <Typography
                      variant="h3"
                      style={{ marginLeft: "8px", fontWeight: "500" }}
                    >
                      {item?.comprobanteNumero}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      marginLeft: "36px",
                    }}
                  >
                    <Typography variant="h3">Fecha OC:</Typography>
                    <Typography
                      variant="h3"
                      style={{ marginLeft: "8px", fontWeight: "500" }}
                    >
                      {item?.fechaCreacion}
                    </Typography>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      width: "250px",
                    }}
                  >
                    <Typography variant="h3">Status OC:</Typography>
                    <Typography
                      variant="h3"
                      style={{ marginLeft: "8px", fontWeight: "500" }}
                    >
                      {item?.status}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      marginLeft: "36px",
                    }}
                  >
                    <Typography variant="h3">
                      Fecha de entrega final pactada:
                    </Typography>
                    <Typography
                      variant="h3"
                      style={{ marginLeft: "8px", fontWeight: "500" }}
                    >
                      {item?.fechaEntrega}
                    </Typography>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      width: "250px",
                    }}
                  >
                    <Typography variant="h3">Código:</Typography>
                    <Typography
                      variant="h3"
                      style={{ marginLeft: "8px", fontWeight: "500" }}
                    >
                      {item?.skuCodigo}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      marginLeft: "36px",
                    }}
                  >
                    <Typography variant="h3">Nombre:</Typography>
                    <Typography
                      variant="h3"
                      style={{ marginLeft: "8px", fontWeight: "500" }}
                    >
                      {item?.skuDescripcion}
                    </Typography>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <Typography variant="h3">Unidad medida:</Typography>
                    <Typography
                      variant="h3"
                      style={{ marginLeft: "8px", fontWeight: "500" }}
                    >
                      {item?.unidadMedida}
                    </Typography>
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                    }}
                  >
                    <Typography variant="h3">Observacion:</Typography>
                    <Typography
                      variant="h3"
                      style={{ marginLeft: "8px", fontWeight: "500" }}
                    >
                      {item?.comprobanteObservacion}
                    </Typography>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <Typography variant="h3" style={{ width: "200px" }}>
                    Unidad Pedidas:
                  </Typography>
                  <Typography
                    variant="h3"
                    style={{
                      display: "flex",
                      textAlign: "right",
                      paddingLeft: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {item?.comprobanteDetalleCantidad}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <Typography variant="h3" style={{ width: "200px" }}>
                    Unidad Entregadas:
                  </Typography>
                  <Typography
                    variant="h3"
                    style={{
                      display: "flex",
                      textAlign: "right",
                      paddingLeft: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {item?.comprobanteDetalleCantidadRecibida}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  <Typography variant="h3" style={{ width: "200px" }}>
                    Uniadad Restantes:
                  </Typography>
                  <Typography
                    variant="h3"
                    style={{
                      display: "flex",
                      textAlign: "right",
                      paddingLeft: "16px",
                      fontWeight: "500",
                    }}
                  >
                    {item?.comprobanteDetalleCantidadSaldo}
                  </Typography>
                </div>
              </div>

              <script src="script.js"></script>
            </body>
          </html>
        );
      })}
    </Box>
  );
};
