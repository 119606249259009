import { Box } from "@mui/material";
import { Field } from "react-final-form";
import Button from "../../../components/Button";
import { CheckBoxMuiOneOrZero } from "../../../components/CheckBoxMui";
import { InputText } from "../../../components/Input";
import { required } from "../../../utils/validations";

const CardUbicacion = ({ name, onclick, disabled }) => (
  <Box
    className="col-12 col-lg-10 col-xl-8"
    sx={{ display: "flex", flexWrap: "wrap" }}
  >
    <Box className="col-3" sx={{ p: 1 }}>
      <Field
        disabled
        name={`${name}.entidadDescripcion`}
        component={InputText as any}
        label="Depósito"
      />
    </Box>
    <Box className="col-3" sx={{ p: 1 }}>
      <Field
        validate={required}
        name={`${name}.stockUbicacionDescripcion`}
        component={InputText as any}
        label="Ubicacion"
      />
    </Box>
    <Box className="col-3" sx={{ p: 1 }}>
      <Field
        component={CheckBoxMuiOneOrZero as any}
        name={`${name}.stockUbicacionActivo`}
        label="Activo"
      />
    </Box>
    <Box
      className="col-2"
      sx={{ display: "flex", alignItems: "center", maxHeight: 50 }}
    >
      <Button label={"Aplicar"} onClick={onclick} disabled={disabled} />
    </Box>
  </Box>
);
export default CardUbicacion;
