import { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box, Skeleton } from "@mui/material";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";
import { Form } from "react-final-form";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import Button from "../../components/Button";
import { useConversionUnidadMedida } from "../../hooks/useConversionUnidadMedida";
import { saveInfoConversionUnidadMedida } from "./hook";
import CardConversionUnidadMedida from "./component/cardConversionUnidadMedida";
import FormAgregarNuevaConversionUnidadMedida from "./component/formAgregarNuevaConversionUnidadMedida";
import { useMeasures } from "../../hooks/useProducts";

const ConversionUnidadMedida = () => {
  const [
    showModalNuevaConversionUnidadMedida,
    setShowModalNuevaConversionUnidadMedida,
  ] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data: listConversionUnidadMedida,
    isLoading: isLoadingListConversionUnidadMedida,
    isError: isErrorListConversionUnidadMedida,
    refetch,
  } = useConversionUnidadMedida();
  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();

  if (isErrorListConversionUnidadMedida || isErrorMeasures) {
    return <ErrorMsg />;
  }

  return (
    <Layout
      title={"Administracion de conversion de unidad de medida"}
      clase={false}
    >
      {!isLoadingListConversionUnidadMedida && !isLoadingMeasures ? (
        <Form
          onSubmit={(values) => {}}
          initialValues={{ datos: listConversionUnidadMedida }}
          mutators={{
            ...arrayMutators,
          }}
          validate={(values) => {
            return {};
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push },
              restart,
            },
            submitting,
            pristine,
            values,
          }) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  p: 2,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                  <Button
                    onClick={() =>
                      setShowModalNuevaConversionUnidadMedida(true)
                    }
                    label={"Agregar nueva conversion de unidad de medida"}
                    color="success"
                  />
                </Box>
                {console.log(listConversionUnidadMedida, values)}
                <FieldArray name="datos">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <Box
                        className="col-12"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <CardConversionUnidadMedida
                          name={name}
                          measures={measures}
                          onclick={() =>
                            saveInfoConversionUnidadMedida(
                              {
                                ...values?.datos[index],
                                unidad_medida_id_origen:
                                  listConversionUnidadMedida[index]
                                    ?.unidadMedidaConversionUnidadMedidaIdOrigen,
                                unidad_medida_id_destino:
                                  listConversionUnidadMedida[index]
                                    ?.unidadMedidaConversionUnidadMedidaIdDestino,
                                coeficiente:
                                  listConversionUnidadMedida[index]
                                    ?.unidadMedidaConversionCoeficiente,
                              },
                              setAlertCustom,
                              refetch
                            )
                          }
                          disabled={
                            values?.datos[index]
                              ?.unidadMedidaConversionUnidadMedidaIdOrigen ===
                              listConversionUnidadMedida[index]
                                ?.unidadMedidaConversionUnidadMedidaIdOrigen &&
                            values?.datos[index]
                              ?.unidadMedidaConversionUnidadMedidaIdDestino ===
                              listConversionUnidadMedida[index]
                                ?.unidadMedidaConversionUnidadMedidaIdDestino &&
                            values?.datos[index]
                              ?.unidadMedidaConversionCoeficiente ===
                              listConversionUnidadMedida[index]
                                ?.unidadMedidaConversionCoeficiente
                          }
                        />
                      </Box>
                    ))
                  }
                </FieldArray>
              </Box>
            );
          }}
        />
      ) : (
        <Box className="col-11">
          <Skeleton variant="text" />

          <Skeleton variant="rectangular" width={""} height={300} />
        </Box>
      )}
      <DialogGen
        open={showModalNuevaConversionUnidadMedida}
        title={"Nueva conversion de unidad de medida"}
        onClick={() => setShowModalNuevaConversionUnidadMedida(false)}
        sx={{ maxWidth: "300px" }}
      >
        <FormAgregarNuevaConversionUnidadMedida
          setAlertCustom={setAlertCustom}
          refetch={refetch}
          measures={measures}
        />
      </DialogGen>

      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </Layout>
  );
};
export default ConversionUnidadMedida;
