import { useState } from "react";
import {
  getAuth,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage";
import {
  GetPermisos,
  GetPermisosGrupo,
  GetPermisosParaOtorgar,
} from "../services/auth";
import { useQuery } from "react-query";
import { OrderGen } from "../utils/orderGen";
import { ReadStorage, SaveStorage } from "../utils/localStorage";
import { getListUsers } from "../services/usersServices";
interface Permisos {
  data: { permisos: [{ idModulo: Number }] };
}
interface permisosParaAsignar {
  data: {};
}

export const usePermisos = (id) => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`GetPermisos`],
    async () => {
      // const datos = await traerPermisos();
      // return datos;
      const permisos = JSON.parse(await ReadStorage("Permisos"))
        .response as unknown as Permisos;
      const permisosOtorgar = JSON.parse(await ReadStorage("PermisosAOtorgar"))
        .permisosOtorgar as unknown as permisosParaAsignar;
      var array = [];
      permisos.data.permisos.map((item: any) => {
        Object.keys(item).map(
          (x) =>
            Number(x) > 0 &&
            !!permisosOtorgar?.data[item.idModulo - 1][x] &&
            Number(item[x]) > 0 &&
            array.push(
              permisosOtorgar.data[item.idModulo - 1][x]?.toLowerCase()
            )
        );
      });
      return array;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useUser = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`GetUser`],
    async () => {
      const currentUser = getUser();
      const response = currentUser?.uid ? await getListUsers() : undefined;
      return response?.data?.find(
        (user) => user?.radiusIdCodigo === currentUser?.uid
      );
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};

export const usePermisosPorModulo = ({ idModulo, idGrupo }) => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`GetPermisosPorModulo${idModulo}${idGrupo}`],
    async () => {
      var array = [];
      const response = !!idGrupo ? await GetPermisosGrupo(idGrupo) : [];
      const permisosOtorgar = await GetPermisosParaOtorgar();
      console.log(permisosOtorgar);
      if (!!idModulo && !!idGrupo) {
        permisosOtorgar?.data?.map((item) => {
          Number(idModulo) === Number(item.idModulo) &&
            Object.keys(item).map(
              (x) =>
                Number(x) > 0 &&
                !!permisosOtorgar.data[item.idModulo - 1][x] &&
                array.push({
                  nombre: x,
                  permiso: permisosOtorgar.data[item.idModulo - 1][x],
                  activo: response.data.find(
                    (activo) => activo.idModulo === idModulo
                  )
                    ? response.data.find(
                        (activo) => activo.idModulo === idModulo
                      )[x]
                    : "0",
                })
            );
        });
      }

      return array;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const usePermisosParaOtorgar = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`GetPermisosParaOtorgar`],
    async () => {
      const response = await GetPermisosParaOtorgar();
      OrderGen(response.data, "nombre");
      return response.data;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
// export const usePermisosMaqueteado = (idRadius) => {

//   const arrayDePermiso = [
//     {
//       radiusId: 2,
//       permisos: [
//         'buscar_otgs',
//         'agregar_otgs',
//         'cambiar_prioridades_de_fabricacion_de_las_otgs',
//         'editar_fecha_de_inicio_de_elaboracion_plan',
//         'cambiar_estado_de_la_otg_plan',
//         'flecha_para_detalle_de_las_otg',
//         'cambiar_estado_de_la_otg',
//         'editar_fecha_de_inicio_de_elaboracion',
//         'declarar_unidades',
//         'des-seleccionar_formula_original',
//         'seleccion_de_campo_descuenta_para_la_ubicacion',
//         'agregar_materiales',
//         'seleccion_y_agregado_de_usuario',
//         'acceso_pantalla_inicio_de_trabajo',
//         'navegacion_general_sin_boton_crear_ni_editar',
//         'crear_nuevo_producto',
//         'editar_todos_los_atriburtos_de_un_producto',
//         'edicion_de_atributos_y_estructura_de_producto',
//         'solo_consulta_item_por_pagina',
//         'solo_consulta_buscar',
//         'solo_consulta_ver_historial',
//         'agregar_nueva_ubicacion',
//         'realizar_transferencia_entre_variables',
//         'boton_realizar_ajustes_de_stock_por_diferencias',
//         'cambio_de_deposito',
//         'cambio_de_ubicacion',
//         'cambiar_el_estado_de_calidad',
//         'realizar_ajustes_de_stock_por_diferencias',
//         'ingreso_de_nuevos_insumos_y_tercerizados',
//         'solo_consulta_de_ubicacion_del_stock', 'crea_oc_para_uso_interno',
//         'solo_consulta_de_oc_segun_provy_status',
//         'crear_nuevos_usuarios',
//         'reestablecar_la_contraseña',
//         'modificar_datos_de_usuarios',
//         'crear_y_editar_datos_de_los_proveedores',
//         'asigna_a_cada_proveedor_los_productos_que_abastece',
//         'crear_editar_y_eliminar_procesos_productivos',
//       ],
//     },
//     {
//       radiusId: 1,
//       permisos: [
//         "buscar_otgs",
//         "agregar_otgs",
//         "cambiar_prioridades_de_fabricacion_de_las_otgs",
//         'crear_nuevos_usuarios',
//         'reestablecar_la_contraseña',
//         'modificar_datos_de_usuarios',
//       ],
//     },
//   ];
//   if (!idRadius) {
//     return { data: [], isLoading: true, isError: false }
//   }
//      return {data: arrayDePermiso?.find((item) => item?.radiusId === idRadius)?.permisos, isLoading:false,isError:false }

// }
export const resetPassword = async ({ email }) => {
  const auth = getAuth();
  const response = sendPasswordResetEmail(auth, email)
    .then(() => {
      return 1;
    })
    .catch(() => {
      return 2;
    });
  return response;
};
export const CreateUser = async ({ email }) => {
  const auth = getAuth();
  const response = createUserWithEmailAndPassword(auth, email, "poltron123")
    .then((userCredential) => {
      const user = userCredential.user;

      return user.uid;
    })
    .catch(() => {
      return null;
    });
  return response;
};
export const getUser = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user;
};

export const updateUser = () => {
  const auth = getAuth();
  updateProfile(auth.currentUser, {})
    .then(() => {})
    .catch((error) => {});
};

export const UpdateFile = (file, name) => {
  const [datos, setDatos] = useState(null);
  const storage = getStorage();
  try {
    const mountainImagesRef = ref(storage, `images/${name}`);
    uploadBytes(mountainImagesRef, file).then(() => {
      getDownloadURL(mountainImagesRef).then(setDatos);
    });
    return datos;
  } catch (error) {
    console.warn(error);
  }
};

export const getUrlImage = async (name) => {
  const storage = getStorage();
  const mountainImagesRef = ref(storage, `images/${name}`);
  try {
    return await getDownloadURL(mountainImagesRef);
  } catch (error) {
    console.log(error);
  }
};

export const DeleteFile = (images, name, key) => {
  const storage = getStorage();

  const desertRef = ref(storage, "images/desert.jpg");

  deleteObject(desertRef)
    .then(() => {})
    .catch((error) => {});
};
