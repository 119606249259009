import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Box, Typography, Card, InputAdornment } from "@mui/material";
import { AutoComplete } from "../../../components/AutoComplete";
import { SelectMui } from "../../../components/SelectMui";
import { BsXLg as X, BsFillTrashFill as Delete } from "react-icons/bs";
import { InputNumber, InputText } from "../../../components/Input";
import { PostProductsStructure } from "../../../hooks/useProducts";
import { AlertCustom } from "../../../components/AlertMui";
import Button from "../../../components/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SaveNewProduct } from "../../../services/productServices";
import { DialogGen } from "../../../components/Dialog";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";

export const FormStructure = ({
  refetch,
  refetchProducts,
  datos = [],
  measures,
  skuCods,
  procesos,
  idProduct,
  permisos,
  inactivarFormulario,
  listUsers,
  arr,
  saveInfoDetails,
}) => {
  const [formValues, setFormValues] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const required = (value) => (value ? undefined : "Requerido");
  useEffect(() => {
    setFormValues(
      datos.map((item) => ({
        ...item,

        formulaComponenteSku: {
          id: item.formulaComponenteSkuId,
          label: skuCods.find(
            (sku) => sku.skuId === item.formulaComponenteSkuId
          )?.skuCodigo,
        },
      }))
    );
  }, [datos]);
  const getDescripProduct = (values, index) => {
    let product = skuCods.find(
      (item) => item.skuId === values.datos[index].formulaComponenteSku?.id
    );

    return product?.skuDescripcion;
  };

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "1px 1px 5px 2px grey",
        borderRadius: "16px",
        backgroundColor: "#fafafa",
        textAlign: "center",
        maxHeight: "600px",
        overflowY: "auto",
        p: 1,
      }}
    >
      <Box
        sx={{
          width: "100%",

          mt: 2,
        }}
      >
        <Typography variant="h1" color="textPrimary.main">
          Estructura
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          {
            <Button
              disabled={
                !verificarPermiso(
                  permisos,
                  PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                )
              }
              label="crear nuevo codigo"
              onClick={() => setShowCreateModal(true)}
            />
          }
        </Box>
        <Form
          onSubmit={(values) => {
            const nuevaFormula = {
              formula: values.datos.map((item) => {
                return {
                  formula_cantidad: Number(item?.formulaCantidad),
                  formula_componente_sku_id: Number(
                    item?.formulaComponenteSku?.id
                  ),
                  formula_merma_porcentaje: Number(
                    item?.formulaMermaPorcentaje
                  ),
                  formula_unidad_medida_id: skuCods?.find(
                    (x) => x?.skuId === item?.formulaComponenteSku?.id
                  )?.unidadMedidaId,
                };
              }),

              sku_proceso_productivo_id: Number(
                values?.datos[0]?.skuProcesoProductivoId
              ),
            };
            if (values?.datos?.length > 0) {
              saveInfoDetails();
            }
            // setFormValues(values.datos);

            PostProductsStructure({
              id: idProduct,
              body: nuevaFormula ? nuevaFormula : { formula: [] },
            })
              .then(() => {
                setAlertCustom({
                  open: true,
                  title: "Confirmacion",
                  severity: "success",
                  message: "Se guardo correctamente",
                });

                refetch();
              })
              .catch((error) =>
                setAlertCustom({
                  open: true,
                  title: "Error",
                  severity: "error",
                  message: error?.message,
                })
              );
          }}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{
            datos: formValues,
          }}
          validate={(values) => {
            const error: any = {};

            return error;
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push },
            },
            pristine,
            submitting,
            values,
          }) => {
            return (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    width: "100%",

                    p: 1,
                  }}
                >
                  {values.datos?.length > 0 && (
                    <Box className={"col-12 p-1"} sx={{ maxWidth: 350 }}>
                      <Field
                        disabled={
                          inactivarFormulario ||
                          !verificarPermiso(
                            permisos,
                            PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                            listUsers?.find(
                              (item) => item?.radiusIdCodigo === getUser()?.uid
                            )?.idGrupo
                          )
                        }
                        validate={required}
                        name={`datos[0].skuProcesoProductivoId`}
                        component={SelectMui as any}
                        label="Proceso productivo"
                        options={procesos
                          .filter((x) => Number(x?.procesoProductivoActivo) > 0)
                          .map((item) => {
                            return {
                              name: `${
                                item.procesoProductivoTipoDescripcion
                              }  ${
                                item.procesoProductivoId !== "1"
                                  ? `- ${item.procesoProductivoDescripcion} `
                                  : ""
                              } `,
                              value: item.procesoProductivoId,
                            };
                          })}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                  )}
                </Box>

                <Box sx={{ maxHeight: "300px", overflowY: "auto" }}>
                  <FieldArray name="datos">
                    {({ fields }) => (
                      <div>
                        {fields.map((name, index) => {
                          let productos = getDescripProduct(values, index);

                          return (
                            <div
                              key={name}
                              className="d-flex flex-wrap mt-2 p-2"
                              style={{ width: "100%" }}
                            >
                              <div className=" col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 p-1 ">
                                <Field
                                  disabled={
                                    inactivarFormulario ||
                                    !verificarPermiso(
                                      permisos,
                                      PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                                      listUsers?.find(
                                        (item) =>
                                          item?.radiusIdCodigo ===
                                          getUser()?.uid
                                      )?.idGrupo
                                    )
                                  }
                                  validate={required}
                                  name={`${name}.formulaComponenteSku`}
                                  component={AutoComplete as any}
                                  placeholder="Componente"
                                  sx={{ maxWidth: "300px" }}
                                  options={skuCods
                                    .filter(
                                      (sku) =>
                                        !values?.datos?.find(
                                          (id) =>
                                            sku?.skuId ===
                                              id?.formulaComponenteSku?.id ||
                                            sku?.skuId === idProduct
                                        )
                                    )
                                    ?.map((item) => {
                                      return {
                                        id: item.skuId,
                                        label: item.skuCodigo,
                                      };
                                    })}
                                />
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 px-1 pb-2 ">
                                <Typography
                                  variant="subtitle1"
                                  alignItems="center"
                                  sx={{
                                    maxWidth: "300px",
                                  }}
                                >
                                  {productos}
                                </Typography>
                              </div>
                              <div className="d-flex justify-content-start col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 p-1 ">
                                <Field
                                  disabled={
                                    inactivarFormulario ||
                                    !verificarPermiso(
                                      permisos,
                                      PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                                      listUsers?.find(
                                        (item) =>
                                          item?.radiusIdCodigo ===
                                          getUser()?.uid
                                      )?.idGrupo
                                    )
                                  }
                                  validate={required}
                                  component={InputNumber as any}
                                  sx={{ maxWidth: "300px" }}
                                  name={`${name}.formulaCantidad`}
                                  label="Cantidad"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {values?.datos[index]?.unidadMedida}
                                      </InputAdornment>
                                    ),
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                />
                              </div>
                              {/* <div
                                className="col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 p-1 "
                                style={{ textAlign: "start" }}
                              >
                                <Field
                                  disabled={
                                    inactivarFormulario ||
                                    !verificarPermiso(
                                      permisos,
                                      PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                                      listUsers?.find(
                                        (item) =>
                                          item?.radiusIdCodigo ===
                                          getUser()?.uid
                                      )?.idGrupo
                                    )
                                  }
                                  validate={required}
                                  name={`${name}.formulaUnidadMedidaId`}
                                  options={measures.map((item) => ({
                                    value: item.unidadMedidaId,
                                    name: item.unidadMedida,
                                  }))}
                                  label="unidad de medida"
                                  component={SelectMui as any}
                                  sx={{
                                    width: "100%",
                                    maxWidth: "300px",
                                  }}
                                />
                              </div> */}

                              <div className="d-flex justify-content-start col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 p-1 ">
                                <Field
                                  disabled={
                                    inactivarFormulario ||
                                    !verificarPermiso(
                                      permisos,
                                      PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                                      listUsers?.find(
                                        (item) =>
                                          item?.radiusIdCodigo ===
                                          getUser()?.uid
                                      )?.idGrupo
                                    )
                                  }
                                  validate={required}
                                  component={InputNumber as any}
                                  sx={{ maxWidth: "300px" }}
                                  name={`${name}.formulaMermaPorcentaje`}
                                  label="Merma estandar"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        %
                                      </InputAdornment>
                                    ),
                                    inputProps: {
                                      style: { textAlign: "right" },
                                    },
                                  }}
                                />
                              </div>

                              <div className="d-flex justify-content-center align-items-start col-12 col-sm-9 col-md-6 col-lg-2 col-xl-2 px-1 ">
                                <Button
                                  label={"estructura"}
                                  sx={{
                                    maxWidth: "150px",
                                    width: "100%",
                                  }}
                                  disabled={!pristine}
                                  href={`/DetalleProductos/${
                                    values?.datos[index]?.formulaComponenteSku
                                      ?.id
                                  }/[${
                                    arr.toString() ? `${arr.toString()},` : ""
                                  }${
                                    values?.datos[index]?.formulaComponenteSku
                                      ?.id
                                  }]`}
                                >
                                  <VisibilityIcon />
                                </Button>
                              </div>
                              <div
                                className="d-flex justify-content-center align-items-center col-12 col-sm-12 col-md-12 col-lg-1 col-xl-1 px-1 "
                                style={{ height: "30px" }}
                              >
                                {!inactivarFormulario &&
                                  verificarPermiso(
                                    permisos,
                                    PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                                    listUsers?.find(
                                      (item) =>
                                        item?.radiusIdCodigo === getUser()?.uid
                                    )?.idGrupo
                                  ) && (
                                    <Delete
                                      onClick={() => fields.remove(index)}
                                      style={{
                                        width: "15px",
                                        height: "15px",
                                        marginLeft: "10px",

                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </FieldArray>
                </Box>
                <div
                  className="d-flex flex-row align-item-center mt-4 pb-1"
                  style={{ height: "100%" }}
                >
                  <div className="col-3">
                    <Button
                      disabled={
                        inactivarFormulario ||
                        !verificarPermiso(
                          permisos,
                          PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                          listUsers?.find(
                            (item) => item?.radiusIdCodigo === getUser()?.uid
                          )?.idGrupo
                        )
                      }
                      label="Agregar"
                      sx={{ width: "80%" }}
                      onClick={() => push("datos", {})}
                    />
                  </div>

                  <div className="d-flex justify-content-center col-6">
                    <Button
                      label="Guardar"
                      sx={{ width: "80%" }}
                      onClick={handleSubmit}
                      color="success"
                      disabled={
                        submitting ||
                        pristine ||
                        inactivarFormulario ||
                        !verificarPermiso(
                          permisos,
                          PERMISOS.EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO,
                          listUsers?.find(
                            (item) => item?.radiusIdCodigo === getUser()?.uid
                          )?.idGrupo
                        )
                      }
                    />
                  </div>

                  <AlertCustom
                    open={alertCustom.open}
                    title={alertCustom.title}
                    message={alertCustom.message}
                    severity={alertCustom.severity}
                    onclick={() =>
                      setAlertCustom({
                        open: false,
                        title: "",
                        severity: "",
                        message: "",
                      })
                    }
                  />
                </div>
              </>
            );
          }}
        />
      </Box>
      <DialogGen
        open={showCreateModal}
        title="Crear Producto"
        onClick={() => setShowCreateModal(false)}
      >
        <Form
          onSubmit={(values) => {
            SaveNewProduct({
              sku_codigo: values?.cod,
              sku_descripcion: values?.description,
              sku_unidad_medida_id: values?.skuUnidadMedidaId,
            })
              .then((response) => {
                setAlertCustom({
                  open: true,
                  title: "Confirmacion",
                  severity: "success",
                  message: "Se guardo correctamente",
                });
                refetchProducts();
                setShowCreateModal(false);
              })
              .catch((response) =>
                setAlertCustom({
                  open: true,
                  title: "Confirmacion",
                  severity: "error",
                  message: "Ha ocurrido un error",
                })
              );
          }}
          initialValues={{ cod: "", description: "" }}
          validate={({ description, cod, skuUnidadMedidaId }) => {
            const errors: any = {};
            if (!cod) {
              errors.cod = "Requerido";
            }
            if (skuCods.find((item) => item.skuCodigo === cod)) {
              errors.cod = "Ya existe";
            }

            if (!description) {
              errors.description = "Requerido";
            }
            if (!skuUnidadMedidaId) {
              errors.skuUnidadMedidaId = "Requerido";
            }
            return errors;
          }}
          render={({ handleSubmit, submitting, pristine, values }) => (
            <div>
              <form className="d-flex flex-column" onSubmit={handleSubmit}>
                <Field name="cod" component={InputText as any} label="Codigo" />

                <div className="mt-3">
                  <Field
                    name="description"
                    component={InputText as any}
                    label="Nombre"
                  />
                </div>
                <div className="mt-3">
                  <Field
                    validate={required}
                    name={`skuUnidadMedidaId`}
                    options={measures.map((item) => ({
                      value: item.unidadMedidaId,
                      name: item.unidadMedida,
                    }))}
                    label="unidad de medida"
                    component={SelectMui as any}
                    sx={{
                      width: "100%",
                      maxWidth: "300px",
                    }}
                  />
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    className="button mt-4 mx-2"
                    type="submit"
                    disabled={submitting || pristine}
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          )}
        />
      </DialogGen>
    </Box>
  );
};
