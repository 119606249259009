import ingre from "../images/stock/IngresoEgreso.png";
import modu from "../images/stock/ModuloDeCalidad.png";
import stockProduct from "../images/stock/StockPorProducto.png";
import stockUbication from "../images/stock/StockPorUbicacion.png";
import generarOC from "../images/stock/Generar OC.png";
import ReportesOC from "../images/stock/Reportes OC.png";
import Layout from "../components/Layout";
import CardStyle from "../components/CardStyle";
import { Loading } from "./loading";
import { getUser, usePermisos } from "../hooks/auth";
import { verificarPermiso } from "../utils/verificarPermiso";
import { PERMISOS } from "../constants/MenuOptions";
import ErrorMsg from "../components/ErrorMsg";
import { useListUsers } from "../hooks/useUsers";

const ControlStock = () => {
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);

  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  if (isLoadingPermisos || loadingListUsers) {
    return <Loading />;
  }
  if (isErrorPermisos || isErrorListUsers) {
    return <ErrorMsg />;
  }

  return (
    <Layout title="Control de Stock">
      <CardStyle
        img={stockProduct}
        href="/StockPorProducto"
        name="Ver stock por productos, insumos y tercerizados"
      />
      {verificarPermiso(
        permisos,
        PERMISOS.INGRESO_DE_NUEVOS_INSUMOS_Y_TERCERIZADOS,
        listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
          ?.idGrupo
      ) && <CardStyle img={ingre} href="/ingreso" name="Ingresos" />}
      {verificarPermiso(
        permisos,
        PERMISOS.INGRESO_DE_NUEVOS_INSUMOS_Y_TERCERIZADOS,
        listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
          ?.idGrupo
      ) && <CardStyle img={ingre} href="/egresos" name="Egresos" />}
      {verificarPermiso(
        permisos,
        PERMISOS.SOLO_CONSULTA_DE_UBICACION_DEL_STOCK,
        listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
          ?.idGrupo
      ) && (
        <CardStyle
          img={stockUbication}
          href="/stockporubicacion"
          name="Ver stock por ubicacion"
        />
      )}
      <CardStyle img={modu} href="/ModuloCalidad" name={"Modulo de calidad"} />

      {verificarPermiso(
        permisos,
        PERMISOS.CREA_OC_PARA_USO_INTERNO,
        listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
          ?.idGrupo
      ) && (
        <CardStyle
          img={generarOC}
          href="/GeneracionOC"
          name="Generacion Orden de compra"
        />
      )}
      {verificarPermiso(
        permisos,
        PERMISOS.SOLO_CONSULTA_DE_OC_SEGUN_PROVY_STATUS,
        listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
          ?.idGrupo
      ) && (
        <CardStyle
          img={ReportesOC}
          href={"/ReporteOC"}
          name="Listado de Ordenes de compras"
        />
      )}
    </Layout>
  );
};

export default ControlStock;
