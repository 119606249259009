import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { AlertCustom } from "../../components/AlertMui";
import Button from "../../components/Button";
import ErrorMsg from "../../components/ErrorMsg";
import { InputDate } from "../../components/Input";
import Layout from "../../components/Layout";
import { SelectMui } from "../../components/SelectMui";
import { useListPedidos } from "../../hooks/usePedidos";
import { fechaActual } from "../../utils/Fecha";
import { Loading } from "../loading";
import Tabla from "./components/tabla";
import ExportExcel from "react-export-excel";
import { useListClientes } from "../../hooks/useProducts";
import CardMenu from "../../components/CardMenu";
const ModuloPedidos = () => {
  const ExcelFile = ExportExcel.ExcelFile;
  const ExcelSheet = ExportExcel.ExcelSheet;
  const ExcelColumn = ExportExcel.ExcelColumn;
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [datos, setDatos] = useState([]);
  const {
    data: listPedidos,
    isLoading: isLoadingListPedidos,
    isError: isErrorListPedidos,
  } = useListPedidos(0);
  const {
    data: listClientes,
    isLoading: isLoadingListClientes,
    isError: isErrorListCliente,
  } = useListClientes(true);
  if (isLoadingListPedidos || isLoadingListClientes) {
    return (
      <Box>
        <Loading />
      </Box>
    );
  }
  if (isErrorListPedidos || isErrorListCliente) {
    return (
      <Box>
        <ErrorMsg />
      </Box>
    );
  }

  return (
    <Layout title={"Pedidos"} clase={false}>
      <Box
        sx={{ display: "flex", justifyContent: "end", width: "100%", px: 1 }}
      >
        {datos?.length === 0 ? (
          <Button label="Exportar a excel" disabled={true} />
        ) : (
          <ExcelFile
            element={
              <Button label="Exportar a excel" disabled={datos?.length === 0} />
            }
            filename="Listado de pedidos"
          >
            <ExcelSheet data={datos} name="Pedidos">
              <ExcelColumn label="Orden de Pedido" value="comprobanteNumero" />
              <ExcelColumn
                label="Cliente"
                value="entidadDescripcion"
                font={{
                  fontsize: "30px",
                }}
              />
              <ExcelColumn label="OTG asignada" value="ordenTrabajoId" />
              <ExcelColumn label="Estado" value="estado" />
              <ExcelColumn label="Fecha de OP" value="fechaCreacion" />
              <ExcelColumn label="Código" value="skuCodigo" />
              <ExcelColumn label="Nombre" value="skuDescripcion" />
              <ExcelColumn
                label="Cantidad"
                value="comprobanteDetalleCantidad"
              />
              <ExcelColumn
                label="Cantidad producidas"
                value="ordenTrabajoCantidadProducida"
              />
              <ExcelColumn label="Cantidad restante" value="cantidadRestante" />
              <ExcelColumn
                label="Precio Unitario"
                value="comprobanteDetallePrecioUnitario"
              />
              <ExcelColumn label="Fecha de Creación" value="fechaCreacion" />
              <ExcelColumn
                label="Fecha de Entrega"
                value="fechaEntregaPedido"
              />
              <ExcelColumn
                label="Provee tela"
                value="comprobanteDetalleTelaProvistaPorCliente"
              />
              <ExcelColumn label="Observación" value="comprobanteObservacion" />
              <ExcelColumn label="Usuario" value="nombreUsuario" />
              <ExcelColumn
                label="Plano aprobado"
                value="comprobanteDetallePlanoAprobado"
              />
              <ExcelColumn label="Embalaje" value="embalajeDescripcion" />
            </ExcelSheet>
          </ExcelFile>
        )}
      </Box>
      <Box sx={{ flex: 1, ml: 4, mb: 1 }}>
        <Typography variant="h3">
          Fechas de creación de pedido en el sistema
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", width: "100%", px: 1 }}
      >
        <Box sx={{ display: "flex", justifyContent: "start" }}>
          <Form
            onSubmit={(values) => {
              const filtro = listPedidos.filter(
                (pedido) =>
                  (!values.desde ||
                    Number(pedido?.comprobanteFecha.replaceAll("-", "")) >=
                      Number(values?.desde.replaceAll("-", ""))) &&
                  (!values.hasta ||
                    Number(pedido?.comprobanteFecha.replaceAll("-", "")) <=
                      Number(values?.hasta.replaceAll("-", ""))) &&
                  (values.estadoId === "0" ||
                    pedido?.estadoId === values?.estadoId) &&
                  (values.cliente === "Todos" ||
                    pedido?.entidadDescripcion === values?.cliente)
              );

              setDatos(filtro);
            }}
            initialValues={{ estadoId: "0", cliente: "Todos" }}
            render={({ handleSubmit, values }) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                }}
              >
                <Box sx={{ p: 1 }}>
                  <Field
                    focused
                    name="desde"
                    component={InputDate as any}
                    label={"Fecha desde"}
                    sx={{ width: "150px" }}
                    max={fechaActual()}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <Field
                    focused
                    name="hasta"
                    component={InputDate as any}
                    max={fechaActual()}
                    label={"Fecha hasta"}
                    sx={{ width: "150px" }}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <Field
                    name="cliente"
                    component={SelectMui as any}
                    label="Cliente"
                    options={listClientes?.map(({ entidadDescripcion }) => ({
                      value: entidadDescripcion,
                      name: entidadDescripcion,
                    }))}
                    sx={{ width: "150px" }}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <Field
                    name="estadoId"
                    component={SelectMui as any}
                    label={"Estado orden pedido"}
                    sx={{ width: "150px" }}
                    options={[
                      { estadoId: "0", statusDescripcion: "Todos" },
                      { estadoId: "1", statusDescripcion: "Pendiente" },
                      { estadoId: "2", statusDescripcion: "Entrega Parcial" },
                      { estadoId: "3", statusDescripcion: "Cumplida" },
                    ].map((item) => {
                      return {
                        value: item.estadoId,
                        name: item.statusDescripcion,
                      };
                    })}
                  />
                </Box>
                <Box sx={{ p: 1 }}>
                  <Button label={"Aplicar"} onClick={handleSubmit} />
                </Box>
              </Box>
            )}
          />
        </Box>
        {datos.length > 0 && (
          <Box sx={{ p: 1, mt: 4 }}>
            <Tabla datos={datos} />
          </Box>
        )}
      </Box>
      <AlertCustom
        open={alertCustom.open}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
        severity={alertCustom.severity}
        title={alertCustom.title}
        message={alertCustom.message}
      />
    </Layout>
  );
};

export default ModuloPedidos;
