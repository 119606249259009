import { fetchContent, Method } from "../utils/fetchContent";
export const GetListadoCalidad = async (orden_trabajo_id) => {
  try {
    return await fetchContent(`/stock_pendiente_calidad_listado/${orden_trabajo_id}`);
  } catch (error) {
    throw (error)
  }
}
export const GetListadoEtiquetaCalidad = async (proceso_productivo_id) => {
  try {
    return await fetchContent(`/proceso_productivo_etiqueta_calidad_listado/${proceso_productivo_id}`);
  } catch (error) {
    throw (error)
  }
}

export const SaveUpdateEtiquetas = async ( body) => {
  try {
    return await fetchContent(`/proceso_productivo_etiqueta_calidad_update`,
      {
        body,
        method: Method.POST,
      });

  } catch (error) {
    throw (error)
  }
}
export const SaveInformeModuloCalidad = async ( body,stock_id) => {
  try {
    return await fetchContent(`/stock_pendiente_calidad_update/${stock_id}`,
      {
        body,
        method: Method.POST,
      });

  } catch (error) {
    throw (error)
  }
}