import { Box } from "@mui/material";
import { Field, Form } from "react-final-form";
import Button from "../../../components/Button";
import { InputText } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { SaveNewProduct } from "../../../services/productServices";
import { required } from "../../../utils/validations";

export const Formulario = ({ setMensaje, products, refetch, measures }) => {
  return (
    <Form
      onSubmit={(values) => {
        SaveNewProduct({
          sku_codigo: values.cod,
          sku_descripcion: values.description,
          sku_unidad_medida_id: values?.skuUnidadMedidaId,
        })
          .then((response) => {
            refetch();
            setMensaje({
              open: true,
              title: "Se creo correctamente",
              showLink: true,
              id: response.data[0].skuId,
            });
          })
          .catch((response) =>
            setMensaje({
              open: true,
              title: response.message,
              showLink: false,
              id: "",
            })
          );
      }}
      initialValues={{ cod: "", description: "" }}
      validate={({ description, cod, skuUnidadMedidaId }) => {
        const errors: any = {};
        if (!cod) {
          errors.cod = "Requerido";
        }
        if (products.find((item) => item.skuCodigo === cod)) {
          errors.cod = "Ya existe";
        }

        if (!description) {
          errors.description = "Requerido";
        }
        if (!skuUnidadMedidaId) {
          errors.skuUnidadMedidaId = "Requerido";
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, pristine, values }) => (
        <Box>
          <Field name="cod" component={InputText as any} label="Codigo" />
          <Box className="mt-3">
            <Field
              name="description"
              component={InputText as any}
              label="Nombre"
            />
          </Box>
          <Box className="mt-3">
            <Field
              validate={required}
              name="skuUnidadMedidaId"
              component={SelectMui as any}
              label="unidad de medida"
              sx={{ minWidth: 150, width: "100%" }}
              options={measures.map((item) => {
                return {
                  value: item.unidadMedidaId,
                  name: item.unidadMedida,
                };
              })}
            />
          </Box>

          <Box className="d-flex justify-content-center">
            <Button
              label="Enviar"
              color="success"
              sx={{ mx: 1, mt: 2, width: "100%" }}
              onClick={handleSubmit}
              disabled={submitting || pristine}
            />
          </Box>
        </Box>
      )}
    />
  );
};
