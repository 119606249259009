import { Box } from "@mui/material";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputText } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { SaveUpdateEtiquetas } from "../../../services/moduloCalidad";
import { required } from "../../../utils/validations";

const AgregarEtiquetas = ({
  procesos,
  setAlertCustom,
  RefetchListadoEtiquetaCalidad,
  listadoEtiquetaCalidad,
}) => {
  const FuncionRestart: any = {};
  return (
    <Form
      onSubmit={(values) => {
        SaveUpdateEtiquetas({
          ...values,
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });

            FuncionRestart.funcion({
              procesoProductivoEtiquetaCalidadActivo: 1,
            });
            RefetchListadoEtiquetaCalidad();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={{ procesoProductivoEtiquetaCalidadActivo: 1 }}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        FuncionRestart.funcion = restart;
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Box className="col-10" sx={{ mt: 1 }}>
              <Field
                validate={required}
                name={`procesoProductivoId`}
                component={SelectMui as any}
                label="Proceso productivo"
                options={procesos
                  .filter((x) => Number(x?.procesoProductivoActivo) > 0)
                  .map((item) => {
                    return {
                      name: `${item.procesoProductivoTipoDescripcion}  ${
                        item.procesoProductivoId !== "1"
                          ? `- ${item.procesoProductivoDescripcion} `
                          : ""
                      } `,
                      value: item.procesoProductivoId,
                    };
                  })}
                sx={{ width: "100%" }}
              />
            </Box>
            <Box className="col-10" sx={{ mt: 1 }}>
              <Field
                validate={required}
                name={`procesoProductivoEtiquetaCalidadDescripcion`}
                component={InputText as any}
                label="Descripcion"
                sx={{ width: "100%" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
              className="col-12"
            >
              <Button
                label={"Agregar nueva etiqueta"}
                disabled={pristine}
                onClick={handleSubmit}
                sx={{ minWidth: "150px" }}
                color="success"
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};
export default AgregarEtiquetas;
