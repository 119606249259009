import { TableCell, Typography } from "@mui/material";
import TableSortLabelMui from "@mui/material/TableSortLabel";
export const TableSortLabel = ({
  showComponent,
  OrderGen,
  setShowComponent,
  items,
  name,
  nameHeader,
  ordenBy,
  number = false,
}) => {
  return (
    <TableCell>
      {showComponent[name] ? (
        <TableSortLabelMui
          active={true}
          direction={"desc"}
          onClick={() => {
            OrderGen(items, `${ordenBy}`, "asc", number);
            setShowComponent({ ...showComponent, [name]: false });
          }}
        >
          <Typography sx={{ color: "#FAFAFA" }} variant="h1">
            {nameHeader}
          </Typography>
        </TableSortLabelMui>
      ) : (
        <TableSortLabelMui
          active={true}
          direction={"asc"}
          onClick={() => {
            OrderGen(items, `${ordenBy}`, "desc", number);

            setShowComponent({ ...showComponent, [name]: true });
          }}
        >
          <Typography sx={{ color: "#FAFAFA" }} variant="h1">
            {nameHeader}
          </Typography>
        </TableSortLabelMui>
      )}
    </TableCell>
  );
};
