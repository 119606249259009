import { Box, Typography } from "@mui/material";

export const Encabezado = () => (
  <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 }}>
    <Box className="col-3" sx={{ p: 1 }}>
      <Typography
        variant="h2"
        alignItems="center"
        sx={{
          maxWidth: "300px",
        }}
      >
        Codigo
      </Typography>
    </Box>
    <Box className="col-2" sx={{ p: 1 }}>
      <Typography
        variant="h2"
        alignItems="center"
        sx={{
          maxWidth: "300px",
        }}
      >
        Nombre
      </Typography>
    </Box>
    <Box className="col-2" sx={{ p: 1 }}>
      <Typography
        variant="h2"
        alignItems="center"
        sx={{
          maxWidth: "300px",
        }}
      >
        Lote
      </Typography>
    </Box>
    <Box className="col-2" sx={{ p: 1 }}>
      <Typography
        variant="h2"
        alignItems="center"
        sx={{
          maxWidth: "300px",
        }}
      >
        Stock disponible
      </Typography>
    </Box>
    <Box className="col-3" sx={{ p: 1 }}>
      <Typography
        variant="h2"
        alignItems="center"
        sx={{
          maxWidth: "300px",
        }}
      >
        Cantidad
      </Typography>
    </Box>
  </Box>
);
