import { Box, InputAdornment } from "@mui/material";
import { Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputNumber, InputText } from "../../../components/Input";
const CardModuloCalidad = ({ name, unidadMedida, setModalEtiqueta }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ p: 1 }}>
        <Field
          disabled
          component={InputText as any}
          name={`${name}.skuCodigo`}
          label="codigo"
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          disabled
          component={InputNumber as any}
          name={`${name}.stockCantidad`}
          label="Cantidad"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{unidadMedida}</InputAdornment>
            ),
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          disabled
          component={InputNumber as any}
          name={`${name}.ordenTrabajoId`}
          label="IR/OTG"
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          disabled
          component={InputText as any}
          name={`${name}.stockLote`}
          label="Lote"
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          disabled
          component={InputText as any}
          name={`${name}.ordenTrabajoElaboracionFecha`}
          label="Fecha elaboracion"
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          disabled
          component={InputText as any}
          name={`${name}.procesoProductivo`}
          label="Proceso productivo"
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          disabled
          component={InputText as any}
          name={`${name}.stockUbicacionDescripcion`}
          label="Deposito"
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Button label={"Control"} onClick={setModalEtiqueta} />
      </Box>
    </Box>
  );
};
export default CardModuloCalidad;
