export const validarHuboCambios = (values, datosInicial) => {
  if (
    values.procesoProductivoDescripcion ===
      datosInicial.procesoProductivoDescripcion &&
    values.stockUbicacionId === datosInicial.stockUbicacionId &&
    values.procesoProductivoTipoId === datosInicial.procesoProductivoTipoId &&
    Number(values.procesoProductivoCostoPorHoraPorPersona) ===
      Number(datosInicial.procesoProductivoCostoPorHoraPorPersona) &&
    Number(values.procesoProductivoHorasDisponiblesPorDia) ===
      Number(datosInicial.procesoProductivoHorasDisponiblesPorDia)
  ) {
    return false;
  }
  return true;
};
