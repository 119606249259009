 import { useQuery } from 'react-query';
import { GetListadoCalidad, GetListadoEtiquetaCalidad } from '../services/moduloCalidad';


export const useListadoCalidad= (id) => {
  const { data, isLoading, isError, refetch } = useQuery([`useListadoCalidad${id}`], async () => {
    const response = await GetListadoCalidad(id);
    const NuevaData = response.data.map((item) => {

      return  { ...item, procesoProductivo: item.ordenTrabajoId ? `${item.ordenTrabajoProcesoProductivoTipoDescripcion} ${
                                item.ordenTrabajoProcesoProductivoId !== "1" &&
                                item.ordenTrabajoProcesoProductivoId !== "0"
                                  ? `- ${item.ordenTrabajoProcesoProductivoDescripcion}`
                                  : ""
                              }` :`${item.skuProcesoProductivoTipoDescripcion} ${
                                item.skuProcesoProductivoId !== "1" &&
                                item.skuProcesoProductivoId !== "0"
                                  ? `- ${item.skuProcesoProductivoDescripcion}`
                                  : ""
                              }`, procesoProductivoId:  item.ordenTrabajoId ? item.ordenTrabajoProcesoProductivoId : item.skuProcesoProductivoId} })
    return NuevaData?.filter((item)=> !!item?.ordenTrabajoId);

    // return response.data.filter((item ) => item.ordenTrabajoEstructura === "001")
  });   

  return { 
    data: data,
    isLoading,
    isError,
    refetch
    
   }
}
export const useListadoEtiquetaCalidad= (id) => {
  const { data, isLoading, isError, refetch } = useQuery([`useListadoEtiquetaCalidad${id}`], async () => {
    const response = await GetListadoEtiquetaCalidad(id);
   return response.data

    // return response.data.filter((item ) => item.ordenTrabajoEstructura === "001")
  });   

  return { 
    data: data,
    isLoading,
    isError,
    refetch
    
   }
}