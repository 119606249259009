import { useQuery } from "react-query";
import { GetListPedidos } from "../services/pedidosServices";
import { convertDateDDMMAAAA } from "../utils/Fecha";
import { OrderGen } from "../utils/orderGen";
export const useListPedidos = (id = 1) => {
  const { data, isLoading, isError, refetch } = useQuery(
    "listPedidos",
    async () => {
      const response = await GetListPedidos(id);
      OrderGen(response?.data, "comprobanteDetalleId", "desc", true);

      const results = response.data.map((item) => {
        const cantidadOriginal = Number(item?.comprobanteDetalleCantidad);
        const cantidadProducida = Number(item?.ordenTrabajoCantidadProducida);
        const cantidadRestante = cantidadOriginal - cantidadProducida;

        return {
          ...item,
          fechaCreacion: convertDateDDMMAAAA(item?.comprobanteFecha),
          fechaEntregaPedido: convertDateDDMMAAAA(
            item?.comprobanteFechaEntregaPedido
          ),
          nombreUsuario: `${item?.nombre} ${item?.apellido}`,
          comprobanteDetallePrecioUnitario: `$${item?.comprobanteDetallePrecioUnitario}`,
          nombreSelectCrearOtg: `${
            item?.comprobanteNumero
          } || ${convertDateDDMMAAAA(item?.comprobanteFecha)}`,
          cantidadRestante: cantidadRestante,
          ordenTrabajoId: item?.ordenTrabajoId
            ? `OTG - ${item?.ordenTrabajoId}`
            : "Sin OTG asignada",
          estado:
            cantidadRestante === cantidadOriginal
              ? "Pendiente"
              : cantidadRestante !== 0 && cantidadRestante < cantidadOriginal
              ? "Entrega Parcial"
              : "Cumplida",
          estadoId:
            cantidadRestante === cantidadOriginal
              ? "1"
              : cantidadRestante !== 0 && cantidadRestante < cantidadOriginal
              ? "2"
              : "3",
          comprobanteDetalleTelaProvistaPorCliente:
            item?.comprobanteDetalleTelaProvistaPorCliente === "0"
              ? "poltron"
              : "cliente",
          comprobanteDetallePlanoAprobado:
            item?.comprobanteDetallePlanoAprobado === "0" ? "NO" : "SI",
        };
      });

      return results;
    }
  );
  return {
    data: data,
    isLoading,
    refetch,
    isError,
  };
};
