import { Typography } from "@mui/material";
import { useState } from "react";
export const LinkStyle = ({
  href,
  img,
  name,
  description,
  enContruccion = false,
}) => {
  return (
    <a
      style={{ textDecoration: "none" }}
      href={href}
      className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 card"
    >
      <div className="d-flex flex-column">
        <img src={img} alt="" />
        <h5
          className="d-flex justify-content-center"
          style={{ textAlign: "center" }}
        >
          {name}
        </h5>
        {enContruccion && (
          <Typography
            color="red"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            - EN CONSTRUCCIÓN -
          </Typography>
        )}
        <span>{description}</span>
      </div>
    </a>
  );
};
export const LinkStyleModal = ({
  img,
  name,
  onClick,
  href = null,
  description,
  enContruccion = false,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      style={{ cursor: "pointer", textDecoration: "none" }}
      onClick={onClick}
      href={href}
      className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 card"
    >
      <div className="d-flex flex-column" style={{ height: "100%" }}>
        <img src={img} alt="" />
        <h5
          className="d-flex justify-content-center align-items-center"
          style={{ textAlign: "center", height: "100%" }}
        >
          {name}
        </h5>
        {enContruccion && (
          <Typography
            color="red"
            sx={{ display: "flex", justifyContent: "center" }}
          >
            - EN CONSTRUCCIÓN -
          </Typography>
        )}
      </div>
    </a>
  );
};
