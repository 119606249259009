import { Box, InputAdornment, Typography } from "@mui/material";
import { InpuNumberNotForm, InputNumber } from "../../../components/Input";
import { Field } from "react-final-form";
import { CheckBoxMui } from "../../../components/CheckBoxMui";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";
export const CardDetailsOtg = ({
  name,
  consumoActual,
  consumoTotal,
  nameField,
  eliminar = "n",
  permisos,
  listUsers,
  unidadMedida,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        background: eliminar !== "s" ? "#FFFFFF" : "rgba(0, 0, 0, 0.15)",
        border: "1px solid #C1C1C1",
        boxSizing: "border-box",
        boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
        p: 1,
        my: 2,
      }}
    >
      <Box className="col-3" sx={{ px: 0.5, py: 1, ml: 3 }}>
        <Typography variant="subtitle1">{name}</Typography>
      </Box>
      <Box className="col-2" sx={{ px: 0.5, py: 1 }}>
        <Field
          name={`${nameField}.ordenTrabajoFormulaCantidad`}
          component={InputNumber as any}
          label="Cons. Actual"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{unidadMedida}</InputAdornment>
            ),
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box className="col-2" sx={{ px: 0.5, py: 1 }}>
        <Field
          name={`${nameField}.ordenTrabajoFormulaMermaPorcentaje`}
          component={InputNumber as any}
          label="Merma std"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box className="col-2" sx={{ px: 0.5, py: 1 }}>
        <InpuNumberNotForm
          onchange={() => {}}
          label={"Cons. Actual"}
          value={consumoActual}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{unidadMedida}</InputAdornment>
            ),
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          disabled
        />
      </Box>
      <Box className="col-2" sx={{ px: 0.5, py: 1 }}>
        <InpuNumberNotForm
          onchange={() => {}}
          label={"Cons. Total"}
          value={consumoTotal}
          sx={{ width: "100%" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">{unidadMedida}</InputAdornment>
            ),
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          disabled
        />
      </Box>
      <Box className="col-2" sx={{ px: 0.5 }}>
        <Field
          disabled={
            !verificarPermiso(
              permisos,
              PERMISOS.DES_SELECCIONAR_FORMULA_ORIGINAL,
              listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
                ?.idGrupo
            )
          }
          name={`${nameField}.eliminar`}
          component={CheckBoxMui as any}
        />
      </Box>
    </Box>
  );
};
