import { Box, Typography } from "@mui/material";
import { getColorMaterial } from "../../../utils/getColorMaterial";
import { numeroOtg } from "../../../utils/numeroOtg";
import CircleIcon from "@mui/icons-material/Circle";
export const CardOtgHorasTrabajoMobi = ({
  datos: {
    ordenTrabajoId,
    ordenTrabajoCantidad,
    ordenTrabajoCantidadProducida,
    skuDescripcion,
    ordenTrabajoStatusMateriales,
    ordenTrabajoPrioridad,
    sx = {},
  },
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      border: "0.5px solid #C1C1C1",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      p: 1,
      mt: 1,
      ...sx,
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      className="col-3"
    >
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Prioridad
      </Typography>

      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {ordenTrabajoPrioridad}
      </Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      className="col-5"
    >
      <Typography>{`OTG - ${numeroOtg(Number(ordenTrabajoId))}`}</Typography>
      <Typography
        sx={{
          mt: 0.1,
          fontSize: "10px",
          px: 1,
        }}
      >
        {skuDescripcion}
      </Typography>
    </Box>
    <Box className="col-4">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography>Materiales</Typography>
        <Box>
          <CircleIcon
            color={getColorMaterial(Number(ordenTrabajoStatusMateriales))}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", mt: 0.2 }}>
        <Typography>{`${ordenTrabajoCantidadProducida} / ${ordenTrabajoCantidad}`}</Typography>
      </Box>
    </Box>
  </Box>
);
