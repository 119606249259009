import * as React from "react";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Box } from "@mui/material";
import { InputNumber, InputText } from "../../../components/Input";
import ButtonCustom from "../../../components/Button";
import { required } from "../../../utils/validations";
import { SelectMui } from "../../../components/SelectMui";
import { PostUpdateOrCreateSupplier } from "../../../services/supplierServices";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";

export const FormSuppliersDetails = ({
  supplier,
  refetch,
  modalOpen,
  typeSuppliers,
  submitSucceeded,
  setShowModalProductsSuppliers,
  setModalOpen,
  setSubmitSucceeded,
  setAlertCustom,
  permisos,
  botonAsignarProducto = false,
  listUsers,
}) => {
  return (
    <Form
      onSubmit={(values) => {
        const { entidadTipoDescripcion, ...body } = values;

        PostUpdateOrCreateSupplier(body)
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });

            refetch();
            setSubmitSucceeded(true);
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Ha ocurrido un error",
              severity: "error",
              message: error?.message ? error.message : "Error inesperado",
            });
          });
      }}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={supplier}
      render={({ handleSubmit, form: { restart }, invalid, pristine }) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                minWidth: "300px",
                height: "100%",
              }}
            >
              <Field
                name="entidadCuit"
                label="CUIT"
                component={InputNumber}
                validate={(value) =>
                  !value
                    ? "Requerido"
                    : value.length > 11
                    ? "Maximo 11 digitos"
                    : undefined
                }
                sx={{ mt: 2, textAlign: "center" }}
              />

              <Field
                name="entidadRazonSocial"
                label="Razon Social"
                component={InputText}
                validate={required}
                sx={{ mt: 2 }}
              />
              <Field
                name="entidadDescripcion"
                label="Nombre de empresa"
                component={InputText}
                validate={required}
                sx={{ mt: 2 }}
              />
              <Field
                name="entidadContactoNombre"
                label="Nombre"
                component={InputText}
                validate={required}
                sx={{ mt: 2 }}
              />
              <Field
                name="entidadContactoApellido"
                label="Apellido"
                component={InputText}
                validate={required}
                sx={{ mt: 2 }}
              />
              <Field
                name="entidadContactoTelefono"
                label="Teléfono"
                component={InputNumber}
                validate={(value) =>
                  !value
                    ? "Requerido"
                    : value.length > 10
                    ? "Maximo 10 digitos"
                    : undefined
                }
                sx={{ mt: 2 }}
              />

              <Field
                name="entidadContactoMail"
                label="Correo"
                component={InputText}
                validate={(value) =>
                  !value
                    ? "Requerido"
                    : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
                    ? "correo invalido"
                    : undefined
                }
                sx={{ mt: 2 }}
              />
              <Field
                name="entidadTipoId"
                label="Tipo"
                align="right"
                component={SelectMui as any}
                options={typeSuppliers.map((item) => {
                  return {
                    name: item.entidadTipoDescripcion,
                    value: item.entidadTipoId,
                  };
                })}
                validate={required}
                sx={{ mt: 2, width: "100%", textAlign: "left" }}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
            >
              <ButtonCustom
                label="Guardar"
                sx={{ mt: 2 }}
                onClick={handleSubmit}
                disabled={invalid || pristine}
                hidden={submitSucceeded}
              />
              <ButtonCustom
                label="Volver al inicio"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 2,
                  width: "100%",
                }}
                hidden={!submitSucceeded}
                onClick={() => {
                  setModalOpen(!modalOpen);
                  setAlertCustom({
                    open: false,
                    title: "",
                    severity: "",
                    message: "",
                  });
                  setSubmitSucceeded(false);
                }}
                color="success"
              />
              {botonAsignarProducto && (
                <ButtonCustom
                  disabled={
                    !verificarPermiso(
                      permisos,
                      PERMISOS.ASIGNA_A_CADA_PROVEEDOR_LOS_PRODUCTOS_QUE_ABASTECE,
                      listUsers?.find(
                        (item) => item?.radiusIdCodigo === getUser()?.uid
                      )?.idGrupo
                    )
                  }
                  sx={{ mt: 2, marginLeft: 3 }}
                  label={"Asignar Producto"}
                  onClick={() => {
                    setShowModalProductsSuppliers(true);
                  }}
                />
              )}
            </Box>
          </>
        );
      }}
    />
  );
};
