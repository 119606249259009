import { Box } from "@mui/material";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputDate } from "../../../components/Input";
import { SaveFeriado } from "../../../services/ordenTrabajo";
import { fechaActual } from "../../../utils/Fecha";
import { required } from "../../../utils/validations";

const FormAgregarNuevoFeriado = ({ setAlertCustom, refetch }) => {
  const obj: any = {};
  return (
    <Form
      onSubmit={(values) => {
        SaveFeriado({
          ...values,
          feriado_activo: 1,
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
            obj.funcion();
            refetch();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={{}}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        obj.funcion = restart;
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Box className="col-12">
              <Field
                validate={required}
                component={InputDate as any}
                name={"feriado_fecha"}
                label={values?.feriado_fecha ? "Fecha" : ""}
                sx={{ width: "100%" }}
                defaultValue={fechaActual()}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
              className="col-12"
            >
              <Button
                label={"Agregar"}
                onClick={handleSubmit}
                sx={{ minWidth: "150px" }}
                color="success"
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};
export default FormAgregarNuevoFeriado;
