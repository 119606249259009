import { Field, Form } from "react-final-form";
import { Box } from "@mui/material";
import { SelectMui } from "../../../components/SelectMui";
import Button from "../../../components/Button";

export const ModalMenuStop = ({
  ordentrabajoMotivoPausa,
  id,
  SaveActividadUsuario,
  user,
  closer,
  setAlertCustom,
  refetchListadoHorasPorUsuario,
}) => {
  return (
    <Form
      onSubmit={(values) => {
        SaveActividadUsuario(id, {
          id_radius: user,
          orden_trabajo_estado_id: 3,
          orden_trabajo_motivo_pausa_id: values.motivo,
        })
          .then(() => {
            closer();
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se pausó correctamente",
            });
            refetchListadoHorasPorUsuario();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={{}}
      render={({
        handleSubmit,
        submitting,
        pristine,
        values,
        invalid,
        form: { restart },
      }) => (
        <>
          <Box
            sx={{
              m: 1,
              p: 1,
              background: "#FFFFFF",
            }}
          >
            <Field
              name="motivo"
              component={SelectMui as any}
              label="Seleccionar el motivo"
              options={ordentrabajoMotivoPausa.map((item) => {
                return {
                  value: item.ordenTrabajoMotivoPausaId,
                  name: item.ordenTrabajoMotivoPausaDescripcion,
                };
              })}
              sx={{ width: "250px", maxWidth: "100%" }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              label="Enviar"
              onClick={handleSubmit}
              color="success"
              sx={{ width: "150px" }}
            />
          </Box>
        </>
      )}
    />
  );
};
