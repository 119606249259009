export const menuOptions = ["planning", "Historial"];

export const STATUS = {
    APPROVED: "approved",
    REJECT: "reject",
    PENDING: "pending"
};
export const PERMISOS = {
BUSCAR_OTGS: 'buscar otgs',
AGREGAR_OTGS: 'agregar otgs',
CAMBIAR_PRIORIDADES_DE_FABRICACION_DE_LAS_OTGS: 'cambiar prioridades de fabricación de las otgs',
EDITAR_FECHA_DE_INICIO_DE_ELABORACION_PLAN: 'editar fecha de inicio de elaboración',
CAMBIAR_ESTADO_DE_LA_OTG_PLAN: 'cambiar estado de la otg',
FLECHA_PARA_DETALLE_DE_LAS_OTG: 'flecha para detalle de las otg',
CAMBIAR_ESTADO_DE_LA_OTG: 'cambiar estado de la otg',
EDITAR_FECHA_DE_INICIO_DE_ELABORACION: 'editar fecha de inicio de elaboración',
DECLARAR_UNIDADES: 'declarar unidades',
DES_SELECCIONAR_FORMULA_ORIGINAL: 'deseleccionar fórmula original',
SELECCION_DE_CAMPO_DESCUENTA_PARA_LA_UBICACION: 'selección de campo descuenta para la ubicación',
AGREGAR_MATERIALES: 'agregar materiales',
SELECCION_Y_AGREGADO_DE_USUARIO: 'selección y agregado de usuario',
ACCESO_PANTALLA_INICIO_DE_TRABAJO: 'acceso pantalla inicio de trabajo',
NAVEGACION_GENERAL_SIN_BOTON_CREAR_NI_EDITAR: 'navegación general',
CREAR_NUEVO_PRODUCTO: 'crear nuevo producto',
EDITAR_TODOS_LOS_ATRIBURTOS_DE_UN_PRODUCTO: 'editar todos los atriburtos de un producto',
EDICION_DE_ATRIBUTOS_Y_ESTRUCTURA_DE_PRODUCTO: 'edición de atributos y estructura de producto',
SOLO_CONSULTA_ITEM_POR_PAGINA: 'sólo consulta item por página',
SOLO_CONSULTA_BUSCAR: 'sólo consulta buscar',
SOLO_CONSULTA_VER_HISTORIAL: 'sólo consulta ver historial',
AGREGAR_NUEVA_UBICACION: 'agregar nueva ubicación',
REALIZAR_TRANSFERENCIA_ENTRE_VARIABLES: 'realizar transferencia entre variables',
BOTON_REALIZAR_AJUSTES_DE_STOCK_POR_DIFERENCIAS: 'realizar ajustes de stock por diferencias',
CAMBIO_DE_DEPOSITO: 'cambio de depósito',
CAMBIO_DE_UBICACION: 'cambio de ubicación',
CAMBIAR_EL_ESTADO_DE_CALIDAD: 'cambiar el estado de calidad',
REALIZAR_AJUSTES_DE_STOCK_POR_DIFERENCIAS: 'realizar ajustes de stock por diferencias',
INGRESO_DE_NUEVOS_INSUMOS_Y_TERCERIZADOS: 'ingreso de nuevos insumos y tercerizados',
SOLO_CONSULTA_DE_UBICACION_DEL_STOCK: 'sólo consulta de ubicación del stock',
CREA_OC_PARA_USO_INTERNO: 'crea o.c. para uso interno',
SOLO_CONSULTA_DE_OC_SEGUN_PROVY_STATUS: 'sólo consulta de oc según proveedor y status',
CREAR_NUEVOS_USUARIOS: 'grupos y usuarios',
REESTABLECAR_LA_CONTRASEÑA: 'grupos y usuarios',
MODIFICAR_DATOS_DE_USUARIOS: 'grupos y usuarios',
CREAR_Y_EDITAR_DATOS_DE_LOS_PROVEEDORES: 'crear y editar datos de los proveedores',
ASIGNA_A_CADA_PROVEEDOR_LOS_PRODUCTOS_QUE_ABASTECE: 'asigna a cada proveedor los productos que abastece',
CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS: 'crear, editar y eliminar procesos productivos',
};

export const error_login = "error_login";
export const titleDialog = {error: "Error",informacion: "Informacion", advertencia: "Advertencia"}