import { useState } from "react";
import { Field, Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Box, Typography } from "@mui/material";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";
import { SelectMui } from "../../components/SelectMui";
import { usePermisosParaOtorgar, usePermisosPorModulo } from "../../hooks/auth";
import { useListGroup } from "../../hooks/useUsers";
import { Loading } from "../loading";
import { FieldArray } from "react-final-form-arrays";
import { SwitchMui, SwitchMuiPermisos } from "../../components/SwichMui";
import { InputText } from "../../components/Input";
import Button from "../../components/Button";
import { PostPermisosGrupo } from "../../services/auth";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import ABMGrupo from "./component/abmGrupo";

const Permisos = () => {
  const [id, setId] = useState({ idModulo: null, idGrupo: null });
  const [initialValue, setInitialValue] = useState(null);
  const [modalEditarAgregarTarea, setModalEditarAgregarTarea] = useState(false);
  const {
    data: permisosParaOtorgar,
    isError: isErrorPermisosParaOtorgar,
    isLoading: isLoadingPermisosParaOtorgar,
  } = usePermisosParaOtorgar();
  const {
    data: listGroup,
    isError: isErrorListGroup,
    isLoading: loadingListGroup,
    refetch: refetchListGroup,
  } = useListGroup();
  const {
    data: listPermisosPorModulo,
    isError: isErrorPermisosPorModulo,
    isLoading: loadingPermisosPorModulo,
    refetch,
  } = usePermisosPorModulo(id);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  if (
    isLoadingPermisosParaOtorgar ||
    loadingListGroup ||
    loadingPermisosPorModulo
  ) {
    return <Loading />;
  }
  if (
    isErrorPermisosParaOtorgar ||
    isErrorListGroup ||
    isErrorPermisosPorModulo
  ) {
    return <ErrorMsg />;
  }
  return (
    <>
      <Layout title={"Administrar permisos"}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "100%",
            mb: 4,
            mr: 4,
          }}
        >
          <Button
            label="Editar/agregar grupo"
            onClick={() => setModalEditarAgregarTarea(true)}
          />
        </Box>
        <Form
          onSubmit={(values) => {
            const body: any = {
              id_grupo: values.idGrupo,
              id_modulo: values.idModulo,
            };
            values.datos.map((item) => {
              body[`_${item.nombre}`] = item.activo;
            });

            PostPermisosGrupo(body)
              .then(() => {
                setAlertCustom({
                  open: true,
                  title: "Confirmacion",
                  severity: "success",
                  message: "Se guardo correctamente",
                });
                refetch();
              })
              .catch((error) => {
                setAlertCustom({
                  open: true,
                  title: "Error",
                  severity: "error",
                  message: error.message,
                });
              });
          }}
          initialValues={{ ...initialValue, datos: listPermisosPorModulo }}
          mutators={{
            ...arrayMutators,
          }}
          render={({ values, handleSubmit, pristine }) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                ml: 2,
                px: 2,
              }}
            >
              <Box className="col-4 col-xl-2" sx={{ p: 1 }}>
                <Field
                  name="idModulo"
                  label={"Modulo"}
                  component={SelectMui as any}
                  sx={{ width: "300px", maxWidth: "100%" }}
                  options={permisosParaOtorgar.map((item) => {
                    return {
                      value: item.idModulo,
                      name: item.nombre,
                    };
                  })}
                  onchange={(id) => {
                    setId({
                      idGrupo: values?.idGrupo,
                      idModulo: id,
                    });
                    setInitialValue({ ...values, idModulo: id });
                  }}
                />
              </Box>
              <Box className="col-4 col-lg-3 col-xl-3" sx={{ p: 1 }}>
                <Field
                  name="idGrupo"
                  component={SelectMui as any}
                  label={"Grupo"}
                  sx={{ width: "300px", maxWidth: "100%", ml: 2 }}
                  options={listGroup.map((item) => {
                    return {
                      value: item.idGrupo,
                      name: item.nombre,
                    };
                  })}
                  onchange={(id) => {
                    setId({
                      idModulo: values.idModulo,
                      idGrupo: id,
                    });
                    setInitialValue({ ...values, idGrupo: id });
                  }}
                />
              </Box>

              <Box
                className="col-4 col-xl-3"
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  pl: 2,
                  maxWidth: "100%",
                }}
              >
                <Typography variant="body2">Descripcion del grupo:</Typography>
                <Typography>
                  {
                    listGroup.find(
                      (grupo) => grupo?.idGrupo === values?.idGrupo
                    )?.descripcion
                  }
                </Typography>
              </Box>
              <Box className="col-12" sx={{ mt: 2 }}>
                <FieldArray name="datos">
                  {({ fields }) => (
                    <Box sx={{}}>
                      {fields.map((name, index) => {
                        return (
                          <Box sx={{ maxWidth: 300, p: 1.5 }}>
                            <Box
                              sx={{
                                boxShadow: "2px 2px 2px 2px grey",
                                ml: 1,
                                px: 1,
                              }}
                            >
                              <Field
                                color={
                                  values.skuActivo === "0" ? "error" : "success"
                                }
                                name={`${name}.activo`}
                                component={SwitchMuiPermisos as any}
                                check={
                                  values.datos[index].activo === "0"
                                    ? false
                                    : true
                                }
                                label={values.datos[index].permiso}
                              />
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
                </FieldArray>
              </Box>
              <Button
                label={"Guardar"}
                onClick={handleSubmit}
                disabled={pristine}
                color={"success"}
                sx={{ width: "300px", maxHeight: "25px", mt: 4 }}
              />
            </Box>
          )}
        />
      </Layout>
      <DialogGen
        open={modalEditarAgregarTarea}
        title={"Grupo"}
        onClick={() => {
          setModalEditarAgregarTarea(false);
        }}
        sx={{ width: "100%", maxWidth: "900px" }}
      >
        <ABMGrupo
          listGroup={listGroup}
          setAlertCustom={(obj) => setAlertCustom(obj)}
          refetchListGroup={refetchListGroup}
        />
      </DialogGen>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({ open: false, title: "", severity: "", message: "" })
        }
      />
    </>
  );
};
export default Permisos;
