import { useQuery } from "react-query";
import { GetConversionUnidadMedida } from "../services/conversionUnidadMedidaServices";
import { OrderGen } from "../utils/orderGen";
export const useConversionUnidadMedida = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    "ConversionUnidadMedida",
    async () => {
      const response = await GetConversionUnidadMedida();
      //  OrderGen(response?.data, "monedaOrden");
      return response;
    }
  );
  return {
    data: data?.data,
    isLoading,
    refetch,
    isError,
  };
};
