import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { SaveStorage, ReadStorage } from "./localStorage";
// import env from "react-dotenv";

const httpClient = axios.create({
  baseURL: "https://api-dev.poltron.app/v1/",
});

export const Method = {
  GET: "GET",
  PUT: "PUT",
  PATCH: "PATCH",
  POST: "POST",
  DELETE: "DELETE",
};
const baseURL = "https://api-dev.poltron.app/v1";

export const fetchContent = async (url, config = {}, snake = true) => {
  try {
    const { body, ...options } = config;
    const source = axios.CancelToken.source();
    const token = await ReadStorage("Token");

    const request = {
      cancelToken: source.token,
      method: Method.GET,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      ...options,
      url: `${baseURL}${url.startsWith("/") ? "" : "/"}${url}`,
    };

    if (body) {
      if (snake) {
        request.data = snakecaseKeys(body, { deep: true });
      } else {
        request.data = body;
      }
    }

    const promise = httpClient.request(request);
    promise.cancel = () => source.cancel("cancelled");
    const response = await promise;
    if (response?.data?.error) {
      throw new Error(response.data.error);
    }
    return camelcaseKeys(response?.data, { deep: true });
  } catch (error) {
    if (error?.response?.status === 401) {
      await ObtenerToken();
      return fetchContent(url, config, snake);
    } else {
      throw error?.response?.data;
    }
  }
};
const ObtenerToken = async () => {
  const responseToken = await fetch("https://api-dev.poltron.app/v1/login", {
    method: Method.POST,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      user: "poltron_authorization",
      pass: "Mmkjdd4gjkd5eKjjdJLG#",
    }),
  });
  const token = await responseToken.json();
  SaveStorage("Token", token.token);
};
