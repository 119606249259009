import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { DialogGen } from "../Dialog";
import { Box, Typography } from "@mui/material";
import Button from "../Button";

export function SwitchMui({
  input: { value, onChange, checked = true },
  check,
  color,
  disabled = false,
  setInactivarFormulario,
}) {
  const [showModalConfir, setShowModalConfir] = React.useState(false);
  if (value === "0") {
    setInactivarFormulario(true);
  } else {
    setInactivarFormulario(false);
  }
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Switch checked={check} color={color} disabled={disabled} />}
          onClick={() => {
            if (!disabled) {
              setShowModalConfir(true);
            }
          }}
          label={"Activo"}
        />
      </FormGroup>
      <DialogGen
        open={showModalConfir}
        title={"Confirmar"}
        onClick={() => {
          setShowModalConfir(false);
        }}
      >
        <Box
          sx={{
            maxWidth: 300,
          }}
        >
          <Typography>
            Esta seguro que quiere
            <b>{value === "0" ? " desarchivar " : " archivar "}</b> este
            producto
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button
              label="Cancelar"
              onClick={() => setShowModalConfir(false)}
            />
            <Button
              color="success"
              label="Confirmar"
              onClick={() => {
                onChange(value === "0" ? "1" : "0");
                setShowModalConfir(false);
              }}
            />
          </Box>
        </Box>
      </DialogGen>
    </>
  );
}
export function SwitchMuiPermisos({
  input: { value, onChange, checked = true },
  check,
  color,
  label,
}) {
  return (
    <>
      <FormGroup>
        <FormControlLabel
          label={label}
          control={<Switch checked={check} color={color} />}
          onClick={() => onChange(value === "0" ? "1" : "0")}
        />
      </FormGroup>
    </>
  );
}
