import { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box, Skeleton } from "@mui/material";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";
import { Form } from "react-final-form";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
// import Button from "../../components/Button";
import { saveInfoUbicacion } from "./hook";
import FormAgregarNuevaMoneda from "./components/formAgregarNuevaMoneda";
import { useListUbicationProvider } from "../../hooks/useProducts";
import CardUbicacion from "./components/cardUbicacion";
const AdminUbicacion = () => {
  const [showModalNuevaMoneda, setShowModalNuevaMoneda] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data: listUbicationProvider,
    isLoading: isLoadingListUbicationProvider,
    isError: isErrorListUbicationProvider,
    refetch,
  } = useListUbicationProvider(0, false);

  if (isErrorListUbicationProvider) {
    return <ErrorMsg />;
  }
  console.log(listUbicationProvider);
  return (
    <Layout title={"Administrar Ubicaciones de stock"} clase={false}>
      {!isLoadingListUbicationProvider ? (
        <Form
          onSubmit={(values) => {}}
          initialValues={{ datos: listUbicationProvider }}
          mutators={{
            ...arrayMutators,
          }}
          validate={(values) => {
            return {};
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push },
              restart,
            },
            submitting,
            pristine,
            values,
          }) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  p: 2,
                }}
              >
                {/* {console.log(values)}
                <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                  <Button
                    onClick={() => setShowModalNuevaMoneda(true)}
                    label={"Agregar una nueva moneda"}
                    color="success"
                  />
                </Box> */}
                <FieldArray name="datos">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <Box
                        className="col-12"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <CardUbicacion
                          name={name}
                          onclick={() =>
                            saveInfoUbicacion(
                              values?.datos[index],
                              setAlertCustom,
                              refetch
                            )
                          }
                          disabled={
                            values?.datos[index]?.entidadDescripcion ===
                              listUbicationProvider[index]
                                ?.entidadDescripcion &&
                            values?.datos[index]?.stockUbicacionDescripcion ===
                              listUbicationProvider[index]
                                ?.stockUbicacionDescripcion &&
                            values?.datos[index]?.stockUbicacionActivo ===
                              listUbicationProvider[index]?.stockUbicacionActivo
                          }
                        />
                      </Box>
                    ))
                  }
                </FieldArray>
              </Box>
            );
          }}
        />
      ) : (
        <Box className="col-11">
          <Skeleton variant="text" />

          <Skeleton variant="rectangular" width={""} height={300} />
        </Box>
      )}
      <DialogGen
        open={showModalNuevaMoneda}
        title={"Agregar nueva moneda"}
        onClick={() => setShowModalNuevaMoneda(false)}
        sx={{ maxWidth: "500px" }}
      >
        <FormAgregarNuevaMoneda
          setAlertCustom={setAlertCustom}
          refetch={refetch}
          listUbicationProvider={listUbicationProvider}
        />
      </DialogGen>

      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </Layout>
  );
};

export default AdminUbicacion;
