import { Box } from "@mui/material";
import { Field } from "react-final-form";
import { AutoComplete } from "../../../components/AutoComplete";
import Button from "../../../components/Button";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";
import { verificarPermiso } from "../../../utils/verificarPermiso";

const FormAddUsersOtgMobi = ({
  values: { usersOtg, formulaComponenteSku },
  users,
  onClick,
  permisos,
  listUsers,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Field
        disabled={
          !verificarPermiso(
            permisos,
            PERMISOS.SELECCION_Y_AGREGADO_DE_USUARIO,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          )
        }
        name={`formulaComponenteSku`}
        component={AutoComplete as any}
        sx={{ maxWidth: "300px", width: "100%" }}
        label={"Usuario"}
        options={users
          .filter(
            (user) => !usersOtg?.find((id) => user?.idRadius === id?.idRadius)
          )
          ?.map((item) => {
            return {
              id: item.idRadius,
              label: item.user,
            };
          })}
      />
      <Button
        disabled={
          !verificarPermiso(
            permisos,
            PERMISOS.SELECCION_Y_AGREGADO_DE_USUARIO,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          )
        }
        label={"Agregar"}
        onClick={() => {
          if (
            !!formulaComponenteSku?.id &&
            !usersOtg?.find(
              (item) => item?.idRadius === formulaComponenteSku?.id
            )
          ) {
            onClick({
              idRadius: formulaComponenteSku.id,
              user: formulaComponenteSku.label,
            });
          }
        }}
      />
    </Box>
  );
};
export default FormAddUsersOtgMobi;
