import { SaveUbicationProvider } from "../../services/productServices";

export const saveInfoUbicacion = (
  body,
  setAlertCustom,
  refetch,
  refrescar = null
) => {
  console.log(body);
  SaveUbicationProvider({
    body: {
      ...body,
      stock_ubicacion_entidad_id: body.entidadId,
      stock_ubicacion_activo: Number(2),
    },
  })
    .then(() => {
      setAlertCustom({
        open: true,
        title: "Confirmacion",
        severity: "success",
        message: "Se guardo correctamente",
      });
      refrescar && refrescar();
      refetch();
    })
    .catch((error) => {
      setAlertCustom({
        open: true,
        title: "Error",
        severity: "error",
        message: error?.message,
      });
    });
};
const body ={
  entidad_cuit: "30459632159",
entidad_descripcion: "Carpintero",
entidad_id: "2",
entidad_razon_social: "Jose el carpintero",
stock_ubicacion_activo: 0,
stock_ubicacion_descripcion: "Casa de María",
stock_ubicacion_entidad_id: "2",
stock_ubicacion_id: "13"
}