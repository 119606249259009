import { Box } from "@mui/material";
import { Field } from "react-final-form";
import CheckCustom from "../../../components/CheckCustom";
import { required } from "../../../utils/validations";

export const CardEtiquetaCalidad = ({ name, label }) => {
  return (
    <Field
      validate={required}
      component={CheckCustom as any}
      name={`${name}.valor`}
      label={label}
    />
  );
};
