import Layout from "../../components/Layout";
import TableContainer from "../../components/TableContainer";
import { useListDetailsOcCompleto } from "../../hooks/useStock";
import { Loading } from "../loading";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { SelectMui } from "../../components/SelectMui";
import ErrorMsg from "../../components/ErrorMsg";
import { useSuppliers } from "../../hooks/useSuppliers";
import { ordenarArrayConParametros, OrderGen } from "../../utils/orderGen";
import { InputDate } from "../../components/Input";
import Button from "../../components/Button";
import { imprimirElemento } from "../../utils/imprimir";
import { DataToPrint } from "./components/dataToPrint";
import DownloadExcel from "../../components/DownloadExcel";
import { datosExcel } from "./constants";
const ReporteOC = () => {
  const {
    data: listDetailsOC,
    isLoading: isLoadingListDetailsOC,
    isError: isErrorListDetailsOC,
  } = useListDetailsOcCompleto();
  const [datos, setDatos] = useState([]);
  const { data, isError, isLoading } = useSuppliers(true);

  useEffect(() => {
    setDatos(
      listDetailsOC?.map((item) => {
        return {
          ...item,
        };
      })
    );
  }, [listDetailsOC]);

  if (isLoadingListDetailsOC || isLoading || !Array.isArray(datos)) {
    return <Loading />;
  }
  if (
    !Array.isArray(data) ||
    !Array.isArray(listDetailsOC) ||
    isErrorListDetailsOC ||
    isError
  ) {
    return <ErrorMsg />;
  }
  ordenarArrayConParametros(
    datos,
    ["entidadDescripcion", "comprobanteNumero", "status", "skuCodigo"],
    true
  );
  OrderGen(datos, "fechaNumber", "desc", true);
  const header = [
    "Proveedor",
    "OC Número",
    "Fecha OC",
    "Status OC",
    "Codigo",
    "Nombre",
    "Unidad Pedidas",
    "Unidad Entregadas",
    "Uniadad Restantes",
    "Fecha de entrega final pactada",
    "Unidad medida",
    "Observación",
    "Usuario",
    "",
  ];

  const row = [
    "entidadDescripcion",
    "comprobanteNumero",
    "fechaCreacion",
    "status",
    "skuCodigo",
    "skuDescripcion",
    "comprobanteDetalleCantidad",
    "comprobanteDetalleCantidadRecibida",
    "comprobanteDetalleCantidadSaldo",
    "fechaEntrega",
    "unidadMedida",
    "comprobanteObservacion",
    "usuario",
  ];

  return (
    <Layout title="Listado de ordenes de compras" clase={false}>
      <DataToPrint datos={datos} />
      <>
        <Form
          onSubmit={() => {}}
          initialValues={{ entidadId: "0", statusId: "0" }}
          render={({ values }) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                ml: 2,
                px: 2,
              }}
            >
              <Field
                name="entidadId"
                label={"Proveedor"}
                component={SelectMui as any}
                sx={{ width: "300px", p: 1 }}
                options={data.map((item) => {
                  return {
                    value: item.entidadId,
                    name: item.entidadDescripcion,
                  };
                })}
              />
              <Field
                name="statusId"
                component={SelectMui as any}
                label={"Status OC"}
                sx={{ width: "300px", p: 1 }}
                options={[
                  { statusId: "0", statusDescripcion: "Todos" },
                  { statusId: "1", statusDescripcion: "Pendiente" },
                  { statusId: "2", statusDescripcion: "Entrega Parcial" },
                  { statusId: "3", statusDescripcion: "Cumplida" },
                ].map((item) => {
                  return {
                    value: item.statusId,
                    name: item.statusDescripcion,
                  };
                })}
              />
              <Field
                focused
                name="fechaDesde"
                component={InputDate as any}
                label={"fecha desde"}
                sx={{ width: "300px", p: 1 }}
              />
              <Field
                focused
                name="fechaHasta"
                component={InputDate as any}
                label={"fecha Hasta"}
                sx={{ width: "300px", p: 1 }}
                min={values?.fechaDesde}
              />
              <Box sx={{ p: 1 }}>
                <Button
                  label="Aplicar"
                  onClick={() =>
                    onChangeFiltro({
                      setDatos,
                      values: values,
                      listDetailsOC,
                    })
                  }
                />
              </Box>
            </Box>
          )}
        />
        <Box sx={{ width: "100%", pr: 2 }}>
          <DownloadExcel
            objArray={datos}
            fileName="Listado de OC"
            datosExcel={datosExcel}
          />
        </Box>
        <TableContainer
          objeto={datos}
          sx={{ mt: 4, ml: 2, p: 2 }}
          header={header}
          row={row}
          itemRight={["remito", "fechaEntrega"]}
          imprimirOption={{
            onClick: (value) =>
              imprimirElemento(document.querySelector(`#imprimir${value}`)),
            key: "comprobanteId",
            disabled: false,
          }}
          maxHeightBody={500}
        />
      </>
    </Layout>
  );
};
const onChangeFiltro = ({ setDatos, listDetailsOC, values }) => {
  setDatos(
    listDetailsOC?.filter((item) => {
      return (
        (values.statusId === "0" || item.statusId === values.statusId) &&
        (values.entidadId === "0" || item.entidadId === values.entidadId) &&
        (!values?.fechaDesde ||
          item?.fechaNumber >=
            Number(values?.fechaDesde?.replaceAll("-", ""))) &&
        (!values?.fechaHasta ||
          item?.fechaNumber <= Number(values?.fechaHasta?.replaceAll("-", "")))
      );
    })
  );
};
export default ReporteOC;
