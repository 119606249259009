import { Box } from "@mui/material";
import { Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputDate, InputNumber, InputText } from "../../../components/Input";

const CardCotizacion = ({ name, onclick, disabled }) => (
  <Box
    className="col-12 col-lg-10 col-xl-8"
    sx={{ display: "flex", flexWrap: "wrap" }}
  >
    <Box className="col-3" sx={{ p: 1 }}>
      <Field
        disabled
        name={`${name}.monedaDescripcion`}
        component={InputText as any}
        label="Moneda"
      />
    </Box>
    <Box className="col-2" sx={{ p: 1 }}>
      <Field
        disabled
        name={`${name}.monedaSigno`}
        component={InputText as any}
        label="Moneda signo"
      />
    </Box>
    <Box className="col-3" sx={{ p: 1 }}>
      <Field
        name={`${name}.cotizacionFecha`}
        component={InputDate as any}
        label="Fecha cotización"
      />
    </Box>
    <Box className="col-2" sx={{ p: 1 }}>
      <Field
        name={`${name}.cotizacionValor`}
        component={InputNumber as any}
        label="Cotizacion"
      />
    </Box>
    <Box
      className="col-2"
      sx={{ display: "flex", alignItems: "center", maxHeight: 50 }}
    >
      <Button label={"Aplicar"} onClick={onclick} disabled={disabled} />
    </Box>
  </Box>
);
export default CardCotizacion;
