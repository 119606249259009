import { Box, InputAdornment } from "@mui/material";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputDate, InputNumber } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { SaveDiaExeptuado } from "../../../services/ordenTrabajo";
import { fechaActual } from "../../../utils/Fecha";
import { required } from "../../../utils/validations";

const FormAgregarNuevoFeriado = ({ setAlertCustom, refetch, procesos }) => {
  const obj: any = {};
  return (
    <Form
      onSubmit={(values) => {
        SaveDiaExeptuado({
          ...values,
          dia_laborable_excepcion_activo: 1,
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
            obj.funcion();
            refetch();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={{}}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        obj.funcion = restart;
        return (
          <>
            <Box sx={{ p: 1 }}>
              <Field
                validate={required}
                name={`proceso_productivo_id`}
                component={SelectMui as any}
                label="Proceso productivo"
                options={procesos
                  ?.filter((item) => item.procesoProductivoActivo === "1")
                  .map((item) => {
                    return {
                      name: `${item.procesoProductivoTipoDescripcion} ${
                        item.procesoProductivoId !== "1" &&
                        item.procesoProductivoId !== "0"
                          ? `- ${item.procesoProductivoDescripcion}`
                          : ""
                      }`,
                      value: item.procesoProductivoId,
                    };
                  })}
                sx={{ width: "100%" }}
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <Field
                validate={required}
                component={InputDate as any}
                name={`diaLaborableExcepcionFecha`}
                label={values?.diaLaborableExcepcionFecha ? "Fecha" : ""}
                min={fechaActual()}
                defaultValue={fechaActual()}
              />
            </Box>
            <Box sx={{ p: 1 }}>
              <Field
                validate={required}
                name={`diaLaborableExcepcionHorasDisponiblesPorDia`}
                component={InputNumber as any}
                label="Horas disponibles"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Horas</InputAdornment>
                  ),
                  inputProps: {
                    style: { textAlign: "right" },
                  },
                }}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button label="Agregar" onClick={handleSubmit} color="success" />
            </Box>
          </>
        );
      }}
    />
  );
};
export default FormAgregarNuevoFeriado;
