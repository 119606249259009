import { createNotaPedido } from "../../services/pedidosServices";

export const CreateNewNotaPedido = (body, setAlertCustom, restart) => {
  createNotaPedido(body)
    .then(() => {
      setAlertCustom({
        open: true,
        title: "Confirmacion",
        severity: "success",
        message: "Se creó correctamente",
      });
      restart();
    })
    .catch((error) =>
      setAlertCustom({
        open: true,
        title: "Error",
        severity: "error",
        message: error?.message,
      })
    );
};
