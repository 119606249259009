import { fetchContent, Method } from "../utils/fetchContent";
export const getListGroup = async () => {
    try {
      return await fetchContent('/radius_grupos_listado');
      
    } catch(error) {
       throw(error)
    }  
}
export const getListUsers = async () => {
    try {
      return await fetchContent('/radius_listado');
      
    } catch(error) {
       throw(error)
    }  
}
// export const getUserActual = async (body) => {
//   console.log(body);
//     try {
//       return await fetchContent(`/radius_listado/`);
      
//     } catch(error) {
//        throw(error)
//     }  
// }

export const updateUser = async (body) => {
    try {
      return await fetchContent('radius_update',
            {
                body,
                method: Method.POST,
            });
      
    } catch(error) {
       throw(error)
    }  
}
