import TreeItem from "@mui/lab/TreeItem";
import { Box } from "@mui/material";
import { posicionesTabla } from "../contants";
import { Fila } from "./fila";

export const FuncionRecursiva = ({ array, item }) => {
  const resultado = array.filter(
    (data) =>
      data["4"]?.startsWith(`${item}.`) && data["4"]?.length === item.length + 7
  );
  return (
    <>
      {resultado.map((ramita) => (
        <>
          {!!array.find(
            (data) =>
              data["4"].length > ramita["4"].length &&
              data["4"]?.startsWith(`${ramita["4"]}.`)
          ) ? (
            <TreeItem
              sx={{ backgroundColor: "rgba(240, 240, 240 , 0.30)" }}
              nodeId={ramita["4"]}
              label={
                <Fila
                  datos={[ramita]}
                  nameObj={posicionesTabla.row}
                  mostrarPorcentaje={true}
                />
              }
            >
              <FuncionRecursiva array={array} item={ramita["4"]} />
            </TreeItem>
          ) : (
            <Box sx={{ pl: 3, backgroundColor: "rgba(240, 240, 240 , 0.30)" }}>
              <Fila
                datos={[ramita]}
                nameObj={posicionesTabla.row}
                mostrarPorcentaje={true}
              />
            </Box>
          )}
        </>
      ))}
    </>
  );
};
