/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Form, Field } from "react-final-form";
import { Dialog, DialogResetPass } from "../components/Dialog";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { error_login } from "../constants/MenuOptions";
import { Loading } from "./loading";
import Logo from "../images/Logo.png";
import "../App.css";
import "../components/Login/index.css";
import { getUser } from "../hooks/auth";
import { GetPermisos, GetPermisosParaOtorgar } from "../services/auth";
import { SaveStorage } from "../utils/localStorage";
export const Login = () => {
  const [errorModal, setModalError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [modalReset, setModalReset] = useState(false);
  const onSubmit = (mailPass) => {
    const auth = getAuth();
    setLoading(true);
    signInWithEmailAndPassword(auth, mailPass.mail, mailPass.password)
      .then(async (userCredential) => {
        const user = getUser();
        const response = user?.uid ? await GetPermisos(user?.uid) : undefined;
        const permisosOtorgar = await GetPermisosParaOtorgar();
        console.log(user);
        await SaveStorage("Permisos", JSON.stringify({ response }));
        await SaveStorage(
          "PermisosAOtorgar",
          JSON.stringify({ permisosOtorgar })
        );
        await SaveStorage("Usuario", JSON.stringify({ user }));
        setTimeout(() => {
          setLoading(false);
        }, 4500);
      })
      .catch(async (error) => {
        setTimeout(() => {
          setLoading(false);
        }, 4500);
        const analytics = getAnalytics();

        setModalError(true);
        logEvent(analytics, error_login, error);
      });
  };
  let formData = {};

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="d-flex justify-content-center FondoLogin">
      <Form
        onSubmit={onSubmit}
        initialValues={formData}
        validate={(values) => {
          const errors: any = {};
          if (!values.mail) {
            errors.mail = "Requerido";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.mail)
          )
            errors.mail = "correo invalido";

          if (!values.password) {
            errors.password = "Requerido";
          }
          return errors;
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <div className="d-flex justify-content-center align-items-center ">
            <div className="contenedor p-5">
              <div className="d-flex flex-column ">
                <div className="d-flex justify-content-center">
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ width: "40%", height: "50%" }}
                  />
                </div>
                <form className="d-flex flex-column" onSubmit={handleSubmit}>
                  <Field name="mail">
                    {({ input, meta }) => (
                      <div className="d-flex flex-column mt-3">
                        <input
                          {...input}
                          autoComplete="off"
                          style={{ color: "#eaf2d7 " }}
                          type="text"
                          placeholder="Correo"
                          className="input px-4"
                        />
                        {meta.error && meta.touched && (
                          <span style={{ color: "white" }} className="px-4">
                            {meta.error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                  <Field name="password">
                    {({ input, meta }) => (
                      <div className="d-flex flex-column mt-3">
                        <input
                          {...input}
                          style={{ color: "#eaf2d7 " }}
                          autoComplete="off"
                          type="password"
                          placeholder="Contraseña"
                          className="input px-4"
                        />
                        {meta.error && meta.touched && (
                          <span className="px-4" style={{ color: "white" }}>
                            {meta.error}
                          </span>
                        )}
                      </div>
                    )}
                  </Field>
                  <div className="d-flex justify-content-center mt-3">
                    <button
                      className="button"
                      type="submit"
                      disabled={submitting}
                    >
                      Ingresar
                    </button>
                  </div>
                  <div className="d-flex justify-content-center mt-2">
                    <div className="d-flex flex-column">
                      <a
                        className="mt-2"
                        style={{ color: "white", cursor: "pointer" }}
                        onClick={() => {
                          setModalReset(true);
                        }}
                      >
                        No sé mi contraseña
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      />
      {
        <>
          <Dialog
            open={errorModal}
            title="Error"
            body="Usuario o Contraseña invalido"
            onClick={() => setModalError(false)}
          />
          <DialogResetPass
            open={modalReset}
            onClick={() => setModalReset(false)}
          />
        </>
      }
    </div>
  );
};
