import { useState } from "react";
import { Typography } from "@mui/material";

import { Form, Field } from "react-final-form";
import { AutoComplete } from "../../../components/AutoComplete";
import Button from "../../../components/Button";
import { InputNumber } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { SaveMovementStock } from "../../../services/productServices";
import { BuscarInfo } from "../hook/buscarStockDispo";
import { filtrarUbicationMovements } from "../hook/filtrarUbicacionMovimientos";

export const FormTransferencia = ({
  products,
  listDeposito,
  stockProducts,
  listUbicationProvider,
  listStockState,
  setAlertCustom,
  refetch,
  user,
}) => {
  const [initialValue, setInitialValue] = useState({});
  return (
    <Form
      onSubmit={(values) => {
        const body = {
          sku_id: values.idProducto.id,
          stock_ubicacion_id_origen: values.origenStockUbicacionId,
          stock_ubicacion_id_destino: values.toStockUbicacionId,
          stock_estado_id_origen: values.origenStockStateId,
          stock_estado_id_destino: values.toStockStateId,
          stock_cantidad: values.amount,
          id_radius: user,
        };
        SaveMovementStock(body)
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
            setInitialValue({
              idProducto: { id: "", label: "" },
              origenProveiderStock: "",
              toProveiderStock: "",
              origenStockUbicacionId: "",
              toStockUbicacionId: "",
              origenStockStateId: "",
              toStockStateId: "",
              amount: " ",
            });
            refetch();
          })
          .catch((response) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: response.message,
            });
          });
      }}
      initialValues={initialValue}
      validate={(values) => {
        const error: any = {};
        if (!values?.idProducto?.id) {
          error.idProducto = "Requerido";
        }
        if (!values?.origenProveiderStock) {
          error.origenProveiderStock = "Requerido";
        }
        if (BuscarInfo(stockProducts, values, "origen") < values.amount) {
          error.amount = "Limite superado";
        }
        if (!values?.toProveiderStock) {
          error.toProveiderStock = "Requerido";
        }
        if (!values?.origenStockUbicacionId) {
          error.origenStockUbicacionId = "Requerido";
        }
        if (!values?.toStockUbicacionId) {
          error.toStockUbicacionId = "Requerido";
        }

        if (!values?.origenStockStateId) {
          error.origenStockStateId = "Requerido";
        }
        if (values?.origenStockUbicacionId === values?.toStockUbicacionId) {
          error.toStockUbicacionId =
            "La ubicación de destino tiene que ser diferente de la ubicación de origen";
        }

        if (!values?.amount || values.amount === 0 || values?.amount === "0") {
          error.amount = "Requerido";
        }

        return error;
      }}
      render={({ handleSubmit, submitting, pristine, values }) => {
        return (
          <>
            <div className="d-flex flex-wrap">
              <div className="col-12 col-lg-6 p-2">
                <Field
                  name="idProducto"
                  component={AutoComplete as any}
                  sx={{ width: "100%" }}
                  options={products.map((item) => {
                    return {
                      id: item.skuId,
                      label: item.skuCodigo,
                    };
                  })}
                />
              </div>
              <div className="d-flex align-items-center col-12 col-lg-6 p-2">
                <Typography variant="subtitle1">
                  {values?.idProducto?.id
                    ? products.find(
                        (item: { skuId: any }) =>
                          values?.idProducto?.id === item.skuId
                      )?.skuDescripcion
                    : ""}
                </Typography>
              </div>
              <div className="col-12 col-lg-6 p-2">
                <Field
                  name="origenProveiderStock"
                  label="Deposito origen"
                  component={SelectMui as any}
                  options={listDeposito.map((item) => ({
                    value: item.entidadId,
                    name: item.entidadDescripcion,
                  }))}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-12 col-lg-6 p-2">
                <Field
                  name="toProveiderStock"
                  label="Deposito destino"
                  component={SelectMui as any}
                  options={listDeposito.map((item) => ({
                    value: item.entidadId,
                    name: item.entidadDescripcion,
                  }))}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-12 col-lg-6 p-2">
                <Field
                  name="origenStockUbicacionId"
                  label="Ubicacion origen"
                  component={SelectMui as any}
                  options={
                    filtrarUbicationMovements(
                      listUbicationProvider,
                      values,
                      "origenProveiderStock"
                    ) !== undefined
                      ? filtrarUbicationMovements(
                          listUbicationProvider,
                          values,
                          "origenProveiderStock"
                        )?.map((item) => ({
                          value: item.stockUbicacionId,
                          name: item.stockUbicacionDescripcion,
                        }))
                      : []
                  }
                  sx={{ width: "100%" }}
                />
              </div>

              <div className="col-12 col-lg-6 p-2">
                <Field
                  name="toStockUbicacionId"
                  label="Ubicacion destino"
                  component={SelectMui as any}
                  options={
                    filtrarUbicationMovements(
                      listUbicationProvider,
                      values,
                      "toProveiderStock"
                    ) !== undefined
                      ? filtrarUbicationMovements(
                          listUbicationProvider,
                          values,
                          "toProveiderStock"
                        )?.map((item) => ({
                          value: item.stockUbicacionId,
                          name: item.stockUbicacionDescripcion,
                        }))
                      : []
                  }
                  sx={{ width: "100%" }}
                />
              </div>

              <div className="col-12 col-lg-6 p-2">
                <Field
                  name="origenStockStateId"
                  label="Estado stock origen"
                  component={SelectMui as any}
                  options={listStockState.map((item) => ({
                    value: item.stockEstadoId,
                    name: item.stockEstadoDescripcion,
                  }))}
                  onchange={(valor) =>
                    setInitialValue({
                      ...values,
                      origenStockStateId: valor,
                      toStockStateId: valor,
                    })
                  }
                  sx={{ width: "100%" }}
                />
              </div>

              <div className="col-12 col-lg-6 p-2">
                <Field
                  disabled
                  name="toStockStateId"
                  label="Estado stock destino"
                  component={SelectMui as any}
                  options={listStockState.map((item) => ({
                    value: item.stockEstadoId,
                    name: item.stockEstadoDescripcion,
                  }))}
                  defaultValue={values?.origenStockStateId}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="d-flex align-items-center col-12 col-lg-6 p-2">
                <Typography variant="subtitle1">
                  {BuscarInfo(stockProducts, values, "origen") !== undefined
                    ? `stock origen actual: ${BuscarInfo(
                        stockProducts,
                        values,
                        "origen"
                      )}`
                    : ""}
                </Typography>
              </div>
              <div className="d-flex align-items-center col-12 col-lg-6 p-2">
                <Typography variant="subtitle1">
                  {BuscarInfo(stockProducts, values, "to") !== undefined
                    ? `stock destino actual: ${BuscarInfo(
                        stockProducts,
                        values,
                        "to"
                      )}`
                    : ""}
                </Typography>
              </div>
            </div>

            <div className="col-12 col-lg-6 p-2">
              <Field
                name="amount"
                component={InputNumber as any}
                label="Cantidad"
                sx={{ width: "100%" }}
              />
            </div>

            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "16px" }}
            >
              <Button
                label="Realizar transferencia"
                onClick={handleSubmit}
                disabled={submitting || pristine}
                sx={{ mb: 1 }}
              />
            </div>
          </>
        );
      }}
    />
  );
};
