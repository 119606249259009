export const required = (value) => (!value ? "requerido" : undefined);

export const requiredArray = (value) =>
  value && value.length > 0 ? undefined : "Requerido";

export const composeValidatorsNoRequired =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => (!value ? undefined : error || validator(value)),
      undefined
    );

export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const onlyLength = (length: number) => (val: any) => {
  return val
    ? val?.toString()?.length === length
      ? undefined
      : "longitud no permitida"
    : undefined;
};

export const minLength = (minimumLength) => (val) =>
  val ? (val.length >= minimumLength ? undefined : "error") : undefined;

export const maxLength = (maximumLength) => (val) =>
  val ? (val.length <= maximumLength ? undefined : "error") : undefined;
