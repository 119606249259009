import { PostProcesosProductivo } from "../../../services/productServices";

export const newProcesoProductivo = ({
  values,
  refetch,
  setAlertCustom,
  setModalNewProceso,
}) => {
  const body = {
    procesoProductivoActivo: 1,
    procesoProductivoDescripcion: values?.procesoProductivoDescripcion,
    procesoProductivoTipoId: Number(values?.procesoProductivoTipoId),
    stockUbicacionId: Number(values?.stockUbicacionId),
    proceso_productivo_costo_por_hora_por_persona: Number(
      values?.proceso_productivo_costo_por_hora_por_persona
    ),

    proceso_productivo_horas_disponibles_por_dia: Number(
      values?.proceso_productivo_horas_disponibles_por_dia
    ),
    proceso_productivo_horas_necesarias_estimadas_por_unidad: 0,
  };
  PostProcesosProductivo(body)
    .then(() => {
      setAlertCustom({
        open: true,
        title: "Confirmacion",
        severity: "success",
        message: "Se guardo correctamente",
      });
      refetch();
      setModalNewProceso(false);
    })
    .catch((error) =>
      setAlertCustom({
        open: true,
        title: "Error",
        severity: "error",
        message: error?.message,
      })
    );
};
