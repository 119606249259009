export const armarObjeto = (values,products,measures) => {
    let detalleSku = [];

          const nuevoObj = values.datos.map(({ lotes, ...item }) => ({
            ...item,
            lotes,
            totalLote: sumarLote(lotes),
          }));

          // eslint-disable-next-line array-callback-return
          nuevoObj.map(({ lotes, ...item }) => {
            if (lotes && lotes.length > 0) {
              lotes.map((lote) =>
                detalleSku.push({
                  skuId: Number(
                    products.find(
                      (sku) => sku.skuDescripcion === item.skuDescripcion
                    ).skuId
                  ),
                  cantidad: Number(lote.cantidad),
                  stockLote: lote.lote,
                  unidadMedidaId: Number(
                    measures.find((m) => m.unidadMedida === item.unidadMedida)
                      .unidadMedidaId
                  ),
                })
              );

              if (item.totalLote < item.comprobanteDetalleCantidadEntregada) {
                detalleSku.push({
                  skuId: Number(
                    products.find(
                      (sku) => sku.skuDescripcion === item.skuDescripcion
                    ).skuId
                  ),
                  cantidad: Number(
                    item.comprobanteDetalleCantidadEntregada - item.totalLote
                  ),

                  unidadMedidaId: Number(
                    measures.find((m) => m.unidadMedida === item.unidadMedida)
                      .unidadMedidaId
                  ),
                });
              }
            } else {
              detalleSku.push({
                skuId: Number(
                  products.find((sku) => sku.skuCodigo === item.skuCodigo).skuId
                ),
                cantidad: Number(item.comprobanteDetalleCantidadEntregada),
                unidadMedidaId: Number(
                  measures.find((m) => m.unidadMedida === item.unidadMedida)
                    .unidadMedidaId
                ),
              });
            }
          });
          const body = {
            detalleSku: detalleSku,
            comprobanteId: Number(values.OC),
            remitoProveedorSerie: Number(values.serie),
            remitoProveedorNumero: Number(values.remito),
            stockUbicacionId: Number(values.ubicacion),
            stockEstadoId: 3,
    };
    return { body: body, values: values }
}
const sumarLote = (lotes) => {
  let cuenta = 0;
  lotes?.map(
    (item) => (cuenta += Number(item.cantidad) ? Number(item.cantidad) : 0)
  );
  return cuenta;
};