import { Typography } from "@mui/material";
import Layout from "../Layout";

export const ErrorMsg = () => {
  return (
    <Layout title="Error inesperado">
        <Typography variant="h3">
            Nos apena informate que ha ocurrido un error que debemos revisar
        </Typography>
    </Layout>
  );
};

export default ErrorMsg;
