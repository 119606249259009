export const getStatusOC = ({ comprobanteDetalleCantidadSaldo, comprobanteDetalleCantidad }) => {

    if (Number(comprobanteDetalleCantidadSaldo) === 0) {
        return "Complida"
    }
    if (Number(comprobanteDetalleCantidadSaldo) <
        Number(comprobanteDetalleCantidad) && Number(comprobanteDetalleCantidadSaldo) > 0
    ) {
        return "Entrega Parcial"
    }       
    if (Number(comprobanteDetalleCantidadSaldo) ===
        Number(comprobanteDetalleCantidad)) {
        return "Pendiente"
    }
}
export const getStatusOCId = ({ comprobanteDetalleCantidadSaldo, comprobanteDetalleCantidad }) => {

    if (Number(comprobanteDetalleCantidadSaldo) === 0) {
        return "3"
    }
    if (Number(comprobanteDetalleCantidadSaldo) <
        Number(comprobanteDetalleCantidad) && Number(comprobanteDetalleCantidadSaldo) > 0
    ) {
        return "2"
    }       
    if (Number(comprobanteDetalleCantidadSaldo) ===
        Number(comprobanteDetalleCantidad)) {
        return "1"
    }
}