import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import arrayMutators from "final-form-arrays";
import { Loading } from "../loading";
import { AlertCustom } from "../../components/AlertMui";
import Layout from "../../components/Layout";
import { FieldArray } from "react-final-form-arrays";
import {
  useListUbicationProvider,
  useProcesos,
  useProcesosTipoId,
} from "../../hooks/useProducts";
import ReactPaginate from "react-paginate";
import { IsMobi } from "../../hooks/isMobi";
import { InputNotForm } from "../../components/Input";
import Button from "../../components/Button";
import { DialogGen } from "../../components/Dialog";
import CardProcesos from "./components/cardProcesos";
import CardNewProcesos from "../procesosProductivosMobi/components/cardNewProcesoMobi";
import ErrorMsg from "../../components/ErrorMsg";
import UpdateAreaProcesoProductivo from "./components/updateAreaProcesoProductivo";
import ModalAdminitracionEtiquetas from "./components/modalAdministrarEtiquetas";
import { useListadoEtiquetaCalidad } from "../../hooks/moduloCalidad";
import { validarHuboCambios } from "../procesosProductivosMobi/hook/validarHuboCambios";

const ProcesosProductivos = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [pageSelected, setPageSelected] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const itemsPerPage = 8;
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const {
    data: procesosTipoId,
    isLoading: isLoadingProcesosTipoId,
    isError: isErrorProcesosTipoId,
    refetch: RefetchProcesosTipoId,
  } = useProcesosTipoId();
  const {
    data: listUbicationProvider,
    isLoading: isLoadingListUbicationProvider,
    isError: isErrorListUbicationProvider,
  } = useListUbicationProvider(0);
  const {
    data: procesos,
    isLoading: isLoadingProcesos,
    isError: isErrorProcesos,
    refetch,
  } = useProcesos();
  const {
    data: listadoEtiquetaCalidad,
    isLoading: isLoadingListadoEtiquetaCalidad,
    isError: isErrorListadoEtiquetaCalidad,
    refetch: RefetchListadoEtiquetaCalidad,
  } = useListadoEtiquetaCalidad(0);
  const [modalNewProceso, setModalNewProceso] = useState(false);
  const [modalUpdateAreaProceso, setModalUpdateAreaProceso] = useState(false);
  const [modalAdminitracionEtiquetas, setModalAdminitracionEtiquetas] =
    useState(false);
  const [radioButton, setRadioButton] = useState("1");
  const [initialValue, setInitialValue] = useState({
    datos: [],
  });
  const [dataFilter, setDataFilter] = useState([]);
  const [search, setSearch] = useState(null);
  useEffect(() => {
    if (
      !!procesos &&
      !!procesosTipoId &&
      procesosTipoId.length > 0 &&
      procesos.length > 0
    ) {
      setInitialValue({
        datos: procesos
          .map((item) => {
            return {
              ...item,

              procesoProductivoTipoId: procesosTipoId?.find(
                (x) =>
                  x?.procesoProductivoTipoDescripcion ===
                  item?.procesoProductivoTipoDescripcion
              )?.procesoProductivoTipoId,
            };
          })
          .filter((item) => item?.procesoProductivoActivo === radioButton),
      });
    }
  }, [procesos, procesosTipoId, radioButton]);
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems({
      datos: !!search
        ? dataFilter.slice(itemOffset, endOffset)
        : initialValue.datos.slice(itemOffset, endOffset),
    });

    setPageCount(
      Math.ceil(
        (!!search ? dataFilter.length : initialValue.datos.length) /
          itemsPerPage
      )
    );
  }, [itemOffset, itemsPerPage, initialValue, search, dataFilter]);
  if (
    isLoadingProcesos ||
    isLoadingProcesosTipoId ||
    isLoadingListadoEtiquetaCalidad ||
    isLoadingListUbicationProvider
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(procesos) ||
    !Array.isArray(procesosTipoId) ||
    !Array.isArray(listUbicationProvider) ||
    !Array.isArray(listadoEtiquetaCalidad) ||
    isErrorProcesos ||
    isErrorListadoEtiquetaCalidad ||
    isErrorProcesosTipoId ||
    isErrorListUbicationProvider
  ) {
    return <ErrorMsg />;
  }
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % initialValue.datos.length;
    setPageSelected(event.selected);
    setItemOffset(newOffset);
  };

  const changeSearch = (evt) => {
    setSearch(evt.target.value ? evt.target.value : null);
    setItemOffset(0);
    setPageSelected(0);
    if (evt.target.value !== "") {
      setDataFilter(
        initialValue.datos.filter((item) =>
          Object.values(item).find(
            (valor) =>
              !!valor &&
              valor
                .toString()
                .toUpperCase()
                .includes(evt.target.value.toUpperCase())
          )
        )
      );
    } else {
      setDataFilter([]);
    }
  };
  const changeRadioButton = (valor) => {
    setRadioButton(valor);
  };
  return (
    <>
      <Layout title="Listado de procesos" sx={{ p: 2 }}>
        <Form
          onSubmit={(values) => {}}
          initialValues={currentItems}
          mutators={{
            ...arrayMutators,
          }}
          validate={(values) => {
            return {};
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push },
              restart,
            },
            submitting,
            pristine,
            values,
          }) => {
            return (
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Button
                      label={"Administrar etiquetas"}
                      onClick={() => setModalAdminitracionEtiquetas(true)}
                      color="success"
                    />
                    <Button
                      label={"Agregar nuevo proceso productivo"}
                      onClick={() => setModalNewProceso(true)}
                      sx={{ mt: 1 }}
                    />

                    <Button
                      label={"Modificar área"}
                      onClick={() => setModalUpdateAreaProceso(true)}
                      sx={{ mt: 1 }}
                    />
                  </Box>
                </Box>
                <FormControl
                  sx={{ display: "flex", justifyContent: "center", mb: 1 }}
                >
                  <FormLabel sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="body2">Mostrar</Typography>
                  </FormLabel>
                  <RadioGroup
                    defaultValue="1"
                    onChange={(evt) => changeRadioButton(evt.target.value)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio />}
                        label="Activos"
                      />

                      <FormControlLabel
                        value="0"
                        control={<Radio />}
                        label="Inactivos"
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",

                    mb: 2,
                  }}
                >
                  <InputNotForm
                    onchange={(evt) => changeSearch(evt)}
                    label="Buscar"
                    sx={{ maxWidth: "300px" }}
                  />
                </Box>

                <FieldArray name="datos">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <CardProcesos
                        name={name}
                        procesosTipoId={procesosTipoId}
                        listUbicationProvider={listUbicationProvider}
                        procesoProductivoId={
                          values.datos[index].procesoProductivoId
                        }
                        values={values}
                        index={index}
                        refetch={refetch}
                        setAlertCustom={setAlertCustom}
                        disabled={validarHuboCambios(values?.datos[index], {
                          procesoProductivoTipoId: procesosTipoId?.find(
                            (x) =>
                              x?.procesoProductivoTipoDescripcion ===
                              values?.datos[index]
                                ?.procesoProductivoTipoDescripcion
                          )?.procesoProductivoTipoId,
                          ...procesos?.find(
                            (proceso) =>
                              proceso?.procesoProductivoId ===
                              values?.datos[index]?.procesoProductivoId
                          ),
                        })}
                      />
                    ))
                  }
                </FieldArray>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <ReactPaginate
                    nextLabel="Siguiente >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={IsMobi() ? 2 : 5}
                    pageCount={pageCount}
                    forcePage={pageSelected}
                    previousLabel="< Anterior"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </Box>
              </Box>
            );
          }}
        />
        <DialogGen
          open={modalAdminitracionEtiquetas}
          title={"Administrar etiquetas"}
          onClick={() => setModalAdminitracionEtiquetas(false)}
          sx={{ width: 500 }}
          sxBody={{ maxHeight: "600px" }}
        >
          <ModalAdminitracionEtiquetas
            procesos={procesos}
            setAlertCustom={setAlertCustom}
            RefetchListadoEtiquetaCalidad={RefetchListadoEtiquetaCalidad}
            listadoEtiquetaCalidad={listadoEtiquetaCalidad}
          />
        </DialogGen>
        <DialogGen
          open={modalNewProceso}
          title={"Agregar proceso productivo"}
          onClick={() => setModalNewProceso(false)}
          sx={{ maxWidth: "500px" }}
        >
          <CardNewProcesos
            procesosTipoId={procesosTipoId}
            listUbicationProvider={listUbicationProvider}
            refetch={refetch}
            setAlertCustom={setAlertCustom}
            setModalNewProceso={setModalNewProceso}
          />
        </DialogGen>
        <DialogGen
          open={modalUpdateAreaProceso}
          title={"Área proceso productivo"}
          onClick={() => setModalUpdateAreaProceso(false)}
          sx={{ maxWidth: "500px" }}
        >
          <UpdateAreaProcesoProductivo
            procesosTipoId={procesosTipoId}
            setAlertCustom={setAlertCustom}
            RefetchProcesosTipoId={RefetchProcesosTipoId}
          />
        </DialogGen>
      </Layout>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </>
  );
};
export default ProcesosProductivos;
