import {
  CrearOrdenTrabajo,
  SaveFormulaOtg,
} from "../../../services/ordenTrabajo";

const saveData = ({
  datos,
  setAlertCustom,
  restart,
  refetch,
  setDatosOtgApi,
  setInitialValue,
}) => {
  let cuenta = 0;
  datos.datos.map((item) => {
    const formulaNueva = item.formula.filter(
      (item) => !!item.esNuevo && (!item.eliminar || item.eliminar === "n")
    );
    const formulaEliminar = item.formula.filter(
      (item) => !!item.eliminar && item.eliminar === "s" && !item.esNuevo
    );
    const NuevoSku = formulaNueva.map((item) => {
      return {
        unidad_medida_id: item.unidad_medida_id,
        sku_Id: Number(item.sku_Id),
        cantidad: Number(item.cantidad),
        merma: Number(item.merma),
      };
    });

    const SkuEliminar = formulaEliminar.map((item) => {
      return {
        formulaId: item.ordenTrabajoFormulaId,
        eliminar: item.eliminar,
      };
    });
    const body = {
      detalle_orden_trabajo_formula: [...NuevoSku, ...SkuEliminar],
    };

    if (body.detalle_orden_trabajo_formula.length !== 0) {
      SaveFormulaOtg(item.ordenTrabajoId, body)
        .then(() => {
          cuenta = cuenta + 1;
          setAlertCustom({
            open: true,
            title: "Confirmacion",
            severity: "success",
            message: "Se guardo correctamente",
          });
          if (cuenta === item.formula.length) {
            setDatosOtgApi([]);
            setInitialValue({});
            restart({});
          }
        })
        .catch((error) => {
          setAlertCustom({
            open: true,
            title: "Error",
            severity: "error",
            message: error.measures,
          });
        });
    } else {
      setAlertCustom({
        open: true,
        title: "Confirmacion",
        severity: "success",
        message: "Se guardo correctamente",
      });
      setDatosOtgApi([]);
      setInitialValue({});
      restart({});
    }
  });
};
export const crearNuevaOtg = (
  values,
  products,
  setLoading,
  setInitialValue,
  setDatosOtgApi,
  refetch,
  setAlertCustom
) => {
  setLoading(true);

  const body = values?.comprobanteDetalleId
    ? {
        skuId: Number(values?.sku?.id),
        ordenTrabajoCantidad: Number(values.ordenTrabajoCantidad),
        orden_trabajo_elaboracion_fecha: values.fecha,
        ordenTrabajoUnidadMedidaId: products?.find(
          (x) => x.skuId === values?.sku?.id
        )?.unidadMedidaId,
        comprobanteDetalleId: values.comprobanteDetalleId,
      }
    : {
        skuId: Number(values?.sku?.id),
        ordenTrabajoCantidad: Number(values.ordenTrabajoCantidad),
        orden_trabajo_elaboracion_fecha: values.fecha,
        ordenTrabajoUnidadMedidaId: products?.find(
          (x) => x.skuId === values?.sku?.id
        )?.unidadMedidaId,
      };
  CrearOrdenTrabajo(body)
    .then((response) => {
      setInitialValue(values);
      setLoading(false);
      setDatosOtgApi(response?.data);
      refetch();
    })
    .catch((error) => {
      setLoading(false);
      setAlertCustom({
        open: true,
        title: "Error",
        severity: "error",
        message: error.message,
      });
    });
};
export default saveData;
