export const fontConfig = {
  fontFamily: 'Raleway, sans-serif',
  h1: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
  },
  h2: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
  },
  h3: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
  },
   h4: {
   fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
  },
  h6: {
   fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 10,
  },
  subtitle1: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 16,
  },
  subtitle2: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
  },
  button: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
  },
  body1: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
  },
   body2: {
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: 12,
  },
  caption: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
  },
};
