import { fetchContent, Method } from "../utils/fetchContent";
export const GetEmbalaje = async () => {
  try {
    return await fetchContent(`/embalaje_listado`);
  } catch (error) {
    throw error;
  }
};
export const UpdateEmbalaje = async (body) => {
  try {
    return await fetchContent(`/Aun no esta`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
