import { useState } from "react";
import arrayMutators from "final-form-arrays";
import { Loading } from "../loading";
import { Form } from "react-final-form";
import Layout from "../../components/Layout";
import { FieldArray } from "react-final-form-arrays";
import ErrorMsg from "../../components/ErrorMsg";
import { useListadoCalidad } from "../../hooks/moduloCalidad";
import CardBoxCalidad from "./component/cardBoxCalidad";
import CardModuloCalidad from "./component/cardModuloCalidad";
import { Box } from "@mui/material";
import { DialogGen } from "../../components/Dialog";
import { AlertCustom } from "../../components/AlertMui";
import ModalEtiqueta from "./component/modalEtiqueta";

const ModuloCalidad = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [modalEtiqueta, setModalEtiqueta] = useState({
    open: false,
    datos: null,
  });
  const {
    data: listadoCalidad,
    isLoading: isLoadingListadoCalidad,
    isError: isErrorListadoCalidad,
    refetch: refetchListadoCalidad,
  } = useListadoCalidad(0);
  if (isLoadingListadoCalidad) {
    return <Loading />;
  }
  if (!Array.isArray(listadoCalidad) || isErrorListadoCalidad) {
    return <ErrorMsg />;
  }

  return (
    <>
      <Layout title={"Modulo calidad"} clase={false}>
        <Form
          onSubmit={(values) => {
            console.log(values);
          }}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={{ datos: listadoCalidad }}
          render={({
            handleSubmit,
            submitting,
            pristine,
            values,
            invalid,
            form: {
              restart,
              mutators: { push },
            },
          }) => (
            <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  maxWidth: 1000,
                }}
              >
                <CardBoxCalidad
                  size={{
                    width: "145px",
                    Height: "112px",
                  }}
                  color={{
                    background:
                      "linear-gradient(108deg, #FA0808 -40.6%, rgba(250, 8, 8, 0.51) 84.33%)",
                    border: "0.5px solid #FF0202",
                    borderRadius: "16px",
                  }}
                  title={"Urgente"}
                  info={"16"}
                  funcion={() => console.log("16")}
                />
                <CardBoxCalidad
                  size={{
                    width: "145px",
                    Height: "112px",
                  }}
                  color={{
                    background:
                      "linear-gradient(108deg, #E6FA08 -40.6%, rgba(209, 25, 0, 0.56) 84.33%)",
                    border: "0.5px solid #FF5D02",
                    borderRadius: "16px",
                  }}
                  title={"Recien Fabricados"}
                  info={"2"}
                  funcion={() => console.log("16")}
                />
                <CardBoxCalidad
                  size={{
                    width: "145px",
                    Height: "112px",
                  }}
                  color={{
                    background:
                      "linear-gradient(108deg, #084CFA -40.6%, rgba(8, 250, 61, 0.6) 84.33%)",
                    border: "0.5px solid #44FF02",
                    borderRadius: "16px",
                  }}
                  title={"Recien ingresados"}
                  info={"16"}
                  funcion={() => console.log("16")}
                />
                <CardBoxCalidad
                  size={{
                    width: "145px",
                    Height: "112px",
                  }}
                  color={{
                    background:
                      "linear-gradient(108deg, #FA00FF -40.6%, #73CCD8 84.33%)",
                    border: "0.5px solid #FF0202",
                    borderRadius: "16px",
                  }}
                  title={"Antiguos"}
                  info={"16"}
                  funcion={() => console.log("16")}
                />
                <CardBoxCalidad
                  size={{
                    width: "145px",
                    Height: "112px",
                  }}
                  color={{
                    background:
                      "linear-gradient(108deg, #0D08FA -40.6%, rgba(250, 8, 8, 0.51) 84.33%)",
                    border: "0.5px solid #FF0202",
                    borderRadius: "16px",
                  }}
                  title={"Cuarentena"}
                  info={"16"}
                  funcion={() => console.log("16")}
                />
              </Box>

              <Box
                sx={{
                  mt: 2,
                }}
              >
                <FieldArray name="datos">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <Box
                          sx={{
                            background: "#FAFAFA",
                            border: "0.5px solid #D3D3D3",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                            p: 1,
                            py: 3,
                            pb: 6,
                            mt: 2,
                          }}
                          key={name}
                        >
                          <CardModuloCalidad
                            name={name}
                            unidadMedida={values?.datos[index]?.unidadMedida}
                            setModalEtiqueta={() =>
                              setModalEtiqueta({
                                open: true,
                                datos: values.datos[index],
                              })
                            }
                          />
                        </Box>
                      ))}
                    </>
                  )}
                </FieldArray>
              </Box>
            </Box>
          )}
        />
      </Layout>
      <DialogGen
        open={modalEtiqueta.open}
        title=""
        onClick={() => setModalEtiqueta({ open: false, datos: null })}
        sx={{ maxWidth: "600px" }}
        sxBody={{ maxHeight: "700px" }}
      >
        <ModalEtiqueta
          datos={modalEtiqueta.datos}
          cerrarModal={() => setModalEtiqueta({ open: false, datos: null })}
          setAlertCustom={setAlertCustom}
          refetchListadoCalidad={refetchListadoCalidad}
        />
      </DialogGen>

      <AlertCustom
        open={alertCustom.open}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
        severity={alertCustom.severity}
        title={alertCustom.title}
        message={alertCustom.message}
      />
    </>
  );
};
export default ModuloCalidad;
