import { FieldArray } from "react-final-form-arrays";
import { Field, Form } from "react-final-form";
import { ListDrop } from "./components/listDrop";
import arrayMutators from "final-form-arrays";
import { Box } from "@mui/material";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import { Loading } from "../loading";
import {
  useOrdenTrabajo,
  useOrdenTrabajoEstado,
} from "../../hooks/useOrdenTrabajo";
import Layout from "../../components/Layout";
import { SelectMui } from "../../components/SelectMui";
import { InputDate } from "../../components/Input";
import {
  useMeasures,
  useProcesos,
  useProcesosTipoId,
} from "../../hooks/useProducts";
import ErrorMsg from "../../components/ErrorMsg";
import { ReadStorage, SaveStorage } from "../../utils/localStorage";
import { isObjEmpty } from "../../utils/isObjectEmpty";
import { SaveDatailsOtg } from "../../services/ordenTrabajo";
import { AlertCustom } from "../../components/AlertMui";
import { PERMISOS } from "../../constants/MenuOptions";
import { verificarPermiso } from "../../utils/verificarPermiso";
import { getUser, usePermisos } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";
export const ProductionPlan = () => {
  const [paramsOtgApi, setParamsOtgApi] = useState({});
  const { data, isLoading, isError, refetch } = useOrdenTrabajo(paramsOtgApi);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();
  const [datosStorage, setDatosStorage] = useState(null);
  const {
    data: estados,
    isLoading: isLoadingEstados,
    isError: isErrorEstados,
  } = useOrdenTrabajoEstado();
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  const {
    data: procesos,
    isLoading: isLoadingProcesos,
    isError: isErrorProcesos,
  } = useProcesos(true);
  const {
    data: procesosTipoId,
    isLoading: isLoadingProcesosTipoId,
    isError: isErrorProcesosTipoId,
  } = useProcesosTipoId(true);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    ReadStorage("datos")
      .then((result) => {
        setDatosStorage(JSON.parse(result) || []);
        if (!isObjEmpty(JSON.parse(result))) {
          setParamsOtgApi({
            proceso_productivo_tipo_id: JSON.parse(result)
              .proceso_productivo_tipo_id
              ? JSON.parse(result).proceso_productivo_tipo_id
              : null,
            proceso_productivo_id: JSON.parse(result).skuProcesoProductivoId
              ? JSON.parse(result).skuProcesoProductivoId
              : null,
            elaboracion_fecha_desde: JSON.parse(result).fechaFrom
              ? JSON.parse(result).fechaFrom
              : null,
            elaboracion_fecha_hasta: JSON.parse(result).fechaTo
              ? JSON.parse(result).fechaTo
              : null,
          });
        }
      })
      .catch(() => {});
    setInitialValue({
      datos: data,
    });
  }, [data]);

  if (
    isLoadingProcesos ||
    isLoadingEstados ||
    isLoadingMeasures ||
    isLoading ||
    loadingListUsers ||
    isLoadingPermisos ||
    isLoadingProcesosTipoId ||
    !Array.isArray(initialValue?.datos)
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(procesos) ||
    !Array.isArray(data) ||
    !Array.isArray(measures) ||
    !Array.isArray(estados) ||
    isErrorPermisos ||
    isErrorListUsers ||
    isError ||
    isErrorEstados ||
    isErrorMeasures ||
    isErrorProcesos ||
    isErrorProcesosTipoId
  ) {
    return <ErrorMsg />;
  }

  return (
    <>
      <Layout title={"Listado de ordenes de fabricacion"} clase={false}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            p: 1,
          }}
        >
          <Form
            onSubmit={(values) => {
              let cuenta = 0;

              // eslint-disable-next-line array-callback-return
              values.datos.map((item, index) => {
                SaveDatailsOtg(item.ordenTrabajoId, { ...item })
                  .then(() => {
                    cuenta = cuenta + 1;
                    if (cuenta === values.datos.length) {
                      setAlertCustom({
                        open: true,
                        title: "Confirmacion",
                        severity: "success",
                        message: "Se guardo correctamente",
                      });
                      refetch();
                    }
                  })
                  .catch((error) => {
                    setAlertCustom({
                      open: true,
                      title: "Error",
                      severity: "error",
                      message: error?.message,
                    });
                  });
              });
            }}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={{
              datos: initialValue.datos,
              proceso_productivo_tipo_id:
                datosStorage?.proceso_productivo_tipo_id
                  ? datosStorage.proceso_productivo_tipo_id
                  : null,
              skuProcesoProductivoId: datosStorage?.skuProcesoProductivoId
                ? datosStorage.skuProcesoProductivoId
                : null,
              fechaFrom: datosStorage?.fechaFrom
                ? datosStorage.fechaFrom
                : null,
              fechaTo: datosStorage?.fechaTo ? datosStorage.fechaTo : null,
            }}
            render={({ handleSubmit, form: { restart }, values }) => (
              <Box sx={{ width: "100%" }}>
                {verificarPermiso(
                  permisos,
                  PERMISOS.BUSCAR_OTGS,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <>
                    {" "}
                    {console.log(values)}
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <Box sx={{ ml: 2 }}>
                        <Field
                          focused
                          name={"proceso_productivo_tipo_id"}
                          component={SelectMui as any}
                          label={"Area"}
                          options={procesosTipoId
                            ?.filter(
                              (item) =>
                                item.procesoProductivoTipoActivo === "1" ||
                                item?.rocesoProductivoTipoId === "0"
                            )
                            .map((item) => {
                              return {
                                name: `${item.procesoProductivoTipoDescripcion}`,
                                value: item.procesoProductivoTipoId,
                              };
                            })}
                          sx={{ minWidth: 200, ml: 2, mb: 1 }}
                        />
                      </Box>
                      <Field
                        name={`skuProcesoProductivoId`}
                        component={SelectMui as any}
                        label="Proceso productivo"
                        options={procesos
                          ?.filter(
                            (item) =>
                              item?.procesoProductivoActivo === "1" &&
                              (values?.proceso_productivo_tipo_id ===
                                item?.procesoProductivoTipoId ||
                                values?.proceso_productivo_tipo_id === "0" ||
                                !values?.proceso_productivo_tipo_id ||
                                item?.procesoProductivoId === "0")
                          )
                          .map((item) => {
                            return {
                              name: `${item.procesoProductivoDescripcion} `,
                              value: item.procesoProductivoId,
                            };
                          })}
                        sx={{ minWidth: 200, ml: 2, mb: 1 }}
                      />

                      <Box sx={{ ml: 2 }}>
                        <Field
                          focused
                          name={"fechaFrom"}
                          component={InputDate as any}
                          label={"Desde"}
                          max={values.fechaTo}
                        />
                      </Box>
                      <Box sx={{ ml: 2 }}>
                        <Field
                          focused
                          name={"fechaTo"}
                          component={InputDate as any}
                          label={"Hasta"}
                          min={values.fechaFrom}
                        />
                      </Box>
                      <Box sx={{ ml: 2 }}>
                        <Button
                          label={"Buscar"}
                          onClick={() => {
                            SaveStorage(
                              "datos",
                              JSON.stringify({
                                proceso_productivo_tipo_id:
                                  values?.proceso_productivo_tipo_id,
                                skuProcesoProductivoId:
                                  values?.skuProcesoProductivoId,
                                fechaFrom: values?.fechaFrom,
                                fechaTo: values?.fechaTo,
                              })
                            );
                            setParamsOtgApi({
                              proceso_productivo_tipo_id:
                                values?.proceso_productivo_tipo_id,
                              skuProcesoProductivoId:
                                values?.skuProcesoProductivoId,
                              fechaFrom: values?.fechaFrom,
                              fechaTo: values?.fechaTo,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                )}
                <>
                  {verificarPermiso(
                    permisos,
                    PERMISOS.AGREGAR_OTGS,
                    listUsers?.find(
                      (item) => item?.radiusIdCodigo === getUser()?.uid
                    )?.idGrupo
                  ) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                      }}
                    >
                      <Button
                        label={"Agregar OTG"}
                        href="/CrearOtg"
                        onClick={() => {
                          SaveStorage(
                            "datos",
                            JSON.stringify({
                              skuProcesoProductivoId:
                                values.skuProcesoProductivoId,
                              fechaFrom: values.fechaFrom,
                              fechaTo: values.fechaTo,
                            })
                          );
                        }}
                      />
                    </Box>
                  )}
                </>
                <Box sx={{ maxHeight: 450, overflowY: "auto", my: 1 }}>
                  <FieldArray name="datos">
                    {({ fields }) => (
                      <>
                        <ListDrop
                          estados={estados}
                          fields={fields}
                          datosGuardarStorage={{
                            skuProcesoProductivoId:
                              values.skuProcesoProductivoId,
                            fechaFrom: values.fechaFrom,
                            fechaTo: values.fechaTo,
                          }}
                          onchange={(valor) => {
                            setInitialValue({
                              ...initialValue,
                              datos: valor,
                            });
                          }}
                          permisos={permisos}
                          listUsers={listUsers}
                        />
                      </>
                    )}
                  </FieldArray>
                </Box>
                <Box
                  sx={{
                    display: "flex ",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {data !== initialValue.datos && (
                    <Box sx={{}}>
                      <Button
                        sx={{ width: "100vw", maxWidth: "150px" }}
                        label="Reiniciar"
                        onClick={() => {
                          setInitialValue({ datos: data });
                        }}
                      />
                    </Box>
                  )}
                  {data !== initialValue.datos && (
                    <Box sx={{ ml: 2 }}>
                      <Button
                        sx={{ width: "100vw", maxWidth: "300px" }}
                        label="Guardar"
                        color="success"
                        onClick={handleSubmit}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          />
        </Box>
      </Layout>
      <AlertCustom
        open={alertCustom.open}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
        severity={alertCustom.severity}
        title={alertCustom.title}
        message={alertCustom.message}
      />
    </>
  );
};
