import { Box } from "@mui/material";
import ExportExcel from "react-export-excel";
import Button from "../Button";
const DownloadExcel = ({ objArray, fileName, datosExcel }: any) => {
  const ExcelFile = ExportExcel.ExcelFile;
  const ExcelSheet = ExportExcel.ExcelSheet;
  const ExcelColumn = ExportExcel.ExcelColumn;
  return (
    <Box sx={{ display: "flex", justifyContent: "end", width: "100%", px: 1 }}>
      {objArray?.length === 0 ? (
        <Button label="Exportar a excel" disabled={true} />
      ) : (
        <ExcelFile
          element={
            <Button
              label="Exportar a excel"
              disabled={objArray?.length === 0}
            />
          }
          filename={fileName}
        >
          <ExcelSheet data={objArray ? objArray : []} name={"Listado"}>
            {datosExcel?.map((key: any) => (
              <ExcelColumn label={key?.nombre} value={key?.key} />
            ))}
          </ExcelSheet>
        </ExcelFile>
      )}
    </Box>
  );
};
export default DownloadExcel;
