import { Box } from "@mui/material";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputText } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { updateUser } from "../../../services/usersServices";
import { required } from "../../../utils/validations";

const FormUpdateUser = ({
  user,
  listGroup,
  setAlertCustom,
  closer,
  CreateUser = null,
  refetch,
}) => {
  return (
    <Form
      onSubmit={(values) => {
        if (CreateUser) {
          CreateUser(values).then((response) => {
            if (response) {
              updateUser({ ...values, radius_id_codigo: response, activa: 1 })
                .then(() => {
                  setAlertCustom({
                    open: true,
                    title: "Confirmacion",
                    severity: "success",
                    message: "Se guardo correctamente",
                  });
                  closer();
                  refetch();
                })
                .catch((error) => {
                  setAlertCustom({
                    open: true,
                    title: "Error",
                    severity: "error",
                    message: error?.message,
                  });
                });
            } else {
              setAlertCustom({
                open: true,
                title: "Error",
                severity: "error",
                message: "El usuario ya existe",
              });
            }
          });
        } else {
          updateUser(values)
            .then(() => {
              setAlertCustom({
                open: true,
                title: "Confirmacion",
                severity: "success",
                message: "Se guardo correctamente",
              });
              closer();
              refetch();
            })
            .catch((error) => {
              setAlertCustom({
                open: true,
                title: "Error",
                severity: "error",
                message: error?.message,
              });
            });
        }
      }}
      initialValues={user}
      render={({ handleSubmit, form: { restart }, invalid, pristine }) => {
        return (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                minWidth: "300px",
                height: "100%",
              }}
            >
              <Field
                name="user"
                label="usuario"
                component={InputText}
                sx={{ mt: 2, textAlign: "center" }}
              />
              <Field
                name="nombre"
                label="Nombre"
                component={InputText}
                sx={{ mt: 2, textAlign: "center" }}
              />
              <Field
                name="apellido"
                label="Apellido"
                component={InputText}
                validate={required}
                sx={{ mt: 2 }}
              />
              <Field
                name="email"
                label="Correo"
                component={InputText}
                disabled={CreateUser ? false : true}
                validate={(value) =>
                  !value
                    ? "Requerido"
                    : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
                    ? "correo invalido"
                    : undefined
                }
                sx={{ mt: 2 }}
              />
              <Field
                name="idGrupo"
                label="Grupo"
                component={SelectMui as any}
                options={listGroup.map((item) => {
                  return {
                    name: item.nombre,
                    value: item.idGrupo,
                  };
                })}
                validate={required}
                sx={{ mt: 2, width: "100%", textAlign: "left" }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                label="Guardar"
                sx={{ mt: 2 }}
                color={"success"}
                onClick={handleSubmit}
                disabled={invalid || pristine}
              />
            </Box>
          </>
        );
      }}
    />
  );
};
export default FormUpdateUser;
