import TableInfo from "./components/tablaInfo";
import {
  useImgProducts,
  useMeasures,
  useProducts,
} from "../../hooks/useProducts";
import { Loading } from "../loading";
import Layout from "../../components/Layout";
import { useState } from "react";
import { usePermisos } from "../../hooks/auth";
import ErrorMsg from "../../components/ErrorMsg";
import { useListUsers } from "../../hooks/useUsers";
import { Box } from "@mui/system";

const Products = () => {
  const { data: products, isLoading, isError, refetch } = useProducts();
  const [filtro, setFiltro] = useState("1");

  const {
    data: ImgProducts,
    isLoading: isLoadingImgProducts,
    isError: isErrorImgProducts,
  } = useImgProducts(0);
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();
  if (
    isLoading ||
    isLoadingImgProducts ||
    isLoadingPermisos ||
    loadingListUsers ||
    isLoadingMeasures
  ) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  if (
    isError ||
    isErrorImgProducts ||
    isErrorPermisos ||
    isErrorListUsers ||
    isErrorMeasures
  ) {
    return (
      <div>
        <ErrorMsg />
      </div>
    );
  }
  const changeRadioButton = (evt) => {
    setFiltro(evt.target.value);
  };

  const datos = products.filter(
    (item) => filtro === "todos" || item.skuActivo === filtro
  );

  return (
    <Layout title="Modulo de productos, insumos y tercerizados">
      <div style={{ flex: 1, padding: "0px 32px 0px 32px " }}>
        {products && (
          <TableInfo
            datos={datos}
            ImgProducts={ImgProducts}
            changeRadioButton={(evt) => changeRadioButton(evt)}
            refetch={refetch}
            permisos={permisos}
            listUsers={listUsers}
            measures={measures}
          />
        )}
      </div>
    </Layout>
  );
};
export default Products;
