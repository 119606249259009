import { fetchContent, Method } from "../utils/fetchContent";
export const GetListPedidos = async (id) => {
  try {
    return await fetchContent(`/pedidos_ispyme_listado/${id}`);
  } catch (error) {
    throw error;
  }
};
export const importarDatosIspyme = async () => {
  try {
    return await fetchContent(`/importar_pedidos_ispyme`);
  } catch (error) {
    throw error;
  }
};
export const createNotaPedido = async (body) => {
  try {
    return await fetchContent(`/pedidos_ispyme_update`, {
      body,
      method: Method.POST,
    });
  } catch (error) {
    throw error;
  }
};
