import { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box, Skeleton, Typography } from "@mui/material";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";

import { Form } from "react-final-form";
import { CardDiasLaborableExepcion } from "./components/cardDiasLaborableExepcion";
import { useDiasLaborablesExcepcion } from "../../hooks/useOrdenTrabajo";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import Button from "../../components/Button";
import FormAgregarNuevoFeriado from "./components/formAgregarNuevoFeriado";
import { guardarDatosFeriado } from "./hook/guardarDatosFeriado";
import { useProcesos } from "../../hooks/useProducts";

const AdminDiaslaborableExepcion = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [showModalDiasLaborablesExepcion, setShowModalDiasLaborablesExepcion] =
    useState(false);
  const {
    data: listDiasLaborablesExcepcion,
    isLoading: isLoadingListDiasLaborablesExcepcion,
    isError: isErrorListDiasDiasLaborablesExcepcion,
    refetch,
  } = useDiasLaborablesExcepcion();
  const {
    data: procesos,
    isLoading: isLoadingProcesos,
    isError: isErrorProcesos,
  } = useProcesos(false);

  if (isErrorListDiasDiasLaborablesExcepcion || isErrorProcesos) {
    return <ErrorMsg />;
  }

  return (
    <>
      <Layout title={"Administrar excepción de dias laborables"} clase={false}>
        {!isLoadingListDiasLaborablesExcepcion && !isLoadingProcesos ? (
          <Box className="col-12" sx={{ p: 2 }}>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Typography variant="h1">
                Aquí puedes gestionar excepciones de dias laborables
              </Typography>
            </Box>
            <Form
              onSubmit={(values) => {}}
              initialValues={{
                datos:
                  listDiasLaborablesExcepcion.length > 0
                    ? listDiasLaborablesExcepcion
                    : [],
              }}
              mutators={{
                ...arrayMutators,
              }}
              validate={(values) => {
                return {};
              }}
              render={({
                handleSubmit,
                form: {
                  mutators: { push },
                  restart,
                },
                submitting,
                pristine,
                values,
              }) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "end", mb: 4 }}>
                      <Button
                        onClick={() => setShowModalDiasLaborablesExepcion(true)}
                        label={"Agregar nueva excepción de un dia laboral"}
                        color="success"
                      />
                    </Box>
                    {console.log(values)}
                    <FieldArray name="datos">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <Box
                            className="col-12"
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <CardDiasLaborableExepcion
                              name={name}
                              onclick={() => {
                                guardarDatosFeriado({
                                  datos: values.datos[index],
                                  setAlertCustom: setAlertCustom,
                                  refetch: refetch,
                                });
                              }}
                              procesos={procesos}
                            />
                          </Box>
                        ))
                      }
                    </FieldArray>
                  </Box>
                );
              }}
            />
          </Box>
        ) : (
          <Box className="col-10">
            <Skeleton variant="text" />

            <Skeleton variant="rectangular" width={""} height={200} />
          </Box>
        )}
      </Layout>
      <DialogGen
        open={showModalDiasLaborablesExepcion}
        title={"Agregar nueva excepción de un dia laboral"}
        onClick={() => setShowModalDiasLaborablesExepcion(false)}
        sx={{ maxWidth: "500px" }}
      >
        <FormAgregarNuevoFeriado
          setAlertCustom={setAlertCustom}
          refetch={refetch}
          procesos={procesos}
        />
      </DialogGen>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </>
  );
};
export default AdminDiaslaborableExepcion;
