import { Box } from "@mui/material";
import Button from "../../../components/Button";

export const FormConfirm = ({ method, funcion, closer }) => {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Button label={"Cancelar"} onClick={closer} />
        <Button
          label={"Confirmar"}
          onClick={() => {
            funcion();
            closer();
          }}
          color="success"
        />
      </Box>
    </Box>
  );
};
