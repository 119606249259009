import { Typography } from "@mui/material";

export const EncabezadoTabla = () => (
  <div className="d-flex flex-wrap p-2">
    <Typography
      variant="subtitle1"
      className="d-flex align-items-center col-2  p-1 "
    >
      Codigo
    </Typography>
    <Typography
      variant="subtitle1"
      className="d-flex align-items-center  col-2 p-1 "
    >
      Nombre
    </Typography>

    <Typography variant="subtitle1" className="col-1 p-1 ">
      Cantidad original
    </Typography>
    <Typography variant="subtitle1" className="col-1 p-1 ">
      Cantidad Recibida
    </Typography>
    <Typography variant="subtitle1" className="col-1 p-1 ">
      Cantidad restante
    </Typography>
    <Typography
      variant="subtitle1"
      className=" d-flex justify-content-center col-1 p-1 "
    >
      Cantidad a recibir
    </Typography>
    <Typography
      variant="subtitle1"
      className="col-1 p-1 "
      style={{ textAlign: "start" }}
    >
      U. de medida
    </Typography>
    <Typography
      variant="subtitle1"
      className="d-flex align-items-center col-1 p-1"
      sx={{ ml: 3 }}
    >
      Lote
    </Typography>
  </div>
);
