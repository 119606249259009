import {
  TableContainer,
  Paper,
  Table as TableMui,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import React from "react";
import Button from "../../../components/Button";
import { DialogGen } from "../../../components/Dialog";
import { Formulario } from "./formulario";
import { TableSortLabel as TableSort } from "./tableSortLabel";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { AddDataTable } from "./addDataTable";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";

export const Table = ({
  rows,
  products,
  showComponent,
  OrderGen,
  items,
  ImgProducts,
  setShowComponent,
  refetch,
  permisos,
  listUsers,
  measures,
}) => {
  const [showCreateModal, setShowCreateModal] = React.useState(false);

  const [mensaje, setMensaje] = React.useState({
    open: false,
    title: "",
    showLink: false,
    id: "",
  });
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 700,

          borderRadius: 5,
          boxShadow: "5px 1px 10px 1px rgba(122,122,122,0.49)",
        }}
      >
        <TableMui
          aria-label="collapsible table"
          sx={{
            background: "rgba(250, 250, 250, 0.5)",
          }}
        >
          <TableHead style={{ backgroundColor: "rgba(0, 0, 0, 0.49)" }}>
            <TableRow>
              <TableCell />
              <TableSort
                showComponent={showComponent}
                OrderGen={OrderGen}
                setShowComponent={setShowComponent}
                items={items}
                name={"cod"}
                nameHeader={"Codigo"}
                ordenBy={"skuCodigo"}
              />
              <TableSort
                showComponent={showComponent}
                OrderGen={OrderGen}
                setShowComponent={setShowComponent}
                items={items}
                name={"descrip"}
                nameHeader={"Nombre"}
                ordenBy={"skuDescripcion"}
              />
              <TableSort
                showComponent={showComponent}
                OrderGen={OrderGen}
                setShowComponent={setShowComponent}
                items={items}
                name={"lead"}
                nameHeader={"Tiempo de compra"}
                ordenBy={"skuLeadtime"}
                number={true}
              />
              <TableSort
                showComponent={showComponent}
                OrderGen={OrderGen}
                setShowComponent={setShowComponent}
                items={items}
                name={"mbq"}
                nameHeader={"MBQ"}
                ordenBy={"skuMbq"}
                number={true}
              />

              <TableCell>
                {verificarPermiso(
                  permisos,
                  PERMISOS.CREAR_NUEVO_PRODUCTO,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Button
                    label="Crear Producto"
                    onClick={() => setShowCreateModal(true)}
                  >
                    <AddCircleOutlineIcon sx={{ pr: 1 }} />
                  </Button>
                )}
              </TableCell>

              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}>
            {rows.map((row) => (
              <AddDataTable
                key={row.name}
                row={row}
                ImgProducts={ImgProducts}
                permisos={permisos}
                listUsers={listUsers}
              />
            ))}
          </TableBody>
        </TableMui>
      </TableContainer>
      <DialogGen
        open={showCreateModal}
        title="Crear Producto"
        onClick={() => setShowCreateModal(false)}
      >
        <Formulario
          setMensaje={setMensaje}
          products={products}
          refetch={refetch}
          measures={measures}
        />
        <DialogGen
          open={mensaje.open}
          title={mensaje.title}
          onClick={() =>
            setMensaje({
              open: false,
              title: "",
              showLink: false,
              id: "",
            })
          }
        >
          {mensaje.showLink && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                color="success"
                label="Editar Ahora"
                href={`/DetalleProductos/${mensaje.id}/[${mensaje.id}]`}
                sx={{ width: "100%" }}
              />
            </Box>
          )}
          {!mensaje.showLink && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                sx={{ width: "100%", mt: 1 }}
                label="Continuar"
                color="error"
                onClick={() =>
                  setMensaje({
                    open: false,
                    title: "",
                    showLink: false,
                    id: "",
                  })
                }
              />
            </Box>
          )}
        </DialogGen>
      </DialogGen>
    </>
  );
};
