import { SaveDiaExeptuado } from "../../../services/ordenTrabajo"

export const guardarDatosFeriado = ({datos, setAlertCustom,refetch}) => {
  const body = {
    dia_laborable_excepcion_id:datos?.diaLaborableExcepcionId,
    proceso_productivo_id: datos?.diaLaborableExcepcionProcesoProductivoId,
    dia_laborable_excepcion_fecha: datos?.diaLaborableExcepcionFecha,
    dia_laborable_excepcion_horas_disponibles_por_dia: Number(datos?.diaLaborableExcepcionHorasDisponiblesPorDia),
    dia_laborable_excepcion_activo: datos?.diaLaborableExcepcionActivo
  }
     SaveDiaExeptuado(
         body,
     )
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
          
            refetch();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });

}