import { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box, Skeleton, Typography } from "@mui/material";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";
import { Form } from "react-final-form";
import { CardMotivoAjusteStock } from "./components/cardMotivoAjusteStock";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import Button from "../../components/Button";
import FormAgregarNuevoFeriado from "./components/formAgregarNuevoMotivoAjuste";
import { guardarDatosFeriado } from "./hook/guardarDatosFeriado";
import { useListMotivoStock } from "../../hooks/useProducts";

const AdminMotivoAjusteStock = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [showModalDiasNoLaborables, setShowModalDiasLaborables] =
    useState(false);
  const {
    data: listMotiveStock,
    isLoading: isLoadingListMotiveStock,
    isError: isErrorListMotiveStock,
    refetch,
  } = useListMotivoStock();

  if (isErrorListMotiveStock) {
    return <ErrorMsg />;
  }

  return (
    <>
      <Layout title={"Administrar motivos de ajuste de stock"} clase={false}>
        {!isLoadingListMotiveStock ? (
          <Box className="col-12" sx={{ p: 2 }}>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Typography variant="h1">
                Aquí puedes gestionar los motivos de ajuste de stock
              </Typography>
            </Box>
            <Form
              onSubmit={(values) => {}}
              initialValues={{
                datos: listMotiveStock.length > 0 ? listMotiveStock : [],
              }}
              mutators={{
                ...arrayMutators,
              }}
              validate={(values) => {
                return {};
              }}
              render={({
                handleSubmit,
                form: {
                  mutators: { push },
                  restart,
                },
                submitting,
                pristine,
                values,
              }) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={() => setShowModalDiasLaborables(true)}
                        label={"Agregar nuevo motivo de ajuste de stock"}
                        color="success"
                      />
                    </Box>

                    <FieldArray name="datos">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <Box
                            className="col-10 col-xl-8"
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <CardMotivoAjusteStock
                              name={name}
                              onclick={() => {
                                guardarDatosFeriado({
                                  datos: values.datos[index],
                                  setAlertCustom: setAlertCustom,
                                  refetch: refetch,
                                });
                              }}
                            />
                          </Box>
                        ))
                      }
                    </FieldArray>
                  </Box>
                );
              }}
            />
          </Box>
        ) : (
          <Box className="col-10">
            <Skeleton variant="text" />

            <Skeleton variant="rectangular" width={""} height={200} />
          </Box>
        )}
      </Layout>
      <DialogGen
        open={showModalDiasNoLaborables}
        title={"Agregar nuevo motivo de ajuste de stock"}
        onClick={() => setShowModalDiasLaborables(false)}
        sx={{ maxWidth: "500px" }}
      >
        <FormAgregarNuevoFeriado
          setAlertCustom={setAlertCustom}
          refetch={refetch}
        />
      </DialogGen>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </>
  );
};
export default AdminMotivoAjusteStock;
