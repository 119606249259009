import { FieldArray } from "react-final-form-arrays";
import { Field, Form } from "react-final-form";
import { ListDrop } from "./components/listDrop";
import arrayMutators from "final-form-arrays";
import { Box, Typography } from "@mui/material";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import { Loading } from "../loading";
import {
  useOrdenTrabajo,
  useOrdenTrabajoEstado,
} from "../../hooks/useOrdenTrabajo";
import { LayoutMobi } from "../../components/Layout";
import { SelectMui } from "../../components/SelectMui";
import { InputDate } from "../../components/Input";
import { useMeasures, useProcesos } from "../../hooks/useProducts";
import { ReadStorage, SaveStorage } from "../../utils/localStorage";
import { isObjEmpty } from "../../utils/isObjectEmpty";
import { SaveDatailsOtg } from "../../services/ordenTrabajo";
import { AlertCustom } from "../../components/AlertMui";
import { verificarPermiso } from "../../utils/verificarPermiso";
import { PERMISOS } from "../../constants/MenuOptions";
import { getUser, usePermisos } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";
export const ProductionPlanMobi = () => {
  const [paramsOtgApi, setParamsOtgApi] = useState({});
  const { data, isLoading, isError, refetch } = useOrdenTrabajo(paramsOtgApi);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });

  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();
  const [datosStorage, setDatosStorage] = useState(null);
  const {
    data: estados,
    isLoading: isLoadingEstados,
    isError: isErrorEstados,
  } = useOrdenTrabajoEstado();
  const {
    data: procesos,
    isLoading: isLoadingProcesos,
    isError: isErrorProcesos,
  } = useProcesos(true);
  const [initialValue, setInitialValue] = useState(null);
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  useEffect(() => {
    ReadStorage("datos")
      .then((result) => {
        setDatosStorage(JSON.parse(result) || []);
        if (!isObjEmpty(JSON.parse(result))) {
          setParamsOtgApi({
            proceso_productivo_id: JSON.parse(result).skuProcesoProductivoId
              ? JSON.parse(result).skuProcesoProductivoId
              : null,
            elaboracion_fecha_desde: JSON.parse(result).fechaFrom
              ? JSON.parse(result).fechaFrom
              : null,
            elaboracion_fecha_hasta: JSON.parse(result).fechaTo
              ? JSON.parse(result).fechaTo
              : null,
          });
        }
      })
      .catch(() => {});
    setInitialValue({
      datos: data,
    });
  }, [data]);
  if (
    isLoadingProcesos ||
    isLoadingEstados ||
    isLoadingMeasures ||
    isLoading ||
    loadingListUsers ||
    isLoadingPermisos ||
    !Array.isArray(initialValue?.datos)
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(procesos) ||
    !Array.isArray(data) ||
    !Array.isArray(measures) ||
    !Array.isArray(estados) ||
    isError ||
    isErrorListUsers ||
    isErrorPermisos ||
    isErrorEstados ||
    isErrorMeasures ||
    isErrorProcesos
  ) {
    return (
      <LayoutMobi title={"Hubo un Error"} href={"/"}>
        <Box>
          <Typography variant="h3">
            Nos apena informate que ha ocurrido un error que debemos revisar
          </Typography>
        </Box>
      </LayoutMobi>
    );
  }

  return (
    <>
      <LayoutMobi title={"Listado de OTG"} href={"/"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 1,
          }}
        >
          <Form
            onSubmit={(values) => {
              let cuenta = 0;

              values.datos.map((item) =>
                SaveDatailsOtg(item.ordenTrabajoId, { ...item })
                  .then(() => {
                    cuenta = cuenta + 1;

                    if (cuenta === values.datos.length) {
                      setAlertCustom({
                        open: true,
                        title: "Confirmacion",
                        severity: "success",
                        message: "Se guardo correctamente",
                      });
                      refetch();
                    }
                  })
                  .catch((error) => {
                    setAlertCustom({
                      open: true,
                      title: "Error",
                      severity: "error",
                      message: error?.message,
                    });
                  })
              );
            }}
            mutators={{
              ...arrayMutators,
            }}
            initialValues={{
              datos: initialValue.datos,
              skuProcesoProductivoId: datosStorage?.skuProcesoProductivoId
                ? datosStorage.skuProcesoProductivoId
                : null,
              fechaFrom: datosStorage?.fechaFrom
                ? datosStorage.fechaFrom
                : null,
              fechaTo: datosStorage?.fechaTo ? datosStorage.fechaTo : null,
            }}
            render={({ handleSubmit, form: { restart }, values }) => (
              <Box>
                {verificarPermiso(
                  permisos,
                  PERMISOS.BUSCAR_OTGS,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <>
                    <Box>
                      <Field
                        name={`skuProcesoProductivoId`}
                        component={SelectMui as any}
                        label="Proceso productivo"
                        options={procesos
                          ?.filter(
                            (item) => item.procesoProductivoActivo === "1"
                          )
                          .map((item) => {
                            return {
                              name: `${item.procesoProductivoTipoDescripcion} ${
                                item.procesoProductivoId !== "1" &&
                                item.procesoProductivoId !== "0"
                                  ? `- ${item.procesoProductivoDescripcion}`
                                  : ""
                              }`,
                              value: item.procesoProductivoId,
                            };
                          })}
                        sx={{ width: "100%", maxWidth: 400 }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        maxWidth: 400,
                        mt: 1,
                      }}
                    >
                      <Box className="col-6" sx={{ pr: 1 }}>
                        <Field
                          focused
                          name={"fechaFrom"}
                          component={InputDate as any}
                          label={"Desde"}
                          max={values.fechaTo}
                        />
                      </Box>

                      <Box className="col-6" sx={{ pl: 1 }}>
                        <Field
                          focused
                          name={"fechaTo"}
                          component={InputDate as any}
                          label={"Hasta"}
                          min={values.fechaFrom}
                        />
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        <Button
                          label={"Buscar"}
                          onClick={() => {
                            SaveStorage(
                              "datos",
                              JSON.stringify({
                                skuProcesoProductivoId:
                                  values.skuProcesoProductivoId,
                                fechaFrom: values.fechaFrom,
                                fechaTo: values.fechaTo,
                              })
                            );
                            setParamsOtgApi({
                              skuProcesoProductivoId:
                                values.skuProcesoProductivoId,
                              fechaFrom: values.fechaFrom,
                              fechaTo: values.fechaTo,
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
                >
                  <Button
                    disabled={
                      !verificarPermiso(
                        permisos,
                        PERMISOS.AGREGAR_OTGS,
                        listUsers?.find(
                          (item) => item?.radiusIdCodigo === getUser()?.uid
                        )?.idGrupo
                      )
                    }
                    label={"Agregar OTG"}
                    href="/CrearOtg"
                    onClick={() => {
                      SaveStorage(
                        "datos",
                        JSON.stringify({
                          skuProcesoProductivoId: values.skuProcesoProductivoId,
                          fechaFrom: values.fechaFrom,
                          fechaTo: values.fechaTo,
                        })
                      );
                    }}
                  />
                </Box>
                <FieldArray name="datos">
                  {({ fields }) => (
                    <>
                      <ListDrop
                        fields={fields}
                        datosGuardarStorage={{
                          skuProcesoProductivoId: values.skuProcesoProductivoId,
                          fechaFrom: values.fechaFrom,
                          fechaTo: values.fechaTo,
                        }}
                        onchange={(valor) => {
                          setInitialValue({
                            ...initialValue,
                            datos: valor,
                          });
                        }}
                        estados={estados}
                        permisos={permisos}
                        listUsers={listUsers}
                      />
                    </>
                  )}
                </FieldArray>
                <Box sx={{ display: "flex ", flexDirection: "row" }}>
                  {data !== initialValue.datos && (
                    <Box sx={{ display: "flex", justifyContent: "start" }}>
                      <Button
                        label="Reiniciar"
                        onClick={() => {
                          setInitialValue({ datos: data });
                        }}
                      />
                    </Box>
                  )}

                  {data !== initialValue.datos && (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        label="Guardar"
                        color="success"
                        onClick={handleSubmit}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          />
        </Box>
      </LayoutMobi>
      <AlertCustom
        open={alertCustom.open}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
        severity={alertCustom.severity}
        title={alertCustom.title}
        message={alertCustom.message}
      />
    </>
  );
};
