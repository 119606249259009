import { useState } from "react";
import { Typography } from "@mui/material";
import { Form, Field } from "react-final-form";
import { AutoComplete } from "../../../components/AutoComplete";
import { InputNumber, InputText } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { SaveAjusteStock } from "../../../services/productServices";
import { filtrarUbication } from "../hook/filtrarUbicacion";

export const FormAjusteStock = ({
  listDeposito,
  products,
  listMotiveStock,
  listUbicationProvider,
  listStockState,
  setAlertCustom,
  refetch,
  user,
}) => {
  const [initialValue, setInitialValue] = useState({
    idProducto: { id: "", label: "" },
    proveiderStock: "",
    motive: "",
    cantidad: "",
    stock_estado_id: "",
    stock_auditoria_observacion: "",
  });
  return (
    <Form
      onSubmit={(values) => {
        const body = {
          sku_id: Number(values.idProducto.id),
          stock_ubicacion_id: Number(values.stock_ubicacion_id),
          stock_cantidad: Number(values.cantidad),
          unidad_medida_id: Number(10),
          stock_estado_id: Number(values.stock_estado_id),
          stock_motivo_ajuste_id: Number(values.motive),
          stock_auditoria_observacion: values.stock_auditoria_observacion,
          id_radius: user,
        };

        SaveAjusteStock(body)
          .then(() => {
            setInitialValue({
              idProducto: { id: "", label: "" },
              proveiderStock: "",
              motive: "",
              cantidad: " ",
              stock_estado_id: "",
              stock_auditoria_observacion: "",
            });
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });

            refetch();
          })
          .catch(() => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: "Ha ocurrido un error",
            });
          });
      }}
      initialValues={initialValue}
      validate={(values) => {
        const error: any = {};
        if (!values.idProducto.id) {
          error.idProducto = "Requerido";
        }
        if (!values.proveiderStock) {
          error.proveiderStock = "Requerido";
        }
        if (!values.stock_ubicacion_id) {
          error.stock_ubicacion_id = "Requerido";
        }
        if (!values.motive) {
          error.motive = "Requerido";
        }
        if (!values.stock_estado_id) {
          error.stock_estado_id = "Requerido";
        }
        if (!values.cantidad) {
          error.cantidad = "Requerido";
        }
        if (values.cantidad === "0") {
          error.cantidad = "No puede ser 0";
        }

        return error;
      }}
      render={({ handleSubmit, submitting, pristine, values }) => {
        return (
          <>
            <div className="d-flex flex-wrap">
              <div className="col-12 col-lg-4 p-2">
                <Field
                  name="idProducto"
                  component={AutoComplete as any}
                  sx={{ maxWidth: "300px" }}
                  options={products.map((item) => {
                    return {
                      id: item.skuId,
                      label: item.skuCodigo,
                    };
                  })}
                />
              </div>
              <div className="col-12 col-lg-4 p-2">
                <Typography align="left" variant="subtitle1">
                  {values?.idProducto?.id
                    ? products.find(
                        (item) => values?.idProducto?.id === item.skuId
                      )?.skuDescripcion
                    : ""}
                </Typography>
              </div>
              <div className="col-12 col-lg-4 p-2">
                <Field
                  name="proveiderStock"
                  label="Deposito"
                  component={SelectMui as any}
                  options={listDeposito.map((item) => ({
                    value: item.entidadId,
                    name: item.entidadDescripcion,
                  }))}
                  sx={{ width: "100%" }}
                />
              </div>

              <div className="col-12 col-lg-4 p-2">
                <Field
                  name="stock_ubicacion_id"
                  label="Ubicacion"
                  component={SelectMui as any}
                  options={
                    filtrarUbication(listUbicationProvider, values) !==
                    undefined
                      ? filtrarUbication(listUbicationProvider, values)?.map(
                          (item) => ({
                            value: item.stockUbicacionId,
                            name: item.stockUbicacionDescripcion,
                          })
                        )
                      : []
                  }
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-12 col-lg-4 p-2">
                <Field
                  name="motive"
                  label="Motivo"
                  component={SelectMui as any}
                  options={listMotiveStock.map((item) => ({
                    value: item.stockMotivoAjusteId,
                    name: item.stockMotivoAjusteDescripcion,
                  }))}
                  sx={{ width: "100%" }}
                />
              </div>

              <div className="col-12 col-lg-4 p-2">
                <Field
                  name="cantidad"
                  component={InputNumber as any}
                  label="Cantidad"
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-12 col-lg-4 p-2">
                <Field
                  name="stock_estado_id"
                  label="Estado stock"
                  component={SelectMui as any}
                  options={listStockState.map((item) => ({
                    value: item.stockEstadoId,
                    name: item.stockEstadoDescripcion,
                  }))}
                  sx={{ width: "100%" }}
                />
              </div>
              <div className="col-12 col-lg-4 p-2">
                <Field
                  name="stock_auditoria_observacion"
                  component={InputText as any}
                  label="observacion"
                  sx={{ width: "100%" }}
                />
              </div>
            </div>

            <div
              className="d-flex justify-content-center align-items-center"
              style={{ marginTop: "16px" }}
            >
              <button
                className="button"
                style={{ width: "50%" }}
                onClick={handleSubmit}
                disabled={submitting || pristine}
              >
                <Typography>Realizar Ajuste</Typography>
              </button>
            </div>

            <Typography className="mt-2">
              los ajustes realizados no se pueden borrar, para revetir el cambio
              debera hacer un ajuste inverso al realizado
            </Typography>
          </>
        );
      }}
    />
  );
};
