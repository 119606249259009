import * as React from "react";
import { BsXLg as X } from "react-icons/bs";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { DeleteStorage } from "../../utils/localStorage";
import MenuIcon from "@mui/icons-material/Menu";
import { Divider, Box } from "@mui/material";

export default function PositionedMenu({ signOut, auth, sx = {} }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon sx={{ ...sx }} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 1 }}>
          <X onClick={handleClose} />
        </Box>

        <LinkStyleMain
          href={"/"}
          name={"inicio"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href="/NotaPedidos"
          name={"Crear nota pedido"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href={"/Pedidos"}
          name={"Pedidos"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href={"/Plan"}
          name={"Plan"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href={"/Productos"}
          name={"productos,insumos y tercerizados"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href={"/costo"}
          name={"Ver reporte costo"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href={"/ControlStock"}
          name={"Control de stock por productos, insumos y tercerizados"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href={"/Reportes"}
          name={"Reportes"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href={"/Administración"}
          name={"Gestión de usuarios"}
          handleClose={() => handleClose()}
        />
        <LinkStyleMain
          href={"/Ajustes"}
          name={"Otros ajustes"}
          handleClose={() => handleClose()}
        />
        <Button
          onClick={() =>
            signOut(auth)
              .then(() => {
                DeleteStorage("Permisos");
                DeleteStorage("PermisosAOtorgar");
                DeleteStorage("Token");
                DeleteStorage("Usuario");
                DeleteStorage("user");
                DeleteStorage("datos");
                window.location.href = "/";
              })
              .catch((error) => {})
          }
        >
          Cerrar sesion
        </Button>
      </Menu>
    </div>
  );
}
const LinkStyleMain = ({ href, name, handleClose }) => (
  <>
    <Button href={href} onClick={handleClose} sx={{ maxWidth: "300px" }}>
      {name}
    </Button>

    <Divider />
  </>
);
