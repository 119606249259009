import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { CheckBoxMuiOneOrZero } from "../../../components/CheckBoxMui";
import { InputText } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { SaveUpdateEtiquetas } from "../../../services/moduloCalidad";

import { PostProcesosProductivoArea } from "../../../services/productServices";
import { required } from "../../../utils/validations";

const EditarEtiquetas = ({
  procesos,
  setAlertCustom,
  RefetchListadoEtiquetaCalidad,
  listadoEtiquetaCalidad,
}) => {
  const [InitialValue, setInitialValue] = useState({});
  const FuncionRestart: any = {};
  return (
    <Form
      onSubmit={(values) => {
        SaveUpdateEtiquetas({
          ...values,
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });

            FuncionRestart.funcion({});
            RefetchListadoEtiquetaCalidad();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={InitialValue}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        FuncionRestart.funcion = restart;
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Box className="col-10" sx={{ mt: 1 }}>
              <Field
                validate={required}
                name={`procesoProductivoId`}
                component={SelectMui as any}
                label="Proceso productivo"
                options={procesos
                  .filter((x) => Number(x?.procesoProductivoActivo) > 0)
                  .map((item) => {
                    return {
                      name: `${item.procesoProductivoTipoDescripcion}  ${
                        item.procesoProductivoId !== "1"
                          ? `- ${item.procesoProductivoDescripcion} `
                          : ""
                      } `,
                      value: item.procesoProductivoId,
                    };
                  })}
                sx={{ width: "100%" }}
              />
            </Box>

            <Box className="col-8" sx={{ mt: 1 }}>
              <Field
                validate={required}
                name={`etiquetaId`}
                component={SelectMui as any}
                label="Etiqueta"
                options={listadoEtiquetaCalidad
                  .filter(
                    (x) =>
                      Number(values.procesoProductivoId) > 0 &&
                      x.procesoProductivoId === values.procesoProductivoId
                  )
                  .map((item) => {
                    return {
                      name: item.procesoProductivoEtiquetaCalidadDescripcion,
                      value: item.procesoProductivoEtiquetaCalidadId,
                    };
                  })}
                sx={{ width: "100%" }}
                onchange={(valor) => {
                  setInitialValue({
                    ...values,
                    etiquetaId: valor,
                    ...listadoEtiquetaCalidad.filter(
                      (x) =>
                        Number(values.procesoProductivoId) > 0 &&
                        x.procesoProductivoId === values.procesoProductivoId &&
                        x.procesoProductivoEtiquetaCalidadId === valor
                    )[0],
                  });
                }}
              />
            </Box>
            <Box
              className="col-2"
              sx={{
                display: "flex",
                alignItems: "center",
                ml: 1,
              }}
            >
              <Typography sx={{ pr: 1 }}>Activo</Typography>

              <Field
                component={CheckBoxMuiOneOrZero as any}
                name={"procesoProductivoEtiquetaCalidadActivo"}
              />
            </Box>
            <Box className="col-8" sx={{ mt: 1 }}>
              <Field
                validate={required}
                name={`procesoProductivoEtiquetaCalidadDescripcion`}
                component={InputText as any}
                label="Descripcion"
                sx={{ width: "100%" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
              className="col-12"
            >
              <Button
                label={"Guardar Cambios"}
                disabled={pristine}
                onClick={handleSubmit}
                sx={{ minWidth: "150px" }}
                color="success"
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};
export default EditarEtiquetas;
