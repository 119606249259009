import { PostProcesosProductivo } from "../../../services/productServices"

export const saveDataProcesoProductivo = ({ values, procesoProductivoId, refetch, setAlertCustom, eliminar = false }) => {
    if (eliminar) {
     PostProcesosProductivo({ ...values[0],  procesoProductivoId,procesoProductivoHorasDisponiblesPorDia: Number(values[0]?.procesoProductivoHorasDisponiblesPorDia),procesoProductivoActivo: values[0].procesoProductivoActivo === "1" || values[0].procesoProductivoActivo === 1 ? 0 : 1}).then(() => {
            setAlertCustom({
                open: true,
                title: "Confirmacion",
                severity: "success",
                message: "Se guardo correctamente"
            })
            refetch();
        }).catch((error) => setAlertCustom({
            open: true,
            title: "Error",
            severity: "error",
            message: error?.message
        }))
    } else {
             PostProcesosProductivo({ ...values[0],procesoProductivoHorasDisponiblesPorDia: Number(values[0]?.procesoProductivoHorasDisponiblesPorDia), procesoProductivoId }).then(() => {
            setAlertCustom({
                open: true,
                title: "Confirmacion",
                severity: "success",
                message: "Se guardo correctamente"
            })
            refetch();
        }).catch((error) => setAlertCustom({
            open: true,
            title: "Error",
            severity: "error",
            message: error?.message
        }))
      
    }

}
