import { useState } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Box, Card } from "@mui/material";
import { Loading } from "../loading";
import {
  useListprovider,
  useMeasures,
  useProducts,
  useStockProduct,
} from "../../hooks/useProducts";
import ButtonCustom from "../../components/Button";
import { SaveNewOC } from "../../services/stockServices";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import Layout from "../../components/Layout";
import { VistPreviaDatos } from "./components/vistaPreviaDatos";
import { FieldsForm } from "./components/fieldsForm";
import ErrorMsg from "../../components/ErrorMsg";
import { useProductsSupplier } from "../../hooks/useSuppliers";
import { getUser } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";
const GeneracionOC = () => {
  const [entidad, setEntidad] = useState(0);
  const {
    data: listProvider,
    isLoading: isLoadingListProvider,
    isError: isErrorListProvider,
  } = useListprovider();
  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();

  // const { data: products, isLoading } = useProductsEntidadById(id);
  const { data: products, isLoading, isError: isErrorProducts } = useProducts();
  const { data, isLoading: isLoadingStockProduct, isError } = useStockProduct();
  const {
    data: productsSuppliers,
    isError: isErrorProductsSuppliers,
    isLoading: isLoadingProductsSuppliers,
  } = useProductsSupplier(entidad);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValue, setInitialValue] = useState({
    proveedorId: "",
    comprobanteObservacion: "",
    detalleSku: [
      {
        dateEntrega: "",
        unidadMedidaId: "",
        amount: "",
        idProducto: { id: "", label: "", name: "" },
      },
    ],
  });
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });

  const [showModalConfir, setShowModalConfir] = useState({
    open: false,
    values: null,
  });

  if (
    isLoadingListProvider ||
    isLoadingStockProduct ||
    isLoadingMeasures ||
    isLoadingProductsSuppliers ||
    isLoading ||
    loadingListUsers
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(productsSuppliers) ||
    !Array.isArray(data) ||
    !Array.isArray(measures) ||
    !Array.isArray(listProvider) ||
    !Array.isArray(products) ||
    isError ||
    isErrorListProvider ||
    isErrorMeasures ||
    isErrorProductsSuppliers ||
    isErrorProducts ||
    isErrorListUsers
  ) {
    return <ErrorMsg />;
  }
  const user = listUsers.find(
    (item) => item.radiusIdCodigo === getUser().uid
  )?.idRadius;
  return (
    <Layout title="Crear orden de compra">
      <Card
        sx={{
          display: "flex",
          flexWrap: "wrap",

          borderRadius: 5,
          width: "100%",
        }}
      >
        <Form
          onSubmit={(values) => {
            setShowModalConfir({ open: true, values: values });
          }}
          initialValues={initialValue}
          mutators={{
            ...arrayMutators,
          }}
          validate={(values) => {
            const error: any = {};
            if (!values.proveedorId) {
              error.proveedorId = "Requerido";
            }

            return error;
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push },

              restart,
            },
            submitting,
            pristine,
            values,
          }) => {
            return (
              <Box sx={{ width: "100%" }}>
                <DialogGen
                  open={showModalConfir.open}
                  title={"Confirmar"}
                  onClick={() =>
                    setShowModalConfir({ open: false, values: [] })
                  }
                >
                  <VistPreviaDatos
                    listProvider={listProvider}
                    values={values}
                    measures={measures}
                  />
                  <Box className="d-flex flex-row mt-4">
                    <ButtonCustom
                      label="editar"
                      onClick={() =>
                        setShowModalConfir({ open: false, values: null })
                      }
                    />
                    <ButtonCustom
                      label="Confirmar orden de compra"
                      color="success"
                      sx={{ mx: 2 }}
                      onClick={() => {
                        let detalleSku = [];
                        values.detalleSku.map((item, index) =>
                          detalleSku.push({
                            skuId: Number(item.idProducto.id),
                            cantidad: Number(item.amount),
                            unidadMedidaId: Number(
                              values?.detalleSku[index]?.idProducto
                                ?.unidadMedidaId
                            ),

                            fechaEntrega: item.dateEntrega,
                            precio_unitario: Number(item.precio_unitario),
                          })
                        );
                        const body = {
                          detalleSku,
                          comprobanteObservacion: values.comprobanteObservacion,
                          entidadId: Number(values.proveedorId),
                          id_radius: user,
                        };

                        SaveNewOC(body)
                          .then(() => {
                            restart();
                            setShowModalConfir({
                              open: false,
                              values: null,
                            });
                            setAlertCustom({
                              open: true,
                              title: "Confirmacion",
                              severity: "success",
                              message:
                                "Se creo correctamente la orden de compra",
                            });
                          })
                          .catch((error) => {
                            setShowModalConfir({
                              open: false,
                              values: null,
                            });
                            setAlertCustom({
                              open: true,
                              title: "Error",
                              severity: "error",
                              message: `${error.message}`,
                            });
                          });
                      }}
                    />
                  </Box>
                </DialogGen>

                <FieldsForm
                  listProvider={listProvider}
                  products={productsSuppliers}
                  setEntidad={setEntidad}
                  showFieldArray={entidad !== 0}
                  values={values}
                  data={data}
                  measures={measures}
                  push={(dato) => push(dato)}
                  setInitialValue={setInitialValue}
                  initialValue={initialValue}
                />

                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  <ButtonCustom
                    label={"generar orden de compra"}
                    onClick={handleSubmit}
                    sx={{ width: "100%", maxWidth: 300 }}
                    color="success"
                    disabled={submitting || pristine}
                  />
                </Box>
              </Box>
            );
          }}
        />
      </Card>

      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({ open: false, title: "", severity: "", message: "" })
        }
      />
    </Layout>
  );
};

export default GeneracionOC;
