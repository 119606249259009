import { UpdateMoneda } from "../../services/cotizacion";

export const saveInfoMoneda = (body, setAlertCustom, refetch, refrescar = null) => {
  UpdateMoneda(body)
    .then(() => {
      setAlertCustom({
        open: true,
        title: "Confirmacion",
        severity: "success",
        message: "Se guardo correctamente",
      });
      refrescar && refrescar();
      refetch();
    })
    .catch((error) => {
      setAlertCustom({
        open: true,
        title: "Error",
        severity: "error",
        message: error?.message,
      });
    });
}
export const NumeroOrdenDeMonedaMayor = (array) => {
  let todasLasOrdenDeMoneda = [];
  array?.map((item) => todasLasOrdenDeMoneda.push(Number(item.monedaOrden)));
  return Math.max(...todasLasOrdenDeMoneda);


}