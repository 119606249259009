import { useState } from "react";
import { useParams } from "react-router";
import { Box, Typography } from "@mui/material";
import { LayoutMobi } from "../../components/Layout";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Loading } from "../loading";
import {
  useAllOrdenTrabajo,
  useListadoHorasPorUsuario,
  useOrdenTrabajoMotivoPausa,
  useOrdenTrabajoUsuario,
} from "../../hooks/useOrdenTrabajo";
import { AlertCustom } from "../../components/AlertMui";
import { Form } from "react-final-form";
import { CardOtgHorasTrabajoMobi } from "./component/cardOtgHorasTrabajoMobi";

import { DialogGen } from "../../components/Dialog";
import { ModalMenuStop } from "./component/modalMenuStop";
import { useListUsers } from "../../hooks/useUsers";
import { getUser } from "../../hooks/auth";
import { SaveActividadUsuario } from "../../services/ordenTrabajo";
import { fromSecondsToHours } from "../../utils/fromSecondsToHours";

const HorasDeTrabajoMobi = () => {
  let { id } = useParams();
  const [showModalStop, setShowModalStop] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const { data, isLoading, isError } = useAllOrdenTrabajo();
  const {
    data: ordentrabajoUsuario,
    isLoading: isLoadingOrdentrabajoUsuario,
    isError: isErrorOrdentrabajoUsuario,
    refetch: refetchOrdenTrabajoUsuario,
  } = useOrdenTrabajoUsuario(id);

  const {
    data: listadoHorasPorUsuario,
    isLoading: isLoadingListadoHorasPorUsuario,
    isError: isErrorListadoHorasPorUsuario,
    refetch: refetchListadoHorasPorUsuario,
  } = useListadoHorasPorUsuario(id);
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
  } = useListUsers();
  const {
    data: ordentrabajoMotivoPausa,
    isLoading: isLoadingOrdentrabajoMotivoPausa,
    isError: isErrorOrdentrabajoMotivoPausa,
  } = useOrdenTrabajoMotivoPausa();
  if (
    isLoading ||
    loadingListUsers ||
    isLoadingOrdentrabajoUsuario ||
    isLoadingListadoHorasPorUsuario ||
    isLoadingOrdentrabajoMotivoPausa
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(data) ||
    !Array.isArray(ordentrabajoUsuario) ||
    !Array.isArray(listadoHorasPorUsuario) ||
    !Array.isArray(listUsers) ||
    !Array.isArray(ordentrabajoMotivoPausa) ||
    isError ||
    isErrorOrdentrabajoUsuario ||
    isErrorListadoHorasPorUsuario ||
    isErrorListUsers ||
    isErrorOrdentrabajoMotivoPausa
  ) {
    return (
      <LayoutMobi title={"Hubo un Error"}>
        <Box>
          <Typography variant="h3">
            Nos apena informate que ha ocurrido un error que debemos revisar
          </Typography>
        </Box>
      </LayoutMobi>
    );
  }
  const otg = data.filter((item) => item.ordenTrabajoId === id);
  const user = listUsers.find((item) => item.radiusIdCodigo === getUser().uid);
  let suma = 0;
  const estadoOtgId = listadoHorasPorUsuario.filter(
    (item) => item.idRadius === user.idRadius
  )[0]?.ordenTrabajoEstadoId;
  listadoHorasPorUsuario
    .filter(
      (item) =>
        item?.idRadius === user?.idRadius && item?.ordenTrabajoEstadoId === "2"
    )

    .forEach(function ({ duracion }) {
      suma += Number(duracion);
    });
  
  const href = `../detailsOrdenTrabajo/${id}`;
  return (
    <>
      <LayoutMobi title={"Horas de trabajo"} href={href}>
        <Box>
          <Form
            onSubmit={(values) => {}}
            initialValues={{ ...otg[0] }}
            // eslint-disable-next-line no-empty-pattern
            render={({}) => (
              <>
                <Box
                  sx={{
                    m: 1,
                    p: 1,
                    background: "#FFFFFF",
                  }}
                >
                  <CardOtgHorasTrabajoMobi
                    datos={{
                      ...otg[0],
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2">Horas trabajadas:</Typography>
                  <Typography sx={{ ml: 1 }}>
                    {fromSecondsToHours(suma)}
                  </Typography>
                </Box>
              </>
            )}
          />

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <PlayCircleIcon
              color={estadoOtgId !== "2" ? "success" : "primary"}
              sx={{ cursor: "pointer", fontSize: "80px" }}
              onClick={() => {
                if (estadoOtgId !== "2") {
                  SaveActividadUsuario(id, {
                    id_radius: user.idRadius,
                    orden_trabajo_estado_id: 2,
                  })
                    .then(() => {
                      setAlertCustom({
                        open: true,
                        title: "Confirmacion",
                        severity: "success",
                        message: "Se inició correctamente",
                      });
                      refetchListadoHorasPorUsuario();
                    })
                    .catch((error) =>
                      setAlertCustom({
                        open: true,
                        title: "Error",
                        severity: "error",
                        message: error.message,
                      })
                    );
                } else {
                  setAlertCustom({
                    open: true,
                    title: "Error",
                    severity: "error",
                    message: "Ya esta en curso",
                  });
                }
              }}
            />

            <StopCircleIcon
              color={estadoOtgId === "2" ? "error" : "primary"}
              sx={{ cursor: "pointer", fontSize: "80px" }}
              onClick={() => {
                if (estadoOtgId === "2") {
                  setShowModalStop(true);
                } else {
                  setAlertCustom({
                    open: true,
                    title: "Error",
                    severity: "error",
                    message: "No hay ninguna orden de trabajo en curso",
                  });
                }
              }}
            />
          </Box>
        </Box>
      </LayoutMobi>
      <AlertCustom
        open={alertCustom.open}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
        severity={alertCustom.severity}
        title={alertCustom.title}
        message={alertCustom.message}
      />
      <DialogGen
        open={showModalStop}
        onClick={() => setShowModalStop(false)}
        title={"Debe Seleccionar un motivo de parada"}
        sx={{ maxWidth: "300px" }}
      >
        <ModalMenuStop
          ordentrabajoMotivoPausa={ordentrabajoMotivoPausa}
          SaveActividadUsuario={SaveActividadUsuario}
          user={user}
          id={id}
          closer={() => setShowModalStop(false)}
          setAlertCustom={setAlertCustom}
          refetchListadoHorasPorUsuario={refetchListadoHorasPorUsuario}
        />
      </DialogGen>
    </>
  );
};
export default HorasDeTrabajoMobi;
