import { fetchContent, Method } from "../utils/fetchContent";

export const SaveNewOC = async (body) => {

    try {
        return await fetchContent("/stock/oc/",
            {
                body,
                method: Method.POST,
            });
      
    } catch (error) {
   throw (error)
    }
}
export const SaveNuevoEgreso = async (body) => {

    try {
        return await fetchContent("/remito_entrega_update",
            {
                body,
                method: Method.POST,
            });
      
    } catch (error) {
   throw (error)
    }
}
export const SaveNuevoMotivoAjuste = async (body) => {

    try {
        return await fetchContent("/stock/motivo_update/",
            {
                body,
                method: Method.POST,
            });
      
    } catch (error) {
   throw (error)
    }
}
export const GetListOC = async (id) => {

    try {
      return await fetchContent(`/stock/oc_listado/${id}`);
      
    } catch(error) {
        throw (error)
    }  
}
export const GetListHeaderOC = async (id) => {

    try {
      return await fetchContent(`/stock/oc_cabecera_listado/${id}`);
      
    } catch(error) {
        throw (error)
    }  
}
export const GetListDetailsOC = async (id) => {

    try {
      return await fetchContent(`/stock/oc_detalle_listado/${id}`);
      
    } catch(error) {
         throw (error)
    }  
}