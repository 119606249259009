import { useQuery } from "react-query";
import { GetListDetailsOC, GetListOC } from "../services/stockServices";
import { convertDateDDMMAAAA } from "../utils/Fecha";
import { getStatusOC, getStatusOCId } from "../utils/getStatusOC";
export const useListOC = () => {
  const { data, isLoading, isError } = useQuery(["ListOC"], () => GetListOC(0));

  return {
    data: data?.data,
    isLoading,
    isError,
  };
};
// export const useListHeaderOC = () => {

//   const { data, isLoading, isError } = useQuery(['ListOCHeader' ],()=> GetListHeaderOC(3));

//   return {
//     data: data?.data,
//     isLoading,
//     isError,

//    }
// }
export const useListDetailsOC = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["ListDetailsOC"],
    async () => {
      const response = await GetListDetailsOC(0);
      const results = response?.data?.filter(
        (item) => Number(item.comprobanteDetalleCantidadSaldo) > 0
      );

      return results;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
export const useListDetailsOcCompleto = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    ["ListDetailsOC"],
    async () => {
      const response = await GetListDetailsOC(0);

      const results = response.data.map((item) => {
        return {
          ...item,
          status: getStatusOC(item),

          statusId: getStatusOCId(item),

          fechaCreacion: convertDateDDMMAAAA(item.comprobanteFecha),
          fechaEntrega: convertDateDDMMAAAA(
            item.comprobanteDetalleFechaEntrega
          ),
          remito: `${
            !!item.comprobanteRemitoProveedorSerie
              ? `${item.comprobanteRemitoProveedorSerie} - ${item.comprobanteRemitoProveedorNumero}`
              : ""
          }`,
          fechaNumber: Number(item?.comprobanteFecha?.replaceAll("-", "")),
          usuario: `${item?.nombre} ${item?.apellido}`,
        };
      });

      return results;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
