import { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Form, Field } from "react-final-form";
import {
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { SelectMui } from "../components/SelectMui";
import { IsMobi } from "../hooks/isMobi";
import {
  useListDeposito,
  useListStockState,
  useListUbicationProvider,
  useStockProduct,
} from "../hooks/useProducts";
import { Loading } from "./loading";
import Layout from "../components/Layout";
import { ordenarArrayConParametros } from "../utils/orderGen";

const StockPerUbication = () => {
  const { data, isLoading: isLoadingStockProduct } = useStockProduct();

  const {
    data: listUbicationProvider,
    isLoading: isLoadingListUbicationProvider,
  } = useListUbicationProvider(0);
  const { data: listDeposito, isLoading: isLoadingDeposito } =
    useListDeposito();

  const { data: listStockState, isLoading: isLoadingListStockState } =
    useListStockState();

  if (
    isLoadingListUbicationProvider ||
    isLoadingDeposito ||
    isLoadingStockProduct ||
    isLoadingListStockState
  ) {
    return <Loading />;
  }
  let stockProducts = [];
  data.map(({ ubication }) => {
    ubication.map((name) => {
      stockProducts.push(name);
    });
  });
  ordenarArrayConParametros(stockProducts, [
    "stockUbicacionDescripcion",
    "skuCodigo",
  ]);

  return (
    <Layout title={"stock por ubicacion"}>
      <Form
        onSubmit={(values) => console.log(values)}
        initialValues={{}}
        render={({ values }) => {
          return (
            <>
              <div className="d-flex flex-wrap col-12 p-2">
                <div className="col-4 p-2">
                  <Field
                    name="stockProveedorDescrip"
                    component={SelectMui as any}
                    label="Deposito"
                    options={listDeposito.map((item) => ({
                      value: item.entidadDescripcion,
                      name: item.entidadDescripcion,
                    }))}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="col-4 p-2">
                  <Field
                    name="stockUbicacionId"
                    component={SelectMui as any}
                    label="Ubicacion"
                    initialValue={"todos"}
                    empty={true}
                    options={listUbicationProvider
                      .filter(
                        (list) =>
                          list.entidadDescripcion ===
                          values.stockProveedorDescrip
                      )
                      ?.map((item) => ({
                        value: item.stockUbicacionId,
                        name: item.stockUbicacionDescripcion,
                      }))}
                    sx={{ width: "100%" }}
                  />
                </div>
                <div className="col-4 p-2">
                  <Field
                    name="stockEstado"
                    component={SelectMui as any}
                    label="Estado"
                    initialValue={"todos"}
                    empty={true}
                    options={listStockState.map((item) => ({
                      value: item.stockEstadoId,
                      name: item.stockEstadoDescripcion,
                    }))}
                    sx={{ width: "100%" }}
                  />
                </div>
              </div>

              <CallTable datos={stockProducts} values={values} />
            </>
          );
        }}
      />
    </Layout>
  );
};
const CallTable = ({ values, datos }) => {
  const estado =
    values.stockEstado === "1"
      ? "Aprobado"
      : values.stockEstado === "2"
      ? "Rechazado"
      : values.stockEstado === "3"
      ? "PendienteQa"
      : values.stockEstado === "4"
      ? "Tercero"
      : "";
  const datosTable = datos.filter(
    (item) =>
      values.stockProveedorDescrip !== "" &&
      values.stockProveedorDescrip === item.proveider &&
      (values.stockUbicacionId === "todos" ||
        values.stockUbicacionId === item.stockUbicacionId) &&
      (values.stockEstado === "todos" || item[estado] !== 0)
  );
  const header = [
    "Deposito",
    "Ubicacion",
    "Codigo",
    "Nombre",
    "Aprobado",
    "Rechazado",
    "Cuarentena",
    "Pendiente de calidad",
    "Unidad de medida",
  ];
  const row = [
    "proveider",
    "stockUbicacionDescripcion",
    "skuCodigo",
    "skuDescripcion",
    "Aprobado",
    "Rechazado",
    "Tercero",
    "PendienteQa",
    "unidadMedida",
  ];
  return (
    <TableStockPerUbication datos={datosTable} header={header} nameRow={row} />
  );
};
const TableStockPerUbication = ({ datos, header, nameRow, sx = {} }) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageSelected, setPageSelected] = useState(0);
  const items = datos;
  const [itemOffset, setItemOffset] = useState(0);
  const changeNumPage = (evt) => {
    setItemOffset(0);
    setPageSelected(0);
    setItemsPerPage(evt.target.value);
  };
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setPageSelected(event.selected);
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="d-flex flex-column mx-3" style={{ width: "100%" }}>
        <div className="d-flex justify-content-start mb-2">
          <FormControl
            sx={{ width: "160px", backgroundColor: "#fafafa" }}
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{ marginTop: "4px" }}
            >
              Item por pagina
            </InputLabel>

            <Select name="Number" onChange={(evt) => changeNumPage(evt)}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </div>
        {currentItems && (
          <TableMui
            datos={currentItems}
            header={header}
            nameRow={nameRow}
            sx={sx}
          />
        )}
      </div>
      <div
        className={!IsMobi() && "d-flex justify-content-center "}
        style={{ display: "solid", overflowX: "auto", marginTop: "16px" }}
      >
        <ReactPaginate
          nextLabel="Siguiente >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={IsMobi() ? 2 : 5}
          pageCount={pageCount}
          forcePage={pageSelected}
          previousLabel="< Anterior"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};
function TableMui({ datos, header, nameRow, sx = {} }) {
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 500 }}>
          <Table>
            <TableHead
              className="headTable"
              style={{
                position: "sticky",
                top: "-10px",
                backgroundColor: "rgb(184, 184, 184)",
              }}
              sx={sx}
            >
              <TableRow>
                {header.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {datos.map((item) => (
                <TableRow>
                  {nameRow.map((row, index) => (
                    <TableCell
                      align={index > 3 && index < 8 ? "center" : "left"}
                    >
                      {item[row]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default StockPerUbication;
