import { Box } from "@mui/material";
import { Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputText } from "../../../components/Input";

export const CardMotivoAjusteStock = ({ name, onclick }) => {
  return (
    <>
      <Box className="col-9 col-md-6 col-lg-5 col-xl-4" sx={{ p: 1 }}>
        <Field
          component={InputText as any}
          name={`${name}.stockMotivoAjusteDescripcion`}
          label={"Descripcion "}
        />
      </Box>

      <Box className="col-3" sx={{ px: 1, pt: 0.5 }}>
        <Button label="Guardar cambios" onClick={() => onclick()} />
      </Box>
    </>
  );
};
