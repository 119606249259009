import { Box } from "@mui/material";
import { Field } from "react-final-form";
import { InputNumber, InputDate } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { fechaActual } from "../../../utils/Fecha";
import {
  required,
  composeValidators,
  onlyLength,
} from "../../../utils/validations";

export const FieldForm = ({
  values,
  listProvider,
  listDetailsOC,
  listDeposito,
  listUbicationProvider,
  setInitialValues,
}) => (
  <Box className="d-flex flex-wrap" sx={{ width: "100%" }}>
    <Box className="col-12 col-sm-4 col-md-4 col-lg-1 col-xl-1 p-2">
      <Field
        focused={!values.serie}
        name="serie"
        validate={composeValidators(required, onlyLength(4))}
        label={"Serie"}
        placeholder="0000"
        component={InputNumber as any}
      />
    </Box>
    <Box className="col-12 col-sm-6 col-md-6 col-lg-2 col-xl-2 p-2">
      <Field
        name="remito"
        validate={required as any}
        label="Numero de remito"
        component={InputNumber as any}
      />
    </Box>
    <Box className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-2">
      <Field
        name="proveedor"
        validate={required as any}
        label="Proveedor"
        component={SelectMui as any}
        options={listProvider.map((item) => ({
          value: item.entidadDescripcion,
          name: item.entidadDescripcion,
        }))}
        sx={{ width: "100%" }}
      />
    </Box>
    <Box className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-2">
      <Field
        name="OC"
        label="Orden de compra"
        validate={required as any}
        component={SelectMui as any}
        options={FiltrarOC(values, listDetailsOC).map((item) => ({
          value: item.comprobanteId,
          name: item.comprobanteNumero,
        }))}
        onchange={(evtValue) => {
          setInitialValues({
            ...values,
            OC: evtValue,
            datos: listDetailsOC.filter(
              (item) => item.comprobanteId === evtValue
            ),
          });
        }}
        sx={{ width: "100%" }}
      />
    </Box>
    <Box className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-2">
      <Field
        label="Fecha de entrega"
        name="fecha"
        validate={required as any}
        defaultValue={fechaActual()}
        component={InputDate as any}
        max={fechaActual()}
      />
    </Box>
    <Box className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-2">
      <Field
        name="deposito"
        label="Deposito"
        validate={required as any}
        component={SelectMui as any}
        options={listDeposito.map((item) => ({
          value: item.entidadDescripcion,
          name: item.entidadDescripcion,
        }))}
        sx={{ width: "100%" }}
      />
    </Box>
    <Box className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 p-2">
      <Field
        name="ubicacion"
        label="Ubicacion"
        validate={required as any}
        component={SelectMui as any}
        options={listUbicationProvider
          .filter((list) => list.entidadDescripcion === values.deposito)
          ?.map((item) => ({
            value: item.stockUbicacionId,
            name: item.stockUbicacionDescripcion,
          }))}
        sx={{ width: "100%" }}
      />
    </Box>
  </Box>
);
const FiltrarOC = (values, array) => {
  const data = array.filter(
    (item) =>
      values.proveedor === item.entidadDescripcion &&
      item.tipoComprobanteDescripcion === "Orden de Compra al Proveedor"
  );
  var hash = {};
  const datos = data.filter(function (current) {
    var exists = !hash[current.comprobanteId];
    hash[current.comprobanteId] = true;
    return exists;
  });

  return datos;
};
