import { Grid, Typography, Box } from "@mui/material";
import { style } from "../style";
import { MostrarFecha } from "./MostrarFecha";

export const Fila = ({
  datos = [],
  nameObj = null,
  sx = {},
  mostrarPorcentaje = false,
}) => {
  return (
    <Grid
      container
      sx={{
        ...sx,
        borderTop: style.tabla,
      }}
    >
      {datos?.map((item, index) => (
        <>
          {!nameObj ? (
            <Grid
              xs={index > 0 ? 1 : 2}
              sx={{
                border: "solid 1px black",

                p: 1,
              }}
            >
              <Typography variant="subtitle2">{item}</Typography>
            </Grid>
          ) : (
            nameObj?.map((name, index2) => (
              <Grid
                xs={index2 > 0 ? 1 : 2}
                sx={{
                  display: "flex",
                  alignItems: "center",

                  p: 1,
                }}
              >
                {mostrarPorcentaje ? (
                  <>
                    {index2 >= 2 && index2 !== 4 && index2 !== 7
                      ? index2 < 8
                        ? `$${item[name]}`
                        : `${item[name]}%`
                      : index2 === 4 || index2 === 7
                      ? `${Number(item[name])} Hs`
                      : item[name]}
                    {index2 >= 8 && <MostrarFecha porcentaje={item[name]} />}
                  </>
                ) : (
                  <Box>
                    <Typography variant="subtitle2">{item[name]}</Typography>
                  </Box>
                )}
              </Grid>
            ))
          )}
        </>
      ))}
    </Grid>
  );
};
