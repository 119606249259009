import { Box } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { BsXLg as X } from "react-icons/bs";
export const IconoSubirImagen = ({
  img,
  funcionEliminar,
  dashabilitarEliminar,
  mostrarEliminar,
  setAmpliarImagen,
}) => {
  return (
    <Box>
      <Box
        sx={{
          background: "#FFFFFF",

          border: "1px solid #797979",
          boxSizing: "border-box",

          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box sx={{ display: "flex", flexWrap: "wrap", p: 1 }}>
          {mostrarEliminar && (
            <Box
              className="col-6"
              sx={{ display: "flex", justifyContent: "start" }}
            >
              <FullscreenIcon
                style={{ cursor: "pointer" }}
                onClick={() => setAmpliarImagen()}
              />
            </Box>
          )}
          {mostrarEliminar && !dashabilitarEliminar && (
            <Box
              className="col-6"
              sx={{ display: "flex", justifyContent: "end", mt: 0.5 }}
            >
              <X
                style={{ cursor: "pointer" }}
                onClick={() => funcionEliminar()}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            padding: "8px 48px 24px 48px",
          }}
        >
          <img
            src={img}
            alt=""
            style={{ cursor: "pointer", maxWidth: "100px", maxHeight: "150px" }}
          ></img>
        </Box>
      </Box>
    </Box>
  );
};
