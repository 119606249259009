import { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box, Skeleton } from "@mui/material";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";
import { Form } from "react-final-form";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import Button from "../../components/Button";
import { useCotizacion, useMonedas } from "../../hooks/useCotizacion";
import CardCotizacion from "./components/cardCotizacion";
import { saveInfoCotizacion } from "./hook";
import FormAgregarNuevaCotizacion from "./components/formAgregarNuevaCotizacion";
const AdminCotizacion = () => {
  const [showModalNuevaCotizacion, setShowModalNuevaCotizacion] =
    useState(false);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data: listCotizacion,
    isLoading: isLoadingListCotizacion,
    isError: isErrorListCotizacion,
    refetch,
  } = useCotizacion();
  const {
    data: listMonedas,
    isLoading: isLoadingListMonedas,
    isError: isErrorListMonedas,
  } = useMonedas();

  if (isErrorListCotizacion || isErrorListMonedas) {
    return <ErrorMsg />;
  }
  return (
    <Layout title={"Administracion de cotización"} clase={false}>
      {!isLoadingListCotizacion || !isLoadingListMonedas ? (
        <Form
          onSubmit={(values) => {}}
          initialValues={{ datos: listCotizacion }}
          mutators={{
            ...arrayMutators,
          }}
          validate={(values) => {
            return {};
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push },
              restart,
            },
            submitting,
            pristine,
            values,
          }) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  p: 2,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                  <Button
                    onClick={() => setShowModalNuevaCotizacion(true)}
                    label={"Agregar una nueva cotización"}
                    color="success"
                  />
                </Box>

                <FieldArray name="datos">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <Box
                        className="col-12"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <CardCotizacion
                          name={name}
                          onclick={() =>
                            saveInfoCotizacion(
                              values?.datos[index],
                              setAlertCustom,
                              refetch
                            )
                          }
                          disabled={
                            values?.datos[index]?.cotizacionValor ===
                              listCotizacion[index]?.cotizacionValor &&
                            values?.datos[index]?.cotizacionFecha ===
                              listCotizacion[index]?.cotizacionFecha
                          }
                        />
                      </Box>
                    ))
                  }
                </FieldArray>
              </Box>
            );
          }}
        />
      ) : (
        <Box className="col-11">
          <Skeleton variant="text" />

          <Skeleton variant="rectangular" width={""} height={300} />
        </Box>
      )}
      <DialogGen
        open={showModalNuevaCotizacion}
        title={"Agregar nueva Cotización"}
        onClick={() => setShowModalNuevaCotizacion(false)}
        sx={{ maxWidth: "500px" }}
      >
        <FormAgregarNuevaCotizacion
          setAlertCustom={setAlertCustom}
          refetch={refetch}
          listCotizacion={listCotizacion}
          listMonedas={listMonedas}
        />
      </DialogGen>

      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </Layout>
  );
};
export default AdminCotizacion;
