import { Button, Card, Box, Typography } from "@mui/material";

const CardStyle = ({
  img,
  href,
  name,
  onclick = null,
  descripcion = null,
  enContruccion = false,
}) => (
  <a
    style={{ borderRadius: "16px", textDecoration: "none" }}
    href={href}
    onClick={onclick ? onclick : () => {}}
    className="col-6 col-sm-5 col-md-4 col-lg-3 col-xl-2 card"
  >
    <img src={img} alt="" />
    <div>
      <Typography
        variant="h5"
        className="d-flex justify-content-center"
        style={{ textAlign: "center" }}
      >
        {name}
      </Typography>
      {!!enContruccion && (
        <Typography color="red">- EN CONSTRUCCIÓN -</Typography>
      )}
      {!!descripcion && <Typography>{descripcion}</Typography>}
    </div>
  </a>
);

export default CardStyle;
