import { UpdateConversionUnidadMedida } from "../../services/conversionUnidadMedidaServices";

export const saveInfoConversionUnidadMedida = (body, setAlertCustom, refetch, refrescar = null) => {
  UpdateConversionUnidadMedida(body)
    .then(() => {
      setAlertCustom({
        open: true,
        title: "Confirmacion",
        severity: "success",
        message: "Se guardo correctamente",
      });
      refrescar && refrescar();
      refetch();
    })
    .catch((error) => {
      setAlertCustom({
        open: true,
        title: "Error",
        severity: "error",
        message: error?.message,
      });
    });
 
}
