import { Box } from "@mui/system";
import { Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputText } from "../../../components/Input";
import { saveProductsSupplier } from "../../suppliers/hook/saveProductsSupplier";

export const CardSupplierMobi = ({
  nameField,
  setShowModalProductsSuppliers,
  setSupplier,
  saveDataFunction,
  huboCambios,
}) => {
  return (
    <>
      <Box sx={{ p: 1 }}>
        <Field
          name={`${nameField}.entidadCuit`}
          component={InputText as any}
          label={"CUIT "}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          name={`${nameField}.entidadRazonSocial`}
          component={InputText as any}
          label={"Razon Social "}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          name={`${nameField}.entidadDescripcion`}
          component={InputText as any}
          label={"Nombre de empresa"}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          name={`${nameField}.entidadContactoNombre`}
          component={InputText as any}
          label={"Nombre"}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          name={`${nameField}.entidadContactoApellido`}
          component={InputText as any}
          label={"Apellido "}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          name={`${nameField}.entidadContactoTelefono`}
          component={InputText as any}
          label={"Telefono "}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          name={`${nameField}.entidadContactoMail`}
          component={InputText as any}
          validate={(value) =>
            value
              ? "Requerido"
              : !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
              ? "correo invalido"
              : undefined
          }
          label={"Mail"}
        />
      </Box>
      <Box sx={{ p: 1 }}>
        <Field
          name={`${nameField}.entidadTipoDescripcion`}
          component={InputText as any}
          label={"Tipo"}
        />
        <Box sx={{ display: "flex", justifyContent: "end", mt: 1 }}>
          <Button
            label={"Asignar producto"}
            onClick={() => {
              setShowModalProductsSuppliers(true);
              setSupplier();
            }}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", p: 1, mt: 2 }}>
          <Button
            disabled={!huboCambios}
            label={"Guardar Cambios"}
            color="success"
            onClick={() => saveDataFunction()}
          />
        </Box>
      </Box>
    </>
  );
};
