import { Box, Typography } from "@mui/material";
import { Field } from "react-final-form";
import { InputText } from "../../../components/Input";

export const HorasDeTrabajo = ({ name, nameUser, userDescripcion }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        width: "100%",
        background: "#FFFFFF",
        py: 2,
        px: 2,
        mt: 2,
      }}
    >
      <Box className="col-2 col-xl-1" sx={{ p: 1 }}>
        <Typography variant={"h3"} sx={{ mb: 1 }}>
          Usuario
        </Typography>
        <Typography variant={"body1"}>{nameUser}</Typography>
      </Box>
      <Box className="col-2 col-xl-1" sx={{ p: 1 }}>
        <Typography variant={"h3"} sx={{ mb: 1 }}>
          {" "}
          Grupo
        </Typography>
        <Typography variant={"body1"}>{userDescripcion}</Typography>
      </Box>
      <Box className="col-2" sx={{ p: 1 }}>
        <Field
          disabled
          name={`${name}.ordentrabajoFechaInicio`}
          component={InputText as any}
          sx={{ maxWidth: "300px", width: "100%" }}
          label={"Empezó"}
        />
      </Box>
      <Box className="col-2" sx={{ p: 1 }}>
        <Field
          disabled
          name={`${name}.ordentrabajoFechaFin`}
          component={InputText as any}
          sx={{ maxWidth: "300px", width: "100%" }}
          label={"Terminó"}
        />
      </Box>
      <Box className="col-2" sx={{ p: 1 }}>
        <Field
          disabled
          name={`${name}.ordenTrabajoUsuarioAuditoriaDuracionSegundos`}
          component={InputText as any}
          sx={{ maxWidth: "300px", width: "100%" }}
          label={"Duración"}
        />
      </Box>
      <Box className="col-2" sx={{ p: 1 }}>
        <Field
          disabled
          name={`${name}.ordenTrabajoMotivoPausaDescripcion`}
          component={InputText as any}
          sx={{ maxWidth: "300px", width: "100%" }}
          label={"Motivo"}
        />
      </Box>
    </Box>
  );
};
export default HorasDeTrabajo;
