export const nameDataTabla = {
  nameHeader: [
    "Orden de Pedido",
    "Cliente",
    "OTG asignada",
    "Estado",
    "Fecha de OP",
    "Código",
    "Nombre",
    "Cantidad",
    "Cantidad producidas",
    "Cantidad restante",
    "Precio Unitario",
    "Fecha de Creación",
    "Fecha de Entrega",
    "Provee tela",
    "Observación",
    "Usuario",
    "Plano aprobado",
    "Embalaje",
  ],
  nameRow: [
    "comprobanteNumero",
    "entidadDescripcion",
    "ordenTrabajoId",
    "estado",
    "fechaCreacion",
    "skuCodigo",
    "skuDescripcion",
    "comprobanteDetalleCantidad",
    "ordenTrabajoCantidadProducida",
    "cantidadRestante",
    "comprobanteDetallePrecioUnitario",
    "fechaCreacion",
    "fechaEntregaPedido",
    "comprobanteDetalleTelaProvistaPorCliente",
    "comprobanteObservacion",
    "nombreUsuario",
    "comprobanteDetallePlanoAprobado",
    "embalajeDescripcion",
  ],
};
