import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { IsMobi } from "../../../hooks/isMobi";
import { InputNotForm } from "../../../components/Input";

import { Table } from "./tabla";

function TableInfo({
  datos,
  ImgProducts,
  changeRadioButton,
  refetch,
  permisos,
  listUsers,
  measures,
}) {
  // We start with an empty list of items.
  const [showComponent, setShowComponent] = React.useState({
    descrip: true,
    cod: true,
    lead: true,
    mbq: true,
  });
  const [pageSelected, setPageSelected] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);

  const [items, setItems] = useState(datos);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const changeNumPage = (evt) => {
    setItemOffset(0);
    setPageSelected(0);
    setItemsPerPage(evt.target.value);
  };
  const isNumber = (valor) => {
    if (Number(valor)) {
      return Number(valor);
    }
    return valor;
  };
  const OrderGen = (array, orderBy, direction, number = false) => {
    let i = direction === "asc" ? 1 : -1;
    if (number) {
      array.sort((a, b) =>
        isNumber(a[orderBy]) > isNumber(b[orderBy]) ? i : -1 * i
      );
    } else {
      array.sort((a, b) =>
        a[orderBy].toUpperCase() > b[orderBy].toUpperCase() ? i : -1 * i
      );
    }
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  let dataFilter;

  const changeSearch = (evt) => {
    setItemOffset(0);
    setPageSelected(0);
    if (evt.target.value !== "") {
      console.log(evt.target.value);
      dataFilter = datos.filter((item) =>
        Object.values(item).find(
          (valor) =>
            !!valor &&
            valor
              .toString()
              .toUpperCase()
              .includes(evt.target.value.toUpperCase())
        )
      );
    } else {
      dataFilter = datos;
    }
    setItems(dataFilter);
  };
  useEffect(() => {
    setItems(datos);
  }, [datos]);
  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items, datos]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setPageSelected(event.selected);
    setItemOffset(newOffset);
  };

  return (
    <>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between mb-2">
          <FormControl
            sx={{ width: "160px", backgroundColor: "#fafafa" }}
            size="small"
          >
            <InputLabel
              id="demo-simple-select-autowidth-label"
              sx={{ marginTop: "4px" }}
            >
              Item por pagina
            </InputLabel>

            <Select name="Number" onChange={(evt) => changeNumPage(evt)}>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Filtro</FormLabel>
            <RadioGroup defaultValue="1" onChange={changeRadioButton}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="Activos"
                />

                <FormControlLabel
                  value="0"
                  control={<Radio />}
                  label="Inactivos"
                />
                <FormControlLabel
                  value="todos"
                  control={<Radio />}
                  label="Todos"
                />
              </Box>
            </RadioGroup>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#fafafa",
            }}
          >
            <InputNotForm
              onchange={(evt) => changeSearch(evt)}
              label="Buscar"
            />
          </Box>
        </div>
        {currentItems && (
          <Table
            rows={currentItems}
            products={items}
            showComponent={showComponent}
            OrderGen={OrderGen}
            ImgProducts={ImgProducts}
            items={items}
            setShowComponent={setShowComponent}
            refetch={refetch}
            permisos={permisos}
            listUsers={listUsers}
            measures={measures}
          />
        )}
        <div
          className={!IsMobi() && "d-flex justify-content-center "}
          style={{ display: "solid", marginTop: "16px" }}
        >
          <ReactPaginate
            nextLabel="Siguiente >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={IsMobi() ? 2 : 5}
            pageCount={pageCount}
            forcePage={pageSelected}
            previousLabel="< Anterior"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
}

export default TableInfo;
