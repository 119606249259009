import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { Box, InputAdornment, Skeleton, Typography } from "@mui/material";
import { useListadoEtiquetaCalidad } from "../../../hooks/moduloCalidad";
import { Field, Form } from "react-final-form";
import { CardEtiquetaCalidad } from "./cardEtiquetaCalidad";
import { InputText } from "../../../components/Input";
import Button from "../../../components/Button";
import { SaveInformeModuloCalidad } from "../../../services/moduloCalidad";

const ModalEtiqueta = ({
  datos,
  cerrarModal,
  setAlertCustom,
  refetchListadoCalidad,
}) => {
  const {
    data: listadoEtiquetaCalidad,
    isLoading: isLoadingListadoEtiquetaCalidad,
  } = useListadoEtiquetaCalidad(datos.procesoProductivoId);
  if (isLoadingListadoEtiquetaCalidad) {
    return (
      <Box>
        <Skeleton variant="text" />

        <Skeleton variant="rectangular" width={210} height={118} />
      </Box>
    );
  }
  return (
    <Form
      onSubmit={(values) => {
        console.log(values);
        const body = {
          detalle_etiqueta_calidad: values.datos.map((item) => {
            return {
              etiqueta_calidad_id: Number(
                item?.procesoProductivoEtiquetaCalidadId
              ),
              etiqueta_calidad_valor: Number(item?.valor),
            };
          }),
        };
        SaveInformeModuloCalidad(body, datos.stockId)
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardó correctamente",
            });

            cerrarModal();
            refetchListadoCalidad();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{
        datos: listadoEtiquetaCalidad.filter(
          (x) => x.procesoProductivoEtiquetaCalidadActivo === "1"
        ),
      }}
      render={({
        handleSubmit,
        submitting,
        pristine,
        values,
        invalid,
        form: {
          restart,
          mutators: { push },
        },
      }) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                background: "rgba(27, 137, 172, 0.71)",
                border: "2px solid #000000",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                minWidth: "140px",
                p: 1,
                mx: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "23px",
                  color: "white",
                }}
              >
                {`OTG : ${datos.ordenTrabajoId ? datos.ordenTrabajoId : ""}`}
              </Typography>
            </Box>
            <Box
              sx={{
                background: "rgba(27, 137, 172, 0.71)",
                border: "2px solid #000000",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                minWidth: "140px",
                p: 1,
                mx: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "23px",
                  color: "white",
                }}
              >
                {`Producto : ${
                  datos.skuDescripcion ? datos.skuDescripcion : ""
                }`}
              </Typography>
            </Box>
            <Box
              sx={{
                background: "rgba(27, 137, 172, 0.71)",
                border: "2px solid #000000",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                minWidth: "140px",
                p: 1,
                mx: 1,
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "23px",
                  color: "white",
                }}
              >
                {`${datos.procesoProductivo ? datos.procesoProductivo : ""}`}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "start", px: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Raleway",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "24px",
                  lineHeight: "28px",
                }}
              >
                PUNTOS A REVISAR
              </Typography>
            </Box>
            {values?.datos?.length > 0 ? (
              <FieldArray name="datos">
                {({ fields }) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      minHeight: 300,
                    }}
                  >
                    {fields.map((name, index) => (
                      <Box
                        className="col-12"
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          mt: 1,
                        }}
                      >
                        <CardEtiquetaCalidad
                          name={name}
                          label={
                            values.datos[index]
                              .procesoProductivoEtiquetaCalidadDescripcion
                          }
                        />
                      </Box>
                    ))}
                  </Box>
                )}
              </FieldArray>
            ) : (
              <Typography variant="body2">
                este proceso productivo no tiene etiquetas de control de calidad
              </Typography>
            )}
            <Box sx={{ mt: 2 }}>
              <Field
                component={InputText as any}
                name={"comentario"}
                propsInput={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="body2"> COMENTARIO:</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              mt: 2,
              mb: 1,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              disabled={invalid}
              label={"Confirmar"}
              color="success"
              onClick={handleSubmit}
            />
            <Button
              label={"Cancelar"}
              color="error"
              sx={{ ml: 5 }}
              onClick={cerrarModal}
            />
          </Box>
        </Box>
      )}
    />
  );
};
export default ModalEtiqueta;
