import { Field, Form } from "react-final-form";
import { Box, Typography } from "@mui/material";
import Button from "../Button";
import { required } from "../../utils/validations";
import { AutoComplete } from "../AutoComplete";

import { InputNumber } from "../Input";

export const FormularioAgregarMaterial = ({
  push,
  datosFormPrincipal,
  nameKeyArray = "Formula",
  setShowAgregarMaterial,
  products,
  otg,
  sx = {},
}) => {
  return (
    <Form
      onSubmit={(values) => {
        const NuevaFormula = {
          sku_Id: values.sku.id,
          unidad_medida_id: products?.find(
            (item) => item?.skuId === values?.sku?.id
          )?.unidadMedidaId,
          merma: values.merma,
          cantidad: values.cantidad,
          ordenTrabajoFormulaMermaPorcentaje: values.merma,
          ordenTrabajoFormulaCantidad: values.cantidad,
          skuDescripcion: values.sku.name,
          skuCodigo: values.sku.label,
          esNuevo: true,
        };
        push(NuevaFormula);
        setShowAgregarMaterial(false);
      }}
      initialValues={{
        sku: {},
        formulaCantidad: 0,
        ordenTrabajoFormulaMermaPorcentaje: 0,
      }}
      render={({ handleSubmit, submitting, pristine }) => (
        <Box
          sx={{
            m: 1,
            p: 1,
            background: "#FFFFFF",
            ...sx,
          }}
        >
          <Field
            validate={required}
            name="sku"
            component={AutoComplete as any}
            sx={{ maxWidth: "300px" }}
            options={products
              .filter(
                (producto) =>
                  !datosFormPrincipal[nameKeyArray].find(
                    (item2) =>
                      item2.skuCodigo === producto.skuCodigo ||
                      otg.skuCodigo === producto.skuCodigo
                  )
              )

              .map((item) => {
                return {
                  id: item.skuId,
                  label: item.skuCodigo,
                  name: item.skuDescripcion,
                };
              })}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <Box sx={{ pr: 0.5 }}>
              <Field
                validate={(valor) =>
                  valor > 0 ? undefined : "Tiene que ser mayor a 0"
                }
                component={InputNumber as any}
                name={"cantidad"}
                label={"Consumo por unidad"}
              />
            </Box>
            <Box sx={{ pl: 0.5 }}>
              <Field
                validate={(valor) =>
                  valor > 0 ? undefined : "Tiene que ser mayor a 0"
                }
                component={InputNumber as any}
                name={"merma"}
                label={"Merma %"}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button
              label={"Cancelar"}
              onClick={() => setShowAgregarMaterial(false)}
            />
            <Button
              label={"Confirmar"}
              onClick={handleSubmit}
              disabled={pristine || submitting}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="caption">
              *esta accion solo cambiara la formula de esta OTG
            </Typography>
          </Box>
        </Box>
      )}
    />
  );
};
