import { useQuery } from "react-query";
import { GetEmbalaje } from "../services/embalajeServices";
export const useEmbalaje = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [`embalaje`],
    async () => {
      const response = await GetEmbalaje();
      const responseSinGuion = response?.data?.filter(
        (item) => item.embalajeId !== "1"
      );
      const datos = responseSinGuion.map((item) => {
        return {
          ...item,
          usuario: `${item?.nombre} ${item?.apellido}`,
        };
      });

      return datos;
    }
  );

  return {
    data: data,
    isLoading,
    isError,
    refetch,
  };
};
