import { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box, Skeleton } from "@mui/material";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";
import { Form } from "react-final-form";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import Button from "../../components/Button";
import { useMonedas } from "../../hooks/useCotizacion";
import CardMoneda from "./components/cardMoneda";
import { saveInfoMoneda } from "./hook";
import FormAgregarNuevaMoneda from "./components/formAgregarNuevaMoneda";
const AdminMoneda = () => {
  const [showModalNuevaMoneda, setShowModalNuevaMoneda] = useState(false);
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data: listMonedas,
    isLoading: isLoadingListMonedas,
    isError: isErrorListMonedas,
    refetch,
  } = useMonedas();

  if (isErrorListMonedas) {
    return <ErrorMsg />;
  }

  return (
    <Layout title={"Administracion de moneda"} clase={false}>
      {!isLoadingListMonedas ? (
        <Form
          onSubmit={(values) => {}}
          initialValues={{ datos: listMonedas }}
          mutators={{
            ...arrayMutators,
          }}
          validate={(values) => {
            return {};
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push },
              restart,
            },
            submitting,
            pristine,
            values,
          }) => {
            return (
              <Box
                sx={{
                  width: "100%",
                  p: 2,
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
                  <Button
                    onClick={() => setShowModalNuevaMoneda(true)}
                    label={"Agregar una nueva moneda"}
                    color="success"
                  />
                </Box>

                <FieldArray name="datos">
                  {({ fields }) =>
                    fields.map((name, index) => (
                      <Box
                        className="col-12"
                        sx={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <CardMoneda
                          name={name}
                          onclick={() =>
                            saveInfoMoneda(
                              values?.datos[index],
                              setAlertCustom,
                              refetch
                            )
                          }
                          disabled={
                            values?.datos[index]?.monedaOrden ===
                              listMonedas[index]?.monedaOrden &&
                            values?.datos[index]?.monedaSigno ===
                              listMonedas[index]?.monedaSigno &&
                            values?.datos[index]?.monedaDescripcion ===
                              listMonedas[index]?.monedaDescripcion
                          }
                        />
                      </Box>
                    ))
                  }
                </FieldArray>
              </Box>
            );
          }}
        />
      ) : (
        <Box className="col-11">
          <Skeleton variant="text" />

          <Skeleton variant="rectangular" width={""} height={300} />
        </Box>
      )}
      <DialogGen
        open={showModalNuevaMoneda}
        title={"Agregar nueva moneda"}
        onClick={() => setShowModalNuevaMoneda(false)}
        sx={{ maxWidth: "500px" }}
      >
        <FormAgregarNuevaMoneda
          setAlertCustom={setAlertCustom}
          refetch={refetch}
          listMonedas={listMonedas}
        />
      </DialogGen>

      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </Layout>
  );
};
export default AdminMoneda;
