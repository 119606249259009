import { Stack, Chip } from "@mui/material";

export const ChipMui = ({
  input: { value, onChange },
  handleDelete,
  disabled = false,
  sx = {},
}) => {
  return (
    <Stack sx={{ ...sx }}>
      <Chip
        label={value}
        variant="outlined"
        onDelete={() => handleDelete()}
        disabled={disabled}
      />
    </Stack>
  );
};
