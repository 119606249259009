import { Box, Typography } from "@mui/material";
import { Form, Field } from "react-final-form";
import Button from "../../../components/Button";
import { CheckBoxMuiTrueOrFalse } from "../../../components/CheckBoxMui";
import { InputText } from "../../../components/Input";
import { PostProcesosProductivoArea } from "../../../services/productServices";

const FormNewAreaProcesoProductivo = ({
  procesosTipoId,
  setAlertCustom,
  RefetchProcesosTipoId,
}) => {
  return (
    <Form
      onSubmit={(values) => {
        PostProcesosProductivoArea({
          ...values,
          procesoProductivoTipoActivo: values?.procesoProductivoTipoActivo
            ? 1
            : 0,
        })
          .then(() => {
            setAlertCustom({
              open: true,
              title: "Confirmacion",
              severity: "success",
              message: "Se guardo correctamente",
            });
            RefetchProcesosTipoId();
          })
          .catch((error) => {
            setAlertCustom({
              open: true,
              title: "Error",
              severity: "error",
              message: error?.message,
            });
          });
      }}
      initialValues={{ procesoProductivoTipoActivo: true }}
      render={({
        handleSubmit,
        form: { restart },
        submitting,
        pristine,
        values,
      }) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <Box className="col-9" sx={{ pr: 1 }}>
              <Field
                component={InputText as any}
                name={"procesoProductivoTipoDescripcion"}
                label={"Descripcion"}
              />
            </Box>
            <Box
              className="col-3"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ pr: 1 }}>Activo</Typography>

              <Field
                component={CheckBoxMuiTrueOrFalse as any}
                name={"procesoProductivoTipoActivo"}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
              }}
              className="col-12"
            >
              <Button
                label={"Crear Nueva Área"}
                onClick={handleSubmit}
                sx={{ minWidth: "150px" }}
                color="success"
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};
export default FormNewAreaProcesoProductivo;
