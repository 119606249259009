import { useQuery } from 'react-query';
import { GetListprovider } from "../services/productServices";
import { GetListProductsSuppliers, GetListTypeSuppliers } from '../services/supplierServices';


export const useSuppliers = (todo = false) => {
  const { data, isLoading, isError, isSuccess, isIdle, refetch } = useQuery('provider', async () => {
      
    const response = await GetListprovider();
    let datos = [];
    for (let x = 0; x < response.data.length + 1; x++) {
      if (x === 0) {
        if (response.data[x].entidadId !== "1") {
          datos.push({
            entidadDescripcion: "Todos",
            entidadId: "0",
          })
        }
      } else {
        datos.push(response.data[x - 1])
      }
  
    }
    if (todo) {

      return datos;
    }
    return response.data;
    
  });
    
  return {
    data: data,
    isLoading,
    isError,
    isSuccess, isIdle,
    refetch
  }
}
export const useTypeSuppliers =  () => {
    const { data, isLoading, isError , isSuccess} = useQuery('TypeSuppliers', async () => {    
      const response = await GetListTypeSuppliers();       
      return response.data;
    }); 
    
    return { 
      data: data,
      isLoading,
      isSuccess,
      isError 
     }
}
export const useProductsSupplier =  (id) => {
    const { data, isLoading, isError , isSuccess,refetch} = useQuery(`ProductsSupplier${id}`, async () => {    
      const response = await GetListProductsSuppliers(id);       
      return response.data;
    }); 
    
    return { 
      data: data,
      isLoading,isSuccess,refetch,
      isError 
     }
}