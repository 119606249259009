import {
  TableContainer,
  Paper,
  Table as TableMui,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";

import { AddDataTable } from "./addDataTable";
export const Table = ({ rows, headerName, rowName, onClick }) => {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 700,
          borderRadius: 5,
          boxShadow: "5px 1px 10px 1px rgba(122,122,122,0.49)",
        }}
      >
        <TableMui
          aria-label="collapsible table"
          sx={{
            background: "rgba(250, 250, 250, 0.5)",
          }}
        >
          <TableHead style={{ backgroundColor: "rgba(0, 0, 0, 0.49)" }}>
            <TableRow>
              {headerName.map((item) => (
                <TableCell>
                  <Typography variant="h3" sx={{ color: "white" }}>
                    {item}
                  </Typography>
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: "rgba(0, 0, 0, 0.08)" }}>
            {rows.map((row) => (
              <AddDataTable
                key={row.name}
                row={row}
                rowName={rowName}
                onClick={onClick}
              />
            ))}
          </TableBody>
        </TableMui>
      </TableContainer>
    </>
  );
};
