import { Card, Box } from "@mui/material";
import { convertDateDDMMAAAA } from "../../../utils/Fecha";
import { StyleBoxModal } from "./styleBoxModal";

export const VistPreviaDatos = ({
  values,
  listDetailsOC,
  listUbicationProvider,
  listStockState,
  measures,
}) => (
  <>
    <Card
      sx={{
        display: "flex",
        flexWrap: "wrap",
        mb: 1,
        p: 1,
        backgroundColor: "rgba(0,0,0,0.10)",
      }}
    >
      <StyleBoxModal
        label={"Remito:"}
        dato={`${values?.values?.serie} - ${values?.values?.remito}`}
      />
      <StyleBoxModal label={"proovedor:"} dato={values?.values?.proveedor} />
      <StyleBoxModal
        label={"Orden de compra:"}
        dato={
          listDetailsOC.find(
            (item) => item.comprobanteId === values?.values?.OC
          )?.comprobanteNumero
        }
      />
      <StyleBoxModal
        label={"Fecha de entrega:"}
        dato={convertDateDDMMAAAA(values?.values?.fecha)}
      />
      <StyleBoxModal label={"Deposito:"} dato={values?.values?.deposito} />
      <StyleBoxModal
        label={"Ubicacion:"}
        dato={
          listUbicationProvider.find(
            (item) => item.stockUbicacionId === values?.values?.ubicacion
          )?.stockUbicacionDescripcion
        }
      />
      <StyleBoxModal
        label={"Estado:"}
        dato={
          listStockState.find(
            (item) => item.stockEstadoId === values?.values?.estado
          )?.stockEstadoDescripcion
        }
      />
    </Card>
    <Box sx={{ maxHeight: 280, overflowY: "auto" }}>
      {values?.values?.datos?.map(({ lotes, ...item }) => (
        <Card
          sx={{
            display: "flex",
            flexWrap: "wrap",
            boxShadow: "1px 1px 5px 1px grey",
            m: 1,
            p: 1,
          }}
        >
          <StyleBoxModal label={"Codigo:"} dato={item.skuCodigo} />
          <StyleBoxModal label={"Nombre:"} dato={item.skuDescripcion} />
          <StyleBoxModal
            label={"Cantidad original:"}
            dato={Number.parseFloat(item.comprobanteDetalleCantidad).toFixed(
              measures.find((m) => m.unidadMedida === item.unidadMedida)
                ?.unidadMedidaDecimales
            )}
          />
          <StyleBoxModal
            label={"Cantidad recibida:"}
            dato={Number.parseFloat(
              item.comprobanteDetalleCantidadRecibida
            ).toFixed(
              measures.find((m) => m.unidadMedida === item.unidadMedida)
                ?.unidadMedidaDecimales
            )}
          />
          <StyleBoxModal
            label={"Cantidad restante:"}
            dato={Number.parseFloat(
              item.comprobanteDetalleCantidadSaldo
            ).toFixed(
              measures.find((m) => m.unidadMedida === item.unidadMedida)
                ?.unidadMedidaDecimales
            )}
          />
          <StyleBoxModal
            label={"Cantidad a recibir:"}
            dato={Number.parseFloat(
              item.comprobanteDetalleCantidadEntregada
            ).toFixed(
              measures.find((m) => m.unidadMedida === item.unidadMedida)
                ?.unidadMedidaDecimales
            )}
          />

          <StyleBoxModal label={"Unidad medida:"} dato={item.unidadMedida} />

          {lotes &&
            lotes.length > 0 &&
            lotes.map((lote, index) => (
              <StyleBoxModal
                label={`Lote ${index + 1}: ${lote.lote}`}
                dato={Number.parseFloat(lote.cantidad).toFixed(
                  measures.find((m) => m.unidadMedida === item.unidadMedida)
                    ?.unidadMedidaDecimales
                )}
              />
            ))}
        </Card>
      ))}
    </Box>
  </>
);
