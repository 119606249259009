import Layout from "../../components/Layout";
import CardStyle from "../../components/CardStyle";
import stockProduct from "../../images/stock/StockPorProducto.png";
import { verificarPermiso } from "../../utils/verificarPermiso";
import { PERMISOS } from "../../constants/MenuOptions";
import ErrorMsg from "../../components/ErrorMsg";
import { getUser, usePermisos } from "../../hooks/auth";
import { Loading } from "../loading";
import { useListUsers } from "../../hooks/useUsers";
import { DialogGen } from "../../components/Dialog";
import { useState } from "react";
import UpdateAreaProcesoProductivo from "./components/administrarMotivoPausaOtg";
import { AlertCustom } from "../../components/AlertMui";
import { Box } from "@mui/material";

export const GeneralConfig = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [showModalAdmintracionMotivos, setShowModalAdmintracionMotivos] =
    useState(false);
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  if (isLoadingPermisos || loadingListUsers) {
    return <Loading />;
  }
  if (isErrorPermisos || isErrorListUsers) {
    return <ErrorMsg />;
  }
  return (
    <>
      <Layout title="Otros ajustes">
        <Box className="d-flex flex-wrap mt-2">
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_Y_EDITAR_DATOS_DE_LOS_PROVEEDORES,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              href="/Proveedores"
              name="Administrar proveedores"
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              href="/ProcesosProductivos"
              name="Administrar procesos productivos"
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              name="Administrar motivos de pausa"
              onclick={() => {
                setShowModalAdmintracionMotivos(true);
              }}
              href={null}
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              name="Administrar Ubicaciones de stock"
              href={"administrarUbicacionesDeStock"}
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              name="Administrar dias NO laborables"
              href={"adminDiasNoLaborables"}
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              name="Administrar excepción dias laborables"
              href={"adminDiasLaborablesExepcion"}
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              name="Administrar motivos de ajuste de stock"
              href={"adminMotivoAjusteStock"}
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              name="Administrar moneda"
              href={"administrarMoneda"}
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              name="Administrar Cotizacion"
              href={"administrarCotizacion"}
            />
          )}
          {verificarPermiso(
            permisos,
            PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
            listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
              ?.idGrupo
          ) && (
            <CardStyle
              img={stockProduct}
              name="Administrar conversion de unidad medida"
              href={"administrarConversionDeUnidadDeMedida"}
            />
          )}
        </Box>
      </Layout>
      <DialogGen
        open={showModalAdmintracionMotivos}
        onClick={() => setShowModalAdmintracionMotivos(false)}
        sx={{ width: 400 }}
      >
        <UpdateAreaProcesoProductivo setAlertCustom={setAlertCustom} />
      </DialogGen>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </>
  );
};

export default GeneralConfig;
