export const paletteConfig = {
    primary: {
      main: '#9BE2FB',
    },
    secondary: {
      main: '#FAFAFA',
    },
    tertiary: {
      main: '#c1c1c1',
    },
    warning: {
      main: "rgb(255, 243, 0)",
    },
    textPrimary: {
      main: '#0d6efd',
    },
    textSecondary: {
      main: '#FAFAFA',
    },
    textTertiary: {
      main: '#c1c1c1',
    },
    textWarning: {
      main: "#F00",
    },
  };
  