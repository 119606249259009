import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { CardOTG } from "./CardOTG";
import { useEffect, useState } from "react";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";
export const ListDrop = ({
  fields: { value },
  onchange,
  estados,
  datosGuardarStorage,
  permisos,
  listUsers,
}) => {
  const [items, setItems] = useState(value);
  const onDrop = ({ removedIndex, addedIndex }) => {
    setItems((items) => arrayMoveImmutable(items, removedIndex, addedIndex));
  };
  useEffect(() => {
    onchange(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  useEffect(() => {
    setItems(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <List>
      <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
        {items?.map((item, index) => (
          <Draggable key={item.id}>
            <ListItem style={{ padding: 6 }} key={item.id}>
              <ListItemText>
                <CardOTG
                  datos={{
                    ...item,
                    index,
                    estados,
                    datosGuardarStorage,
                    permisos,
                    listUsers,
                  }}
                />
              </ListItemText>
              {verificarPermiso(
                permisos,
                PERMISOS.CAMBIAR_PRIORIDADES_DE_FABRICACION_DE_LAS_OTGS,
                listUsers?.find(
                  (item) => item?.radiusIdCodigo === getUser()?.uid
                )?.idGrupo
              ) && (
                <ListItemSecondaryAction>
                  <ListItemIcon
                    className="drag-handle"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <DragHandleIcon />
                  </ListItemIcon>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </Draggable>
        ))}
      </Container>
    </List>
  );
};
