import { useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box } from "@mui/system";
import { AlertCustom } from "../../components/AlertMui";
import Layout from "../../components/Layout";
import { required, requiredArray } from "../../utils/validations";
import { Encabezado } from "./components/encabezado";
import Button from "../../components/Button";
import {
  useListClientes,
  useListDeposito,
  useListUbicationProvider,
  useProducts,
  useStockProductOriginal,
} from "../../hooks/useProducts";
import { CardEgreso } from "./components/cardEgreso";
import { Skeleton } from "@mui/material";
import { InputText } from "../../components/Input";
import { SelectMui } from "../../components/SelectMui";
import { SaveNuevoEgreso } from "../../services/stockServices";

const ModuloEgresos = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const { data: products, isLoading: isLoadingProducts } = useProducts();
  const { data: listDeposito, isLoading: isLoadingDeposito } =
    useListDeposito();
  const {
    data: listUbicationProvider,
    isLoading: isLoadingListUbicationProvider,
  } = useListUbicationProvider(0);
  const { data: listClientes, isLoading: isLoadingListClientes } =
    useListClientes();
  const {
    data: stockProducts,
    isLoading: isLoadingStockProduct,
    refetch,
  } = useStockProductOriginal();
  const obj: any = {};

  return (
    <Layout title="Modulo de egreso">
      <Form
        onSubmit={(values) => {
          const body = {
            ...values,
            stock_estado_id: 1,
            detalle_sku: values?.datos?.map((item, index) => {
              return {
                sku_id: Number(item?.sku?.id),
                cantidad: Number(item?.Cantidad),
                unidad_medida_id: Number(
                  stockProducts
                    .filter(
                      (item) =>
                        item.skuId === values?.datos[index]?.sku?.id &&
                        item.stockUbicacionId === values.stock_ubicacion_id
                    )
                    .find(
                      (item) =>
                        item.stockLote ===
                        (values.datos[index]?.lote === "Sin nombre"
                          ? null
                          : values.datos[index]?.lote)
                    )?.unidadMedidaId
                ),
                stockId: Number(
                  stockProducts
                    .filter(
                      (item) =>
                        item.skuId === values?.datos[index]?.sku?.id &&
                        item.stockUbicacionId === values.stock_ubicacion_id
                    )
                    .find(
                      (item) =>
                        item.stockLote ===
                        (values.datos[index]?.lote === "Sin nombre"
                          ? null
                          : values.datos[index]?.lote)
                    )?.stockId
                ),
              };
            }),
          };

          SaveNuevoEgreso(body)
            .then(() => {
              setAlertCustom({
                open: true,
                title: "Confirmacion",
                severity: "success",
                message: "Se guardo correctamente",
              });
              obj.funcion();
              refetch();
            })
            .catch((error) => {
              setAlertCustom({
                open: true,
                title: "Error",
                severity: "error",
                message: error?.message,
              });
            });
        }}
        initialValues={{ datos: [{}] }}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          handleSubmit,
          values,
          form: {
            mutators: { push },
            restart,
          },
          invalid,
          submitting,
          pristine,
        }) => {
          obj.funcion = restart;
          return (
            <>
              <Box
                className="col-12"
                style={{ width: "100%", padding: "8px 24px 8px 24px" }}
              >
                {!isLoadingListClientes &&
                !isLoadingDeposito &&
                !isLoadingListUbicationProvider ? (
                  <Box sx={{ display: "flex", flexWrap: "wrap", mb: 4 }}>
                    <Box className="col-3 col-xl-2" sx={{ p: 1 }}>
                      <Field
                        name="entidad_id"
                        validate={required as any}
                        label="Clientes"
                        component={SelectMui as any}
                        options={listClientes.map((item) => ({
                          value: item.entidadId,
                          name: item.entidadDescripcion,
                        }))}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                    
                    <Box className="col-3 col-xl-2" sx={{ p: 1 }}>
                      <Field
                        name="deposito"
                        label="Deposito"
                        validate={required as any}
                        component={SelectMui as any}
                        options={listDeposito.map((item) => ({
                          value: item.entidadDescripcion,
                          name: item.entidadDescripcion,
                        }))}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                    <Box className="col-3 col-xl-2" sx={{ p: 1 }}>
                      <Field
                        name="stock_ubicacion_id"
                        label="Ubicación"
                        validate={required as any}
                        component={SelectMui as any}
                        options={listUbicationProvider
                          .filter(
                            (list) =>
                              list.entidadDescripcion === values.deposito
                          )
                          ?.map((item) => ({
                            value: item.stockUbicacionId,
                            name: item.stockUbicacionDescripcion,
                          }))}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                    <Box className="col-3 col-xl-2" sx={{ p: 1 }}>
                      <Field
                        name="comprobante_observacion"
                        label="Observación"
                        component={InputText as any}
                        sx={{ width: "100%" }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box className="col-10">
                    <Skeleton variant="text" />

                    <Skeleton variant="rectangular" width={""} height={100} />
                  </Box>
                )}

                {!isLoadingProducts &&
                !isLoadingStockProduct &&
                products?.length > 0 ? (
                  <>
                    <Encabezado />

                    <FieldArray name="datos" validate={requiredArray}>
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <CardEgreso
                              products={products.filter(
                                (item) =>
                                  stockProducts.filter(
                                    (x) =>
                                      x.skuId === item.skuId &&
                                      x.stockUbicacionId ===
                                        values.stock_ubicacion_id
                                  )?.length > 0
                              )}
                              // products={stockProducts.filter(
                              //   (x) =>
                              //     x.stockUbicacionId ===
                              //     values.stock_ubicacion_id
                              // )}
                              stockCantidad={stockProducts.filter(
                                (x) =>
                                  x.skuId === values?.datos[index]?.sku?.id &&
                                  x.stockUbicacionId ===
                                    values.stock_ubicacion_id
                              )}
                              name={name}
                              nombreProducto={values?.datos[index]?.sku?.name}
                              eliminar={() => fields.remove(index)}
                              values={values}
                              index={index}
                            />
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </>
                ) : (
                  <Box className="col-10">
                    <Skeleton variant="text" />

                    <Skeleton variant="rectangular" width={""} height={200} />
                  </Box>
                )}
              </Box>
              <Box
                className="col-12"
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  padding: "8px 24px 8px 32px",
                }}
              >
                <Button
                  label={"Agregar nuevo componente"}
                  onClick={() => push("datos")}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button
                  label={"Enviar"}
                  color="success"
                  onClick={handleSubmit}
                  sx={{ minWidth: 150 }}
                />
              </Box>
            </>
          );
        }}
      />
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({ open: false, title: "", severity: "", message: "" })
        }
      />
    </Layout>
  );
};
export default ModuloEgresos;
