import { Box, Card, InputAdornment, Typography } from "@mui/material";
import { BsFillTrashFill as Delete } from "react-icons/bs";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { AutoComplete } from "../../../components/AutoComplete";
import Button from "../../../components/Button";
import { InputText, InputNumber, InputDate } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { fechaActual } from "../../../utils/Fecha";
import { requiredArray, required } from "../../../utils/validations";

export const FieldsForm = ({
  listProvider,
  products,
  values,
  data,
  showFieldArray,
  measures,
  push,
  setEntidad,
  initialValue,
  setInitialValue,
}) => {
  return (
    <Box className="col-12 m-2 p-3">
      <Box className="d-flex flex-wrap">
        <Box className="col-12 col-md-3 col-lg-3 col-xl-3 p-1 ">
          <Field
            name="proveedorId"
            component={SelectMui as any}
            label="Proveedor"
            options={listProvider.map(({ entidadDescripcion, entidadId }) => ({
              value: entidadId,
              name: entidadDescripcion,
            }))}
            onchange={(valor) => {
              setEntidad(valor);
              setInitialValue({ ...initialValue, proveedorId: valor });
            }}
            sx={{ width: "100%" }}
          />
        </Box>
        <Box className="col-12 col-md-3 col-lg-3 col-xl-3 p-1 ">
          <Field
            name="comprobanteObservacion"
            component={InputText as any}
            label="Observacion"
            sx={{ width: "100%" }}
          />
        </Box>
      </Box>

      <>
        {showFieldArray && (
          <>
            <FieldArray name="detalleSku" validate={requiredArray}>
              {({ fields }) => (
                <Box>
                  {fields.map((name, index) => {
                    return (
                      <Card sx={{ boxShadow: "1px 1px 5px 1px grey", m: 1 }}>
                        <Box key={name} className="d-flex flex-wrap p-2">
                          <Box className="col-12 col-md-3 col-lg-3 col-xl-3 p-1 ">
                            <Field
                              validate={required}
                              name={`${name}.idProducto`}
                              component={AutoComplete as any}
                              sx={{ width: "100%" }}
                              options={products.map((item) => {
                                return {
                                  id: item.skuId,
                                  label: item.skuCodigo,
                                  name: item.skuDescripcion,
                                  unidadMedidaId: item.unidadMedidaId,
                                  unidadMedida: item.unidadMedida,
                                };
                              })}
                            />
                          </Box>
                          <Box
                            className="d-flex align-items-center col-12 col-md-2 col-lg-2 col-xl-2 "
                            style={{ maxHeight: "40px" }}
                          >
                            <Typography variant="subtitle1" alignItems="center">
                              {values?.detalleSku[index]?.idProducto?.name}
                            </Typography>
                          </Box>
                          <Box
                            className="col-12 col-md-2 col-lg-2 col-xl-2 p-1 "
                            style={{ textAlign: "start" }}
                          >
                            <Field
                              name={`${name}.idProducto.unidadMedidaId`}
                              label="Unidad de medida"
                              component={SelectMui as any}
                              sx={{ width: "100%" }}
                              options={measures.map((item) => ({
                                value: item.unidadMedidaId,
                                name: item.unidadMedida,
                              }))}
                            />
                          </Box>
                          <Box
                            className="col-12 col-md-1 col-lg-1 col-xl-1 p-1 "
                            style={{ textAlign: "start" }}
                          >
                            <Field
                              validate={(value) =>
                                !value
                                  ? "Requerido"
                                  : value <= 0
                                  ? "Debe ser mayor a 0"
                                  : undefined
                              }
                              name={`${name}.precio_unitario`}
                              label="Precio Uni."
                              component={InputNumber as any}
                              sx={{ width: "100%" }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    $
                                  </InputAdornment>
                                ),
                                inputProps: {
                                  style: { textAlign: "right" },
                                },
                              }}
                            />
                          </Box>
                          <Box
                            className="col-12 col-md-1 col-lg-1 col-xl-1 p-1 "
                            style={{ textAlign: "start" }}
                          >
                            <Field
                              validate={(value) =>
                                !value
                                  ? "Requerido"
                                  : value <= 0
                                  ? "Debe ser mayor a 0"
                                  : undefined
                              }
                              name={`${name}.amount`}
                              label="Cantidad"
                              component={InputNumber as any}
                              sx={{ width: "100%" }}
                            />
                          </Box>

                          <Box className="col-12 col-md-2 col-lg-2 col-xl-2 p-1 ">
                            <Field
                              validate={required}
                              focused
                              component={InputDate as any}
                              min={fechaActual()}
                              sx={{ width: "100%" }}
                              name={`${name}.dateEntrega`}
                              label={"Fecha de entrega"}
                            />
                          </Box>

                          <Box
                            className="d-flex justify-content-center align-items-center col-12 col-sm-12 col-md-1 px-1 "
                            style={{ maxHeight: "40px" }}
                          >
                            <Delete
                              onClick={() => fields.remove(index)}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </Box>
                      </Card>
                    );
                  })}
                </Box>
              )}
            </FieldArray>
            <Box
              className="d-flex align-items-center"
              style={{ marginTop: "16px" }}
            >
              <Box sx={{ ml: 3 }} className="col-4 ">
                <Button
                  label={"Agregar"}
                  onClick={() => push("detalleSku", {})}
                />
              </Box>
            </Box>
          </>
        )}
      </>
    </Box>
  );
};
