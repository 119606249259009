import { Box } from "@mui/material";
import { Field } from "react-final-form";
import Button from "../../../components/Button";

import { CheckBoxMuiOneOrZero } from "../../../components/CheckBoxMui";
import { InputDate } from "../../../components/Input";

export const CardDiasNoLaborable = ({ name, onclick }) => {
  return (
    <>
      <Box className="col-4" sx={{ p: 1 }}>
        <Field
          component={InputDate as any}
          name={`${name}.feriadoFecha`}
          label={"Fecha"}
        />
      </Box>
      <Box
        className="col-2"
        sx={{
          display: "flex",
          alignItems: "flex-start",
          px: 1,
        }}
      >
        <Field
          component={CheckBoxMuiOneOrZero as any}
          name={`${name}.feriadoActivo`}
          label="Activo"
        />
      </Box>
      <Box className="col-3" sx={{ px: 1, pt: 0.5 }}>
        <Button label="Guardar cambios" onClick={() => onclick()} />
      </Box>
    </>
  );
};
