export const BuscarInfo = (stockProducts, datos, origenOrDestino) => {
  if (
    datos.idProducto &&
    datos[`${origenOrDestino}StockUbicacionId`] &&
    datos[`${origenOrDestino}StockStateId`]
  ) {
    const aFiltrar = stockProducts.find(
      (item) => item.skuId === datos?.idProducto?.id
    )?.ubication;

    const filtrado = aFiltrar?.find(
      (item) =>
        item?.stockUbicacionId === datos[`${origenOrDestino}StockUbicacionId`]
    );

    const stateStockSelect =
      datos[`${origenOrDestino}StockStateId`] === "1"
        ? "Aprobado"
        : datos[`${origenOrDestino}StockStateId`] === "2"
        ? "Rechazado"
        : datos[`${origenOrDestino}StockStateId`] === "3"
        ? "PendienteQa"
        : datos[`${origenOrDestino}StockStateId`] === "4"
        ? "Tercero"
        : "";
    return stateStockSelect && filtrado ? filtrado[stateStockSelect] : 0;
  }
};
