import { Box } from "@mui/material";
import { LinkStyle } from "../components/LinkStyle";
import Plan from "../images/Home/Plan.png";
import Stock from "../images/Home/ControlStock.png";
import Pref from "../images/Home/Preferencias.png";
import Productos from "../images/Home/Productos.png";
import Reportes from "../images/Home/Reportes.png";
import Suppliers from "../images/Home/suppliers.png";
import Ajustment from "../images/Home/ajustment.png";
import Layout, { LayoutMobi } from "../components/Layout";

export const Home = () => {
  return (
    <>
      {window.screen.availWidth < 600 ? (
        <LayoutMobi title="Bienvenido!!">
          <CardHome />
        </LayoutMobi>
      ) : (
        <Layout title="Bienvenido!!">
          <CardHome />
        </Layout>
      )}
    </>
  );
};
const CardHome = () => (
  <>
    <Box className="d-flex flex-wrap mt-2">
      <LinkStyle
        href="/NotaPedidos"
        img={Reportes}
        name="Crear nota de pedido"
        description=""
      />
      <LinkStyle href="/Pedidos" img={Plan} name="Pedidos" description="" />
      <LinkStyle
        href="/Plan"
        img={Plan}
        name="Plan"
        description="Aqui podras ver todas las otg, fechas y estado de cada una"
      />
      <LinkStyle
        href="/Productos"
        img={Productos}
        name="Módulo de productos, insumos y tercerizados"
        description="Aqui podras administrar los productos y su estructura"
      />
      <LinkStyle
        href="/ControlStock"
        img={Stock}
        name="Control de stock por productos, insumos y tercerizados"
        description="Aqui podras administrar el stock de los productos"
      />
      <LinkStyle
        href="/costo"
        img={Reportes}
        name="Ver reporte de costo"
        description=""
      />
      <LinkStyle
        href="/Proveedores"
        img={Suppliers}
        name="Proveedores"
        description="Administracíon de proveedores"
      />
      <LinkStyle
        href="/Administración"
        img={Pref}
        name="Gestión de usuarios"
        description="Aqui podras gestionar las cuentas de usuarios y permisos "
      />
      <LinkStyle
        href="/Ajustes"
        img={Ajustment}
        name="Otros Ajustes"
        description="Aqui podras gestionar la creación de areas, categorias y procesos entre otras cosas"
      />
    </Box>
  </>
);
