import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

export const CheckBoxMui = ({
  input: { onChange, checked = true },
  disabled = false,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox defaultChecked={checked} />}
        disabled={disabled}
        label={""}
        onChange={(valor) => {
          onChange(valor.target["checked"] ? "n" : "s");
        }}
      />
    </FormGroup>
  );
};
export const CheckBoxMuiTrueOrFalse = ({
  input: { value, onChange },
  label = "",
  sx = {},
  disabled,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            value={value}
            checked={value}
            disabled={disabled}
            sx={{ ...sx }}
          />
        }
        label={label}
        onChange={(valor) => {
          onChange(valor.target["checked"]);
        }}
      />
    </FormGroup>
  );
};
export const CheckBoxMuiOneOrZero = ({
  input: { value, onChange },
  label,
  disabled,
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            value={value === "1" ? true : false}
            checked={value === "1" ? true : false}
            disabled={disabled}
          />
        }
        label={label ? label : ""}
        onChange={(valor) => {
          onChange(valor.target["checked"] ? "1" : "0");
        }}
      />
    </FormGroup>
  );
};
