import ingre from "../images/stock/IngresoEgreso.png";
import modu from "../images/stock/ModuloDeCalidad.png";
import stockProduct from "../images/stock/StockPorProducto.png";
import stockUbication from "../images/stock/StockPorUbicacion.png";
import generarOC from "../images/stock/Generar OC.png";
import ReportesOC from "../images/stock/Reportes OC.png";
import Layout from "../components/Layout";
import CardStyle from "../components/CardStyle";
import { Loading } from "./loading";
import { getUser, usePermisos } from "../hooks/auth";
import { verificarPermiso } from "../utils/verificarPermiso";
import { PERMISOS } from "../constants/MenuOptions";
import ErrorMsg from "../components/ErrorMsg";
import { useListUsers } from "../hooks/useUsers";

const Reportes = () => {
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);

  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  if (isLoadingPermisos || loadingListUsers) {
    return <Loading />;
  }
  if (isErrorPermisos || isErrorListUsers) {
    return <ErrorMsg />;
  }

  return (
    <Layout title="Reportes">
      <CardStyle img={stockProduct} href="/costo" name="Ver reporte costo" />
    </Layout>
  );
};

export default Reportes;
