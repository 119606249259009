import { Box } from "@mui/material";
import { Field } from "react-final-form";
import Button from "../../../components/Button";
import { InputAdornment } from "@mui/material";
import { CheckBoxMuiOneOrZero } from "../../../components/CheckBoxMui";
import { InputDate, InputNumber } from "../../../components/Input";
import { SelectMui } from "../../../components/SelectMui";
import { fechaActual } from "../../../utils/Fecha";

export const CardDiasLaborableExepcion = ({ name, onclick, procesos }) => {
  return (
    <>
      <Box className="col-5 col-lg-4 col-xl-3" sx={{ p: 1 }}>
        <Field
          name={`${name}.diaLaborableExcepcionProcesoProductivoId`}
          component={SelectMui as any}
          label="Proceso productivo"
          options={procesos
            ?.filter((item) => item.procesoProductivoActivo === "1")
            .map((item) => {
              return {
                name: `${item.procesoProductivoTipoDescripcion} ${
                  item.procesoProductivoId !== "1" &&
                  item.procesoProductivoId !== "0"
                    ? `- ${item.procesoProductivoDescripcion}`
                    : ""
                }`,
                value: item.procesoProductivoId,
              };
            })}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box className="col-3 col-xl-2" sx={{ p: 1 }}>
        <Field
          component={InputDate as any}
          min={fechaActual()}
          name={`${name}.diaLaborableExcepcionFecha`}
          label={"Fecha"}
        />
      </Box>
      <Box className="col-2" sx={{ p: 1 }}>
        <Field
          name={`${name}.diaLaborableExcepcionHorasDisponiblesPorDia`}
          component={InputNumber as any}
          label="Horas disponibles"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Horas</InputAdornment>
            ),
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
        />
      </Box>
      <Box
        className="col-1"
        sx={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <Field
          component={CheckBoxMuiOneOrZero as any}
          name={`${name}.diaLaborableExcepcionActivo`}
          label="Activo"
        />
      </Box>
      <Box className="col-2" sx={{ px: 1, pt: 0.5 }}>
        <Button label="Guardar cambios" onClick={() => onclick()} />
      </Box>
    </>
  );
};
