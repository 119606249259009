import { Button, Card, Divider, Typography } from "@mui/material";
import { BsFillTrashFill as Delete } from "react-icons/bs";
import { Field } from "react-final-form";
import { InputNumber, InputText } from "../../../components/Input";

import { FieldArray } from "react-final-form-arrays";

import { Box } from "@mui/system";
import { DialogGen } from "../../../components/Dialog";

import { required } from "../../../utils/validations";
import { useState } from "react";

export const ItemCard = ({ fields, values, push, invalid }) => {
  const [showModalLote, setShowModalLote] = useState(null);
  return (
    <Box className="col-12 ">
      {fields.map((name, index) => {
        return (
          <>
            <Divider />
            <Box key={name} className="d-flex flex-wrap p-2">
              <Box
                className="d-flex align-items-center col-2 p-1 "
                style={{ maxHeight: "40px" }}
              >
                <Typography variant="subtitle1">
                  {values.datos[index].skuCodigo}
                </Typography>
              </Box>
              <Box
                className="d-flex align-items-center col-2 p-1 "
                style={{ maxHeight: "40px" }}
              >
                <Typography variant="subtitle1">
                  {values.datos[index].skuDescripcion}
                </Typography>
              </Box>
              <Box
                className="d-flex align-items-center justify-content-center col-1 p-1 "
                style={{ maxHeight: "40px" }}
              >
                <Typography variant="subtitle1">
                  {values.datos[index].comprobanteDetalleCantidad}
                </Typography>
              </Box>
              <Box
                className="d-flex align-items-center justify-content-center col-1 p-1 "
                style={{ maxHeight: "40px" }}
              >
                <Typography variant="subtitle1">
                  {values.datos[index].comprobanteDetalleCantidadRecibida}
                </Typography>
              </Box>
              <Box
                className="d-flex align-items-center justify-content-center col-1 p-1 "
                style={{ maxHeight: "40px" }}
              >
                <Typography variant="subtitle1">
                  {values.datos[index].comprobanteDetalleCantidadSaldo}
                </Typography>
              </Box>
              <Box className="col-1 p-1 ">
                <Field
                  validate={(valor) =>
                    !valor
                      ? "requerido"
                      : Number(valor) >
                        Number(
                          values.datos[index].comprobanteDetalleCantidadSaldo
                        )
                      ? `maximo ${values.datos[index].comprobanteDetalleCantidadSaldo}`
                      : undefined
                  }
                  component={InputNumber as any}
                  sx={{ width: "100%", maxWidth: "120px" }}
                  name={`${name}.comprobanteDetalleCantidadEntregada`}
                />
              </Box>
              <Box
                className="d-flex align-items-center  col-1 p-1"
                style={{
                  maxHeight: "40px",
                }}
              >
                <Typography variant="subtitle1">
                  {values.datos[index].unidadMedida}
                </Typography>
              </Box>
              <Box className="col-2 p-1 ">
                <Button onClick={() => setShowModalLote(index)}>
                  agregar lote
                </Button>
                <DialogGen
                  open={showModalLote === index}
                  title="Agregar lote"
                  onClick={() => setShowModalLote(null)}
                  disabled={
                    CuentaTotal(values, index).cuenta ||
                    CuentaTotal(values, index).cantidad ||
                    CuentaTotal(values, index).lote
                  }
                  sx={{
                    display: "block",
                  }}
                >
                  <FieldArray name={`${name}.lotes`}>
                    {({ fields }) => (
                      <>
                        <Box
                          sx={{
                            maxHeight: "300px",
                            width: "300px",
                            overflowY: "auto",
                          }}
                        >
                          {fields.map((nameLote, index1) => {
                            return (
                              <Card
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                }}
                              >
                                <Box className="col-6 p-2">
                                  <Field
                                    validate={required}
                                    component={InputText as any}
                                    sx={{ width: "100%" }}
                                    name={`${nameLote}.lote`}
                                    label="Lote"
                                  />
                                </Box>
                                <Box className="col-5 p-2">
                                  <Field
                                    validate={required}
                                    component={InputNumber as any}
                                    name={`${nameLote}.cantidad`}
                                    label="Cantidad"
                                  />
                                </Box>

                                <Box
                                  className="d-flex justify-content-center align-items-center col-1  px-1 "
                                  style={{
                                    maxHeight: "40px",
                                  }}
                                >
                                  <Delete
                                    onClick={() => fields.remove(index1)}
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </Box>
                              </Card>
                            );
                          })}
                        </Box>
                        <Box className="col-12 p-2">
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: "red",
                            }}
                            variant="subtitle1"
                          >
                            {CuentaTotal(values, index).cuenta
                              ? "Supera la cantidad entregada"
                              : CuentaTotal(values, index).cantidadRestante
                              ? `Cantidad restante: ${
                                  CuentaTotal(values, index).cantidadRestante
                                }`
                              : undefined}
                          </Typography>
                        </Box>
                        <Box>
                          <Button
                            className="mt-2"
                            style={{
                              backgroundColor: "rgba(114, 208, 114, 0.8)",
                              height: "25px",
                              width: "50%",
                              minWidth: "150px",
                            }}
                            disabled={
                              CuentaTotal(values, index).cuenta ||
                              !values.datos[index]
                                .comprobanteDetalleCantidadEntregada
                            }
                            onClick={() => push(`${name}.lotes`, {})}
                          >
                            Agregar
                          </Button>
                          <Typography variant="body2" sx={{ mt: 2 }}>
                            {!values.datos[index]
                              .comprobanteDetalleCantidadEntregada &&
                              "*Debe completar el campo de cantidad a recibir antes de agregar lotes"}
                          </Typography>
                        </Box>
                      </>
                    )}
                  </FieldArray>
                </DialogGen>
              </Box>
              <Box
                className="d-flex justify-content-center align-items-center col-1 px-1 "
                style={{ maxHeight: "40px" }}
              >
                <Delete
                  onClick={() => fields.remove(index)}
                  style={{
                    width: "17px",
                    height: "17px",
                    cursor: "pointer",
                  }}
                />
              </Box>
            </Box>
          </>
        );
      })}
    </Box>
  );
};
function CuentaTotal(values: any, index: number): any {
  const response: any = {};
  let cuenta = 0;
  values?.datos[index]?.lotes?.map(
    (item) => (cuenta += Number(item?.cantidad) ? Number(item.cantidad) : 0)
  );

  response.cuenta =
    cuenta > Number(values.datos[index].comprobanteDetalleCantidadEntregada)
      ? true
      : false;

  response.cantidadRestante =
    cuenta !== 0 &&
    cuenta - Number(values.datos[index].comprobanteDetalleCantidadEntregada);
  // eslint-disable-next-line array-callback-return
  values?.datos[index]?.lotes?.map((item) => {
    if (!item?.cantidad) {
      response.cantidad = true;
    } else {
      response.cantidad = false;
    }
    if (!item?.lote) {
      response.lote = true;
    } else {
      response.lote = false;
    }
  });
  return response;
}
