import { useState } from "react";
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";
import { Box, Skeleton, Typography } from "@mui/material";
import ErrorMsg from "../../components/ErrorMsg";
import Layout from "../../components/Layout";

import { Form } from "react-final-form";
import { CardDiasNoLaborable } from "./components/cardDiasNoLaborable";
import { useDiasNoLaborables } from "../../hooks/useOrdenTrabajo";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import Button from "../../components/Button";
import FormAgregarNuevoFeriado from "./components/formAgregarNuevoFeriado";
import { guardarDatosFeriado } from "./hook/guardarDatosFeriado";

const AdminDiasNoLaborable = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [showModalDiasNoLaborables, setShowModalDiasLaborables] =
    useState(false);
  const {
    data: listDiasNoLaborables,
    isLoading: isLoadingListDiasNoLaborables,
    isError: isErrorListDiasNoLaborables,
    refetch,
  } = useDiasNoLaborables();

  if (isErrorListDiasNoLaborables) {
    return <ErrorMsg />;
  }

  return (
    <>
      <Layout title={"Administrar dias NO laborables"} clase={false}>
        {!isLoadingListDiasNoLaborables ? (
          <Box className="col-12" sx={{ p: 2 }}>
            <Box sx={{ mx: 2, mb: 2 }}>
              <Typography variant="h1">
                Aquí puedes gestionar los días no laborables
              </Typography>
            </Box>
            <Form
              onSubmit={(values) => {}}
              initialValues={{
                datos:
                  listDiasNoLaborables.length > 0 ? listDiasNoLaborables : [],
              }}
              mutators={{
                ...arrayMutators,
              }}
              validate={(values) => {
                return {};
              }}
              render={({
                handleSubmit,
                form: {
                  mutators: { push },
                  restart,
                },
                submitting,
                pristine,
                values,
              }) => {
                return (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        onClick={() => setShowModalDiasLaborables(true)}
                        label={"Agregar un nuevo dia NO laborable"}
                        color="success"
                      />
                    </Box>

                    <FieldArray name="datos">
                      {({ fields }) =>
                        fields.map((name, index) => (
                          <Box
                            className="col-8"
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <CardDiasNoLaborable
                              name={name}
                              onclick={() => {
                                guardarDatosFeriado({
                                  datos: values.datos[index],
                                  setAlertCustom: setAlertCustom,
                                  refetch: refetch,
                                });
                              }}
                            />
                          </Box>
                        ))
                      }
                    </FieldArray>
                  </Box>
                );
              }}
            />
          </Box>
        ) : (
          <Box className="col-10">
            <Skeleton variant="text" />

            <Skeleton variant="rectangular" width={""} height={200} />
          </Box>
        )}
      </Layout>
      <DialogGen
        open={showModalDiasNoLaborables}
        title={"Agregar un nuevo dia NO laborable"}
        onClick={() => setShowModalDiasLaborables(false)}
        sx={{ maxWidth: "500px" }}
      >
        <FormAgregarNuevoFeriado
          setAlertCustom={setAlertCustom}
          refetch={refetch}
        />
      </DialogGen>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </>
  );
};
export default AdminDiasNoLaborable;
