import { Box, Typography } from "@mui/material";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CancelPresentationRoundedIcon from "@mui/icons-material/CancelPresentationRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
const CheckCustom = ({ input: { onChange, value }, label, meta, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="h3"> {label}</Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "31px",
          height: "26px",
          ml: 1,
        }}
      >
        {value === "" ? (
          <CheckBoxOutlineBlankRoundedIcon
            sx={{ cursor: "pointer", fontSize: 32 }}
            onClick={() => onChange("0")}
          />
        ) : value === "0" ? (
          <CancelPresentationRoundedIcon
            sx={{ cursor: "pointer", fontSize: 32 }}
            onClick={() => onChange("1")}
          />
        ) : value === "1" ? (
          <CheckBoxOutlinedIcon
            sx={{ cursor: "pointer", fontSize: 32 }}
            onClick={() => onChange("2")}
          />
        ) : (
          <IndeterminateCheckBoxOutlinedIcon
            sx={{ cursor: "pointer", fontSize: 32 }}
            onClick={() => onChange("0")}
          />
        )}
        <Typography style={{ color: "red" }}>
          {meta.touched && meta.error}
        </Typography>
      </Box>
      <Box sx={{ width: 90, ml: 1 }}>
        <Typography variant="body2">
          {value === ""
            ? ""
            : value === "0"
            ? "No Aprobado"
            : value === "1"
            ? "Aprobado"
            : "No aplica"}
        </Typography>
      </Box>
    </Box>
  );
};
export default CheckCustom;
