import { Box, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Button from "../../../components/Button";
import { Field } from "react-final-form";
import { DialogSelect } from "../../../components/SelectMui";
import { SaveStorage } from "../../../utils/localStorage";
import { getColorMaterial } from "../../../utils/getColorMaterial";
import { numeroOtg } from "../../../utils/numeroOtg";
import {
  getBackgroundColorChip,
  getColorChip,
} from "../../../utils/getColorChip";
import { InputDate } from "../../../components/Input";
import { verificarPermiso } from "../../../utils/verificarPermiso";
import { PERMISOS } from "../../../constants/MenuOptions";
import { getUser } from "../../../hooks/auth";
import { fromSecondsToHours } from "../../../utils/fromSecondsToHours";

export const CardOTG = ({
  datos: {
    ordenTrabajoEstadoDescripcion,
    ordenTrabajoCantidad,
    ordenTrabajoCantidadProducida,
    skuDescripcion,
    ordenTrabajoEstadoId,
    estados,
    procesoProductivoTipoDescripcion,
    procesoProductivoDescripcion,
    ordenTrabajoElaboracionFecha,
    ordenTrabajoFinalizacionFechaEstimada,
    ordenTrabajoId,
    index,
    ordenTrabajoPrioridad,
    onclick,
    datosGuardarStorage,
    boton = false,
    sx = {},

    ordenTrabajoStatusMateriales,
    cantidadOperarios,
    ordenTrabajoDuracionSegundos,
    ...rest
  },
  permisos,
  listUsers,
}) => {
  const href = `/detailsOrdenTrabajo/${ordenTrabajoId}`;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        p: 1,
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        border: "0.5px solid #C5C5C5",
      }}
    >
      <Box className="col-1">
        <Typography sx={{ display: "flex", pl: 1 }} variant="body2">
          Proceso productivo
        </Typography>
        <Typography sx={{ display: "flex", pl: 1 }}>
          {`${procesoProductivoTipoDescripcion}`}
        </Typography>
        <Typography sx={{ display: "flex", pl: 1 }}>
          {procesoProductivoDescripcion}
        </Typography>
      </Box>
      {/* <Box className="col-1">
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          variant="body2"
        >
          Prioridad
        </Typography>

        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {ordenTrabajoPrioridad}
        </Typography>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="col-1"
      >
        <Typography variant="body2">Producto</Typography>
        <Typography sx={{ fontSize: "10px" }}>{`OTG - ${numeroOtg(
          Number(ordenTrabajoId)
        )}`}</Typography>
        <Box className="col-10">
          <Typography
            variant="h6"
            noWrap={false}
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 0.1,
              px: 0.5,
            }}
          >
            {skuDescripcion}
          </Typography>
        </Box>
      </Box>
      <Box className="col-1">
        <Typography
          variant="body2"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          Estado
        </Typography>

        <Field
          disabled={
            !verificarPermiso(
              permisos,
              PERMISOS.CAMBIAR_ESTADO_DE_LA_OTG_PLAN,
              listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
                ?.idGrupo
            )
          }
          component={DialogSelect as any}
          name={`${
            boton
              ? `ordenTrabajoEstadoId`
              : `datos[${index}].ordenTrabajoEstadoId`
          }`}
          options={estados.map((item) => {
            return {
              name: item.ordenTrabajoEstadoDescripcion,
              value: item.ordenTrabajoEstadoId,
            };
          })}
          color={getColorChip(ordenTrabajoEstadoId)}
          backgroundColor={getBackgroundColorChip(ordenTrabajoEstadoId)}
          estados={estados}
        />
      </Box>
      <Box
        className="col-2"
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box className="col-12 col-lg-6" sx={{ pl: 1 }}>
          <Typography variant="body2">Materiales</Typography>
          <CircleIcon
            color={getColorMaterial(Number(ordenTrabajoStatusMateriales))}
            sx={{ display: "flex", justifyContent: "center", mt: 1 }}
          />
        </Box>
        <Box
          className="col-12 col-lg-6"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="body2">Cantidad</Typography>
          <Typography>{`${ordenTrabajoCantidadProducida} / ${ordenTrabajoCantidad}`}</Typography>
        </Box>
      </Box>

      <Box className="col-1">
        <Typography variant="body2">Personas Trabajando</Typography>
        <Typography>
          {Number(cantidadOperarios) > 0 ? cantidadOperarios : 0}
        </Typography>
        <Typography>
          {fromSecondsToHours(ordenTrabajoDuracionSegundos)
            ? fromSecondsToHours(ordenTrabajoDuracionSegundos)
            : 0}
        </Typography>
      </Box>
      <Box className="col-2">
        <Typography variant="body2">Fecha de inicio elaboracion</Typography>
        <Box sx={{ mt: 1 }}>
          <Field
            disabled={
              !verificarPermiso(
                permisos,
                PERMISOS.EDITAR_FECHA_DE_INICIO_DE_ELABORACION_PLAN,
                listUsers?.find(
                  (item) => item?.radiusIdCodigo === getUser()?.uid
                )?.idGrupo
              )
            }
            component={InputDate as any}
            defaultValue={ordenTrabajoElaboracionFecha}
            name={`${
              boton
                ? `ordenTrabajoElaboracionFecha`
                : `datos[${index}].ordenTrabajoElaboracionFecha`
            }`}
            sx={{ maxWidth: "95%" }}
          />
        </Box>
      </Box>
      <Box className="col-2">
        <Typography variant="body2">Fecha de finalizacion estimada</Typography>
        <Box sx={{ mt: 1 }}>
          <Field
            disabled={true}
            component={InputDate as any}
            defaultValue={ordenTrabajoFinalizacionFechaEstimada}
            name={`${
              boton
                ? `ordenTrabajoFinalizacionFechaEstimada`
                : `datos[${index}].ordenTrabajoFinalizacionFechaEstimada`
            }`}
          />
        </Box>
      </Box>
      <Box
        className="col-1"
        sx={{ display: "flex", alignItems: "center", p: 2 }}
      >
        {boton ? (
          <Button
            label="declarar unidades"
            onClick={onclick}
            disabled={
              !verificarPermiso(
                permisos,
                PERMISOS.DECLARAR_UNIDADES,
                listUsers?.find(
                  (item) => item?.radiusIdCodigo === getUser()?.uid
                )?.idGrupo
              )
            }
          />
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            style={
              verificarPermiso(
                permisos,
                PERMISOS.FLECHA_PARA_DETALLE_DE_LAS_OTG,
                listUsers?.find(
                  (item) => item?.radiusIdCodigo === getUser()?.uid
                )?.idGrupo
              )
                ? { cursor: "pointer" }
                : {}
            }
            href={
              verificarPermiso(
                permisos,
                PERMISOS.FLECHA_PARA_DETALLE_DE_LAS_OTG,
                listUsers?.find(
                  (item) => item?.radiusIdCodigo === getUser()?.uid
                )?.idGrupo
              )
                ? href
                : undefined
            }
            onClick={() =>
              SaveStorage("datos", JSON.stringify(datosGuardarStorage))
            }
          >
            <ArrowForwardIcon fontSize="large" color="primary" to={href} />
          </a>
        )}
      </Box>
    </Box>
  );
};
