export const numeroOtg = (numero) => {
    if (numero >= 0 && numero < 10) {
        return `00000${numero}`
    }
    if (numero >= 10 && numero < 100) {
        return `0000${numero}`
    }
  if (numero >= 100 && numero < 1000) {
        return `000${numero}`
    }
      if (numero > 1000 && numero < 10000) {
        return `00${numero}`
    }
    if (numero >= 10000 && numero < 100000) {
        return `0${numero}`
    }
      if (numero >= 10000 && numero < 100000) {
        return `${numero}`
    }
 }