import { Box, Typography } from "@mui/material";
import { Field } from "react-final-form";
import { InputDate, InputNumber } from "../../../components/Input";
import { fechaActual } from "../../../utils/Fecha";
import { required } from "../../../utils/validations";

export const FormDeclaraciones = ({ cantidadRestante }) => {
  return (
    <>
      <Typography sx={{ color: "#797979" }} variant={"h1"}>
        Declaraciones
      </Typography>

      <Box sx={{ display: "flex", flexWrap: "wrap", my: 1 }}>
        <Box className="col-4" sx={{ p: 1 }}>
          <Field
            validate={(valor) =>
              Number(valor) !== 0 ? undefined : "Debe ser diferente de 0"
            }
            name="cantidad"
            defaultValue={0}
            component={InputNumber as any}
            label="Cantidad"
            helperText={cantidadRestante}
          />
        </Box>
        <Box className="col-8" sx={{ p: 1 }}>
          <Field
            focused
            validate={required}
            name="fecha"
            component={InputDate as any}
            label="Fecha"
            defaultValue={fechaActual()}
          />
        </Box>
        {/* <Box sx={{ p: 1 }} className="col-6">
          <Field
            validate={required}
            name="deposito"
            label="Declarar unidades en"
            component={SelectMui as any}
            options={[{ value: "1", name: "Carpinteria" }].map((item) => {
              return {
                value: item.value,
                name: item.name,
              };
            })}
            sx={{ width: "100%" }}
          />
        </Box> */}
      </Box>
    </>
  );
};
