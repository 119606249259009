import { fetchContent, Method } from "../utils/fetchContent";

export const GetProducts = async () => {
    try {
      return await fetchContent('/sku');
      
    } catch(error) {
       throw(error)
    }  
}
  export const GetListTypeSuppliers = async () => {
    try {
      return await fetchContent('entidad_tipo')
           
      
    } catch(error) {
       throw(error)
    }  
}
  export const GetListProductsSuppliers = async (id) => {
    try {
      return await fetchContent(`/sku_listado/${id}`)
           
      
    } catch(error) {
       throw(error)
    }  
}
export const PostUpdateOrCreateSupplier = async (body) => {
    try {
      return await fetchContent('entidad/entidad_update',
            {
                body,
                method: Method.POST,
            });
      
    } catch(error) {
       throw(error)
    }  
}
export const PostProductsSupplier = async (id,body) => {
    try {
      return await fetchContent(`sku/proveedor/${id}`,
            {
                 body,
                method: Method.POST,
            });
      
    } catch(error) {
       throw(error)
    }  
}