import { useState } from "react";
import {
  DialogResetPass,
  DialogCreateUser,
  DialogInProcess,
} from "../components/Dialog";
import { Box } from "@mui/material";
import { LinkStyleModal } from "../components/LinkStyle";
import { IsMobi } from "../hooks/isMobi";
import Pref from "../images/Home/Preferencias.png";
import Layout from "../components/Layout";
import { getUser, usePermisos } from "../hooks/auth";
import { Loading } from "./loading";
import ErrorMsg from "../components/ErrorMsg";
import { verificarPermiso } from "../utils/verificarPermiso";
import { PERMISOS } from "../constants/MenuOptions";
import { useListUsers } from "../hooks/useUsers";
export const Usuario = () => {
  const [openCreate, setOpenCreate] = useState(false);
  const [openReset, setOpenReset] = useState(false);
  const [openInProcess, setOpenInProcess] = useState(false);
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
  } = useListUsers();
  if (isLoadingPermisos || loadingListUsers) {
    return <Loading />;
  }
  if (isErrorPermisos || isErrorListUsers) {
    return <ErrorMsg />;
  }
  return (
    <Box style={!IsMobi() ? {} : {}}>
      <Layout title="Gestión de usuarios">
        {verificarPermiso(
          permisos,
          PERMISOS.CREAR_NUEVOS_USUARIOS,
          listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
            ?.idGrupo
        ) && (
          <LinkStyleModal
            img={Pref}
            name="Agregar usuario"
            onClick={() => {
              setOpenCreate(true);
            }}
            description="Podras dar de alta un nuevo usuario"
          />
        )}
        {verificarPermiso(
          permisos,
          PERMISOS.REESTABLECAR_LA_CONTRASEÑA,
          listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
            ?.idGrupo
        ) && (
          <LinkStyleModal
            img={Pref}
            name="Restablecer Contraseña"
            onClick={() => {
              setOpenReset(true);
            }}
            description="se le enviara un correo al usuario para blanquear la contraseña"
          />
        )}
        {verificarPermiso(
          permisos,
          PERMISOS.MODIFICAR_DATOS_DE_USUARIOS,
          listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
            ?.idGrupo
        ) && (
          <LinkStyleModal
            img={Pref}
            href="/updateUsers"
            name="Modificar usuarios"
            description=""
            onClick={() => {}}
          />
        )}
        {verificarPermiso(
          permisos,
          PERMISOS.MODIFICAR_DATOS_DE_USUARIOS,
          listUsers?.find((item) => item?.radiusIdCodigo === getUser()?.uid)
            ?.idGrupo
        ) && (
          <LinkStyleModal
            img={Pref}
            href="/Permisos"
            name="Administrar permisos"
            description=""
            onClick={() => {}}
          />
        )}
      </Layout>
      <DialogResetPass
        open={openReset}
        onClick={() => {
          setOpenReset(false);
        }}
        title="Reestablecer Contraseña"
      />
      <DialogCreateUser
        open={openCreate}
        onClick={() => {
          setOpenCreate(false);
        }}
      />
      <DialogInProcess
        open={openInProcess}
        onClick={() => setOpenInProcess(false)}
      />
    </Box>
  );
};
