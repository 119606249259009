import { convertNumberGen } from "./convertNumberGen";

export const OrderGen = (array, orderBy, direction = "asc", number = false) => {
  let i = direction === "asc" ? 1 : -1;
  if (number) {
    array.sort((a, b) =>
      convertNumberGen(a[orderBy]) > convertNumberGen(b[orderBy]) ? i : -1 * i
    );
  } else {
    array.sort((a, b) =>
      a[orderBy].toUpperCase() > b[orderBy].toUpperCase() ? i : -1 * i
    );
  }
};
export function ordenarArrayConParametros(array, params, asc = true) {
  var num = asc ? 1 : -1;

  array.sort((actual, proximo) => {
    var resultado = 0;
    params.map((param) => {
      if (resultado === 0) {
        if (actual[param]?.toUpperCase() > proximo[param]?.toUpperCase()) {
          resultado = num;
        }
        if (actual[param]?.toUpperCase() < proximo[param]?.toUpperCase()) {
          resultado = num * -1;
        }
      }
    });

    return resultado;
  });
}
