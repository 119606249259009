import * as React from "react";
import { Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonCustom from "../../components/Button";
import TableContainer from "../../components/TableContainer";
import Layout from "../../components/Layout";
import ErrorMsg from "../../components/ErrorMsg";
import {
  useProductsSupplier,
  useSuppliers,
  useTypeSuppliers,
} from "../../hooks/useSuppliers";
import { Loading } from "../loading";
import { DialogGen } from "../../components/Dialog";
import { FormSuppliersDetails } from "./components/formSuplliersDetails";
import { AlertCustom } from "../../components/AlertMui";
import { useProducts } from "../../hooks/useProducts";
import { FormProductsSupplier } from "./components/formProductsSupplier";
import { AlertAnimation } from "../../components/Animation";
import { usePermisos } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";
export const Suppliers = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const [supplier, setSupplier] = React.useState(null);
  const [showModalProductsSupplier, setShowModalProductsSuppliers] =
    React.useState(false);
  const [submitSucceeded, setSubmitSucceeded] = React.useState(false);
  const [alertCustom, setAlertCustom] = React.useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [openAlertAnimation, setOpenAlertAnimation] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const { data, isError, isLoading, refetch } = useSuppliers();
  const {
    data: productsSuppliers,
    isError: isErrorProductsSuppliers,
    isLoading: isLoadingProductsSuppliers,
    refetch: refetchProductsSuppliers,
  } = useProductsSupplier(supplier?.entidadId);
  const {
    data: typeSuppliers,
    isError: errorTypeSuppliers,
    isLoading: loadingTypeSuppliers,
  } = useTypeSuppliers();
  const {
    data: products,
    isLoading: isLoadingProducts,
    isError: isErrorProducts,
  } = useProducts();
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  if (
    isLoading ||
    loadingTypeSuppliers ||
    isLoadingProducts ||
    isLoadingProductsSuppliers ||
    isLoadingPermisos ||
    loadingListUsers
  ) {
    return <Loading />;
  }

  if (
    !Array.isArray(products) ||
    !Array.isArray(data) ||
    !Array.isArray(typeSuppliers) ||
    !Array.isArray(productsSuppliers) ||
    isError ||
    errorTypeSuppliers ||
    isErrorProducts ||
    isErrorListUsers ||
    isErrorPermisos ||
    isErrorProductsSuppliers
  ) {
    return <ErrorMsg />;
  }
  const header = [
    "CUIT",
    "Razón Social",
    "Nombre de empresa",
    "Nombre",
    "Apellido",
    "Teléfono",
    "Correo",
    "Tipo",
  ];
  const row = [
    "entidadCuit",
    "entidadRazonSocial",
    "entidadDescripcion",
    "entidadContactoNombre",
    "entidadContactoApellido",
    "entidadContactoTelefono",
    "entidadContactoMail",
    "entidadTipoDescripcion",
  ];

  return (
    <>
      <Layout title="Proveedores">
        <Box
          id="options"
          sx={{
            p: 0,
            pl: 2,
            pr: 2,
            m: 0,
            display: "flex",
            width: "100%",

            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <ButtonCustom
            label="Crear Proveedor"
            onClick={() => {
              setSupplier(null);
              setModalOpen(!modalOpen);
            }}
            sx={{ ml: 2 }}
          >
            <AddCircleOutlineIcon sx={{ pr: 1 }} />
          </ButtonCustom>
          <TableContainer
            objeto={data.sort((a, b) => a.entidadId - b.entidadId)}
            sx={{ mt: 4, ml: 2 }}
            header={header}
            row={row}
            editOptions={{
              onClick: (value) => {
                setSupplier(data.find((item) => item?.entidadId === value));
                setModalOpen(!modalOpen);
              },

              key: "entidadId",
              disabled: false,
            }}
          />
        </Box>
        <DialogGen
          open={modalOpen}
          onClick={() => {
            setModalOpen(!modalOpen);
            setSubmitSucceeded(false);
          }}
          sxBody={{ maxHeight: "" }}
          title={!!supplier ? "Proveedor" : "Agregar nuevo proveedor"}
        >
          <FormSuppliersDetails
            supplier={supplier}
            refetch={refetch}
            modalOpen={modalOpen}
            typeSuppliers={typeSuppliers}
            submitSucceeded={submitSucceeded}
            setModalOpen={setModalOpen}
            setSubmitSucceeded={setSubmitSucceeded}
            setShowModalProductsSuppliers={setShowModalProductsSuppliers}
            setAlertCustom={setAlertCustom}
            botonAsignarProducto={!!supplier}
            permisos={permisos}
            listUsers={listUsers}
          />
        </DialogGen>
        <DialogGen
          open={showModalProductsSupplier}
          onClick={() => {
            setShowModalProductsSuppliers(false);
            setAlertCustom({
              open: false,
              title: "",
              severity: "",
              message: "",
            });
          }}
          sxBody={{ width: 500, maxHeight: 500 }}
          title="Productos asignados"
        >
          <FormProductsSupplier
            products={products}
            supplier={supplier}
            productsSuppliers={productsSuppliers}
            setOpenAlertAnimation={setOpenAlertAnimation}
            refetchProductsSuppliers={refetchProductsSuppliers}
          />
        </DialogGen>
      </Layout>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      >
        <ButtonCustom
          sx={{ marginTop: 2, marginLeft: 3 }}
          label={"volver al inicio"}
          onClick={() => {
            setModalOpen(!modalOpen);
            setAlertCustom({
              open: false,
              title: "",
              severity: "",
              message: "",
            });
          }}
        />
      </AlertCustom>
      <AlertAnimation
        height={100}
        width={100}
        open={openAlertAnimation.open}
        closer={() => {
          setOpenAlertAnimation({ open: false, message: "", severity: "" });
          if (openAlertAnimation.severity !== "error") {
            setShowModalProductsSuppliers(false);
          }
        }}
        message={openAlertAnimation.message}
        severity={openAlertAnimation.severity}
      />
    </>
  );
};

export default Suppliers;
