import * as React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ButtonCustom from "../../components/Button";
import TableContainer from "../../components/TableContainer";
import Layout, { LayoutMobi } from "../../components/Layout";
import ErrorMsg from "../../components/ErrorMsg";
import {
  useProductsSupplier,
  useSuppliers,
  useTypeSuppliers,
} from "../../hooks/useSuppliers";
import { Loading } from "../loading";
import { DialogGen } from "../../components/Dialog";
import { AlertCustom } from "../../components/AlertMui";
import { useProducts } from "../../hooks/useProducts";
import { AlertAnimation } from "../../components/Animation";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import {
  useListUbicationProvider,
  useProcesos,
  useProcesosTipoId,
} from "../../hooks/useProducts";
import ReactPaginate from "react-paginate";
import { IsMobi } from "../../hooks/isMobi";
import { InputNotForm } from "../../components/Input";
import Button from "../../components/Button";

import UpdateAreaProcesoProductivo from "../procesosProductivos/components/updateAreaProcesoProductivo";
import { CardSupplierMobi } from "./component/cardSupplierMobi";
import { FormProductsSupplier } from "../suppliers/components/formProductsSupplier";
import { FormSuppliersDetails } from "../suppliers/components/formSuplliersDetails";
import { PostUpdateOrCreateSupplier } from "../../services/supplierServices";
import { usePermisos } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";
export const SuppliersMobi = () => {
  const [pageSelected, setPageSelected] = useState(0);
  const [currentItems, setCurrentItems] = useState(null);
  const itemsPerPage = 5;
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [dataFilter, setDataFilter] = useState([]);
  const [search, setSearch] = useState(null);
  const [modalOpen, setModalOpen] = React.useState(false);

  const [supplier, setSupplier] = React.useState(null);
  const [showModalProductsSupplier, setShowModalProductsSuppliers] =
    React.useState(false);
  const [submitSucceeded, setSubmitSucceeded] = React.useState(false);
  const [expanded, setExpanded] = useState(null);
  const [alertCustom, setAlertCustom] = React.useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [openAlertAnimation, setOpenAlertAnimation] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const { data, isError, isLoading, refetch } = useSuppliers();
  const {
    data: productsSuppliers,
    isError: isErrorProductsSuppliers,
    isLoading: isLoadingProductsSuppliers,
    refetch: refetchProductsSuppliers,
  } = useProductsSupplier(supplier?.entidadId);
  const {
    data: typeSuppliers,
    isError: errorTypeSuppliers,
    isLoading: loadingTypeSuppliers,
  } = useTypeSuppliers();
  const {
    data: products,
    isLoading: isLoadingProducts,
    isError: isErrorProducts,
  } = useProducts();
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems({
      datos: !!search
        ? dataFilter.slice(itemOffset, endOffset)
        : data?.slice(itemOffset, endOffset),
    });

    setPageCount(
      Math.ceil((!!search ? dataFilter.length : data?.length) / itemsPerPage)
    );
  }, [itemOffset, itemsPerPage, data, search, dataFilter]);
  if (
    isLoading ||
    loadingTypeSuppliers ||
    isLoadingProducts ||
    isLoadingProductsSuppliers ||
    loadingListUsers ||
    isLoadingPermisos
  ) {
    return <Loading />;
  }

  if (
    !Array.isArray(products) ||
    !Array.isArray(data) ||
    !Array.isArray(typeSuppliers) ||
    !Array.isArray(productsSuppliers) ||
    isError ||
    isErrorListUsers ||
    isErrorPermisos ||
    errorTypeSuppliers ||
    isErrorProducts ||
    isErrorProductsSuppliers
  ) {
    return <ErrorMsg />;
  }
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    setPageSelected(event.selected);
    setItemOffset(newOffset);
  };
  const changeSearch = (evt) => {
    setSearch(evt.target.value ? evt.target.value : null);
    setItemOffset(0);
    setPageSelected(0);
    if (evt.target.value !== "") {
      setDataFilter(
        data.filter((item) =>
          Object.values(item).find(
            (valor) =>
              !!valor &&
              valor
                .toString()
                .toUpperCase()
                .includes(evt.target.value.toUpperCase())
          )
        )
      );
    } else {
      setDataFilter([]);
    }
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <LayoutMobi title="Proveedores">
        <Box sx={{ p: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
            <Button
              label={"Crear Proveedor"}
              onClick={() => setModalOpen(!modalOpen)}
            >
              <AddCircleOutlineIcon sx={{ pr: 1 }} />
            </Button>
          </Box>
          <Form
            onSubmit={(values) => {}}
            initialValues={currentItems}
            mutators={{
              ...arrayMutators,
            }}
            validate={(values) => {
              return {};
            }}
            render={({
              handleSubmit,
              form: {
                mutators: { push },
                restart,
              },
              submitting,
              pristine,
              values,
            }) => {
              return (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#fafafa",
                      mb: 2,
                    }}
                  >
                    <InputNotForm
                      onchange={(evt) => changeSearch(evt)}
                      label="Buscar"
                    />
                  </Box>
                  <FieldArray name="datos">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <Accordion
                          expanded={expanded === `panel${index}`}
                          onChange={handleChange(`panel${index}`)}
                          onClick={() => {}}
                        >
                          {console.log(
                            values?.datos[index] === currentItems.datos[index]
                          )}
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body2">
                              {` ${
                                values.datos[index]?.entidadRazonSocial
                                  ? values.datos[index]?.entidadRazonSocial
                                  : ""
                              }`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              border: "1px solid #C1C1C1",
                              boxShadow: "1px 1px 1px 1px rgba(0,0,0, 0.05)",
                            }}
                          >
                            <CardSupplierMobi
                              nameField={name}
                              setShowModalProductsSuppliers={
                                setShowModalProductsSuppliers
                              }
                              setSupplier={() =>
                                setSupplier({
                                  entidadId: values?.datos[index].entidadId,
                                })
                              }
                              huboCambios={
                                values?.datos[index] !==
                                currentItems.datos[index]
                              }
                              saveDataFunction={() =>
                                PostUpdateOrCreateSupplier(values?.datos[index])
                                  .then(() => {
                                    setAlertCustom({
                                      open: true,
                                      title: "Confirmacion",
                                      severity: "success",
                                      message: "Se guardo correctamente",
                                    });

                                    refetch();
                                    setSubmitSucceeded(true);
                                  })
                                  .catch((error) => {
                                    setAlertCustom({
                                      open: true,
                                      title: "Ha ocurrido un error",
                                      severity: "error",
                                      message: error?.message
                                        ? error.message
                                        : "Error inesperado",
                                    });
                                  })
                              }
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))
                    }
                  </FieldArray>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <ReactPaginate
                      nextLabel="Siguiente >"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={IsMobi() ? 2 : 5}
                      pageCount={pageCount}
                      forcePage={pageSelected}
                      previousLabel="< Anterior"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                      renderOnZeroPageCount={null}
                    />
                  </Box>
                </Box>
              );
            }}
          />
        </Box>
      </LayoutMobi>
      <DialogGen
        open={modalOpen}
        onClick={() => {
          setModalOpen(!modalOpen);
          setSubmitSucceeded(false);
        }}
        sxBody={{ maxHeight: "" }}
        sx={{ p: 3 }}
        title={"Agregar nuevo proveedor"}
      >
        <FormSuppliersDetails
          supplier={[]}
          refetch={refetch}
          modalOpen={modalOpen}
          typeSuppliers={typeSuppliers}
          submitSucceeded={submitSucceeded}
          setModalOpen={setModalOpen}
          setSubmitSucceeded={setSubmitSucceeded}
          setShowModalProductsSuppliers={setShowModalProductsSuppliers}
          setAlertCustom={setAlertCustom}
          botonAsignarProducto={false}
          permisos={permisos}
          listUsers={listUsers}
        />
      </DialogGen>
      <DialogGen
        open={showModalProductsSupplier}
        onClick={() => {
          setShowModalProductsSuppliers(false);
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          });
        }}
        sx={{ width: "90%" }}
        sxBody={{ maxHeight: 500 }}
        title="Productos asignados"
      >
        <FormProductsSupplier
          products={products}
          supplier={supplier}
          productsSuppliers={productsSuppliers}
          setOpenAlertAnimation={setOpenAlertAnimation}
          refetchProductsSuppliers={refetchProductsSuppliers}
        />
      </DialogGen>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />

      <AlertAnimation
        height={100}
        width={100}
        open={openAlertAnimation.open}
        closer={() => {
          setOpenAlertAnimation({ open: false, message: "", severity: "" });
          if (openAlertAnimation.severity !== "error") {
            setShowModalProductsSuppliers(false);
          }
        }}
        message={openAlertAnimation.message}
        severity={openAlertAnimation.severity}
      />
    </>
  );
};

export default SuppliersMobi;
