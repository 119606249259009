import { Field } from "react-final-form";
import { InputNumber, InputText } from "../../../components/Input";
import { Box, InputAdornment } from "@mui/material";
import { SelectMui } from "../../../components/SelectMui";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveDataProcesoProductivo } from "../hook/saveDataProcesoProductivo";
import { required } from "../../../utils/validations";
import { FormConfirm } from "./formConfirm";
import { DialogGen } from "../../../components/Dialog";
import { useState } from "react";
const CardProcesos = ({
  name,
  procesosTipoId,
  listUbicationProvider,
  procesoProductivoId,
  values,
  refetch,
  setAlertCustom,
  index,
}) => {
  const [open, setOpen] = useState({
    open: false,
    method: "",
    funcion: () => {},
  });
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box className="col-12" sx={{ p: 1 }}>
        <Field
          name={`${name}.procesoProductivoTipoId`}
          component={SelectMui as any}
          label={"Area"}
          options={procesosTipoId.map((item) => {
            return {
              value: item.procesoProductivoTipoId,
              name: item.procesoProductivoTipoDescripcion,
            };
          })}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box className="col-6" sx={{ p: 1 }}>
        <Field
          validate={required}
          name={`${name}.procesoProductivoDescripcion`}
          label={"Procesos productivo"}
          component={InputText as any}
          sx={{ width: "100%", maxWidth: "150px" }}
        />
      </Box>
      <Box className="col-6" sx={{ p: 1 }}>
        <Field
          name={`${name}.stockUbicacionId`}
          component={SelectMui as any}
          label={"Ubicación"}
          options={listUbicationProvider.map((item) => {
            return {
              value: item.stockUbicacionId,
              name: item.stockUbicacionDescripcion,
            };
          })}
          sx={{ width: "100%", maxWidth: "150px" }}
        />
      </Box>
      <Box className="col-6" sx={{ p: 1 }}>
        <Field
          name={`${name}.procesoProductivoHorasDisponiblesPorDia`}
          component={InputNumber as any}
          label={"Horas disponible por dia"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">HORAS</InputAdornment>
            ),
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
        />
      </Box>
      <Box
        className="col-6"
        sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
      >
        <Field
          name={`${name}.procesoProductivoCostoPorHoraPorPersona`}
          component={InputNumber as any}
          label="Costo mano de obra por hora por persona"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
        />

        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          {!!values.datos[index].procesoProductivoDescripcion && (
            <>
              <SaveIcon
                onClick={() =>
                  setOpen({
                    open: true,
                    method: "Guardar cambios ?",
                    funcion: () => {
                      saveDataProcesoProductivo({
                        procesoProductivoId,
                        values: values.datos.filter(
                          (item) =>
                            item.procesoProductivoId === procesoProductivoId
                        ),
                        refetch,
                        setAlertCustom,
                      });
                    },
                  })
                }
                color="primary"
              />
              <DeleteIcon
                color="error"
                onClick={() =>
                  setOpen({
                    open: true,
                    method: `Estas seguro que deseas eliminar  ${
                      values.datos.filter(
                        (item) =>
                          item.procesoProductivoId === procesoProductivoId
                      )[0]?.procesoProductivoTipoDescripcion
                    } ${
                      values.datos.filter(
                        (item) =>
                          item.procesoProductivoId === procesoProductivoId
                      )[0]?.procesoProductivoTipoDescripcion !== "-"
                        ? "-"
                        : ""
                    } ${
                      values.datos.filter(
                        (item) =>
                          item.procesoProductivoId === procesoProductivoId
                      )[0]?.procesoProductivoDescripcion
                    } ?`,
                    funcion: () => {
                      saveDataProcesoProductivo({
                        procesoProductivoId,
                        values: values.datos.filter(
                          (item) =>
                            item.procesoProductivoId === procesoProductivoId
                        ),
                        refetch,
                        setAlertCustom,
                        eliminar: true,
                      });
                    },
                  })
                }
              />
            </>
          )}
        </Box>
      </Box>
      <DialogGen
        open={open.open}
        onClick={() => setOpen({ open: false, method: "", funcion: () => {} })}
        title={`${open.method}`}
      >
        <FormConfirm
          method={open.method}
          funcion={() => open.funcion()}
          closer={() => setOpen({ open: false, method: "", funcion: () => {} })}
        />
      </DialogGen>
    </Box>
  );
};
export default CardProcesos;
