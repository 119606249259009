import { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Box } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { Login } from "./pages/login";
import { Home } from "./pages/home";
import { Loading } from "./pages/loading";
import { Usuario } from "./pages/usuario";
import { PageNotFound } from "./pages/pageNotFound";
import DetailsProducts from "./pages/detailsProducts/detailsProducts";
import PageNotFunctional from "./pages/pageNotfunctional";
import Products from "./pages/products/products";
import ControlStock from "./pages/controlStock";
import StockPerProduct from "./pages/stockPerProduct/stockPerProduct";
import StockPerUbication from "./pages/StockPerUbication";
import GeneracionOC from "./pages/generateOC/generateOC";
import ReporteOC from "./pages/reporteOC/reporteOC";
import Supplier from "./pages/suppliers/suppliers";
import GeneralConfig from "./pages/generalConfig/generalConfig";
import Income from "./pages/income/income";
import { ProductionPlan } from "./pages/productionPlan/productionPlan";
import { ProductionPlanMobi } from "./pages/productionPlanMobi/productionPlanMobi";
import CrearOtgMobi from "./pages/crearOtgMobi/crearOtgMobi";
import DetailsOTGMobi from "./pages/detailsOtgMobi/detailsOtgMobi";
import DetailsOTG from "./pages/detailsOtg/detailsOtg";
import CrearOtg from "./pages/crearOtg/crearOtg";
import ProcesosProductivosMobi from "./pages/procesosProductivosMobi/ProcesosProductivosMobi";
import ProcesosProductivos from "./pages/procesosProductivos/ProcesosProductivos";
import HorasDeTrabajoMobi from "./pages/horasDeTrabajoMobi/horasDeTrabajoMobi";
import HorasDeTrabajo from "./pages/horasDeTrabajo/horasDeTrabajo";
import { UpdateUsers } from "./pages/UpdateUsers/updateUsers";
import SuppliersMobi from "./pages/suppliersMobi/suppliersMobi";

import { getUser, usePermisos } from "./hooks/auth";

import { verificarPermiso } from "./utils/verificarPermiso";
import { PERMISOS } from "./constants/MenuOptions";
import { useListUsers } from "./hooks/useUsers";
import Permisos from "./pages/permisos/permisos";
import ModuloCalidad from "./pages/moduloCalidad/moduloCalidad";
import AdminDiasNoLaborable from "./pages/adminDiasNoLaborable/adminDiasNoLaborable";
import AdminDiaslaborableExepcion from "./pages/adminDiaslaborableExepcion/adminDiaslaborableExepcion";
import ModuloEgresos from "./pages/egresos/egresos";
import AdminMotivoAjusteStock from "./pages/adminMotivoAjusteStock/adminMotivoAjusteStock";
import ReporteCosto from "./pages/reporteCosto/reporteCosto";
import Reportes from "./pages/reportes";
import AdminMoneda from "./pages/adminMoneda/adminMoneda";
import AdminCotizacion from "./pages/adminCotizacion/adminCotizacion";
import ConversionUnidadMedida from "./pages/conversionUnidadMedida/conversionUnidadMedida";
import AdminUbicacion from "./pages/adminUbicacion/adminUbicacion";
import ModuloPedidos from "./pages/moduloPedidos";
import CrearNotasPedidos from "./pages/crearNotaPedidos";
import AdminEmbalaje from "./pages/adminEmbalaje/adminEmbalaje";

const Main = () => {
  const [user, setUser] = useState(null);
  const [isMobi, setIsMobi] = useState(false);
  const [loading, setLoading] = useState(true);
  const { data: permisos, isLoading: isLoadingPermisos } = usePermisos(1);
  const { data: listUsers, isLoading: loadingListUsers } = useListUsers();
  useEffect(() => {
    if (window.screen.availWidth < 600) {
      setIsMobi(true);
    }
  }, []);

  const firebaseConfig = {
    apiKey: "AIzaSyDNNl7_AJgWp5_2kKfWuzo2PeP3byuR2G8",
    authDomain: "planning-poltron.firebaseapp.com",
    projectId: "planning-poltron",
    storageBucket: "planning-poltron.appspot.com",
    messagingSenderId: "687643132088",
    appId: "1:687643132088:web:df48b54c6f85c598cbf44d",
    measurementId: "G-ETQRZ3N6RY",
  };

  initializeApp(firebaseConfig);
  getFirestore();
  // const analytics = getAnalytics(app);
  const auth = getAuth();

  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user?.uid as any);
      setLoading(false);
    } else {
      setUser(null);
      setLoading(false);
    }
  });
  if (loading || loadingListUsers || isLoadingPermisos) {
    return <Loading />;
  }
  if (user) {
    return (
      <div style={{ flex: 1 }}>
        <div className="d-flex flex-row">
          <Router>
            <hr />
            <Box
              className="FondoGeneral"
              style={{ minHeight: "100vh", width: "100%" }}
            >
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/Home">
                  <Home />
                </Route>
                <Route exact path="/Administración">
                  <Usuario />
                </Route>
                <Route exact path="/Productos">
                  <Products />
                </Route>
                <Route exact path="/Pedidos">
                  <ModuloPedidos />
                </Route>
                <Route exact path="/NotaPedidos">
                  <CrearNotasPedidos />
                </Route>
                <Route exact path="/Ajustes">
                  <GeneralConfig />
                </Route>
                <Route exact path="/ControlStock">
                  <ControlStock />
                </Route>
                <Route exact path="/StockPorProducto">
                  <StockPerProduct />
                </Route>
                {verificarPermiso(
                  permisos,
                  PERMISOS.SOLO_CONSULTA_DE_UBICACION_DEL_STOCK,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/StockPorUbicacion">
                    <StockPerUbication />
                  </Route>
                )}
                {verificarPermiso(
                  permisos,
                  PERMISOS.CREA_OC_PARA_USO_INTERNO,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/GeneracionOC">
                    <GeneracionOC />
                  </Route>
                )}
                {verificarPermiso(
                  permisos,
                  PERMISOS.SOLO_CONSULTA_DE_OC_SEGUN_PROVY_STATUS,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/ReporteOC">
                    <ReporteOC />
                  </Route>
                )}
                {verificarPermiso(
                  permisos,
                  PERMISOS.INGRESO_DE_NUEVOS_INSUMOS_Y_TERCERIZADOS,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/ingreso">
                    <Income />
                  </Route>
                )}
                {verificarPermiso(
                  permisos,
                  PERMISOS.FLECHA_PARA_DETALLE_DE_LAS_OTG,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/detailsOrdenTrabajo/:id">
                    {isMobi ? <DetailsOTGMobi /> : <DetailsOTG />}
                  </Route>
                )}
                <Route exact path="/Plan">
                  {isMobi ? <ProductionPlanMobi /> : <ProductionPlan />}
                </Route>
                <Route exact path="/CrearOtg">
                  {isMobi ? <CrearOtgMobi /> : <CrearOtg />}
                </Route>
                {verificarPermiso(
                  permisos,
                  PERMISOS.ACCESO_PANTALLA_INICIO_DE_TRABAJO,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/HorasDeTrabajo/:id">
                    {isMobi ? <HorasDeTrabajoMobi /> : <HorasDeTrabajo />}
                  </Route>
                )}
                <Route exact path="/DetalleProductos/:id/:array?">
                  <DetailsProducts />
                </Route>
                {verificarPermiso(
                  permisos,
                  PERMISOS.CREAR_EDITAR_Y_ELIMINAR_PROCESOS_PRODUCTIVOS,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/ProcesosProductivos">
                    {isMobi ? (
                      <ProcesosProductivosMobi />
                    ) : (
                      <ProcesosProductivos />
                    )}
                  </Route>
                )}
                {verificarPermiso(
                  permisos,
                  PERMISOS.CREAR_Y_EDITAR_DATOS_DE_LOS_PROVEEDORES,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/Proveedores">
                    {isMobi ? <SuppliersMobi /> : <Supplier />}
                  </Route>
                )}
                {verificarPermiso(
                  permisos,
                  PERMISOS.MODIFICAR_DATOS_DE_USUARIOS,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/updateUsers">
                    {isMobi ? <UpdateUsers /> : <UpdateUsers />}
                  </Route>
                )}
                <Route exact path="/ModuloCalidad">
                  {isMobi ? <ModuloCalidad /> : <ModuloCalidad />}
                </Route>
                {verificarPermiso(
                  permisos,
                  PERMISOS.MODIFICAR_DATOS_DE_USUARIOS,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                ) && (
                  <Route exact path="/Permisos">
                    <Permisos />
                  </Route>
                )}
                <Route exact path="/adminDiasNoLaborables">
                  <AdminDiasNoLaborable />
                </Route>
                <Route exact path="/adminDiasLaborablesExepcion">
                  <AdminDiaslaborableExepcion />
                </Route>
                <Route exact path="/egresos">
                  <ModuloEgresos />
                </Route>
                <Route exact path="/adminMotivoAjusteStock">
                  <AdminMotivoAjusteStock />
                </Route>
                <Route exact path="/reportes">
                  <Reportes />
                </Route>
                <Route exact path="/costo">
                  <ReporteCosto />
                </Route>
                <Route exact path="/administrarMoneda">
                  <AdminMoneda />
                </Route>
                <Route exact path="/administrarCotizacion">
                  <AdminCotizacion />
                </Route>
                <Route exact path="/administrarConversionDeUnidadDeMedida">
                  <ConversionUnidadMedida />
                </Route>
                <Route exact path="/administrarUbicacionesDeStock">
                  <AdminUbicacion />
                </Route>

                <Route component={PageNotFound} />
              </Switch>
            </Box>
          </Router>
        </div>
      </div>
    );
  } else return <Login />;
};
export default Main;
