import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Chip, FormHelperText, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import { DialogGen } from "../Dialog";

export const SelectMui = ({
  input: { value, onChange },
  meta: { touched, error },
  options,
  optionSelected = null,
  label,
  sx = {},
  onchange,
  meta,
  empty,
  disabled = false,
}) => {
  return (
    <div>
      <FormControl sx={sx} size="small">
        <InputLabel id="demo-simple-select-autowidth-label">{label}</InputLabel>
        <Select
          error={!!(touched && error)}
          value={optionSelected ? optionSelected : value}
          disabled={disabled}
          onChange={({ target: { value } }) => {
            onChange(value);
            if (onchange) {
              onchange(value);
            }
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "textTertiary.main",
            },
            "& .MuiOutlinedInput-root:hover": {
              "& > fieldset": {
                borderColor: "textTertiary.main",
              },
            },
          }}
        >
          {empty && <MenuItem value="todos">Todos</MenuItem>}
          {options?.map((item) => (
            <MenuItem key={item.id} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: "red", fontSize: "12px" }}>
          {meta.touched && meta.error}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export const DialogSelect = ({
  input: { value, onChange },
  estados,
  meta: { touched, error },
  options,
  disabled = false,
  color,
  backgroundColor,
  sxChip = {},
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Chip
        size="small"
        color={color}
        sx={{
          mt: 1,
          width: "100%",
          cursor: "pointer",
          color: "black",
          backgroundColor: backgroundColor,
          ...sxChip,
        }}
        label={
          <Typography variant="h6">
            {
              estados.find((item) => item.ordenTrabajoEstadoId === value)
                ?.ordenTrabajoEstadoDescripcion
            }
          </Typography>
        }
        disabled={disabled}
        onClick={() => setOpen(true)}
        variant="outlined"
      />
      <DialogGen
        open={open}
        title={"Cambiar estado"}
        onClick={() => setOpen(false)}
        sx={{ padding: "8px 16px 8px 16px" }}
      >
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel sx={{ marginTop: "4px" }}>Estado</InputLabel>
              <Select
                value={value}
                onChange={(event) => {
                  onChange(event.target.value);
                }}
                input={<OutlinedInput label="Estado" />}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "textTertiary.main",
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "textTertiary.main",
                    },
                  },
                }}
              >
                {options.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
      </DialogGen>
    </>
  );
};
