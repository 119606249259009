import { Field } from "react-final-form";
import { InputNumber, InputText } from "../../../components/Input";
import { Box, InputAdornment } from "@mui/material";
import { SelectMui } from "../../../components/SelectMui";
import SaveIcon from "@mui/icons-material/Save";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { required } from "../../../utils/validations";
import { FormConfirm } from "./formConfirm";
import { DialogGen } from "../../../components/Dialog";
import { useState } from "react";
import { saveDataProcesoProductivo } from "../../procesosProductivosMobi/hook/saveDataProcesoProductivo";
const CardProcesosMobi = ({
  name,
  procesosTipoId,
  listUbicationProvider,
  procesoProductivoId,
  values,
  refetch,
  setAlertCustom,
  index,
  disabled,
}) => {
  const [open, setOpen] = useState({
    open: false,
    method: "",
    funcion: () => {},
  });
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Box className="col-3" sx={{ p: 1 }}>
        <Field
          name={`${name}.procesoProductivoTipoId`}
          component={SelectMui as any}
          label={"Area"}
          options={procesosTipoId.map((item) => {
            return {
              value: item.procesoProductivoTipoId,
              name: item.procesoProductivoTipoDescripcion,
            };
          })}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box className="col-3" sx={{ p: 1 }}>
        <Field
          validate={required}
          name={`${name}.procesoProductivoDescripcion`}
          label={"Procesos productivo"}
          component={InputText as any}
          sx={{ width: "100%" }}
        />
      </Box>
      <Box className="col-2" sx={{ p: 1 }}>
        <Field
          name={`${name}.stockUbicacionId`}
          component={SelectMui as any}
          label={"Ubicacion"}
          options={listUbicationProvider.map((item) => {
            return {
              value: item.stockUbicacionId,
              name: item.stockUbicacionDescripcion,
            };
          })}
          sx={{ width: "100%" }}
        />
      </Box>

      <Box
        className="col-4"
        sx={{ display: "flex", justifyContent: "space-between", p: 1 }}
      >
        <Field
          name={`${name}.procesoProductivoCostoPorHoraPorPersona`}
          component={InputNumber as any}
          label="Costo mano de obra por hora por persona"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          sx={{ pr: 2 }}
        />
        <Field
          name={`${name}.procesoProductivoHorasDisponiblesPorDia`}
          component={InputNumber as any}
          label="Horas disponible por dia"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Horas</InputAdornment>
            ),
            inputProps: {
              style: { textAlign: "right" },
            },
          }}
          sx={{ pr: 2 }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          {!!values.datos[index].procesoProductivoDescripcion && (
            <>
              {disabled && (
                <SaveIcon
                  onClick={() =>
                    setOpen({
                      open: true,
                      method: "Guardar cambios ?",
                      funcion: () => {
                        saveDataProcesoProductivo({
                          procesoProductivoId,
                          values: values.datos.filter(
                            (item) =>
                              item.procesoProductivoId === procesoProductivoId
                          ),
                          refetch,
                          setAlertCustom,
                          eliminar: false,
                        });
                      },
                    })
                  }
                  color="primary"
                  sx={{ cursor: "pointer", mr: 2 }}
                />
              )}
              {values.datos[index].procesoProductivoActivo === "1" ? (
                <ToggleOnIcon
                  sx={{ cursor: "pointer" }}
                  color="success"
                  onClick={() =>
                    setOpen({
                      open: true,
                      method: `Estas seguro que deseas inactivar  "${
                        values.datos.filter(
                          (item) =>
                            item.procesoProductivoId === procesoProductivoId
                        )[0]?.procesoProductivoTipoDescripcion
                      } ${
                        values.datos.filter(
                          (item) =>
                            item.procesoProductivoId === procesoProductivoId
                        )[0]?.procesoProductivoTipoDescripcion !== "-"
                          ? "-"
                          : ""
                      } ${
                        values.datos.filter(
                          (item) =>
                            item.procesoProductivoId === procesoProductivoId
                        )[0]?.procesoProductivoDescripcion
                      }" ?`,
                      funcion: () => {
                        saveDataProcesoProductivo({
                          procesoProductivoId,
                          values: values.datos.filter(
                            (item) =>
                              item.procesoProductivoId === procesoProductivoId
                          ),
                          refetch,
                          setAlertCustom,
                          eliminar: true,
                        });
                      },
                    })
                  }
                />
              ) : (
                <ToggleOffIcon
                  sx={{ cursor: "pointer" }}
                  color="error"
                  onClick={() =>
                    setOpen({
                      open: true,
                      method: `Estas seguro que deseas activar  "${
                        values.datos.filter(
                          (item) =>
                            item.procesoProductivoId === procesoProductivoId
                        )[0]?.procesoProductivoTipoDescripcion
                      } ${
                        values.datos.filter(
                          (item) =>
                            item.procesoProductivoId === procesoProductivoId
                        )[0]?.procesoProductivoTipoDescripcion !== "-"
                          ? "-"
                          : ""
                      } ${
                        values.datos.filter(
                          (item) =>
                            item.procesoProductivoId === procesoProductivoId
                        )[0]?.procesoProductivoDescripcion
                      }" ?`,
                      funcion: () => {
                        saveDataProcesoProductivo({
                          procesoProductivoId,
                          values: values.datos.filter(
                            (item) =>
                              item.procesoProductivoId === procesoProductivoId
                          ),
                          refetch,
                          setAlertCustom,
                          eliminar: true,
                        });
                      },
                    })
                  }
                />
              )}
            </>
          )}
        </Box>
      </Box>
      <DialogGen
        open={open.open}
        onClick={() => setOpen({ open: false, method: "", funcion: () => {} })}
        title={`${open.method}`}
        sx={{ maxWidth: "300px" }}
      >
        <FormConfirm
          method={open.method}
          funcion={() => open.funcion()}
          closer={() => setOpen({ open: false, method: "", funcion: () => {} })}
        />
      </DialogGen>
    </Box>
  );
};
export default CardProcesosMobi;
