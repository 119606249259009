import { useState } from "react";
import { Box } from "@mui/material";
import { AlertCustom } from "../../components/AlertMui";
import { DialogGen } from "../../components/Dialog";
import Layout from "../../components/Layout";
import {
  useListDeposito,
  useListMotivoStock,
  useListStockState,
  useListUbicationProvider,
  useProducts,
  useStockAudi,
  useStockProduct,
} from "../../hooks/useProducts";

import { Loading } from "../loading";
import { ArmarDatosMovimientos } from "./components/armarDatosMomientos";
import { TablaGeneral } from "./components/tablaGeneral";
import { FormCrearUbicacion } from "./components/formCrearUbicacion";
import { FormAjusteStock } from "./components/formAjusteStock";
import { FormTransferencia } from "./components/formTransferencia";
import Button from "../../components/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getUser, usePermisos } from "../../hooks/auth";
import { verificarPermiso } from "../../utils/verificarPermiso";
import { PERMISOS } from "../../constants/MenuOptions";
import { useListUsers } from "../../hooks/useUsers";
import { useSuppliers } from "../../hooks/useSuppliers";
const StockPerProduct = () => {
  const {
    data: stockProducts,
    isLoading: isLoadingStockProduct,
    refetch,
  } = useStockProduct();

  const { data: listStockAudi, isLoading: isLoadingListStockAudi } =
    useStockAudi(0);

  const {
    data: listUbicationProvider,
    isLoading: isLoadingListUbicationProvider,
  } = useListUbicationProvider(0);
  const { data: listStockState, isLoading: isLoadingListStockState } =
    useListStockState();
  const { data: listMotiveStock, isLoading: isLoadingListMotiveStock } =
    useListMotivoStock();
  const { data: products, isLoading } = useProducts();
  const { data: listDeposito, isLoading: isLoadingDeposito } =
    useListDeposito();
  const { data: listEntidades, isLoading: isLoadingListEntidades } =
    useSuppliers();
  const [showModalAddUbi, setShowModalAddUbi] = useState(false);
  const [showModalAddMovements, setShowModalAddMovements] = useState(false);
  const [showModalMovementsProduct, setShowModalMovementsProduct] = useState({
    open: false,
    datos: [],
  });
  const [showModalInvAdj, setShowModalInvAdj] = useState(false);

  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  if (
    isLoadingStockProduct ||
    isLoadingListStockAudi ||
    isLoadingListUbicationProvider ||
    isLoading ||
    isLoadingListMotiveStock ||
    isLoadingDeposito ||
    isLoadingListStockState ||
    loadingListUsers ||
    isLoadingPermisos ||
    isLoadingListEntidades
  ) {
    return <Loading />;
  }
  const user = listUsers.find(
    (item) => item.radiusIdCodigo === getUser().uid
  )?.idRadius;
  return (
    <>
      <Layout title="Stock por productos, insumos y tercerizados">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box className="d-flex flex-wrap" sx={{ ml: 4 }}>
            <Button
              label="Agregar ubicacion"
              disabled={
                !verificarPermiso(
                  permisos,
                  PERMISOS.AGREGAR_NUEVA_UBICACION,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                )
              }
              sx={{
                m: 1,
              }}
              onClick={() => setShowModalAddUbi(true)}
            >
              <AddCircleOutlineIcon sx={{ pr: 1 }} />
            </Button>
            <Button
              disabled={
                !verificarPermiso(
                  permisos,
                  PERMISOS.REALIZAR_TRANSFERENCIA_ENTRE_VARIABLES,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                )
              }
              label="Realizar transferencia"
              onClick={() => setShowModalAddMovements(true)}
              sx={{ m: 1 }}
            />

            <Button
              disabled={
                !verificarPermiso(
                  permisos,
                  PERMISOS.REALIZAR_AJUSTES_DE_STOCK_POR_DIFERENCIAS,
                  listUsers?.find(
                    (item) => item?.radiusIdCodigo === getUser()?.uid
                  )?.idGrupo
                )
              }
              onClick={() => setShowModalInvAdj(true)}
              sx={{ m: 1 }}
              label="Realizar ajustes"
            />
          </Box>
          <Box sx={{ mt: 2 }} className="d-flex justify-content-center">
            <TablaGeneral
              stockProducts={stockProducts}
              setShowModalMovementsProduct={setShowModalMovementsProduct}
              permisos={permisos}
              listUsers={listUsers}
            />
            <DialogGen
              open={showModalAddUbi}
              title="Agregar ubicacion"
              onClick={() => setShowModalAddUbi(false)}
            >
              <FormCrearUbicacion
                user={user}
                listDeposito={listEntidades}
                setShowModalAddUbi={setShowModalAddUbi}
                setAlertCustom={setAlertCustom}
              />
            </DialogGen>
            <DialogGen
              sx={
                window.screen.availWidth < 600
                  ? { width: "80%" }
                  : { width: "50%" }
              }
              open={showModalInvAdj}
              title="Ajuste de stock"
              onClick={() => setShowModalInvAdj(false)}
            >
              <FormAjusteStock
                user={user}
                listDeposito={listDeposito}
                products={products}
                listMotiveStock={listMotiveStock}
                listUbicationProvider={listUbicationProvider}
                listStockState={listStockState}
                setAlertCustom={setAlertCustom}
                refetch={refetch}
              />
            </DialogGen>
            <DialogGen
              sx={
                window.screen.availWidth < 600
                  ? { width: "80%" }
                  : { width: "50%" }
              }
              sxBody={{ maxHeight: "500px" }}
              open={showModalAddMovements}
              title="Realizar transferencia entre ubicaciones"
              onClick={() => setShowModalAddMovements(false)}
            >
              <FormTransferencia
                user={user}
                products={products}
                listDeposito={listDeposito}
                stockProducts={stockProducts}
                listUbicationProvider={listUbicationProvider}
                listStockState={listStockState}
                setAlertCustom={setAlertCustom}
                refetch={refetch}
              />
            </DialogGen>
            <DialogGen
              open={showModalMovementsProduct.open}
              title="Historial"
              onClick={() =>
                setShowModalMovementsProduct({ open: false, datos: [] })
              }
              sxBody={{ maxHeight: "600px" }}
              sx={{ maxWidth: "95%" }}
            >
              <ArmarDatosMovimientos
                datosTable1={showModalMovementsProduct.datos}
                datosTable2={listStockAudi}
              />
            </DialogGen>
          </Box>
        </Box>
      </Layout>
      <AlertCustom
        open={alertCustom.open}
        title={alertCustom.title}
        message={alertCustom.message}
        severity={alertCustom.severity}
        onclick={() =>
          setAlertCustom({
            open: false,
            title: "",
            severity: "",
            message: "",
          })
        }
      />
    </>
  );
};

export default StockPerProduct;
