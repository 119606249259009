import { Box, Typography } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { getColorMaterial } from "../../../utils/getColorMaterial";
import { numeroOtg } from "../../../utils/numeroOtg";
import CircleIcon from "@mui/icons-material/Circle";
import { SaveActividadUsuario } from "../../../services/ordenTrabajo";

export const CardOtgHorasTrabajo = ({
  datos: {
    ordenTrabajoId,
    ordenTrabajoCantidad,
    ordenTrabajoCantidadProducida,
    skuDescripcion,
    ordenTrabajoStatusMateriales,
    ordenTrabajoPrioridad,
    id,
    user,
    userId,
    setShowModalStop,
    setAlertCustom,
    estadoOtgId,
    refetchListadoHorasPorUsuario,
    sx = {},
  },
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "row",
      border: "0.5px solid #C1C1C1",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      p: 1,
      mt: 1,
      ...sx,
    }}
  >
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        className="col-2"
      >
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          variant="h2"
        >
          Prioridad
        </Typography>

        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          variant="h3"
        >
          {ordenTrabajoPrioridad}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="col-3"
      >
        <Typography variant="h2">{`OTG - ${numeroOtg(
          Number(ordenTrabajoId)
        )}`}</Typography>
        <Typography
          sx={{
            mt: 0.1,

            px: 1,
          }}
          variant="h3"
        >
          {skuDescripcion}
        </Typography>
      </Box>
      <Box className="col-2">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h2">Materiales</Typography>
          <Box>
            <CircleIcon
              color={getColorMaterial(Number(ordenTrabajoStatusMateriales))}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mt: 0.2 }}>
          <Typography variant="h3">{`${ordenTrabajoCantidadProducida} / ${ordenTrabajoCantidad}`}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="col-2"
      >
        <Typography variant="h2">Usuario</Typography>
        <Typography
          sx={{
            mt: 1,

            px: 1,
          }}
          variant="h3"
        >
          {user}
        </Typography>
      </Box>

      <Box className="col-4" sx={{ display: "flex", justifyContent: "center" }}>
        <PlayCircleIcon
          color={estadoOtgId !== "2" ? "success" : "primary"}
          sx={{ cursor: "pointer", fontSize: "80px" }}
          onClick={() => {
            if (estadoOtgId !== "2") {
              SaveActividadUsuario(id, {
                id_radius: userId,
                orden_trabajo_estado_id: 2,
              })
                .then(() => {
                  setAlertCustom({
                    open: true,
                    title: "Confirmacion",
                    severity: "success",
                    message: "Se inició correctamente",
                  });
                  refetchListadoHorasPorUsuario();
                })
                .catch((error) =>
                  setAlertCustom({
                    open: true,
                    title: "Error",
                    severity: "error",
                    message: error.message,
                  })
                );
            } else {
              setAlertCustom({
                open: true,
                title: "Error",
                severity: "error",
                message: "Ya esta en curso",
              });
            }
          }}
        />

        <StopCircleIcon
          color={estadoOtgId === "2" ? "error" : "primary"}
          sx={{ cursor: "pointer", fontSize: "80px" }}
          onClick={() => {
            if (estadoOtgId === "2") {
              setShowModalStop({ open: true, userId: userId });
            } else {
              setAlertCustom({
                open: true,
                title: "Error",
                severity: "error",
                message: "No hay ninguna orden de trabajo en curso",
              });
            }
          }}
        />
      </Box>
    </>
  </Box>
);
