import { useQuery } from 'react-query';
import { getListGroup, getListUsers } from '../services/usersServices';
export const useListGroup =  () => {
    
    const { data, isLoading, isError , isSuccess,refetch} = useQuery('useListGroup', async () => {    
      const response = await getListGroup();       
      return response.data;
    }); 
    
    return { 
      data: data,
      isLoading,
      isSuccess,refetch,
      isError 
     }
}

export const useListUsers =  () => {
    const { data, isLoading, isError ,refetch, isSuccess} = useQuery('useListUsers', async () => {    
      const response = await getListUsers();       
      return response.data;
    }); 
    
    return { 
      data: data,
      isLoading,
        isSuccess,
        refetch,
      isError 
     }
}