import { useParams } from "react-router";
import arrayMutators from "final-form-arrays";
import { Box, Card, Typography } from "@mui/material";
import {
  useAllOrdenTrabajo,
  useOrdenTrabajoEstado,
  useOrdenTrabajoFormula,
  useOrdenTrabajoUsuario,
} from "../../hooks/useOrdenTrabajo";
import { Loading } from "../loading";
import { CardOTG } from "../productionPlanMobi/components/CardOTG";
import Button from "../../components/Button";
import { CardDetailsOtg } from "./component/cardDetailsOtg";
import { useEffect, useState } from "react";
import { FormDeclaraciones } from "./component/fieldDeclaraciones";
import { Field, Form } from "react-final-form";
import { LayoutMobi } from "../../components/Layout";
import { FieldArray } from "react-final-form-arrays";
import { DialogGen } from "../../components/Dialog";
import { FormularioAgregarMaterial } from "../../components/FormularioAgregarMaterial";
import {
  useMeasures,
  useProducts,
  useStructure,
} from "../../hooks/useProducts";
import { AlertCustom } from "../../components/AlertMui";
import { saveDatailsOtg } from "./hook/saveDetailsOtg";
import { ChipMui } from "../../components/ChipMui";
import FormAddUsersOtgMobi from "./component/formAddUsersOtgMobi";
import { useListUsers } from "../../hooks/useUsers";
import { getUser, usePermisos } from "../../hooks/auth";
import { verificarPermiso } from "../../utils/verificarPermiso";
import { PERMISOS } from "../../constants/MenuOptions";

const DetailsOTGMobi = () => {
  let { id } = useParams();
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data,
    isLoading,
    isError,
    refetch: refetchOtg,
  } = useAllOrdenTrabajo();
  const {
    data: otgFormula,
    isLoading: isLoadingOtgFormula,
    isError: isErrorOtgFormula,
    refetch,
  } = useOrdenTrabajoFormula(id);
  const {
    data: structure,
    isLoading: isLoadingStructure,
    isError: isErrorStructure,
  } = useStructure(0);
  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();
  const {
    data: ordentrabajoEstado,
    isLoading: isLoadingOrdentrabajoEstado,
    isError: isErrorOrdentrabajoEstado,
  } = useOrdenTrabajoEstado();
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
  } = useListUsers();
  const {
    data: ordentrabajoUsuario,
    isLoading: isLoadingOrdentrabajoUsuario,
    isError: isErrorOrdentrabajoUsuario,
    refetch: refetchOrdenTrabajoUsuario,
  } = useOrdenTrabajoUsuario(id);
  const {
    data: products,
    isLoading: isLoadingProduct,
    isError: isErrorProduct,
  } = useProducts();
  const [showDeclaraciones, setShowDeclaraciones] = useState(false);

  const [showAgregarMaterial, setShowAgregarMaterial] = useState(false);
  const [initialValue, setInitialValue] = useState({});
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  useEffect(() => {
    setInitialValue({
      Formula: otgFormula,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otgFormula]);
  if (
    isLoading ||
    isLoadingOrdentrabajoUsuario ||
    loadingListUsers ||
    isLoadingProduct ||
    isLoadingStructure ||
    isLoadingOtgFormula ||
    isLoadingOrdentrabajoEstado ||
    isLoadingPermisos ||
    isLoadingMeasures
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(products) ||
    !Array.isArray(ordentrabajoUsuario) ||
    !Array.isArray(listUsers) ||
    !Array.isArray(ordentrabajoEstado) ||
    !Array.isArray(otgFormula) ||
    !Array.isArray(structure) ||
    !Array.isArray(data) ||
    !Array.isArray(measures) ||
    isError ||
    isErrorOrdentrabajoUsuario ||
    isErrorListUsers ||
    isErrorOrdentrabajoEstado ||
    isErrorStructure ||
    isErrorProduct ||
    isErrorOtgFormula ||
    isErrorPermisos ||
    isErrorMeasures
  ) {
    return (
      <LayoutMobi title={"Hubo un Error"}>
        <Box>
          <Typography variant="h3">
            Nos apena informate que ha ocurrido un error que debemos revisar
          </Typography>
        </Box>
      </LayoutMobi>
    );
  }
  console.log(listUsers);
  const otg = data.filter((item) => item.ordenTrabajoId === id);
  return (
    <LayoutMobi title={`Detalle de OTG-${id}`} href={"/plan"}>
      <Form
        onSubmit={(values) => {}}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          Formula: initialValue["Formula"],
          ...otg[0],
          usersOtg: ordentrabajoUsuario,
        }}
        render={({
          handleSubmit,
          submitting,
          pristine,
          values,
          invalid,
          form: {
            restart,
            mutators: { push },
          },
        }) => (
          <Card
            sx={{
              m: 1,
              p: 1,
              background: "#FFFFFF",
            }}
          >
            <Box
              sx={{
                background: "#FFFFFF",
                border: "0.5px solid #C5C5C5",
                boxSizing: "border-box",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
              }}
            >
              <CardOTG
                datos={{
                  ...otg[0],
                  boton: true,
                  estados: ordentrabajoEstado,
                  permisos: permisos,
                  listUsers: listUsers,
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                disabled={
                  !verificarPermiso(
                    permisos,
                    PERMISOS.DECLARAR_UNIDADES,
                    listUsers?.find(
                      (item) => item?.radiusIdCodigo === getUser()?.uid
                    )?.idGrupo
                  )
                }
                label={"Declarar unidades"}
                onClick={() => setShowDeclaraciones(true)}
              />
            </Box>

            <Card>
              {showDeclaraciones && (
                <FormDeclaraciones
                  cantidadRestante={
                    Number(values?.ordenTrabajoCantidadProducida) +
                      Number(values?.cantidad) >
                    Number(values?.ordenTrabajoCantidad)
                      ? "Excede la cantidad original"
                      : null
                  }
                />
              )}
            </Card>
            <Typography sx={{ color: "#797979", mt: 1 }} variant={"h1"}>
              Formula
            </Typography>
            <Box>
              <FieldArray name={"Formula"}>
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <>
                        <CardDetailsOtg
                          eliminar={values.Formula[index]?.eliminar}
                          name={values.Formula[index].skuDescripcion}
                          nameField={name}
                          consumoActual={
                            Number(
                              otg[0].ordenTrabajoCantidadProducida *
                                values.Formula[index]
                                  .ordenTrabajoFormulaCantidad
                            ) +
                            (Number(values.cantidad)
                              ? Number(values.cantidad) *
                                values.Formula[index]
                                  .ordenTrabajoFormulaCantidad
                              : 0)
                          }
                          consumoTotal={
                            otg[0].ordenTrabajoCantidad *
                            values.Formula[index].ordenTrabajoFormulaCantidad
                          }
                          permisos={permisos}
                          listUsers={listUsers}
                        />
                      </>
                    ))}
                  </>
                )}
              </FieldArray>

              <Box sx={{ mt: 1, mb: 2 }}>
                <Button
                  disabled={
                    !verificarPermiso(
                      permisos,
                      PERMISOS.AGREGAR_MATERIALES,
                      listUsers?.find(
                        (item) => item?.radiusIdCodigo === getUser()?.uid
                      )?.idGrupo
                    )
                  }
                  label={"Agregar materiales"}
                  onClick={() => setShowAgregarMaterial(true)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                background: "#FFFFFF",
                border: "0.5px solid #C5C5C5",
                boxSizing: "border-box",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                p: 1,
              }}
            >
              <Typography sx={{ display: "flex", justifyContent: "center" }}>
                Usuarios
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", mt: 1 }}>
                <FormAddUsersOtgMobi
                  values={values}
                  onClick={(valor) => push("usersOtg", valor)}
                  users={listUsers}
                  permisos={permisos}
                  listUsers={listUsers}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  maxHeight: 100,
                  overflowY: "auto",
                  mt: 2,
                }}
              >
                <FieldArray name="usersOtg">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <Field
                          disabled={
                            !verificarPermiso(
                              permisos,
                              PERMISOS.SELECCION_Y_AGREGADO_DE_USUARIO,
                              listUsers?.find(
                                (item) =>
                                  item?.radiusIdCodigo === getUser()?.uid
                              )?.idGrupo
                            )
                          }
                          component={ChipMui as any}
                          name={`${name}.user`}
                          handleDelete={() => {
                            fields.remove(index);
                          }}
                          sx={{ p: 0.5 }}
                        />
                      ))}
                    </>
                  )}
                </FieldArray>
              </Box>
            </Box>
            {pristine && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", my: 3 }}>
                <Button
                  disabled={
                    !verificarPermiso(
                      permisos,
                      PERMISOS.ACCESO_PANTALLA_INICIO_DE_TRABAJO,
                      listUsers?.find(
                        (item) => item?.radiusIdCodigo === getUser()?.uid
                      )?.idGrupo
                    )
                  }
                  label={"Acceso Pantalla Inicio de Trabajo"}
                  href={`/HorasDeTrabajo/${id}`}
                />
              </Box>
            )}
            {!pristine && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  px: 1,
                  mt: 2,
                }}
              >
                <Button
                  label="Cancelar"
                  onClick={() => {
                    restart();
                    setShowDeclaraciones(false);
                  }}
                />
                <Button
                  label="Confirmar"
                  color="success"
                  onClick={() => {
                    saveDatailsOtg({
                      values: values,
                      refetchOrdenTrabajoUsuario: refetchOrdenTrabajoUsuario,
                      measures: measures,
                      otg: otg,
                      setAlertCustom: setAlertCustom,
                      restart: () => restart(),
                      refetch: refetch,
                      refetchOtg: refetchOtg,
                      setShowDeclaraciones: setShowDeclaraciones,
                      id: id,
                    });
                  }}
                  disabled={invalid}
                />
              </Box>
            )}
            <DialogGen
              open={showAgregarMaterial}
              title={"Agregar producto a Formula"}
              onClick={() => {
                setShowAgregarMaterial(false);
              }}
              sx={{ width: "90%" }}
            >
              <FormularioAgregarMaterial
                datosFormPrincipal={values}
                push={(valor) => {
                  push("Formula", valor);
                }}
                setShowAgregarMaterial={(valor) =>
                  setShowAgregarMaterial(valor)
                }
                products={products}
                otg={otg}
              />
            </DialogGen>

            <AlertCustom
              open={alertCustom.open}
              onclick={() =>
                setAlertCustom({
                  open: false,
                  title: "",
                  severity: "",
                  message: "",
                })
              }
              severity={alertCustom.severity}
              title={alertCustom.title}
              message={alertCustom.message}
            />
          </Card>
        )}
      />
    </LayoutMobi>
  );
};
export default DetailsOTGMobi;
