import { useParams } from "react-router";
import arrayMutators from "final-form-arrays";
import { Box, Typography } from "@mui/material";
import {
  useAllOrdenTrabajo,
  useListadoHorasPorUsuario,
  useOrdenTrabajoEstado,
  useOrdenTrabajoFormula,
  useOrdenTrabajoMotivoPausa,
  useOrdenTrabajoUsuario,
} from "../../hooks/useOrdenTrabajo";
import { Loading } from "../loading";
import { CardOTG } from "../productionPlan/components/CardOTG";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import { FormDeclaraciones } from "./component/fieldDeclaraciones";
import { Field, Form } from "react-final-form";
import Layout from "../../components/Layout";
import { FieldArray } from "react-final-form-arrays";
import { DialogGen } from "../../components/Dialog";
import { FormularioAgregarMaterial } from "../../components/FormularioAgregarMaterial";
import {
  useMeasures,
  useProducts,
  useStructure,
} from "../../hooks/useProducts";
import { AlertCustom } from "../../components/AlertMui";

import ErrorMsg from "../../components/ErrorMsg";
import { ChipMui } from "../../components/ChipMui";
import FormAddUsersOtg from "./component/formAddUsersOtg";
import HorasDeTrabajo from "./component/horasDeTrabajo";
import { useListUsers } from "../../hooks/useUsers";
import { getUser, usePermisos } from "../../hooks/auth";
import { PERMISOS } from "../../constants/MenuOptions";
import { verificarPermiso } from "../../utils/verificarPermiso";
import Arbolito from "./component/arbolito";
import { CardDetailsOtg } from "./component/cardDetailsOtg";
import { saveDatailsOtgNew } from "./hook/saveDetailsOtgNew";
import { saveDatailsOtg } from "./hook/saveDetailsOtg";

const DetailsOTG = () => {
  let { id } = useParams();
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const {
    data,
    isLoading,
    isError,
    refetch: refetchOtg,
  } = useAllOrdenTrabajo();
  const {
    data: listadoHorasPorUsuario,
    isLoading: isLoadingListadoHorasPorUsuario,
    isError: isErrorListadoHorasPorUsuario,
    // refetch: refetchListadoHorasPorUsuario,
  } = useListadoHorasPorUsuario(id);

  const {
    data: otgFormula,
    isLoading: isLoadingOtgFormula,
    isError: isErrorOtgFormula,
    refetch,
  } = useOrdenTrabajoFormula(id);
  const {
    data: structure,
    isLoading: isLoadingStructure,
    isError: isErrorStructure,
  } = useStructure(0);
  const {
    data: measures,
    isLoading: isLoadingMeasures,
    isError: isErrorMeasures,
  } = useMeasures();
  const {
    data: ordentrabajoEstado,
    isLoading: isLoadingOrdentrabajoEstado,
    isError: isErrorOrdentrabajoEstado,
  } = useOrdenTrabajoEstado();
  const {
    data: ordentrabajoMotivoPausa,
    isLoading: isLoadingOrdentrabajoMotivoPausa,
    isError: isErrorOrdentrabajoMotivoPausa,
  } = useOrdenTrabajoMotivoPausa();
  const {
    data: ordentrabajoUsuario,
    isLoading: isLoadingOrdentrabajoUsuario,
    isError: isErrorOrdentrabajoUsuario,
    refetch: refetchOrdenTrabajoUsuario,
  } = useOrdenTrabajoUsuario(id);

  const {
    data: products,
    isLoading: isLoadingProduct,
    isError: isErrorProduct,
  } = useProducts(true);
  const {
    data: listUsers,
    isError: isErrorListUsers,
    isLoading: loadingListUsers,
  } = useListUsers();
  const [showDeclaraciones, setShowDeclaraciones] = useState(false);
  const [showAgregarMaterial, setShowAgregarMaterial] = useState(false);
  const [initialValue, setInitialValue] = useState({});
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  useEffect(() => {
    setInitialValue({
      Formula: otgFormula,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otgFormula]);

  if (
    loadingListUsers ||
    isLoadingListadoHorasPorUsuario ||
    isLoading ||
    isLoadingProduct ||
    isLoadingStructure ||
    isLoadingOtgFormula ||
    isLoadingOrdentrabajoEstado ||
    isLoadingOrdentrabajoUsuario ||
    isLoadingOrdentrabajoMotivoPausa ||
    isLoadingPermisos ||
    isLoadingMeasures
  ) {
    return <Loading />;
  }
  if (
    !Array.isArray(listUsers) ||
    !Array.isArray(listadoHorasPorUsuario) ||
    !Array.isArray(products) ||
    !Array.isArray(ordentrabajoEstado) ||
    !Array.isArray(ordentrabajoUsuario) ||
    !Array.isArray(ordentrabajoMotivoPausa) ||
    !Array.isArray(otgFormula) ||
    !Array.isArray(structure) ||
    !Array.isArray(data) ||
    !Array.isArray(measures) ||
    isErrorListUsers ||
    isErrorListadoHorasPorUsuario ||
    isError ||
    isErrorOrdentrabajoEstado ||
    isErrorOrdentrabajoUsuario ||
    isErrorOrdentrabajoMotivoPausa ||
    isErrorStructure ||
    isErrorProduct ||
    isErrorOtgFormula ||
    isErrorPermisos ||
    isErrorMeasures
  ) {
    return <ErrorMsg />;
  }

  const otg = data.filter((item) => item.ordenTrabajoId === id);
  const otgFamilia = data.filter(
    (item) =>
      item?.ordenTrabajoOrdenTrabajoIdCabecera ===
      otg[0]?.ordenTrabajoOrdenTrabajoIdCabecera
  );
  return (
    <Layout title={`Detalle de OTG-${id}`} clase={false}>
      <Form
        onSubmit={(values) => {}}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={{
          ...otg[0],
          listHorasTrabajo: listadoHorasPorUsuario.filter(
            (item) => item.ordenTrabajoEstadoId !== "3"
          ),
          usersOtg: ordentrabajoUsuario,
          orden_trabajo_generar_nuevo_lote: "1",
          Formula: initialValue["Formula"],
        }}
        render={({
          handleSubmit,
          submitting,
          pristine,
          values,
          invalid,
          form: {
            restart,
            mutators: { push },
          },
        }) => (
          <Box
            sx={{
              mx: 1,
              px: 1,
              background: "#FFFFFF",
              width: "100%",
            }}
          >
            <CardOTG
              datos={{
                ...otg[0],
                boton: true,
                onclick: () => setShowDeclaraciones(!showDeclaraciones),
                estados: ordentrabajoEstado,
              }}
              permisos={permisos}
              listUsers={listUsers}
            />
            <Arbolito otgFamilia={otgFamilia} />
            <Box sx={{ mt: 2 }}>
              {showDeclaraciones && (
                <FormDeclaraciones
                  sx={{
                    body: {
                      display: "flex",
                      flexDirection: "row",
                      background: "#FAFAFA",
                      p: 1,
                      mt: 1,
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      border: "0.5px solid #C5C5C5",
                    },
                    sxBackColor: {
                      background: "#E5E5E5",
                      p: 2,
                    },
                  }}
                  nuevoLote={values?.ordenTrabajoStockLoteProximo}
                  LoteAnterior={values?.ordenTrabajoStockLote}
                  orden_trabajo_generar_nuevo_lote={
                    values?.orden_trabajo_generar_nuevo_lote
                  }
                  cantidadRestante={
                    Number(values?.ordenTrabajoCantidadProducida) +
                      Number(values?.cantidad) >
                    Number(values?.ordenTrabajoCantidad)
                      ? "Excede la cantidad original"
                      : null
                  }
                />
              )}
            </Box>
            {/* {console.log(values)} */}
            <Typography sx={{ color: "#797979", mt: 1, ml: 1 }} variant={"h1"}>
              Formula
            </Typography>

            <Box sx={{ background: "#E5E5E5", p: 1, px: 2, mt: 0.5 }}>
              <FieldArray name="Formula">
                {({ fields }) => (
                  <>
                    {fields.map((name, index) => (
                      <>
                        <CardDetailsOtg
                          eliminar={values.Formula[index]?.eliminar}
                          name={values?.Formula[index]?.skuDescripcion}
                          nameField={name}
                          consumoActual={
                            Number(
                              otg[0]?.ordenTrabajoCantidadProducida *
                                values?.Formula[index]
                                  ?.ordenTrabajoFormulaCantidad
                            ) +
                            (Number(values?.cantidad)
                              ? Number(values?.cantidad) *
                                values?.Formula[index]
                                  ?.ordenTrabajoFormulaCantidad
                              : 0)
                          }
                          consumoTotal={
                            otg[0]?.ordenTrabajoCantidad *
                            values?.Formula[index]?.ordenTrabajoFormulaCantidad
                          }
                          permisos={permisos}
                          listUsers={listUsers}
                          unidadMedida={values?.Formula[index]?.unidadMedida}
                        />
                      </>
                    ))}
                  </>
                )}
              </FieldArray>

              <Box sx={{ mt: 1, mb: 2 }}>
                <Button
                  disabled={
                    !verificarPermiso(
                      permisos,
                      PERMISOS.AGREGAR_MATERIALES,
                      listUsers?.find(
                        (item) => item?.radiusIdCodigo === getUser()?.uid
                      )?.idGrupo
                    )
                  }
                  label={"Agregar materiales"}
                  onClick={() => {
                    setShowAgregarMaterial(true);
                  }}
                />
              </Box>
            </Box>
            <Typography sx={{ color: "#797979", mt: 1, ml: 1 }} variant={"h1"}>
              Usuarios
            </Typography>
            <Box
              sx={{
                background: "#E5E5E5",
                py: 2,
                px: 2,
                mt: 0.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  background: "#FFFFFF",
                  py: 2,
                  px: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: 1,
                    minWidth: "500px",
                  }}
                >
                  <FormAddUsersOtg
                    values={values}
                    onClick={(valor) => push("usersOtg", valor)}
                    users={listUsers}
                    permisos={permisos}
                    listUsers={listUsers}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <FieldArray name="usersOtg">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (
                            <Field
                              // disabled={
                              //   !verificarPermiso(
                              //     permisos,
                              //     PERMISOS.SELECCION_Y_AGREGADO_DE_USUARIO
                              //   )
                              // }
                              component={ChipMui as any}
                              name={`${name}.user`}
                              handleDelete={() => {
                                fields.remove(index);
                              }}
                              sx={{ px: 1, py: 1 }}
                            />
                          ))}
                        </>
                      )}
                    </FieldArray>
                  </Box>
                </Box>

                <Box>
                  <Button
                    disabled={
                      !verificarPermiso(
                        permisos,
                        PERMISOS.ACCESO_PANTALLA_INICIO_DE_TRABAJO,
                        listUsers?.find(
                          (item) => item?.radiusIdCodigo === getUser()?.uid
                        )?.idGrupo
                      )
                    }
                    label={"Acceso pantalla inicio de trabajo"}
                    href={`/HorasDeTrabajo/${id}`}
                  />
                </Box>
              </Box>
            </Box>
            <Typography sx={{ color: "#797979", mt: 1, ml: 1 }} variant={"h1"}>
              Horas de trabajo
            </Typography>
            <Box sx={{ background: "#E5E5E5", py: 2, px: 2, mt: 0.5 }}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  height: 400,
                  overflowY: "auto",
                }}
              >
                <FieldArray name="listHorasTrabajo">
                  {({ fields }) => (
                    <>
                      {fields.map((name, index) => (
                        <HorasDeTrabajo
                          name={name}
                          nameUser={values.listHorasTrabajo[index].user}
                          userDescripcion={
                            values.listHorasTrabajo[index].grupoNombre
                          }
                        />
                      ))}
                    </>
                  )}
                </FieldArray>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                px: 1,
                mt: 2,
              }}
            >
              <Button
                label="Cancelar"
                onClick={() => {
                  restart();
                  setShowDeclaraciones(false);
                }}
                sx={{ mr: 2 }}
              />
              <Button
                label="Confirmar"
                onClick={() => {
                  saveDatailsOtgNew({
                    values: values,
                    measures: measures,
                    otg: otg,
                    setAlertCustom: setAlertCustom,
                    restart: () => restart(),
                    refetch: refetch,
                    refetchOtg: refetchOtg,
                    refetchOrdenTrabajoUsuario: refetchOrdenTrabajoUsuario,
                    setShowDeclaraciones: setShowDeclaraciones,
                    id: id,
                    formula: otgFormula,
                    products: products,
                  });
                }}
                sx={{ ml: 2 }}
                color="success"
                disabled={invalid || submitting || pristine}
              />
            </Box>

            <DialogGen
              open={showAgregarMaterial}
              title={"Agregar producto a formula"}
              onClick={() => {
                setShowAgregarMaterial(false);
              }}
              sx={{ width: "100%", maxWidth: "400px" }}
            >
              <FormularioAgregarMaterial
                datosFormPrincipal={values}
                push={(valor) => {
                  let formulaNueva = values?.Formula ? values?.Formula : [];
                  formulaNueva.push(valor);

                  saveDatailsOtg({
                    values: { ...values, Formula: formulaNueva },
                    measures: measures,
                    otg: otg,
                    setAlertCustom: setAlertCustom,
                    restart: () => restart(),
                    refetch: refetch,
                    refetchOtg: refetchOtg,
                    refetchOrdenTrabajoUsuario: refetchOrdenTrabajoUsuario,
                    setShowDeclaraciones: setShowDeclaraciones,
                    id: id,
                  });
                }}
                setShowAgregarMaterial={() => setShowAgregarMaterial(false)}
                products={products}
                otg={otg[0]}
              />
            </DialogGen>
            <AlertCustom
              open={alertCustom.open}
              onclick={() =>
                setAlertCustom({
                  open: false,
                  title: "",
                  severity: "",
                  message: "",
                })
              }
              severity={alertCustom.severity}
              title={alertCustom.title}
              message={alertCustom.message}
            />
          </Box>
        )}
      />
    </Layout>
  );
};
export default DetailsOTG;
