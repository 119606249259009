import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { AlertCustom } from "../../components/AlertMui";
import { AutoComplete } from "../../components/AutoComplete";
import Button from "../../components/Button";
import arrayMutators from "final-form-arrays";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FieldArray } from "react-final-form-arrays";
import { InputDate, InputNumber, InputText } from "../../components/Input";
import { LayoutMobi } from "../../components/Layout";
import {
  useListDeposito,
  useProcesos,
  useProducts,
} from "../../hooks/useProducts";
import { CrearOrdenTrabajo } from "../../services/ordenTrabajo";
import { fechaActual } from "../../utils/Fecha";
import { required } from "../../utils/validations";
import { Loading } from "../loading";
import { useOrdenTrabajoFormula } from "../../hooks/useOrdenTrabajo";
import { CardDetailsOtg } from "../detailsOtgMobi/component/cardDetailsOtg";
import { DialogGen } from "../../components/Dialog";
import { FormularioAgregarMaterial } from "../../components/FormularioAgregarMaterial";
import saveData from "../crearOtg/hook/SaveData";
import { numeroOtg } from "../../utils/numeroOtg";
import { usePermisos } from "../../hooks/auth";
import { useListUsers } from "../../hooks/useUsers";

const CrearOtgMobi = () => {
  const [alertCustom, setAlertCustom] = useState({
    open: false,
    title: "",
    severity: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [showAgregarMaterial, setShowAgregarMaterial] = useState(null);
  const [datosCompleto, setDatosCompleto] = useState(null);
  const [datosOtgApi, setDatosOtgApi] = useState([]);
  const [initialValues, setInitialValue] = useState({});
  const [expanded, setExpanded] = useState(null);
  const { data: products, isLoading, isError } = useProducts();
  const {
    data: procesos,
    isLoading: isLoadingProcesos,
    isError: isErrorProcesos,
  } = useProcesos();
  const {
    data: otgFormula,
    isLoading: isLoadingOtgFormula,
    isError: isErrorOtgFormula,
    refetch,
  } = useOrdenTrabajoFormula(0);
  const {
    data: listDeposito,
    isLoading: isLoadingDeposito,
    isError: isErrorDeposito,
  } = useListDeposito();

  useEffect(() => {
    setDatosCompleto(
      datosOtgApi.map((item) => {
        let newDatos = [];
        otgFormula.map(
          (x) => item.ordenTrabajoId === x.ordenTrabajoId && newDatos.push(x)
        );
        return {
          ...item,
          formula: newDatos,
        };
      })
    );
  }, [otgFormula, datosOtgApi]);
  const {
    data: permisos,
    isLoading: isLoadingPermisos,
    isError: isErrorPermisos,
  } = usePermisos(1);
  const {
    data: listUsers,
    isLoading: loadingListUsers,
    isError: isErrorListUsers,
  } = useListUsers();
  if (
    isLoading ||
    isLoadingOtgFormula ||
    isLoadingProcesos ||
    isLoadingDeposito ||
    loadingListUsers ||
    isLoadingPermisos ||
    loading
  ) {
    return <Loading />;
  }

  if (
    !Array.isArray(products) ||
    !Array.isArray(procesos) ||
    !Array.isArray(otgFormula) ||
    !Array.isArray(listDeposito) ||
    isError ||
    isErrorOtgFormula ||
    isErrorProcesos ||
    isErrorDeposito ||
    isErrorPermisos ||
    isErrorListUsers
  ) {
    <LayoutMobi title={"Hubo un Error"}>
      <Box>
        <Typography variant="h3">
          Nos apena informate que ha ocurrido un error que debemos revisar
        </Typography>
      </Box>
    </LayoutMobi>;
  }

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <LayoutMobi title={"Agregar nueva OTG"} href={"/plan"}>
      <Box sx={{ width: "100%" }}>
        <Form
          onSubmit={(values) => {
            setLoading(true);
            setInitialValue(values);
            CrearOrdenTrabajo({
              skuId: Number(values.sku.id),
              ordenTrabajoCantidad: Number(values.ordenTrabajoCantidad),
              orden_trabajo_elaboracion_fecha: values.fecha,
              ordenTrabajoUnidadMedidaId: products?.find(
                (x) => x.skuId === values?.sku?.id
              )?.unidadMedidaId,
            })
              .then((response) => {
                setLoading(false);
                setDatosOtgApi(response.data);
                refetch();
              })
              .catch((error) => {
                setLoading(false);
                setAlertCustom({
                  open: true,
                  title: "Error",
                  severity: "error",
                  message: error.message,
                });
              });
          }}
          initialValues={{
            cantidad: 0,
            ...initialValues,
            datos: datosCompleto,
          }}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            submitting,
            pristine,
            invalid,
            values,
            form: {
              restart,
              mutators: { push },
            },
          }) => (
            <Box
              sx={{
                m: 1,
                p: 1,
                background: "#FFFFFF",
              }}
            >
              <Field
                disabled={datosOtgApi.length > 0}
                validate={required}
                name="sku"
                component={AutoComplete as any}
                sx={{ maxWidth: "300px" }}
                options={products.map((item) => {
                  return {
                    id: item.skuId,
                    label: item.skuCodigo,
                  };
                })}
              />
              <Box sx={{ mt: 3 }}>
                <Field
                  disabled={datosOtgApi.length > 0}
                  name="nombre"
                  component={InputText as any}
                  label={"Nombre"}
                  sx={{ maxWidth: "300px" }}
                  values={
                    values?.sku?.id
                      ? products.find((item) => values?.sku?.id === item.skuId)
                          ?.skuDescripcion
                      : " "
                  }
                />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "row", mt: 3 }}>
                <Box sx={{ pr: 0.5 }}>
                  <Field
                    disabled={datosOtgApi.length > 0}
                    validate={(valor) =>
                      valor > 0 ? undefined : "No puede ser 0"
                    }
                    name="ordenTrabajoCantidad"
                    label="Cantidad a fabricar"
                    component={InputNumber as any}
                    sx={{ maxWidth: "300px" }}
                  />
                </Box>
                <Box sx={{ pl: 0.5 }}>
                  <Field
                    focused
                    validate={required}
                    disabled={datosOtgApi.length > 0}
                    sx={{ maxWidth: "300px" }}
                    name="fecha"
                    label="Fecha estimada de terminación"
                    component={InputDate as any}
                    min={fechaActual()}
                    defaultValue={fechaActual()}
                  />
                </Box>
              </Box>

              {datosOtgApi?.length !== 0 && (
                <>
                  <Typography
                    sx={{ color: "#797979", mt: 2, ml: 1, mb: 2 }}
                    variant={"h1"}
                  >
                    Ordenes de trabajo generadas
                  </Typography>
                  <FieldArray name="datos">
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <>
                          <Accordion
                            expanded={expanded === `panel${index}`}
                            onChange={handleChange(`panel${index}`)}
                            onClick={() => {}}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography variant="h6">{`Codigo: ${
                                values.datos[index].skuCodigo
                              } || Otg n° : ${numeroOtg(
                                Number(values.datos[index].ordenTrabajoId)
                              )}`}</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                border: "1px solid #C1C1C1",
                                boxShadow: "1px 1px 1px 1px rgba(0,0,0, 0.05)",
                              }}
                            >
                              {datosOtgApi?.length !== 0 && (
                                <>
                                  <FieldArray name={`${name}.formula`}>
                                    {({ fields }) =>
                                      fields.map((name1, index1) => (
                                        <>
                                          <CardDetailsOtg
                                            name={
                                              values.datos[index].formula[
                                                index1
                                              ].skuDescripcion
                                            }
                                            nameField={name1}
                                            consumoActual={
                                              Number(
                                                values.datos[index]
                                                  ?.ordenTrabajoCantidadProducida *
                                                  values.datos[index].formula[
                                                    index1
                                                  ]?.ordenTrabajoFormulaCantidad
                                              ) +
                                              (Number(
                                                values.datos[index].cantidad
                                              )
                                                ? Number(
                                                    values.datos[index].cantidad
                                                  ) *
                                                  values.datos[index].formula[
                                                    index1
                                                  ]?.ordenTrabajoFormulaCantidad
                                                : 0)
                                            }
                                            consumoTotal={
                                              values.datos[index]
                                                ?.ordenTrabajoCantidad *
                                              values.datos[index].formula[
                                                index1
                                              ]?.ordenTrabajoFormulaCantidad
                                            }
                                            permisos={permisos}
                                            listUsers={listUsers}
                                          />
                                        </>
                                      ))
                                    }
                                  </FieldArray>

                                  <Box sx={{ mt: 1, mb: 2 }}>
                                    <Button
                                      label={"Agregar materiales"}
                                      onClick={() =>
                                        setShowAgregarMaterial(index)
                                      }
                                    />
                                  </Box>

                                  <DialogGen
                                    open={showAgregarMaterial === index}
                                    title={"Agregar producto a formula"}
                                    onClick={() => {
                                      setShowAgregarMaterial(null);
                                    }}
                                    sx={{
                                      width: "100%",
                                      maxWidth: "400px",
                                    }}
                                  >
                                    <FormularioAgregarMaterial
                                      datosFormPrincipal={values}
                                      nameKeyArray={"datos"}
                                      push={(valor) => {
                                        push(`${name}.formula`, valor);
                                      }}
                                      setShowAgregarMaterial={(valor) =>
                                        setShowAgregarMaterial(valor)
                                      }
                                      products={products}
                                      otg={values.datos[index]}
                                    />
                                  </DialogGen>
                                </>
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </>
                      ))
                    }
                  </FieldArray>
                </>
              )}
              {datosOtgApi.length > 0 && (
                <Box sx={{ mt: 3 }}>
                  <Button
                    label="Guarda Formula"
                    color="success"
                    sx={{ width: "100%", maxWidth: "300px" }}
                    onClick={() =>
                      saveData({
                        datos: values,
                        setAlertCustom: setAlertCustom,
                        restart: restart,
                        refetch: refetch,
                        setDatosOtgApi: setDatosOtgApi,
                        setInitialValue: setInitialValue,
                      })
                    }
                    disabled={submitting || invalid}
                  />
                </Box>
              )}
              {datosOtgApi.length === 0 && (
                <Box sx={{ mt: 3 }}>
                  <Button
                    label="Crear OTG"
                    color="success"
                    sx={{ width: "100%", maxWidth: "300px" }}
                    onClick={handleSubmit}
                    disabled={pristine || submitting || invalid}
                  />
                </Box>
              )}

              <AlertCustom
                open={alertCustom.open}
                onclick={() =>
                  setAlertCustom({
                    open: false,
                    title: "",
                    severity: "",
                    message: "",
                  })
                }
                severity={alertCustom.severity}
                title={alertCustom.title}
                message={alertCustom.message}
              />
            </Box>
          )}
        />
      </Box>
    </LayoutMobi>
  );
};
export default CrearOtgMobi;
